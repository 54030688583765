import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { Container } from '@material-ui/core';

import Header from './components/Header';
import Orders from './pages/Orders';
import PrivateRoute from './components/shared/PrivateRoute';

// App state
import GlobalDate from './store/globalDate';
import GlobalSearch from './store/globalSearch';
import PrintTransaction from './pages/PrintTransaction';
import PrintCustomerRecipe from './pages/PrintCustomerRecipe';
import Availabilities from './pages/Availabilities';
import { useAuth0 } from '@auth0/auth0-react';
import Loader from './components/shared/Loader';
import Products from './pages/Products';
import Seasons from './pages/Seasons';
import GlobalLocation from './store/globalLocation';
import PrintBookingLabel from './pages/PrintBookingLabel';
import Locations from './pages/Locations';

const App: React.FC = () => {
  const { isLoading } = useAuth0();

  if (isLoading) return <Loader />;

  return (
    <>
      <GlobalDate.Provider>
        <GlobalSearch.Provider>
          <GlobalLocation.Provider>
            <Router>
              <Header />
              <Container maxWidth={false}>
                <Switch>
                  <PrivateRoute path='/print-transaction/:transactionId' component={PrintTransaction} />
                  <PrivateRoute path='/print-booking-label/:bookingId' component={PrintBookingLabel} />
                  <PrivateRoute path='/print-customer-recipe/:transactionId' component={PrintCustomerRecipe} />
                  <PrivateRoute path='/availabilities' component={Availabilities} />
                  <PrivateRoute path='/products' component={Products} />
                  <PrivateRoute path='/seasons' component={Seasons} />
                  <PrivateRoute path='/locations' component={Locations} />
                  <PrivateRoute path='/' component={Orders} />
                </Switch>
              </Container>
            </Router>
          </GlobalLocation.Provider>
        </GlobalSearch.Provider>
      </GlobalDate.Provider>
    </>
  );
};

export default App;
