import React, { ReactElement } from 'react';
import { getMainDefinition } from '@apollo/client/utilities';
import { useAuth0 } from '@auth0/auth0-react';
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  DefaultOptions,
  HttpOptions,
  InMemoryCache,
  split,
} from '@apollo/client';
import { WebSocketLink, WebSocketParams } from '@apollo/client/link/ws';
import { setContext } from '@apollo/client/link/context';

interface Definition {
  kind: string;
  operation?: string;
}

interface ApolloHeaders {
  Authorization: string;
}

interface ApolloWrapperProps {
  children: ReactElement;
}

const ApolloWrapper: React.FC<ApolloWrapperProps> = ({ children }) => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  const getHeaders = async () => {
    const headers = {} as ApolloHeaders;
    if (isAuthenticated) {
      const token: string = await getAccessTokenSilently();
      headers.Authorization = `Bearer ${token}`;
    }

    return headers;
  };

  const authMiddleware = setContext(async (operation, { originalHeaders }) => {
    return {
      headers: {
        ...originalHeaders,
        ...(await getHeaders()),
      },
    };
  });

  const httpLinkOptions: HttpOptions = {
    uri: process.env.REACT_APP_API_URL_HTTP,
  };

  const wsLinkOptions: WebSocketParams = {
    uri: process.env.REACT_APP_API_URL_WEBSOCKET || '',
    options: {
      reconnect: true,
      lazy: true,
      connectionParams: async () => {
        console.log('websocket headers',  await getHeaders())
        return { headers: await getHeaders() };
      },
    },
  };

  const httpLink = createHttpLink(httpLinkOptions);
  const wsLink = new WebSocketLink(wsLinkOptions);

  const link = split(
    // split based on operation type
    ({ query }) => {
      const { kind, operation }: Definition = getMainDefinition(query);
      return kind === 'OperationDefinition' && operation === 'subscription';
    },
    authMiddleware.concat(wsLink),
    authMiddleware.concat(httpLink),
  );

  /* Set up local cache */
  const cache = new InMemoryCache();

  const defaultOptions: DefaultOptions = {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  };

  /* Create Apollo Client */
  const client = new ApolloClient({ link, cache, defaultOptions });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default ApolloWrapper;
