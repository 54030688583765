import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle, Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Slider,
} from '@material-ui/core';

import AvailableIcon from '@material-ui/icons/Check';
import UnknownIcon from '@material-ui/icons/BatteryUnknown';
import ErrorIcon from '@material-ui/icons/Error';
import * as dateFns from 'date-fns';

import {
  ObserveOrderSubscription,
  Orders,
  useGetAvailabilitiesForDateRangeQuery,
} from '../api/generated';
import { Alert } from '@material-ui/lab';

type AlertModalProps = {
  onCancel: () => void,
  onAccept: (newEndDate: string) => void,
  order: ObserveOrderSubscription['orders_by_pk'] //{
  //   __typename?: "orders" | undefined;
  // } & Pick<Orders, "id" | "bookings" | "verified" | "duration" | "startDate" | "created_at" | "updated_at" | "confirmation_sent_at" | "verification_sent_at" | "cancellation_sent_at">
};

export default function OrderProlongForm({
                                           onCancel,
                                           onAccept,
                                           order
                                         }: AlertModalProps) {
  const [daysToProlong, setDaysToProlong] = React.useState(0);

  const productIds = order?.bookings.map(b => b.product.id);
  const duration = parseInt(order?.duration ?? '0');

  // Negative values are allowed to reduce order duration
  const daysToAdd = duration - 1;

  const currentOrderEndDate = dateFns.addDays(dateFns.parseISO(order?.startDate), daysToAdd);
  const currentOrderStartDate = order?.startDate;

  const tomorrow = dateFns.addDays(currentOrderEndDate, 1);
  const prolongEnd = dateFns.addDays(currentOrderEndDate, daysToProlong);
  const { data: availabilitiesData, loading: availabilitiesLoading } = useGetAvailabilitiesForDateRangeQuery({
    initialFetchPolicy: 'network-only',
    variables: {
      productIds: productIds,
      startDate: dateFns.format(tomorrow, 'yyyy-MM-dd'),
      endDate: dateFns.format(prolongEnd, 'yyyy-MM-dd'),
    }
  });

  if (availabilitiesLoading) <>Loading</>;

  const availabilities = availabilitiesData?.snapshotAvailabilities.map(snapshot => ({
    productId: snapshot.productId,
    date: snapshot.date,
    availableBikes: snapshot.availableBikes,
  }))

  const productAvailabilities: Array<{ bookingId: string, canBeProlonged: boolean }> = [];

  for (const booking of order?.bookings ?? []) {
    const productAvailability = availabilities?.filter(a => a.productId === booking.product.id);
    const daysAvailable = productAvailability?.filter(p => p.availableBikes > 0);
    const isAvailable = daysAvailable?.length === productAvailability?.length;

    productAvailabilities.push({
      bookingId: booking.id,
      canBeProlonged: isAvailable
    });
  }

  const canBeProlonged = productAvailabilities.filter(a => a.canBeProlonged).length == order?.bookings.length;

  const handleSliderChange = async (event: unknown, value: number | number[]): Promise<void> => {
    const days = Array.isArray(value) ? value[0] : value;

    setDaysToProlong(days);
  };

  const minDateInPast = new Date() < dateFns.parseISO(currentOrderStartDate) ? dateFns.parseISO(currentOrderStartDate) : new Date();
  const maxDurationInPast = dateFns.differenceInCalendarDays(minDateInPast,currentOrderEndDate);

  return (
    <>
      <Dialog
        open={true}
        onClose={() => onCancel()}
      >
        <DialogTitle>Buchungsdauer ändern</DialogTitle>

        <DialogContent style={{height: '500px', width: '500px', maxWidth: '500px;'}}>
          <DialogContentText>
            Um wie viele Tage soll die Buchung verändert werden?
            <br /><br />
          </DialogContentText>

          <Grid container spacing={4}>
            <Grid item xs={8} style={{padding: '2em'}}>
              <Slider
                color={'secondary'}
                defaultValue={0}
                step={1}
                onChange={handleSliderChange}
                marks
                min={maxDurationInPast}
                max={7}
                valueLabelDisplay="on"
              />
            </Grid>
            <Grid item xs={4}>
              Neues Enddatum: {dateFns.format(prolongEnd, 'dd.MM.yyyy')}
            </Grid>
          </Grid>



          <DialogContentText>
            <strong>Buchungen</strong>
          </DialogContentText>
          <List component="nav" dense={true}>
            {order?.bookings.map(booking => {
              const isAvailable = productAvailabilities.find(b => b.bookingId == booking.id)?.canBeProlonged === true ?? false;

              return (
                <ListItem key={booking.id}>
                  <ListItemIcon>
                    {availabilitiesLoading ? <UnknownIcon /> : isAvailable ? <AvailableIcon color={'primary'} /> :
                      <ErrorIcon color={'error'} />}
                  </ListItemIcon>
                  <ListItemText
                    primary={`${booking.product.productType.name} (${booking.product.size}) ${booking.riderName ? ` [${booking.riderName}]` : ''}`} />
                </ListItem>
              )
            })}
          </List>

          {!canBeProlonged && (
            <Alert severity={'error'}>
              Die Buchung kann nicht verlängert werden, <br/>
              da es ansonsten zu einer Überbuchung kommen würde.
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} color="default" variant={'contained'}>
            Abbrechen
          </Button>

          <Button
            onClick={() => onAccept(dateFns.format(prolongEnd, 'YYY-MM-dd'))}
            color="primary"
            variant={'contained'}
            autoFocus
            disabled={availabilitiesLoading || !canBeProlonged}
          >
            Buchungsdauer ändern
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
