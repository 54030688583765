import { useGlobalStyles } from '../styles/global';
import {
  Box,
  Button,
  createStyles,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Tooltip,
} from '@material-ui/core';
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PlusIcon from '@material-ui/icons/AddCircle';
import {
  ExtraRecipeLines,
  useDeleteExtraRecipeLineMutation, useUpdateOrderUpdateDateMutation,
  useUpsertExtraRecipeLineMutation,
} from '../api/generated';
import ExtraRecipeLine, { ModeEnum } from './ExtraRecipeLine';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    icon: {},
    priceCol: {
      textAlign: 'right',
      whiteSpace: 'nowrap',
    },
    content: {
      flexGrow: 1,
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
    table: {
      marginBottom: '2em',
    },
  }),
);

type ExtraRecipesProps = {
  orderId: string;
  extraRecipeLines?: Partial<ExtraRecipeLines>[];
  selectedLines?: string[];
  onSelectLine: (lineId: string, selected: boolean) => void;
};

export default function ExtraRecipes({
  orderId,
  extraRecipeLines,
  selectedLines,
  onSelectLine,
}: ExtraRecipesProps) {
  const classes = {
    ...useGlobalStyles(),
    ...useStyles(),
  };

  const [
    upsertExtraRecipeLine,
    {
      loading: upsertExtraRecipeLineLoading,
      error: upsertExtraRecipeLineError,
    },
  ] = useUpsertExtraRecipeLineMutation();

  const [
    deleteExtraRecipeLine,
    {
      loading: deleteExtraRecipeLineLoading,
      error: deleteExtraRecipeLineError,
    },
  ] = useDeleteExtraRecipeLineMutation();

  const [
    triggerUpdateOrderUpdateDateMutation,
    { loading: triggerUpdateOrderUpdateDateLoading, error: triggerUpdateOrderUpdateDateError },
  ] = useUpdateOrderUpdateDateMutation();

  const [showLineForm, setShowLineForm] = useState(false);

  const handleSaveLine = async (
    line: Partial<ExtraRecipeLines>,
  ): Promise<void> => {
    await upsertExtraRecipeLine({
      variables: {
        line: [
          {
            id: line?.id,
            amount: (line?.amount ?? 0.0) * 100 * 100,
            description: line?.description,
            status: line?.status ?? 'new',
            orderId: orderId,
          },
        ],
      },
    });

    await triggerUpdateOrderUpdateDateMutation({variables: {id: orderId}});

    setShowLineForm(false);

    return;
  };

  const handleDeleteLine = async (lineId: string): Promise<void> => {
    await deleteExtraRecipeLine({ variables: { id: lineId } });

    await triggerUpdateOrderUpdateDateMutation({variables: {id: orderId}});

    return;
  };

  const handleSelectLine = (id: string, selected: boolean): void => {
    onSelectLine(id, selected);
  };

  return (
    <>
      <Typography variant='h3' gutterBottom className={classes.content}>
        <span>Freie Buchungszeilen</span>
        <Tooltip title='Rabatt oder Zuschlag hinzufügen'>
          <Button
            color={'primary'}
            aria-label='Add discount and surcharges'
            onClick={() => setShowLineForm(true)}
          >
            <PlusIcon color={'primary'} />
          </Button>
        </Tooltip>
      </Typography>

      <Divider />

      <Box className={classes.content}>
        <TableContainer className={classes.table}>
          <Table stickyHeader size='small'>
            <TableHead>
              <TableRow>
                <TableCell align={'center'} style={{ width: '10%' }}>
                  Status
                </TableCell>

                <TableCell align={'left'}>Beschreibung</TableCell>

                <TableCell align={'center'} style={{ width: '20%' }}>
                  Aktionen
                </TableCell>

                <TableCell align={'right'} style={{ width: '20%' }}>
                  Betrag
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {extraRecipeLines?.map(extraRecipeLine => {
                return (
                  <ExtraRecipeLine
                    key={extraRecipeLine.id}
                    selected={
                      selectedLines?.includes(extraRecipeLine.id) ?? false
                    }
                    initialLine={extraRecipeLine}
                    mode={ModeEnum.DISPLAY}
                    onDelete={handleDeleteLine}
                    onUpdate={handleSaveLine}
                    onSelect={handleSelectLine}
                    onCancel={() => void 0}
                  />
                );
              })}

              {showLineForm && (
                <ExtraRecipeLine
                  mode={ModeEnum.INSERT}
                  selected={false}
                  initialLine={{}}
                  onDelete={handleDeleteLine}
                  onUpdate={handleSaveLine}
                  onCancel={() => setShowLineForm(false)}
                  onSelect={() => void 0}
                />
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
}
