import React, { ReactElement } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';

type AlertModalProps = {
  alertContent: ReactElement,
  alertTitle?: string,
  entityId: string,
  onCancel: (entityId?: string) => void,
  onAccept: (entityId: string, primaryAction: boolean) => void,
};

export default function CancellationAlertModal({
  onCancel,
  onAccept,
  alertContent,
  alertTitle,
  entityId
}: AlertModalProps) {
  return (
    <>
      <Dialog
        open={true}
        onClose={() => onCancel(entityId)}
      >
        {alertTitle && (
          <DialogTitle>{alertTitle}</DialogTitle>
        )}

        <DialogContent>
          <DialogContentText>
            {alertContent}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onCancel(entityId)} color="default" variant={'contained'}>
            Abbrechen
          </Button>
          <Button onClick={() => onAccept(entityId, false)} color="secondary" variant={'contained'}>
            Nur Stornieren ohne E-Mail
          </Button>
          <Button onClick={() => onAccept(entityId, true)} color="primary" variant={'contained'} autoFocus>
            Stornieren mit E-Mail
          </Button>

        </DialogActions>
      </Dialog>
    </>
  );
}
