import React, { ChangeEvent, FC, ReactElement, useState } from 'react';
import {
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Hidden,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { IProductType } from './types';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useGlobalStyles } from '../../styles/global';
import ProductForm from './ProductForm';
import { useGetProductTypeModelsQuery } from '../../api/generated';
import Loader from '../shared/Loader';
import Error from '../shared/Error';

export interface IProductTypeFormProps {
  data: IProductType;
  onSubmit: (productType: IProductType) => void;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useStyles = makeStyles(() =>
  createStyles({
    form: {
      '& > div': {
        marginBottom: '1em',
      },
    },
  }),
);

const ProductTypeForm: FC<IProductTypeFormProps> = ({
  data,
  onSubmit,
}): ReactElement => {
  /**
   * Styles
   */
  const classes = {
    ...useGlobalStyles(),
    ...useStyles(),
  };

  /** States */
  const productTypeDefaults: IProductType = {
    id: data.id,
    productTypeModelId: data.productTypeModelId,
    category: data.category,
    description: data.description,
    image: data.image,
    name: data.name,
    sortPosition: data.sortPosition,
    forceInsurance: data.forceInsurance
  };
  const [productType, setProductType] = useState<IProductType>(productTypeDefaults);

  /**
   * GraphQL
   */
  const {
    data: productTypeModelsData,
    loading: productTypeModelsLoading,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    error: productTypeModelsError,
  } = useGetProductTypeModelsQuery();

  /** Handlers */
  const handleProductTypeUpdate = async (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): Promise<void> => {
    const field = e.currentTarget.id;

    let value = e.currentTarget.value;

    if (!value) {
      value = e.currentTarget.checked;
    }

    if ('' === field) {
      return;
    }

    setProductType({
      ...productType,
      [field]: value,
    });
  };

  const handleProductTypeModelUpdate = async (e: ChangeEvent<HTMLSelectElement>): Promise<void> => {
    const modelId = e.target.value;

    setProductType({
      ...productType,
      productTypeModelId: modelId
    });
  };

  const handleSubmit = async (): Promise<void> => {
    await onSubmit(productType);
  }

  if (productTypeModelsError) {
    return <Error error={productTypeModelsError} />
  }

  if (productTypeModelsLoading) {
    return <Loader />;
  }

  return (
    <>
      <Typography variant={'h3'}>Produkttyp anlegen</Typography>
      <div className={classes.form}>
        <Hidden xsUp>
          <Input
            type={'hidden'}
            id={'id'}
            value={productType.id}
            onChange={handleProductTypeUpdate}
            fullWidth
          />
        </Hidden>

        <FormControl fullWidth>
          <InputLabel id="productTypeModelId">Art des Produkttyps</InputLabel>
          <Select
            id={'productTypeModelId'}
            value={productType.productTypeModelId ?? ''}
            onChange={handleProductTypeModelUpdate}
            disabled={!!productType.id}
            fullWidth
          >
            {productTypeModelsData?.productTypeModels
              && productTypeModelsData?.productTypeModels.map(model => {
                return (
                  <MenuItem
                    key={model.id}
                    value={model.id}
                    selected={data.productTypeModelId ? (model.id === data.productTypeModelId) : (model.id === 'configurable')}
                  >
                    {model.name}
                  </MenuItem>
                )
              }
            )}

          </Select>
        </FormControl>

        {productType.productTypeModelId && (
          <>
            <TextField
              id={'name'}
              value={productType.name}
              onChange={handleProductTypeUpdate}
              placeholder='ACME Sprinter 29"'
              label='Name des Produkttyps'
              fullWidth
            />

            <TextField
              id={'category'}
              value={productType.category}
              onChange={handleProductTypeUpdate}
              placeholder='Downhill'
              label='Name der Kategorie'
              fullWidth
            />

            <TextField
              id={'image'}
              value={productType.image}
              onChange={handleProductTypeUpdate}
              placeholder='https://via.placeholder.com/150.png'
              label='URL des Produktbildes'
              fullWidth
            />

            <TextField
              id={'description'}
              value={productType.description}
              onChange={handleProductTypeUpdate}
              label='Beschreibung des Produkttyps'
              fullWidth
              multiline
            />

            <TextField
              id={'sortPosition'}
              value={productType.sortPosition}
              onChange={handleProductTypeUpdate}
              label='Position (Sortierung)'
              fullWidth
              multiline
            />

            <FormControl fullWidth>
              <FormControlLabel
                control={
                  <Checkbox
                    id={'forceInsurance'}
                    name={'forceInsurance'}
                    checked={productType.forceInsurance ?? false}
                    onChange={handleProductTypeUpdate}
                  />
                }
                label="Versicherung im Preis inkludiert."
              />
            </FormControl>

            <Button
              type='button'
              // disabled={isSubmitting}
              color={'primary'}
              variant={'contained'}
              onClick={handleSubmit}
            >
              Produkttyp speichern
            </Button>
          </>
        )}
      </div>

      {(/*productType.productTypeModelId === 'configurable' &&*/ productType.id) && (
        <>
          <Divider className={classes.divider} />
          <ProductForm
            productTypeId={productType.id}
          />
        </>
      )}

    </>
  );
};

export default ProductTypeForm;
