import React, { SyntheticEvent } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
} from '@material-ui/core';

import * as dateFns from 'date-fns';
import { de } from 'date-fns/locale';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

type AlertModalProps = {
  onCancel: () => void,
  onAccept: (locationId: string, startDate: string, endDate: string, title?: string, message?: string) => void,
  locationId: string,
};

export default function AddLocationMessageDialog({ onCancel, onAccept, locationId }: AlertModalProps) {
  const [title, setTitle] = React.useState<string | undefined>();
  const [message, setMessage] = React.useState<string | undefined>();
  const [startDate, setStartDate] = React.useState<Date>(new Date());
  const [endDate, setEndDate] = React.useState<Date>(new Date());

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const title = e.currentTarget.value;

    setTitle(title)
  }

  const handleMessageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const message = e.currentTarget.value;

    setMessage(message);
  }

  const handleStartDateChange = (date: MaterialUiPickersDate) => {
    setStartDate(date ?? new Date());
  }

  const handleEndDateChange = (date: MaterialUiPickersDate) => {
    setEndDate(date ?? new Date());
  }

  const handleAccept = () => {
    const startDateString = dateFns.format(startDate, 'yyy-MM-dd');
    const endDateString = dateFns.format(endDate, 'yyy-MM-dd');

    onAccept(locationId, startDateString, endDateString, title, message);
  }


  return (
    <>
      <Dialog
        open={true}
        onClose={() => onCancel()}
      >
        <DialogTitle>Nachricht für Kunden hinzufügen</DialogTitle>

        <DialogContent>
          <DialogContentText>
            Diese Nachricht wird den Kunden angezeigt, wenn sie bei einer Buchung diese Location ausgewählt haben.
          </DialogContentText>
        </DialogContent>
        <DialogContent>
          <TextField
            value={title}
            label="Titel"
            variant="outlined"
            fullWidth
            placeholder={'Achtung: Großveranstaltung'}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handleTitleChange}
          />
        </DialogContent>

        <DialogContent>
          <TextField
            value={message}
            label="Nachricht"
            variant="outlined"
            fullWidth
            multiline
            placeholder={'Vom 21.07. - 28.07. findet die WM statt und ist daher mit Einschränkungen und großem Andrang zu rechnen.'}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handleMessageChange}
          />
        </DialogContent>

        <DialogContent>
          <Grid container>
            <Grid item xs={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={de}>
                <DatePicker 
                  onChange={handleStartDateChange}
                  value={startDate}
                  label="Startdatum für Anzeige"
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={de}>
                <DatePicker 
                  onChange={handleEndDateChange}
                  value={endDate}
                  label="Enddatum für Anzeige"
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button onClick={onCancel} color="default" variant={'contained'}>
            Abbrechen
          </Button>

          <Button
            onClick={handleAccept}
            color="primary"
            variant={'contained'}
            autoFocus
            disabled={!(message || title)}
          >
            Nachricht speichern
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
