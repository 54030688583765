import React, { FC, ReactElement } from 'react';
import { useGlobalStyles } from '../styles/global';
import {Typography } from '@material-ui/core';
import LocationsList from '../components/locations/LocationsList';
import { useObserveLocationsSubscription } from '../api/generated';

const Locations: FC = (): ReactElement => {
  /**
   * Styles
   */
  const classes = {
    ...useGlobalStyles(),
  };

  /**
   * GraphQL
   */
  const {
    data: locationsData,
  } = useObserveLocationsSubscription();

    const locationListProps = {
      data: locationsData?.locations ?? [],
    }

    return (
      <>
        <main className={classes.content}>
          <Typography variant={'h1'}>Locations verwalten</Typography>

          <LocationsList {...locationListProps} />
        </main>
      </>
    );
  }

  export default Locations;