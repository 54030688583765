import React, { useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Button, Dialog, Drawer, Grid, Typography } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import MaterialTable, { MTableBodyRow } from '@material-table/core';

import { useGlobalStyles } from '../styles/global';
import Order from '../components/Order';
import {
  ObserveOrdersSubscriptionResult,
  useObserveOrdersSubscription
} from '../api/generated';
import GlobalDate from '../store/globalDate';
import GlobalSearch from '../store/globalSearch';
import {
  format,
  parseISO,
  differenceInHours,
  differenceInMinutes,
  isToday,
  subDays,
} from 'date-fns';
import { INTERNAL_DATE_FORMAT } from '../helpers/date';
import Loader from '../components/shared/Loader';
import GlobalLocation from '../store/globalLocation';
import Iframe from 'react-iframe';
import PrintIcon from '@material-ui/icons/Print';
import PlusIcon from '@material-ui/icons/AddCircle';

function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

// @todo: From configuration
const BOOKING_MAX_DAYS = 14;

const DRAWER_WIDTH = '60vw';
const DRAWER_MAX_WIDTH = '1200px';
const DRAWER_MIN_WIDTH = '600px';

enum BookingStatusEnum {
  NEW = 'new',
  CANCELLED = 'cancelled',
  SHIPPED = 'shipped',
  RETURNED = 'returned',
  OVERDUE = 'overdue',
  EXCEEDED = 'exceeded',
}

enum OrderStatusEnum {
  // All reservations are unprocessed
  ALL_NEW = 'ALL_NEW',
  // All reservations are cancelled
  ALL_CANCELLED = 'ALL_CANCELLED',
  // All products have been handed out to customer
  ALL_SHIPPED = 'ALL_SHIPPED',
  // All products have been returned by the customer
  ALL_RETURNED = 'ALL_RETURNED',
  // At least one product was not collected by the customer
  OVERDUE = 'OVERDUE',
  // At least one product should have been already returned by the customer
  EXCEEDED = 'EXCEEDED',
  // None of the above states apply to order
  PENDING = 'PENDING',
}

const statusTranslations = {
  // All reservations are unprocessed
  ALL_NEW: 'Neu',
  // All reservations are cancelled
  ALL_CANCELLED: 'Storniert',
  // All products have been handed out to customer
  ALL_SHIPPED: 'Ausgegeben',
  // All products have been returned by the customer
  ALL_RETURNED: 'Zurückgebracht',
  // At least one product was not collected by the customer
  OVERDUE: 'Überfällige Abholung',
  // At least one product should have been already returned by the customer
  EXCEEDED: 'Überfällige Rückgabe',
  // None of the above states apply to order
  PENDING: 'Laufend...',
};

interface IStartDatesFilter {
  _and: [
    { startDate: { _eq: string } },
    { duration: { _in: string[] } | { _gt: string } },
  ];
}

const calculateOrderStatus = (
  bookingStates: BookingStatusEnum[],
): OrderStatusEnum => {
  // Check if order has an "ALL_*" status
  const uniqueBookingStates = new Set(bookingStates);
  const uniqueBookingStatesWithoutCancelled = new Set(bookingStates);
  uniqueBookingStatesWithoutCancelled.delete(BookingStatusEnum.CANCELLED);

  // If all bookings share exactly one state, return corresponding OrderState
  if (uniqueBookingStates.size === 1) {
    if (uniqueBookingStates.has(BookingStatusEnum.NEW)) {
      return OrderStatusEnum.ALL_NEW;
    }

    if (uniqueBookingStates.has(BookingStatusEnum.CANCELLED)) {
      return OrderStatusEnum.ALL_CANCELLED;
    }
  }

  if (uniqueBookingStatesWithoutCancelled.size === 1) {
    if (uniqueBookingStatesWithoutCancelled.has(BookingStatusEnum.SHIPPED)) {
      return OrderStatusEnum.ALL_SHIPPED;
    }

    if (uniqueBookingStatesWithoutCancelled.has(BookingStatusEnum.RETURNED)) {
      return OrderStatusEnum.ALL_RETURNED;
    }
  }

  /* Check if at least one booking is overdue.
   * If that is the case Order becomes overdue immediately because here the admin has to take an action
   * E.g. call the customer, cancel the reservation, ...
   */
  const hasOverdueBooking =
    bookingStates.find(state => state === BookingStatusEnum.OVERDUE) ?? false;

  if (hasOverdueBooking) {
    return OrderStatusEnum.OVERDUE;
  }

  /* Check if at least one booking is exceeded (i.e. should have been already returned).
   * If that is the case Order becomes exceeded immediately because here the admin has to take an action
   * E.g. call the customer, add fees
   */
  const hasExceededBooking =
    bookingStates.find(state => state === BookingStatusEnum.EXCEEDED) ?? false;

  if (hasExceededBooking) {
    return OrderStatusEnum.EXCEEDED;
  }

  // In any other case the booking is indicated as in progress.
  // No special action has to be taken by admin.
  return OrderStatusEnum.PENDING;
};

/**
 *
 * @param daysInPast
 * @param max
 */
const generateDurationArray = (daysInPast: number, max: number): string[] => {
  const length = max - daysInPast;
  return Array.from(Array(length), (_, x) => max - x)?.map(n =>
    n.toFixed(0),
  );
};

/**
 * Generates a filter for GraphQL which returns a Hasura filter to return all bookings from current day and
 * all multi-day bookings from past which are still valid on given date.
 */
const generateStartDatesFilter = (startDate: string): IStartDatesFilter[] => {
  const startDatesFilter: IStartDatesFilter[] = [];

  // entry for current day (Includes half-day bookings)
  const currentDay: IStartDatesFilter = {
    _and: [
      { startDate: { _eq: startDate } },
      { duration: { _in: ['0.5', ...generateDurationArray(0, BOOKING_MAX_DAYS)] } },
    ],
  };

  startDatesFilter.push(currentDay);

  // entries for bookings in past which are still valid
  let daysInPast = 0;

  while (daysInPast < BOOKING_MAX_DAYS - 1) {
    daysInPast++;

    const dayInPast = subDays(parseISO(startDate), daysInPast);
    const durationArray = generateDurationArray(daysInPast, BOOKING_MAX_DAYS);
    const pastDayFilter: IStartDatesFilter = {
      _and: [
        { startDate: { _eq: format(dayInPast, INTERNAL_DATE_FORMAT) } },
        { duration: { _in: durationArray } }, // (daysInPast + 1).toFixed(0)
      ],
    };

    startDatesFilter.push(pastDayFilter);
  }

  return startDatesFilter;
};

export default function Orders() {
  // Global
  const globalDate = GlobalDate.useContainer();
  const orderFilterDate = generateStartDatesFilter(globalDate.date);

  const globalSearch = GlobalSearch.useContainer();
  const orderSearchTerm =
    globalSearch.term === '' ? '%' : `%${globalSearch.term}%`;

  const globalLocation = GlobalLocation.useContainer();

  // Styles
  const classes = {
    ...useGlobalStyles(),
    ...useStyles(),
  };

  const isTabletOrLower = useMediaQuery((theme: any) => theme.breakpoints.down('md'));

  // Local states
  const [activeOrder, setActiveOrder] = useState<any | null>(null);
  const [isNewBookingOpen, setIsNewBookingOpen] = useState<boolean>(false);

  // Handlers
  const handleSelectOrder = (orderId: string) => {
    const order = orders.find(order => order.id === orderId);
    setActiveOrder(order);
  };

  // GraphQL
  const { data, loading, error } = useObserveOrdersSubscription({
    variables: {
      locationId: globalLocation.locationId,
      startDatesFilter: orderFilterDate,
      searchTerm: orderSearchTerm,
    },
  });

  // GraphQL data
  const orders = data?.orders ?? [];

  if (loading) return <Loader />;
  if (error) return <p>Error :(</p>;

  const tableRow = (props: any) => {
    const rowClass = props?.data?.status ?? 'default';

    return (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      <MTableBodyRow {...props} className={classes['bookingRow-' + rowClass]} />
    );
  };

  const ordersData = orders.map(order => {
    const bookingStates = order?.bookings?.map(
      (b): BookingStatusEnum => {
        const todayElevenThirty = new Date();
        const isOverdue =
          differenceInMinutes(new Date(), todayElevenThirty) > 0;
        const diffHoursExceeded = differenceInHours(
          new Date(),
          parseISO(b.updated_at),
        );
        const isExceeded =
          order.duration === '0.5' && diffHoursExceeded > 4;

        switch (b.status) {
          case 'new':
            // check if overdue
            todayElevenThirty.setHours(11);
            todayElevenThirty.setMinutes(30);

            if (isToday(parseISO(order.startDate)) && isOverdue) {
              return BookingStatusEnum.OVERDUE;
            }

            return BookingStatusEnum.NEW;
          case 'cancelled':
            return BookingStatusEnum.CANCELLED;
          case 'shipped':
            // check if overdue

            if (isExceeded) {
              return BookingStatusEnum.EXCEEDED;
            }

            return BookingStatusEnum.SHIPPED;
          case 'returned':
            return BookingStatusEnum.RETURNED;
          // case 'overdue':
          //   return BookingStatusEnum.OVERDUE;
          //   break;
          // case 'exceeded':
          //   return BookingStatusEnum.EXCEEDED;
          //   break;
          // default:
          //   return false
        }

        // @todo: What should be the default if no option above is true? undefined?
        return BookingStatusEnum.NEW;
      },
    );

    const orderStatus = calculateOrderStatus(bookingStates);

    return {
      verified: (order.verified ? <>✓</> : <></>),
      id: order.id,
      created_at: format(parseISO(order.created_at), 'Y-MM-dd H:mm:ss'),
      start_date: format(parseISO(order.startDate), 'Y-MM-dd'),
      duration: order.duration,
      amount_products: order.bookings.length,
      amount_protection: order.bookings.filter(b => b.includeProtection).length ?? 0,
      name: `${order.customer.firstname} ${order.customer.name}`,
      data: (
        <>
          <strong>Fon:</strong>{' '}
          <a href={`tel:${order.customer.phone}`}>
            {order.customer.phone}
          </a>
          <br />
          <strong>E-Mail:</strong>{' '}
          <a href={`mailto:${order.customer.email}`}>
            {order.customer.email}
          </a>
        </>
      ),
      status: orderStatus,
      statusName: statusTranslations[orderStatus]
    };
  });

  /**
   * Printing
   */
  const handlePrintBookingLabels = async (orders: ObserveOrdersSubscriptionResult['data']): Promise<void> => {
    const ordersData = orders?.orders ?? [];

    for (const order of ordersData) {
      for (const booking of order.bookings) {
        const isCancelled = booking.status === BookingStatusEnum.CANCELLED;
        const isMainProduct = !Number.isNaN(booking.riderWeight)
          && booking.riderWeight
          && (parseInt(booking.riderWeight) > 0);

        if (isCancelled || !isMainProduct) {
          break;
        }

        const printIFrame: HTMLIFrameElement | null = document.getElementById(
          'print-booking-label',
        ) as HTMLIFrameElement;

        if (printIFrame) {
          printIFrame.src = '/print-booking-label/' + booking.id;

          await sleep(2000);

          printIFrame?.contentWindow?.print();
        }
      }
    }
  }

  const newBookingURL = new URL(process.env.REACT_APP_BOOKING_APP_HOST ?? 'http://localhost:5000' );
  newBookingURL.searchParams.set('locationId', globalLocation.locationId ?? '');
  newBookingURL.searchParams.set('locationName', 'Admin');
  newBookingURL.searchParams.set('startDate', globalDate.date);

  const newBookingDialog = (
    <Dialog
      onClose={() => setIsNewBookingOpen(false)}
      open={isNewBookingOpen}
      style={{ minWidth: '400px', minHeight: '800px' }}
    >
      <iframe
        style={{ border: 0, minWidth: '400px', height: '90%', minHeight: '600px', maxHeight: '700px' }}
        src={newBookingURL.toString()}
      >

      </iframe>
    </Dialog>
  );

  /**
   * New admin booking
   */
  const handleCreateNewBooking = () => {
    setIsNewBookingOpen(true);
  };

  return (
    <>
      {activeOrder && (
        <>
          <Drawer
            open={!!activeOrder}
            onClose={() => setActiveOrder(null)}
            anchor={'right'}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div className={classes.drawerContainer}>
              <Order orderId={activeOrder.id} />
            </div>
          </Drawer>
        </>
      )}

      <>
        {newBookingDialog}
      </>

      <>
        {/** iframe to print booking-labels */}
        <Iframe
          id={'print-booking-label'}
          frameBorder={0}
          display={'none'}
          className={classes.printIframe}
          title={'Print booking labels'}
          url={'data:text/html, <h1>Error: No Booking loaded.</h1>'}
        />
      </>

      <main className={classes.content}>
        <Grid container>
          <Grid item xs={8}>
            <Typography variant={'h1'}>Reservierungen verwalten</Typography>
          </Grid>
          <Grid item xs={4}>
            <Grid container style={{marginTop: '1em'}}>
              <Grid item xs={6}>
                <Button
                  aria-label='print-shipping-recipe'
                  color='primary'
                  variant={'outlined'}
                  onClick={() =>
                    handlePrintBookingLabels(data)
                  }
                  endIcon={
                    <PrintIcon />
                  }
                >
                  Bons drucken
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  aria-label='create-new-order'
                  color='primary'
                  variant={'outlined'}
                  onClick={() =>
                    handleCreateNewBooking()
                  }
                  endIcon={
                    <PlusIcon />
                  }
                >
                  Neue Reservierung
                </Button>
              </Grid>
            </Grid>
          </Grid>

        </Grid>

        <MaterialTable
          options={{
            paging: false,
            search: true,
            showTitle: false,
            toolbar: true,
            actionsColumnIndex: 99,
            actionsCellStyle: {
              maxWidth: '5%',
            },
            sorting: true
          }}
          components={{
            Row: tableRow,
          }}
          actions={[
            {
              icon: 'edit',
              onClick: (event, rowData) => {
                // @todo: Check why table can return an array (multiselect?)
                if (Array.isArray(rowData)) {
                  return;
                }

                handleSelectOrder(rowData.id);
              },
            },
          ]}
          columns={[
            { title: 'ID', field: 'id', hidden: true },
            { title: '', field: 'verified', width: '1%' },
            { title: 'Eingangsdatum', field: 'created_at', width: '10%', hidden: isTabletOrLower },
            { title: 'Startdatum', field: 'start_date', width: '10%', hidden: isTabletOrLower },
            { title: 'Dauer', field: 'duration', width: '5%' },
            { title: 'Produkte', field: 'amount_products', width: '10%'  },
            { title: 'Protection', field: 'amount_protection', width: '10%' },
            { title: 'Name', field: 'name', width: '30%' },
            { title: 'Kundendaten', field: 'data', width: '40%' },
            { title: 'Status', field: 'statusName', width: '10%' },
          ]}
          data={ordersData}
        />
      </main>
    </>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: DRAWER_WIDTH,
      maxWidth: DRAWER_MAX_WIDTH,
      minWidth: DRAWER_MIN_WIDTH,
      [theme.breakpoints.down('md')]: {
        width: '80vw',
      }
    },
    drawerPaper: {
      width: '80vw',
    },
    drawerContainer: {
      overflow: 'auto',
    },
    tableHeader: {
      '& *': {
        backgroundColor: theme.palette.grey['500'],
      },
    },
    'bookingRow-default': {
      backgroundColor: 'none',
      fontWeight: 'bold',
    },
    'bookingRow-ALL_NEW': {
      backgroundColor: 'none',
      '*': {
        fontWeight: 'bold',
      },
    },
    'bookingRow-ALL_CANCELLED': {
      backgroundColor: theme.palette.grey['300'],
      color: theme.palette.grey['600'],
      textDecoration: 'line-through',
    },
    'bookingRow-ALL_SHIPPED': {
      backgroundColor: theme.palette.secondary.light,
    },
    'bookingRow-ALL_RETURNED': {
      backgroundColor: theme.palette.primary.light,
    },
    'bookingRow-OVERDUE': {
      backgroundColor: theme.palette.error.light,
    },
    'bookingRow-EXCEEDED': {
      backgroundColor: theme.palette.error.light,
    },
    'bookingRow-PENDING': {
      backgroundColor: theme.palette.warning.light,
    },
    // Printing features
    printIframe: {
      display: 'none',
    },
    printProductRecipes: {

    },
  }),
);