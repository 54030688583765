import React, { FC, useState } from 'react';
import { Customers, useUpsertProductPricesMutation, useUpdateCustomerMutation } from '../../api/generated';
import {
  Box, Button,
  Card,
  CardActions,
  CardContent,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles } from '@material-ui/core/styles';
import { useGlobalStyles } from '../../styles/global';
import theme from '../../styles/theme';
import CustomerCardData from './CustomerCardData';
import CustomerCardForm from './CustomerCardForm';
import { CustomerCardProps } from './types/CustomerCard';

const CustomerCard: FC<CustomerCardProps> = ({
  customer,
  onSave
}) => {
  // Styles
  const classes = {
    ...useGlobalStyles(),
    ...useStyles(),
  };

  const [updateCustomer, { loading: updateCustomerLoading }] = useUpdateCustomerMutation();

  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  const handleToggleEditCustomer = (): void => {
    setIsEditMode(!isEditMode);
  }

  const handleSubmitCustomer = async (customerData: Partial<Customers>): Promise<void> => {
    const sanitizedData = customerData;

    delete sanitizedData.__typename;

    await updateCustomer({
      variables: {
        id: customerData['id'],
        data: sanitizedData
      },
    });

    if (onSave) onSave();

    setIsEditMode(false);
  }

  const handleCancelEditMode = (): void => {
    setIsEditMode(false);
  }

  return (
    <Box className={classes.root}>
      <Card className={classes.card}>
        <CardContent>
          {isEditMode ? (
            <CustomerCardForm customer={customer} onSubmit={handleSubmitCustomer} onCancel={handleCancelEditMode} />
          ) : (
            <CustomerCardData customer={customer} />
          )}
        </CardContent>
        <CardActions>
          {!isEditMode && (
            <Button
              onClick={handleToggleEditCustomer}
              startIcon={<EditIcon />}
            >
              Bearbeiten
            </Button>
          )}
        </CardActions>
      </Card>
    </Box>
  );
};

const useStyles = makeStyles({
  root: {
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
  card: {
    width: '100%',
  },
  title: {
    fontSize: 18,
  },
  pos: {
    marginBottom: 12,
  },
});

export default CustomerCard;