import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  uuid: any;
  timestamptz: any;
  date: any;
  jsonb: any;
  numeric: any;
  bigint: any;
};


/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: Maybe<Scalars['bigint']>;
  _gt?: Maybe<Scalars['bigint']>;
  _gte?: Maybe<Scalars['bigint']>;
  _in?: Maybe<Array<Scalars['bigint']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['bigint']>;
  _lte?: Maybe<Scalars['bigint']>;
  _neq?: Maybe<Scalars['bigint']>;
  _nin?: Maybe<Array<Scalars['bigint']>>;
};

/** columns and relationships of "bookingRequest" */
export type BookingRequest = {
  __typename?: 'bookingRequest';
  /** An array relationship */
  bookings: Array<Bookings>;
  /** An aggregate relationship */
  bookings_aggregate: Bookings_Aggregate;
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  request: Scalars['jsonb'];
  success?: Maybe<Scalars['Boolean']>;
};


/** columns and relationships of "bookingRequest" */
export type BookingRequestBookingsArgs = {
  distinct_on?: Maybe<Array<Bookings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bookings_Order_By>>;
  where?: Maybe<Bookings_Bool_Exp>;
};


/** columns and relationships of "bookingRequest" */
export type BookingRequestBookings_AggregateArgs = {
  distinct_on?: Maybe<Array<Bookings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bookings_Order_By>>;
  where?: Maybe<Bookings_Bool_Exp>;
};


/** columns and relationships of "bookingRequest" */
export type BookingRequestRequestArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "bookingRequest" */
export type BookingRequest_Aggregate = {
  __typename?: 'bookingRequest_aggregate';
  aggregate?: Maybe<BookingRequest_Aggregate_Fields>;
  nodes: Array<BookingRequest>;
};

/** aggregate fields of "bookingRequest" */
export type BookingRequest_Aggregate_Fields = {
  __typename?: 'bookingRequest_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<BookingRequest_Max_Fields>;
  min?: Maybe<BookingRequest_Min_Fields>;
};


/** aggregate fields of "bookingRequest" */
export type BookingRequest_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<BookingRequest_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type BookingRequest_Append_Input = {
  request?: Maybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "bookingRequest". All fields are combined with a logical 'AND'. */
export type BookingRequest_Bool_Exp = {
  _and?: Maybe<Array<BookingRequest_Bool_Exp>>;
  _not?: Maybe<BookingRequest_Bool_Exp>;
  _or?: Maybe<Array<BookingRequest_Bool_Exp>>;
  bookings?: Maybe<Bookings_Bool_Exp>;
  bookings_aggregate?: Maybe<Bookings_Aggregate_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  request?: Maybe<Jsonb_Comparison_Exp>;
  success?: Maybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "bookingRequest" */
export enum BookingRequest_Constraint {
  /** unique or primary key constraint on columns "id" */
  BookingRequestPkey = 'bookingRequest_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type BookingRequest_Delete_At_Path_Input = {
  request?: Maybe<Array<Scalars['String']>>;
};

/**
 * delete the array element with specified index (negative integers count from the
 * end). throws an error if top level container is not an array
 */
export type BookingRequest_Delete_Elem_Input = {
  request?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type BookingRequest_Delete_Key_Input = {
  request?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "bookingRequest" */
export type BookingRequest_Insert_Input = {
  bookings?: Maybe<Bookings_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  request?: Maybe<Scalars['jsonb']>;
  success?: Maybe<Scalars['Boolean']>;
};

/** aggregate max on columns */
export type BookingRequest_Max_Fields = {
  __typename?: 'bookingRequest_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type BookingRequest_Min_Fields = {
  __typename?: 'bookingRequest_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "bookingRequest" */
export type BookingRequest_Mutation_Response = {
  __typename?: 'bookingRequest_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<BookingRequest>;
};

/** input type for inserting object relation for remote table "bookingRequest" */
export type BookingRequest_Obj_Rel_Insert_Input = {
  data: BookingRequest_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<BookingRequest_On_Conflict>;
};

/** on_conflict condition type for table "bookingRequest" */
export type BookingRequest_On_Conflict = {
  constraint: BookingRequest_Constraint;
  update_columns: Array<BookingRequest_Update_Column>;
  where?: Maybe<BookingRequest_Bool_Exp>;
};

/** Ordering options when selecting data from "bookingRequest". */
export type BookingRequest_Order_By = {
  bookings_aggregate?: Maybe<Bookings_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  request?: Maybe<Order_By>;
  success?: Maybe<Order_By>;
};

/** primary key columns input for table: bookingRequest */
export type BookingRequest_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type BookingRequest_Prepend_Input = {
  request?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "bookingRequest" */
export enum BookingRequest_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Request = 'request',
  /** column name */
  Success = 'success'
}

/** input type for updating data in table "bookingRequest" */
export type BookingRequest_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  request?: Maybe<Scalars['jsonb']>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Streaming cursor of the table "bookingRequest" */
export type BookingRequest_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: BookingRequest_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type BookingRequest_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  request?: Maybe<Scalars['jsonb']>;
  success?: Maybe<Scalars['Boolean']>;
};

/** update columns of table "bookingRequest" */
export enum BookingRequest_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Request = 'request',
  /** column name */
  Success = 'success'
}

export type BookingRequest_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<BookingRequest_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<BookingRequest_Delete_At_Path_Input>;
  /**
   * delete the array element with specified index (negative integers count from
   * the end). throws an error if top level container is not an array
   */
  _delete_elem?: Maybe<BookingRequest_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<BookingRequest_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<BookingRequest_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<BookingRequest_Set_Input>;
  /** filter the rows which have to be updated */
  where: BookingRequest_Bool_Exp;
};

/** columns and relationships of "bookings" */
export type Bookings = {
  __typename?: 'bookings';
  /** An object relationship */
  bookingRequest?: Maybe<BookingRequest>;
  bookingRequestId?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  bookings_transactions: Array<Bookings_Transactions>;
  /** An aggregate relationship */
  bookings_transactions_aggregate: Bookings_Transactions_Aggregate;
  cancelled?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  includeInsurance: Scalars['Boolean'];
  includeProtection: Scalars['Boolean'];
  /** An object relationship */
  order: Orders;
  orderId: Scalars['uuid'];
  /** An object relationship */
  product: Products;
  productId: Scalars['uuid'];
  riderName?: Maybe<Scalars['String']>;
  riderWeight?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  updated_at?: Maybe<Scalars['timestamptz']>;
  verifiedPriceSnapshot?: Maybe<Scalars['jsonb']>;
};


/** columns and relationships of "bookings" */
export type BookingsBookings_TransactionsArgs = {
  distinct_on?: Maybe<Array<Bookings_Transactions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bookings_Transactions_Order_By>>;
  where?: Maybe<Bookings_Transactions_Bool_Exp>;
};


/** columns and relationships of "bookings" */
export type BookingsBookings_Transactions_AggregateArgs = {
  distinct_on?: Maybe<Array<Bookings_Transactions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bookings_Transactions_Order_By>>;
  where?: Maybe<Bookings_Transactions_Bool_Exp>;
};


/** columns and relationships of "bookings" */
export type BookingsVerifiedPriceSnapshotArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "bookings" */
export type Bookings_Aggregate = {
  __typename?: 'bookings_aggregate';
  aggregate?: Maybe<Bookings_Aggregate_Fields>;
  nodes: Array<Bookings>;
};

export type Bookings_Aggregate_Bool_Exp = {
  bool_and?: Maybe<Bookings_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<Bookings_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<Bookings_Aggregate_Bool_Exp_Count>;
};

export type Bookings_Aggregate_Bool_Exp_Bool_And = {
  arguments: Bookings_Select_Column_Bookings_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Bookings_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Bookings_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Bookings_Select_Column_Bookings_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Bookings_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Bookings_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Bookings_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Bookings_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "bookings" */
export type Bookings_Aggregate_Fields = {
  __typename?: 'bookings_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Bookings_Max_Fields>;
  min?: Maybe<Bookings_Min_Fields>;
};


/** aggregate fields of "bookings" */
export type Bookings_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Bookings_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "bookings" */
export type Bookings_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Bookings_Max_Order_By>;
  min?: Maybe<Bookings_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Bookings_Append_Input = {
  verifiedPriceSnapshot?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "bookings" */
export type Bookings_Arr_Rel_Insert_Input = {
  data: Array<Bookings_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Bookings_On_Conflict>;
};

/** Boolean expression to filter rows from the table "bookings". All fields are combined with a logical 'AND'. */
export type Bookings_Bool_Exp = {
  _and?: Maybe<Array<Bookings_Bool_Exp>>;
  _not?: Maybe<Bookings_Bool_Exp>;
  _or?: Maybe<Array<Bookings_Bool_Exp>>;
  bookingRequest?: Maybe<BookingRequest_Bool_Exp>;
  bookingRequestId?: Maybe<Uuid_Comparison_Exp>;
  bookings_transactions?: Maybe<Bookings_Transactions_Bool_Exp>;
  bookings_transactions_aggregate?: Maybe<Bookings_Transactions_Aggregate_Bool_Exp>;
  cancelled?: Maybe<Boolean_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  includeInsurance?: Maybe<Boolean_Comparison_Exp>;
  includeProtection?: Maybe<Boolean_Comparison_Exp>;
  order?: Maybe<Orders_Bool_Exp>;
  orderId?: Maybe<Uuid_Comparison_Exp>;
  product?: Maybe<Products_Bool_Exp>;
  productId?: Maybe<Uuid_Comparison_Exp>;
  riderName?: Maybe<String_Comparison_Exp>;
  riderWeight?: Maybe<String_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  verifiedPriceSnapshot?: Maybe<Jsonb_Comparison_Exp>;
};

/** unique or primary key constraints on table "bookings" */
export enum Bookings_Constraint {
  /** unique or primary key constraint on columns "id" */
  BookingsPkey = 'bookings_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Bookings_Delete_At_Path_Input = {
  verifiedPriceSnapshot?: Maybe<Array<Scalars['String']>>;
};

/**
 * delete the array element with specified index (negative integers count from the
 * end). throws an error if top level container is not an array
 */
export type Bookings_Delete_Elem_Input = {
  verifiedPriceSnapshot?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Bookings_Delete_Key_Input = {
  verifiedPriceSnapshot?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "bookings" */
export type Bookings_Insert_Input = {
  bookingRequest?: Maybe<BookingRequest_Obj_Rel_Insert_Input>;
  bookingRequestId?: Maybe<Scalars['uuid']>;
  bookings_transactions?: Maybe<Bookings_Transactions_Arr_Rel_Insert_Input>;
  cancelled?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  includeInsurance?: Maybe<Scalars['Boolean']>;
  includeProtection?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Orders_Obj_Rel_Insert_Input>;
  orderId?: Maybe<Scalars['uuid']>;
  product?: Maybe<Products_Obj_Rel_Insert_Input>;
  productId?: Maybe<Scalars['uuid']>;
  riderName?: Maybe<Scalars['String']>;
  riderWeight?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  verifiedPriceSnapshot?: Maybe<Scalars['jsonb']>;
};

/** aggregate max on columns */
export type Bookings_Max_Fields = {
  __typename?: 'bookings_max_fields';
  bookingRequestId?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  orderId?: Maybe<Scalars['uuid']>;
  productId?: Maybe<Scalars['uuid']>;
  riderName?: Maybe<Scalars['String']>;
  riderWeight?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "bookings" */
export type Bookings_Max_Order_By = {
  bookingRequestId?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  orderId?: Maybe<Order_By>;
  productId?: Maybe<Order_By>;
  riderName?: Maybe<Order_By>;
  riderWeight?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Bookings_Min_Fields = {
  __typename?: 'bookings_min_fields';
  bookingRequestId?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  orderId?: Maybe<Scalars['uuid']>;
  productId?: Maybe<Scalars['uuid']>;
  riderName?: Maybe<Scalars['String']>;
  riderWeight?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "bookings" */
export type Bookings_Min_Order_By = {
  bookingRequestId?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  orderId?: Maybe<Order_By>;
  productId?: Maybe<Order_By>;
  riderName?: Maybe<Order_By>;
  riderWeight?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "bookings" */
export type Bookings_Mutation_Response = {
  __typename?: 'bookings_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Bookings>;
};

/** input type for inserting object relation for remote table "bookings" */
export type Bookings_Obj_Rel_Insert_Input = {
  data: Bookings_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Bookings_On_Conflict>;
};

/** on_conflict condition type for table "bookings" */
export type Bookings_On_Conflict = {
  constraint: Bookings_Constraint;
  update_columns: Array<Bookings_Update_Column>;
  where?: Maybe<Bookings_Bool_Exp>;
};

/** Ordering options when selecting data from "bookings". */
export type Bookings_Order_By = {
  bookingRequest?: Maybe<BookingRequest_Order_By>;
  bookingRequestId?: Maybe<Order_By>;
  bookings_transactions_aggregate?: Maybe<Bookings_Transactions_Aggregate_Order_By>;
  cancelled?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  includeInsurance?: Maybe<Order_By>;
  includeProtection?: Maybe<Order_By>;
  order?: Maybe<Orders_Order_By>;
  orderId?: Maybe<Order_By>;
  product?: Maybe<Products_Order_By>;
  productId?: Maybe<Order_By>;
  riderName?: Maybe<Order_By>;
  riderWeight?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  verifiedPriceSnapshot?: Maybe<Order_By>;
};

/** primary key columns input for table: bookings */
export type Bookings_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Bookings_Prepend_Input = {
  verifiedPriceSnapshot?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "bookings" */
export enum Bookings_Select_Column {
  /** column name */
  BookingRequestId = 'bookingRequestId',
  /** column name */
  Cancelled = 'cancelled',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IncludeInsurance = 'includeInsurance',
  /** column name */
  IncludeProtection = 'includeProtection',
  /** column name */
  OrderId = 'orderId',
  /** column name */
  ProductId = 'productId',
  /** column name */
  RiderName = 'riderName',
  /** column name */
  RiderWeight = 'riderWeight',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VerifiedPriceSnapshot = 'verifiedPriceSnapshot'
}

/** select "bookings_aggregate_bool_exp_bool_and_arguments_columns" columns of table "bookings" */
export enum Bookings_Select_Column_Bookings_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Cancelled = 'cancelled',
  /** column name */
  IncludeInsurance = 'includeInsurance',
  /** column name */
  IncludeProtection = 'includeProtection'
}

/** select "bookings_aggregate_bool_exp_bool_or_arguments_columns" columns of table "bookings" */
export enum Bookings_Select_Column_Bookings_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Cancelled = 'cancelled',
  /** column name */
  IncludeInsurance = 'includeInsurance',
  /** column name */
  IncludeProtection = 'includeProtection'
}

/** input type for updating data in table "bookings" */
export type Bookings_Set_Input = {
  bookingRequestId?: Maybe<Scalars['uuid']>;
  cancelled?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  includeInsurance?: Maybe<Scalars['Boolean']>;
  includeProtection?: Maybe<Scalars['Boolean']>;
  orderId?: Maybe<Scalars['uuid']>;
  productId?: Maybe<Scalars['uuid']>;
  riderName?: Maybe<Scalars['String']>;
  riderWeight?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  verifiedPriceSnapshot?: Maybe<Scalars['jsonb']>;
};

/** Streaming cursor of the table "bookings" */
export type Bookings_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Bookings_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Bookings_Stream_Cursor_Value_Input = {
  bookingRequestId?: Maybe<Scalars['uuid']>;
  cancelled?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  includeInsurance?: Maybe<Scalars['Boolean']>;
  includeProtection?: Maybe<Scalars['Boolean']>;
  orderId?: Maybe<Scalars['uuid']>;
  productId?: Maybe<Scalars['uuid']>;
  riderName?: Maybe<Scalars['String']>;
  riderWeight?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  verifiedPriceSnapshot?: Maybe<Scalars['jsonb']>;
};

/** columns and relationships of "bookings_transactions" */
export type Bookings_Transactions = {
  __typename?: 'bookings_transactions';
  /** An object relationship */
  booking: Bookings;
  bookingId: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  transaction: Transactions;
  transactionId: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "bookings_transactions" */
export type Bookings_Transactions_Aggregate = {
  __typename?: 'bookings_transactions_aggregate';
  aggregate?: Maybe<Bookings_Transactions_Aggregate_Fields>;
  nodes: Array<Bookings_Transactions>;
};

export type Bookings_Transactions_Aggregate_Bool_Exp = {
  count?: Maybe<Bookings_Transactions_Aggregate_Bool_Exp_Count>;
};

export type Bookings_Transactions_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Bookings_Transactions_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Bookings_Transactions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "bookings_transactions" */
export type Bookings_Transactions_Aggregate_Fields = {
  __typename?: 'bookings_transactions_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Bookings_Transactions_Max_Fields>;
  min?: Maybe<Bookings_Transactions_Min_Fields>;
};


/** aggregate fields of "bookings_transactions" */
export type Bookings_Transactions_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Bookings_Transactions_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "bookings_transactions" */
export type Bookings_Transactions_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Bookings_Transactions_Max_Order_By>;
  min?: Maybe<Bookings_Transactions_Min_Order_By>;
};

/** input type for inserting array relation for remote table "bookings_transactions" */
export type Bookings_Transactions_Arr_Rel_Insert_Input = {
  data: Array<Bookings_Transactions_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Bookings_Transactions_On_Conflict>;
};

/** Boolean expression to filter rows from the table "bookings_transactions". All fields are combined with a logical 'AND'. */
export type Bookings_Transactions_Bool_Exp = {
  _and?: Maybe<Array<Bookings_Transactions_Bool_Exp>>;
  _not?: Maybe<Bookings_Transactions_Bool_Exp>;
  _or?: Maybe<Array<Bookings_Transactions_Bool_Exp>>;
  booking?: Maybe<Bookings_Bool_Exp>;
  bookingId?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  transaction?: Maybe<Transactions_Bool_Exp>;
  transactionId?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "bookings_transactions" */
export enum Bookings_Transactions_Constraint {
  /** unique or primary key constraint on columns "id" */
  BookingsTransactionsPkey = 'bookings_transactions_pkey'
}

/** input type for inserting data into table "bookings_transactions" */
export type Bookings_Transactions_Insert_Input = {
  booking?: Maybe<Bookings_Obj_Rel_Insert_Input>;
  bookingId?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  transaction?: Maybe<Transactions_Obj_Rel_Insert_Input>;
  transactionId?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Bookings_Transactions_Max_Fields = {
  __typename?: 'bookings_transactions_max_fields';
  bookingId?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  transactionId?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "bookings_transactions" */
export type Bookings_Transactions_Max_Order_By = {
  bookingId?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  transactionId?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Bookings_Transactions_Min_Fields = {
  __typename?: 'bookings_transactions_min_fields';
  bookingId?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  transactionId?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "bookings_transactions" */
export type Bookings_Transactions_Min_Order_By = {
  bookingId?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  transactionId?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "bookings_transactions" */
export type Bookings_Transactions_Mutation_Response = {
  __typename?: 'bookings_transactions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Bookings_Transactions>;
};

/** on_conflict condition type for table "bookings_transactions" */
export type Bookings_Transactions_On_Conflict = {
  constraint: Bookings_Transactions_Constraint;
  update_columns: Array<Bookings_Transactions_Update_Column>;
  where?: Maybe<Bookings_Transactions_Bool_Exp>;
};

/** Ordering options when selecting data from "bookings_transactions". */
export type Bookings_Transactions_Order_By = {
  booking?: Maybe<Bookings_Order_By>;
  bookingId?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  transaction?: Maybe<Transactions_Order_By>;
  transactionId?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: bookings_transactions */
export type Bookings_Transactions_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "bookings_transactions" */
export enum Bookings_Transactions_Select_Column {
  /** column name */
  BookingId = 'bookingId',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  TransactionId = 'transactionId',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "bookings_transactions" */
export type Bookings_Transactions_Set_Input = {
  bookingId?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  transactionId?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "bookings_transactions" */
export type Bookings_Transactions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Bookings_Transactions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Bookings_Transactions_Stream_Cursor_Value_Input = {
  bookingId?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  transactionId?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "bookings_transactions" */
export enum Bookings_Transactions_Update_Column {
  /** column name */
  BookingId = 'bookingId',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  TransactionId = 'transactionId',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Bookings_Transactions_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Bookings_Transactions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Bookings_Transactions_Bool_Exp;
};

/** update columns of table "bookings" */
export enum Bookings_Update_Column {
  /** column name */
  BookingRequestId = 'bookingRequestId',
  /** column name */
  Cancelled = 'cancelled',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IncludeInsurance = 'includeInsurance',
  /** column name */
  IncludeProtection = 'includeProtection',
  /** column name */
  OrderId = 'orderId',
  /** column name */
  ProductId = 'productId',
  /** column name */
  RiderName = 'riderName',
  /** column name */
  RiderWeight = 'riderWeight',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VerifiedPriceSnapshot = 'verifiedPriceSnapshot'
}

export type Bookings_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Bookings_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Bookings_Delete_At_Path_Input>;
  /**
   * delete the array element with specified index (negative integers count from
   * the end). throws an error if top level container is not an array
   */
  _delete_elem?: Maybe<Bookings_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Bookings_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Bookings_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Bookings_Set_Input>;
  /** filter the rows which have to be updated */
  where: Bookings_Bool_Exp;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: Maybe<Scalars['Boolean']>;
  _gt?: Maybe<Scalars['Boolean']>;
  _gte?: Maybe<Scalars['Boolean']>;
  _in?: Maybe<Array<Scalars['Boolean']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Boolean']>;
  _lte?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Scalars['Boolean']>;
  _nin?: Maybe<Array<Scalars['Boolean']>>;
};

/** columns and relationships of "closingDates" */
export type ClosingDates = {
  __typename?: 'closingDates';
  closedDate: Scalars['date'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  /** An object relationship */
  location: Locations;
  locationId: Scalars['uuid'];
};

/** aggregated selection of "closingDates" */
export type ClosingDates_Aggregate = {
  __typename?: 'closingDates_aggregate';
  aggregate?: Maybe<ClosingDates_Aggregate_Fields>;
  nodes: Array<ClosingDates>;
};

export type ClosingDates_Aggregate_Bool_Exp = {
  count?: Maybe<ClosingDates_Aggregate_Bool_Exp_Count>;
};

export type ClosingDates_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<ClosingDates_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<ClosingDates_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "closingDates" */
export type ClosingDates_Aggregate_Fields = {
  __typename?: 'closingDates_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ClosingDates_Max_Fields>;
  min?: Maybe<ClosingDates_Min_Fields>;
};


/** aggregate fields of "closingDates" */
export type ClosingDates_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<ClosingDates_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "closingDates" */
export type ClosingDates_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<ClosingDates_Max_Order_By>;
  min?: Maybe<ClosingDates_Min_Order_By>;
};

/** input type for inserting array relation for remote table "closingDates" */
export type ClosingDates_Arr_Rel_Insert_Input = {
  data: Array<ClosingDates_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<ClosingDates_On_Conflict>;
};

/** Boolean expression to filter rows from the table "closingDates". All fields are combined with a logical 'AND'. */
export type ClosingDates_Bool_Exp = {
  _and?: Maybe<Array<ClosingDates_Bool_Exp>>;
  _not?: Maybe<ClosingDates_Bool_Exp>;
  _or?: Maybe<Array<ClosingDates_Bool_Exp>>;
  closedDate?: Maybe<Date_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  location?: Maybe<Locations_Bool_Exp>;
  locationId?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "closingDates" */
export enum ClosingDates_Constraint {
  /** unique or primary key constraint on columns "closedDate", "locationId" */
  ClosingDatesClosedDateLocationIdKey = 'closingDates_closedDate_locationId_key',
  /** unique or primary key constraint on columns "id" */
  ClosingDatesIdKey = 'closingDates_id_key',
  /** unique or primary key constraint on columns "id" */
  ClosingDatesPkey = 'closingDates_pkey'
}

/** input type for inserting data into table "closingDates" */
export type ClosingDates_Insert_Input = {
  closedDate?: Maybe<Scalars['date']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  location?: Maybe<Locations_Obj_Rel_Insert_Input>;
  locationId?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type ClosingDates_Max_Fields = {
  __typename?: 'closingDates_max_fields';
  closedDate?: Maybe<Scalars['date']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  locationId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "closingDates" */
export type ClosingDates_Max_Order_By = {
  closedDate?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  locationId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type ClosingDates_Min_Fields = {
  __typename?: 'closingDates_min_fields';
  closedDate?: Maybe<Scalars['date']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  locationId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "closingDates" */
export type ClosingDates_Min_Order_By = {
  closedDate?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  locationId?: Maybe<Order_By>;
};

/** response of any mutation on the table "closingDates" */
export type ClosingDates_Mutation_Response = {
  __typename?: 'closingDates_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ClosingDates>;
};

/** on_conflict condition type for table "closingDates" */
export type ClosingDates_On_Conflict = {
  constraint: ClosingDates_Constraint;
  update_columns: Array<ClosingDates_Update_Column>;
  where?: Maybe<ClosingDates_Bool_Exp>;
};

/** Ordering options when selecting data from "closingDates". */
export type ClosingDates_Order_By = {
  closedDate?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location?: Maybe<Locations_Order_By>;
  locationId?: Maybe<Order_By>;
};

/** primary key columns input for table: closingDates */
export type ClosingDates_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "closingDates" */
export enum ClosingDates_Select_Column {
  /** column name */
  ClosedDate = 'closedDate',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'locationId'
}

/** input type for updating data in table "closingDates" */
export type ClosingDates_Set_Input = {
  closedDate?: Maybe<Scalars['date']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  locationId?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "closingDates" */
export type ClosingDates_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ClosingDates_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ClosingDates_Stream_Cursor_Value_Input = {
  closedDate?: Maybe<Scalars['date']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  locationId?: Maybe<Scalars['uuid']>;
};

/** update columns of table "closingDates" */
export enum ClosingDates_Update_Column {
  /** column name */
  ClosedDate = 'closedDate',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'locationId'
}

export type ClosingDates_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<ClosingDates_Set_Input>;
  /** filter the rows which have to be updated */
  where: ClosingDates_Bool_Exp;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** columns and relationships of "customers" */
export type Customers = {
  __typename?: 'customers';
  birthdate: Scalars['date'];
  city: Scalars['String'];
  countryCode: Scalars['String'];
  created_at: Scalars['timestamptz'];
  email: Scalars['String'];
  firstname: Scalars['String'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  /** An array relationship */
  orders: Array<Orders>;
  /** An aggregate relationship */
  orders_aggregate: Orders_Aggregate;
  phone: Scalars['String'];
  /** An array relationship */
  snapshotBookings: Array<SnapshotBookings>;
  /** An aggregate relationship */
  snapshotBookings_aggregate: SnapshotBookings_Aggregate;
  street?: Maybe<Scalars['String']>;
  streetnumber?: Maybe<Scalars['String']>;
  zipCode: Scalars['String'];
};


/** columns and relationships of "customers" */
export type CustomersOrdersArgs = {
  distinct_on?: Maybe<Array<Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Orders_Order_By>>;
  where?: Maybe<Orders_Bool_Exp>;
};


/** columns and relationships of "customers" */
export type CustomersOrders_AggregateArgs = {
  distinct_on?: Maybe<Array<Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Orders_Order_By>>;
  where?: Maybe<Orders_Bool_Exp>;
};


/** columns and relationships of "customers" */
export type CustomersSnapshotBookingsArgs = {
  distinct_on?: Maybe<Array<SnapshotBookings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SnapshotBookings_Order_By>>;
  where?: Maybe<SnapshotBookings_Bool_Exp>;
};


/** columns and relationships of "customers" */
export type CustomersSnapshotBookings_AggregateArgs = {
  distinct_on?: Maybe<Array<SnapshotBookings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SnapshotBookings_Order_By>>;
  where?: Maybe<SnapshotBookings_Bool_Exp>;
};

/** aggregated selection of "customers" */
export type Customers_Aggregate = {
  __typename?: 'customers_aggregate';
  aggregate?: Maybe<Customers_Aggregate_Fields>;
  nodes: Array<Customers>;
};

/** aggregate fields of "customers" */
export type Customers_Aggregate_Fields = {
  __typename?: 'customers_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Customers_Max_Fields>;
  min?: Maybe<Customers_Min_Fields>;
};


/** aggregate fields of "customers" */
export type Customers_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Customers_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "customers". All fields are combined with a logical 'AND'. */
export type Customers_Bool_Exp = {
  _and?: Maybe<Array<Customers_Bool_Exp>>;
  _not?: Maybe<Customers_Bool_Exp>;
  _or?: Maybe<Array<Customers_Bool_Exp>>;
  birthdate?: Maybe<Date_Comparison_Exp>;
  city?: Maybe<String_Comparison_Exp>;
  countryCode?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  firstname?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  notes?: Maybe<String_Comparison_Exp>;
  orders?: Maybe<Orders_Bool_Exp>;
  orders_aggregate?: Maybe<Orders_Aggregate_Bool_Exp>;
  phone?: Maybe<String_Comparison_Exp>;
  snapshotBookings?: Maybe<SnapshotBookings_Bool_Exp>;
  snapshotBookings_aggregate?: Maybe<SnapshotBookings_Aggregate_Bool_Exp>;
  street?: Maybe<String_Comparison_Exp>;
  streetnumber?: Maybe<String_Comparison_Exp>;
  zipCode?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "customers" */
export enum Customers_Constraint {
  /**
   * unique or primary key constraint on columns "countryCode", "zipCode",
   * "streetnumber", "firstname", "birthdate", "phone", "city", "street", "name", "email"
   */
  CustomersNameFirstnameCountryCodeZipCodeCityPhoneBirthda = 'customers_name_firstname_countryCode_zipCode_city_phone_birthda',
  /** unique or primary key constraint on columns "id" */
  CustomersPkey = 'customers_pkey'
}

/** input type for inserting data into table "customers" */
export type Customers_Insert_Input = {
  birthdate?: Maybe<Scalars['date']>;
  city?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  orders?: Maybe<Orders_Arr_Rel_Insert_Input>;
  phone?: Maybe<Scalars['String']>;
  snapshotBookings?: Maybe<SnapshotBookings_Arr_Rel_Insert_Input>;
  street?: Maybe<Scalars['String']>;
  streetnumber?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Customers_Max_Fields = {
  __typename?: 'customers_max_fields';
  birthdate?: Maybe<Scalars['date']>;
  city?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  streetnumber?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Customers_Min_Fields = {
  __typename?: 'customers_min_fields';
  birthdate?: Maybe<Scalars['date']>;
  city?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  streetnumber?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "customers" */
export type Customers_Mutation_Response = {
  __typename?: 'customers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Customers>;
};

/** input type for inserting object relation for remote table "customers" */
export type Customers_Obj_Rel_Insert_Input = {
  data: Customers_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Customers_On_Conflict>;
};

/** on_conflict condition type for table "customers" */
export type Customers_On_Conflict = {
  constraint: Customers_Constraint;
  update_columns: Array<Customers_Update_Column>;
  where?: Maybe<Customers_Bool_Exp>;
};

/** Ordering options when selecting data from "customers". */
export type Customers_Order_By = {
  birthdate?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  countryCode?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  firstname?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  orders_aggregate?: Maybe<Orders_Aggregate_Order_By>;
  phone?: Maybe<Order_By>;
  snapshotBookings_aggregate?: Maybe<SnapshotBookings_Aggregate_Order_By>;
  street?: Maybe<Order_By>;
  streetnumber?: Maybe<Order_By>;
  zipCode?: Maybe<Order_By>;
};

/** primary key columns input for table: customers */
export type Customers_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "customers" */
export enum Customers_Select_Column {
  /** column name */
  Birthdate = 'birthdate',
  /** column name */
  City = 'city',
  /** column name */
  CountryCode = 'countryCode',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Firstname = 'firstname',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Notes = 'notes',
  /** column name */
  Phone = 'phone',
  /** column name */
  Street = 'street',
  /** column name */
  Streetnumber = 'streetnumber',
  /** column name */
  ZipCode = 'zipCode'
}

/** input type for updating data in table "customers" */
export type Customers_Set_Input = {
  birthdate?: Maybe<Scalars['date']>;
  city?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  streetnumber?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "customers" */
export type Customers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Customers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Customers_Stream_Cursor_Value_Input = {
  birthdate?: Maybe<Scalars['date']>;
  city?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  streetnumber?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

/** update columns of table "customers" */
export enum Customers_Update_Column {
  /** column name */
  Birthdate = 'birthdate',
  /** column name */
  City = 'city',
  /** column name */
  CountryCode = 'countryCode',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Firstname = 'firstname',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Notes = 'notes',
  /** column name */
  Phone = 'phone',
  /** column name */
  Street = 'street',
  /** column name */
  Streetnumber = 'streetnumber',
  /** column name */
  ZipCode = 'zipCode'
}

export type Customers_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Customers_Set_Input>;
  /** filter the rows which have to be updated */
  where: Customers_Bool_Exp;
};


/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: Maybe<Scalars['date']>;
  _gt?: Maybe<Scalars['date']>;
  _gte?: Maybe<Scalars['date']>;
  _in?: Maybe<Array<Scalars['date']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['date']>;
  _lte?: Maybe<Scalars['date']>;
  _neq?: Maybe<Scalars['date']>;
  _nin?: Maybe<Array<Scalars['date']>>;
};

/** columns and relationships of "extraRecipeLines" */
export type ExtraRecipeLines = {
  __typename?: 'extraRecipeLines';
  amount: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  description: Scalars['String'];
  /** An array relationship */
  extraRecipeLinesTransactions: Array<ExtraRecipeLinesTransactions>;
  /** An aggregate relationship */
  extraRecipeLinesTransactions_aggregate: ExtraRecipeLinesTransactions_Aggregate;
  id: Scalars['uuid'];
  /** An object relationship */
  order: Orders;
  orderId: Scalars['uuid'];
  status: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "extraRecipeLines" */
export type ExtraRecipeLinesExtraRecipeLinesTransactionsArgs = {
  distinct_on?: Maybe<Array<ExtraRecipeLinesTransactions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ExtraRecipeLinesTransactions_Order_By>>;
  where?: Maybe<ExtraRecipeLinesTransactions_Bool_Exp>;
};


/** columns and relationships of "extraRecipeLines" */
export type ExtraRecipeLinesExtraRecipeLinesTransactions_AggregateArgs = {
  distinct_on?: Maybe<Array<ExtraRecipeLinesTransactions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ExtraRecipeLinesTransactions_Order_By>>;
  where?: Maybe<ExtraRecipeLinesTransactions_Bool_Exp>;
};

/** aggregated selection of "extraRecipeLines" */
export type ExtraRecipeLines_Aggregate = {
  __typename?: 'extraRecipeLines_aggregate';
  aggregate?: Maybe<ExtraRecipeLines_Aggregate_Fields>;
  nodes: Array<ExtraRecipeLines>;
};

export type ExtraRecipeLines_Aggregate_Bool_Exp = {
  count?: Maybe<ExtraRecipeLines_Aggregate_Bool_Exp_Count>;
};

export type ExtraRecipeLines_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<ExtraRecipeLines_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<ExtraRecipeLines_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "extraRecipeLines" */
export type ExtraRecipeLines_Aggregate_Fields = {
  __typename?: 'extraRecipeLines_aggregate_fields';
  avg?: Maybe<ExtraRecipeLines_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ExtraRecipeLines_Max_Fields>;
  min?: Maybe<ExtraRecipeLines_Min_Fields>;
  stddev?: Maybe<ExtraRecipeLines_Stddev_Fields>;
  stddev_pop?: Maybe<ExtraRecipeLines_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ExtraRecipeLines_Stddev_Samp_Fields>;
  sum?: Maybe<ExtraRecipeLines_Sum_Fields>;
  var_pop?: Maybe<ExtraRecipeLines_Var_Pop_Fields>;
  var_samp?: Maybe<ExtraRecipeLines_Var_Samp_Fields>;
  variance?: Maybe<ExtraRecipeLines_Variance_Fields>;
};


/** aggregate fields of "extraRecipeLines" */
export type ExtraRecipeLines_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<ExtraRecipeLines_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "extraRecipeLines" */
export type ExtraRecipeLines_Aggregate_Order_By = {
  avg?: Maybe<ExtraRecipeLines_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<ExtraRecipeLines_Max_Order_By>;
  min?: Maybe<ExtraRecipeLines_Min_Order_By>;
  stddev?: Maybe<ExtraRecipeLines_Stddev_Order_By>;
  stddev_pop?: Maybe<ExtraRecipeLines_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<ExtraRecipeLines_Stddev_Samp_Order_By>;
  sum?: Maybe<ExtraRecipeLines_Sum_Order_By>;
  var_pop?: Maybe<ExtraRecipeLines_Var_Pop_Order_By>;
  var_samp?: Maybe<ExtraRecipeLines_Var_Samp_Order_By>;
  variance?: Maybe<ExtraRecipeLines_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "extraRecipeLines" */
export type ExtraRecipeLines_Arr_Rel_Insert_Input = {
  data: Array<ExtraRecipeLines_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<ExtraRecipeLines_On_Conflict>;
};

/** aggregate avg on columns */
export type ExtraRecipeLines_Avg_Fields = {
  __typename?: 'extraRecipeLines_avg_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "extraRecipeLines" */
export type ExtraRecipeLines_Avg_Order_By = {
  amount?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "extraRecipeLines". All fields are combined with a logical 'AND'. */
export type ExtraRecipeLines_Bool_Exp = {
  _and?: Maybe<Array<ExtraRecipeLines_Bool_Exp>>;
  _not?: Maybe<ExtraRecipeLines_Bool_Exp>;
  _or?: Maybe<Array<ExtraRecipeLines_Bool_Exp>>;
  amount?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  extraRecipeLinesTransactions?: Maybe<ExtraRecipeLinesTransactions_Bool_Exp>;
  extraRecipeLinesTransactions_aggregate?: Maybe<ExtraRecipeLinesTransactions_Aggregate_Bool_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  order?: Maybe<Orders_Bool_Exp>;
  orderId?: Maybe<Uuid_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "extraRecipeLines" */
export enum ExtraRecipeLines_Constraint {
  /** unique or primary key constraint on columns "id" */
  FreeRecipeLinesPkey = 'freeRecipeLines_pkey'
}

/** input type for incrementing numeric columns in table "extraRecipeLines" */
export type ExtraRecipeLines_Inc_Input = {
  amount?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "extraRecipeLines" */
export type ExtraRecipeLines_Insert_Input = {
  amount?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  extraRecipeLinesTransactions?: Maybe<ExtraRecipeLinesTransactions_Arr_Rel_Insert_Input>;
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Orders_Obj_Rel_Insert_Input>;
  orderId?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type ExtraRecipeLines_Max_Fields = {
  __typename?: 'extraRecipeLines_max_fields';
  amount?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  orderId?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "extraRecipeLines" */
export type ExtraRecipeLines_Max_Order_By = {
  amount?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  orderId?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type ExtraRecipeLines_Min_Fields = {
  __typename?: 'extraRecipeLines_min_fields';
  amount?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  orderId?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "extraRecipeLines" */
export type ExtraRecipeLines_Min_Order_By = {
  amount?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  orderId?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "extraRecipeLines" */
export type ExtraRecipeLines_Mutation_Response = {
  __typename?: 'extraRecipeLines_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ExtraRecipeLines>;
};

/** input type for inserting object relation for remote table "extraRecipeLines" */
export type ExtraRecipeLines_Obj_Rel_Insert_Input = {
  data: ExtraRecipeLines_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<ExtraRecipeLines_On_Conflict>;
};

/** on_conflict condition type for table "extraRecipeLines" */
export type ExtraRecipeLines_On_Conflict = {
  constraint: ExtraRecipeLines_Constraint;
  update_columns: Array<ExtraRecipeLines_Update_Column>;
  where?: Maybe<ExtraRecipeLines_Bool_Exp>;
};

/** Ordering options when selecting data from "extraRecipeLines". */
export type ExtraRecipeLines_Order_By = {
  amount?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  extraRecipeLinesTransactions_aggregate?: Maybe<ExtraRecipeLinesTransactions_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Orders_Order_By>;
  orderId?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: extraRecipeLines */
export type ExtraRecipeLines_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "extraRecipeLines" */
export enum ExtraRecipeLines_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  OrderId = 'orderId',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "extraRecipeLines" */
export type ExtraRecipeLines_Set_Input = {
  amount?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  orderId?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type ExtraRecipeLines_Stddev_Fields = {
  __typename?: 'extraRecipeLines_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "extraRecipeLines" */
export type ExtraRecipeLines_Stddev_Order_By = {
  amount?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type ExtraRecipeLines_Stddev_Pop_Fields = {
  __typename?: 'extraRecipeLines_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "extraRecipeLines" */
export type ExtraRecipeLines_Stddev_Pop_Order_By = {
  amount?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type ExtraRecipeLines_Stddev_Samp_Fields = {
  __typename?: 'extraRecipeLines_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "extraRecipeLines" */
export type ExtraRecipeLines_Stddev_Samp_Order_By = {
  amount?: Maybe<Order_By>;
};

/** Streaming cursor of the table "extraRecipeLines" */
export type ExtraRecipeLines_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ExtraRecipeLines_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ExtraRecipeLines_Stream_Cursor_Value_Input = {
  amount?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  orderId?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type ExtraRecipeLines_Sum_Fields = {
  __typename?: 'extraRecipeLines_sum_fields';
  amount?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "extraRecipeLines" */
export type ExtraRecipeLines_Sum_Order_By = {
  amount?: Maybe<Order_By>;
};

/** update columns of table "extraRecipeLines" */
export enum ExtraRecipeLines_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  OrderId = 'orderId',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type ExtraRecipeLines_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<ExtraRecipeLines_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<ExtraRecipeLines_Set_Input>;
  /** filter the rows which have to be updated */
  where: ExtraRecipeLines_Bool_Exp;
};

/** aggregate var_pop on columns */
export type ExtraRecipeLines_Var_Pop_Fields = {
  __typename?: 'extraRecipeLines_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "extraRecipeLines" */
export type ExtraRecipeLines_Var_Pop_Order_By = {
  amount?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type ExtraRecipeLines_Var_Samp_Fields = {
  __typename?: 'extraRecipeLines_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "extraRecipeLines" */
export type ExtraRecipeLines_Var_Samp_Order_By = {
  amount?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type ExtraRecipeLines_Variance_Fields = {
  __typename?: 'extraRecipeLines_variance_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "extraRecipeLines" */
export type ExtraRecipeLines_Variance_Order_By = {
  amount?: Maybe<Order_By>;
};

/** columns and relationships of "extraRecipeLinesTransactions" */
export type ExtraRecipeLinesTransactions = {
  __typename?: 'extraRecipeLinesTransactions';
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  extraRecipeLine: ExtraRecipeLines;
  extraRecipeLineId: Scalars['uuid'];
  id: Scalars['uuid'];
  /** An object relationship */
  transaction: Transactions;
  transactionId: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "extraRecipeLinesTransactions" */
export type ExtraRecipeLinesTransactions_Aggregate = {
  __typename?: 'extraRecipeLinesTransactions_aggregate';
  aggregate?: Maybe<ExtraRecipeLinesTransactions_Aggregate_Fields>;
  nodes: Array<ExtraRecipeLinesTransactions>;
};

export type ExtraRecipeLinesTransactions_Aggregate_Bool_Exp = {
  count?: Maybe<ExtraRecipeLinesTransactions_Aggregate_Bool_Exp_Count>;
};

export type ExtraRecipeLinesTransactions_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<ExtraRecipeLinesTransactions_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<ExtraRecipeLinesTransactions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "extraRecipeLinesTransactions" */
export type ExtraRecipeLinesTransactions_Aggregate_Fields = {
  __typename?: 'extraRecipeLinesTransactions_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ExtraRecipeLinesTransactions_Max_Fields>;
  min?: Maybe<ExtraRecipeLinesTransactions_Min_Fields>;
};


/** aggregate fields of "extraRecipeLinesTransactions" */
export type ExtraRecipeLinesTransactions_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<ExtraRecipeLinesTransactions_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "extraRecipeLinesTransactions" */
export type ExtraRecipeLinesTransactions_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<ExtraRecipeLinesTransactions_Max_Order_By>;
  min?: Maybe<ExtraRecipeLinesTransactions_Min_Order_By>;
};

/** input type for inserting array relation for remote table "extraRecipeLinesTransactions" */
export type ExtraRecipeLinesTransactions_Arr_Rel_Insert_Input = {
  data: Array<ExtraRecipeLinesTransactions_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<ExtraRecipeLinesTransactions_On_Conflict>;
};

/**
 * Boolean expression to filter rows from the table "extraRecipeLinesTransactions".
 * All fields are combined with a logical 'AND'.
 */
export type ExtraRecipeLinesTransactions_Bool_Exp = {
  _and?: Maybe<Array<ExtraRecipeLinesTransactions_Bool_Exp>>;
  _not?: Maybe<ExtraRecipeLinesTransactions_Bool_Exp>;
  _or?: Maybe<Array<ExtraRecipeLinesTransactions_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  extraRecipeLine?: Maybe<ExtraRecipeLines_Bool_Exp>;
  extraRecipeLineId?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  transaction?: Maybe<Transactions_Bool_Exp>;
  transactionId?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "extraRecipeLinesTransactions" */
export enum ExtraRecipeLinesTransactions_Constraint {
  /** unique or primary key constraint on columns "id" */
  ExtraRecipeLinesTransactionsPkey = 'extraRecipeLinesTransactions_pkey'
}

/** input type for inserting data into table "extraRecipeLinesTransactions" */
export type ExtraRecipeLinesTransactions_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  extraRecipeLine?: Maybe<ExtraRecipeLines_Obj_Rel_Insert_Input>;
  extraRecipeLineId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  transaction?: Maybe<Transactions_Obj_Rel_Insert_Input>;
  transactionId?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type ExtraRecipeLinesTransactions_Max_Fields = {
  __typename?: 'extraRecipeLinesTransactions_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  extraRecipeLineId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  transactionId?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "extraRecipeLinesTransactions" */
export type ExtraRecipeLinesTransactions_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  extraRecipeLineId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  transactionId?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type ExtraRecipeLinesTransactions_Min_Fields = {
  __typename?: 'extraRecipeLinesTransactions_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  extraRecipeLineId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  transactionId?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "extraRecipeLinesTransactions" */
export type ExtraRecipeLinesTransactions_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  extraRecipeLineId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  transactionId?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "extraRecipeLinesTransactions" */
export type ExtraRecipeLinesTransactions_Mutation_Response = {
  __typename?: 'extraRecipeLinesTransactions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ExtraRecipeLinesTransactions>;
};

/** on_conflict condition type for table "extraRecipeLinesTransactions" */
export type ExtraRecipeLinesTransactions_On_Conflict = {
  constraint: ExtraRecipeLinesTransactions_Constraint;
  update_columns: Array<ExtraRecipeLinesTransactions_Update_Column>;
  where?: Maybe<ExtraRecipeLinesTransactions_Bool_Exp>;
};

/** Ordering options when selecting data from "extraRecipeLinesTransactions". */
export type ExtraRecipeLinesTransactions_Order_By = {
  created_at?: Maybe<Order_By>;
  extraRecipeLine?: Maybe<ExtraRecipeLines_Order_By>;
  extraRecipeLineId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  transaction?: Maybe<Transactions_Order_By>;
  transactionId?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: extraRecipeLinesTransactions */
export type ExtraRecipeLinesTransactions_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "extraRecipeLinesTransactions" */
export enum ExtraRecipeLinesTransactions_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExtraRecipeLineId = 'extraRecipeLineId',
  /** column name */
  Id = 'id',
  /** column name */
  TransactionId = 'transactionId',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "extraRecipeLinesTransactions" */
export type ExtraRecipeLinesTransactions_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  extraRecipeLineId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  transactionId?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "extraRecipeLinesTransactions" */
export type ExtraRecipeLinesTransactions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ExtraRecipeLinesTransactions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ExtraRecipeLinesTransactions_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  extraRecipeLineId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  transactionId?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "extraRecipeLinesTransactions" */
export enum ExtraRecipeLinesTransactions_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExtraRecipeLineId = 'extraRecipeLineId',
  /** column name */
  Id = 'id',
  /** column name */
  TransactionId = 'transactionId',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type ExtraRecipeLinesTransactions_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<ExtraRecipeLinesTransactions_Set_Input>;
  /** filter the rows which have to be updated */
  where: ExtraRecipeLinesTransactions_Bool_Exp;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: Maybe<Scalars['Int']>;
  _gt?: Maybe<Scalars['Int']>;
  _gte?: Maybe<Scalars['Int']>;
  _in?: Maybe<Array<Scalars['Int']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Int']>;
  _lte?: Maybe<Scalars['Int']>;
  _neq?: Maybe<Scalars['Int']>;
  _nin?: Maybe<Array<Scalars['Int']>>;
};


export type Jsonb_Cast_Exp = {
  String?: Maybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: Maybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: Maybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: Maybe<Scalars['jsonb']>;
  _eq?: Maybe<Scalars['jsonb']>;
  _gt?: Maybe<Scalars['jsonb']>;
  _gte?: Maybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: Maybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: Maybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: Maybe<Array<Scalars['String']>>;
  _in?: Maybe<Array<Scalars['jsonb']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['jsonb']>;
  _lte?: Maybe<Scalars['jsonb']>;
  _neq?: Maybe<Scalars['jsonb']>;
  _nin?: Maybe<Array<Scalars['jsonb']>>;
};

/** Messages which are displayed to customers on the frontend */
export type LocationMessages = {
  __typename?: 'locationMessages';
  created_at: Scalars['timestamptz'];
  endDate: Scalars['date'];
  id: Scalars['uuid'];
  /** An object relationship */
  location: Locations;
  locationId: Scalars['uuid'];
  message?: Maybe<Scalars['String']>;
  startDate: Scalars['date'];
  title?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "locationMessages" */
export type LocationMessages_Aggregate = {
  __typename?: 'locationMessages_aggregate';
  aggregate?: Maybe<LocationMessages_Aggregate_Fields>;
  nodes: Array<LocationMessages>;
};

export type LocationMessages_Aggregate_Bool_Exp = {
  count?: Maybe<LocationMessages_Aggregate_Bool_Exp_Count>;
};

export type LocationMessages_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<LocationMessages_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<LocationMessages_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "locationMessages" */
export type LocationMessages_Aggregate_Fields = {
  __typename?: 'locationMessages_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<LocationMessages_Max_Fields>;
  min?: Maybe<LocationMessages_Min_Fields>;
};


/** aggregate fields of "locationMessages" */
export type LocationMessages_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<LocationMessages_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "locationMessages" */
export type LocationMessages_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<LocationMessages_Max_Order_By>;
  min?: Maybe<LocationMessages_Min_Order_By>;
};

/** input type for inserting array relation for remote table "locationMessages" */
export type LocationMessages_Arr_Rel_Insert_Input = {
  data: Array<LocationMessages_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<LocationMessages_On_Conflict>;
};

/** Boolean expression to filter rows from the table "locationMessages". All fields are combined with a logical 'AND'. */
export type LocationMessages_Bool_Exp = {
  _and?: Maybe<Array<LocationMessages_Bool_Exp>>;
  _not?: Maybe<LocationMessages_Bool_Exp>;
  _or?: Maybe<Array<LocationMessages_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  endDate?: Maybe<Date_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  location?: Maybe<Locations_Bool_Exp>;
  locationId?: Maybe<Uuid_Comparison_Exp>;
  message?: Maybe<String_Comparison_Exp>;
  startDate?: Maybe<Date_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "locationMessages" */
export enum LocationMessages_Constraint {
  /** unique or primary key constraint on columns "id" */
  LocationMessagesPkey = 'locationMessages_pkey'
}

/** input type for inserting data into table "locationMessages" */
export type LocationMessages_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  endDate?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  location?: Maybe<Locations_Obj_Rel_Insert_Input>;
  locationId?: Maybe<Scalars['uuid']>;
  message?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['date']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type LocationMessages_Max_Fields = {
  __typename?: 'locationMessages_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  endDate?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  locationId?: Maybe<Scalars['uuid']>;
  message?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['date']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "locationMessages" */
export type LocationMessages_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  endDate?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  locationId?: Maybe<Order_By>;
  message?: Maybe<Order_By>;
  startDate?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type LocationMessages_Min_Fields = {
  __typename?: 'locationMessages_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  endDate?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  locationId?: Maybe<Scalars['uuid']>;
  message?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['date']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "locationMessages" */
export type LocationMessages_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  endDate?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  locationId?: Maybe<Order_By>;
  message?: Maybe<Order_By>;
  startDate?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "locationMessages" */
export type LocationMessages_Mutation_Response = {
  __typename?: 'locationMessages_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LocationMessages>;
};

/** on_conflict condition type for table "locationMessages" */
export type LocationMessages_On_Conflict = {
  constraint: LocationMessages_Constraint;
  update_columns: Array<LocationMessages_Update_Column>;
  where?: Maybe<LocationMessages_Bool_Exp>;
};

/** Ordering options when selecting data from "locationMessages". */
export type LocationMessages_Order_By = {
  created_at?: Maybe<Order_By>;
  endDate?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location?: Maybe<Locations_Order_By>;
  locationId?: Maybe<Order_By>;
  message?: Maybe<Order_By>;
  startDate?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: locationMessages */
export type LocationMessages_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "locationMessages" */
export enum LocationMessages_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EndDate = 'endDate',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'locationId',
  /** column name */
  Message = 'message',
  /** column name */
  StartDate = 'startDate',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "locationMessages" */
export type LocationMessages_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  endDate?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  locationId?: Maybe<Scalars['uuid']>;
  message?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['date']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "locationMessages" */
export type LocationMessages_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: LocationMessages_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type LocationMessages_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  endDate?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  locationId?: Maybe<Scalars['uuid']>;
  message?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['date']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "locationMessages" */
export enum LocationMessages_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EndDate = 'endDate',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'locationId',
  /** column name */
  Message = 'message',
  /** column name */
  StartDate = 'startDate',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type LocationMessages_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<LocationMessages_Set_Input>;
  /** filter the rows which have to be updated */
  where: LocationMessages_Bool_Exp;
};

/** columns and relationships of "locations" */
export type Locations = {
  __typename?: 'locations';
  /** An array relationship */
  closingDates: Array<ClosingDates>;
  /** An aggregate relationship */
  closingDates_aggregate: ClosingDates_Aggregate;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  /** An array relationship */
  messages: Array<LocationMessages>;
  /** An aggregate relationship */
  messages_aggregate: LocationMessages_Aggregate;
  name: Scalars['String'];
  /** An array relationship */
  products: Array<Products>;
  /** An aggregate relationship */
  products_aggregate: Products_Aggregate;
  /** An array relationship */
  seasons: Array<Seasons>;
  /** An aggregate relationship */
  seasons_aggregate: Seasons_Aggregate;
  stealthMode?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "locations" */
export type LocationsClosingDatesArgs = {
  distinct_on?: Maybe<Array<ClosingDates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ClosingDates_Order_By>>;
  where?: Maybe<ClosingDates_Bool_Exp>;
};


/** columns and relationships of "locations" */
export type LocationsClosingDates_AggregateArgs = {
  distinct_on?: Maybe<Array<ClosingDates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ClosingDates_Order_By>>;
  where?: Maybe<ClosingDates_Bool_Exp>;
};


/** columns and relationships of "locations" */
export type LocationsMessagesArgs = {
  distinct_on?: Maybe<Array<LocationMessages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LocationMessages_Order_By>>;
  where?: Maybe<LocationMessages_Bool_Exp>;
};


/** columns and relationships of "locations" */
export type LocationsMessages_AggregateArgs = {
  distinct_on?: Maybe<Array<LocationMessages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LocationMessages_Order_By>>;
  where?: Maybe<LocationMessages_Bool_Exp>;
};


/** columns and relationships of "locations" */
export type LocationsProductsArgs = {
  distinct_on?: Maybe<Array<Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Products_Order_By>>;
  where?: Maybe<Products_Bool_Exp>;
};


/** columns and relationships of "locations" */
export type LocationsProducts_AggregateArgs = {
  distinct_on?: Maybe<Array<Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Products_Order_By>>;
  where?: Maybe<Products_Bool_Exp>;
};


/** columns and relationships of "locations" */
export type LocationsSeasonsArgs = {
  distinct_on?: Maybe<Array<Seasons_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Seasons_Order_By>>;
  where?: Maybe<Seasons_Bool_Exp>;
};


/** columns and relationships of "locations" */
export type LocationsSeasons_AggregateArgs = {
  distinct_on?: Maybe<Array<Seasons_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Seasons_Order_By>>;
  where?: Maybe<Seasons_Bool_Exp>;
};

/** aggregated selection of "locations" */
export type Locations_Aggregate = {
  __typename?: 'locations_aggregate';
  aggregate?: Maybe<Locations_Aggregate_Fields>;
  nodes: Array<Locations>;
};

/** aggregate fields of "locations" */
export type Locations_Aggregate_Fields = {
  __typename?: 'locations_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Locations_Max_Fields>;
  min?: Maybe<Locations_Min_Fields>;
};


/** aggregate fields of "locations" */
export type Locations_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Locations_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "locations". All fields are combined with a logical 'AND'. */
export type Locations_Bool_Exp = {
  _and?: Maybe<Array<Locations_Bool_Exp>>;
  _not?: Maybe<Locations_Bool_Exp>;
  _or?: Maybe<Array<Locations_Bool_Exp>>;
  closingDates?: Maybe<ClosingDates_Bool_Exp>;
  closingDates_aggregate?: Maybe<ClosingDates_Aggregate_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  messages?: Maybe<LocationMessages_Bool_Exp>;
  messages_aggregate?: Maybe<LocationMessages_Aggregate_Bool_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  products?: Maybe<Products_Bool_Exp>;
  products_aggregate?: Maybe<Products_Aggregate_Bool_Exp>;
  seasons?: Maybe<Seasons_Bool_Exp>;
  seasons_aggregate?: Maybe<Seasons_Aggregate_Bool_Exp>;
  stealthMode?: Maybe<Boolean_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "locations" */
export enum Locations_Constraint {
  /** unique or primary key constraint on columns "id" */
  LocationPkey = 'location_pkey'
}

/** input type for inserting data into table "locations" */
export type Locations_Insert_Input = {
  closingDates?: Maybe<ClosingDates_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  messages?: Maybe<LocationMessages_Arr_Rel_Insert_Input>;
  name?: Maybe<Scalars['String']>;
  products?: Maybe<Products_Arr_Rel_Insert_Input>;
  seasons?: Maybe<Seasons_Arr_Rel_Insert_Input>;
  stealthMode?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Locations_Max_Fields = {
  __typename?: 'locations_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Locations_Min_Fields = {
  __typename?: 'locations_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "locations" */
export type Locations_Mutation_Response = {
  __typename?: 'locations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Locations>;
};

/** input type for inserting object relation for remote table "locations" */
export type Locations_Obj_Rel_Insert_Input = {
  data: Locations_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Locations_On_Conflict>;
};

/** on_conflict condition type for table "locations" */
export type Locations_On_Conflict = {
  constraint: Locations_Constraint;
  update_columns: Array<Locations_Update_Column>;
  where?: Maybe<Locations_Bool_Exp>;
};

/** Ordering options when selecting data from "locations". */
export type Locations_Order_By = {
  closingDates_aggregate?: Maybe<ClosingDates_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  messages_aggregate?: Maybe<LocationMessages_Aggregate_Order_By>;
  name?: Maybe<Order_By>;
  products_aggregate?: Maybe<Products_Aggregate_Order_By>;
  seasons_aggregate?: Maybe<Seasons_Aggregate_Order_By>;
  stealthMode?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: locations */
export type Locations_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "locations" */
export enum Locations_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  StealthMode = 'stealthMode',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "locations" */
export type Locations_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  stealthMode?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "locations" */
export type Locations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Locations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Locations_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  stealthMode?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "locations" */
export enum Locations_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  StealthMode = 'stealthMode',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Locations_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Locations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Locations_Bool_Exp;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** delete data from the table: "bookingRequest" */
  delete_bookingRequest?: Maybe<BookingRequest_Mutation_Response>;
  /** delete single row from the table: "bookingRequest" */
  delete_bookingRequest_by_pk?: Maybe<BookingRequest>;
  /** delete data from the table: "bookings" */
  delete_bookings?: Maybe<Bookings_Mutation_Response>;
  /** delete single row from the table: "bookings" */
  delete_bookings_by_pk?: Maybe<Bookings>;
  /** delete data from the table: "bookings_transactions" */
  delete_bookings_transactions?: Maybe<Bookings_Transactions_Mutation_Response>;
  /** delete single row from the table: "bookings_transactions" */
  delete_bookings_transactions_by_pk?: Maybe<Bookings_Transactions>;
  /** delete data from the table: "closingDates" */
  delete_closingDates?: Maybe<ClosingDates_Mutation_Response>;
  /** delete single row from the table: "closingDates" */
  delete_closingDates_by_pk?: Maybe<ClosingDates>;
  /** delete data from the table: "customers" */
  delete_customers?: Maybe<Customers_Mutation_Response>;
  /** delete single row from the table: "customers" */
  delete_customers_by_pk?: Maybe<Customers>;
  /** delete data from the table: "extraRecipeLines" */
  delete_extraRecipeLines?: Maybe<ExtraRecipeLines_Mutation_Response>;
  /** delete data from the table: "extraRecipeLinesTransactions" */
  delete_extraRecipeLinesTransactions?: Maybe<ExtraRecipeLinesTransactions_Mutation_Response>;
  /** delete single row from the table: "extraRecipeLinesTransactions" */
  delete_extraRecipeLinesTransactions_by_pk?: Maybe<ExtraRecipeLinesTransactions>;
  /** delete single row from the table: "extraRecipeLines" */
  delete_extraRecipeLines_by_pk?: Maybe<ExtraRecipeLines>;
  /** delete data from the table: "locationMessages" */
  delete_locationMessages?: Maybe<LocationMessages_Mutation_Response>;
  /** delete single row from the table: "locationMessages" */
  delete_locationMessages_by_pk?: Maybe<LocationMessages>;
  /** delete data from the table: "locations" */
  delete_locations?: Maybe<Locations_Mutation_Response>;
  /** delete single row from the table: "locations" */
  delete_locations_by_pk?: Maybe<Locations>;
  /** delete data from the table: "orders" */
  delete_orders?: Maybe<Orders_Mutation_Response>;
  /** delete single row from the table: "orders" */
  delete_orders_by_pk?: Maybe<Orders>;
  /** delete data from the table: "productPrices" */
  delete_productPrices?: Maybe<ProductPrices_Mutation_Response>;
  /** delete single row from the table: "productPrices" */
  delete_productPrices_by_pk?: Maybe<ProductPrices>;
  /** delete data from the table: "productTransactions" */
  delete_productTransactions?: Maybe<ProductTransactions_Mutation_Response>;
  /** delete single row from the table: "productTransactions" */
  delete_productTransactions_by_pk?: Maybe<ProductTransactions>;
  /** delete data from the table: "productTypeModels" */
  delete_productTypeModels?: Maybe<ProductTypeModels_Mutation_Response>;
  /** delete single row from the table: "productTypeModels" */
  delete_productTypeModels_by_pk?: Maybe<ProductTypeModels>;
  /** delete data from the table: "productTypes" */
  delete_productTypes?: Maybe<ProductTypes_Mutation_Response>;
  /** delete single row from the table: "productTypes" */
  delete_productTypes_by_pk?: Maybe<ProductTypes>;
  /** delete data from the table: "products" */
  delete_products?: Maybe<Products_Mutation_Response>;
  /** delete single row from the table: "products" */
  delete_products_by_pk?: Maybe<Products>;
  /** delete data from the table: "publicSettings" */
  delete_publicSettings?: Maybe<PublicSettings_Mutation_Response>;
  /** delete single row from the table: "publicSettings" */
  delete_publicSettings_by_pk?: Maybe<PublicSettings>;
  /** delete data from the table: "seasons" */
  delete_seasons?: Maybe<Seasons_Mutation_Response>;
  /** delete single row from the table: "seasons" */
  delete_seasons_by_pk?: Maybe<Seasons>;
  /** delete data from the table: "snapshotAvailabilities" */
  delete_snapshotAvailabilities?: Maybe<SnapshotAvailabilities_Mutation_Response>;
  /** delete single row from the table: "snapshotAvailabilities" */
  delete_snapshotAvailabilities_by_pk?: Maybe<SnapshotAvailabilities>;
  /** delete data from the table: "snapshotBookings" */
  delete_snapshotBookings?: Maybe<SnapshotBookings_Mutation_Response>;
  /** delete single row from the table: "snapshotBookings" */
  delete_snapshotBookings_by_pk?: Maybe<SnapshotBookings>;
  /** delete data from the table: "transactions" */
  delete_transactions?: Maybe<Transactions_Mutation_Response>;
  /** delete single row from the table: "transactions" */
  delete_transactions_by_pk?: Maybe<Transactions>;
  /** insert data into the table: "bookingRequest" */
  insert_bookingRequest?: Maybe<BookingRequest_Mutation_Response>;
  /** insert a single row into the table: "bookingRequest" */
  insert_bookingRequest_one?: Maybe<BookingRequest>;
  /** insert data into the table: "bookings" */
  insert_bookings?: Maybe<Bookings_Mutation_Response>;
  /** insert a single row into the table: "bookings" */
  insert_bookings_one?: Maybe<Bookings>;
  /** insert data into the table: "bookings_transactions" */
  insert_bookings_transactions?: Maybe<Bookings_Transactions_Mutation_Response>;
  /** insert a single row into the table: "bookings_transactions" */
  insert_bookings_transactions_one?: Maybe<Bookings_Transactions>;
  /** insert data into the table: "closingDates" */
  insert_closingDates?: Maybe<ClosingDates_Mutation_Response>;
  /** insert a single row into the table: "closingDates" */
  insert_closingDates_one?: Maybe<ClosingDates>;
  /** insert data into the table: "customers" */
  insert_customers?: Maybe<Customers_Mutation_Response>;
  /** insert a single row into the table: "customers" */
  insert_customers_one?: Maybe<Customers>;
  /** insert data into the table: "extraRecipeLines" */
  insert_extraRecipeLines?: Maybe<ExtraRecipeLines_Mutation_Response>;
  /** insert data into the table: "extraRecipeLinesTransactions" */
  insert_extraRecipeLinesTransactions?: Maybe<ExtraRecipeLinesTransactions_Mutation_Response>;
  /** insert a single row into the table: "extraRecipeLinesTransactions" */
  insert_extraRecipeLinesTransactions_one?: Maybe<ExtraRecipeLinesTransactions>;
  /** insert a single row into the table: "extraRecipeLines" */
  insert_extraRecipeLines_one?: Maybe<ExtraRecipeLines>;
  /** insert data into the table: "locationMessages" */
  insert_locationMessages?: Maybe<LocationMessages_Mutation_Response>;
  /** insert a single row into the table: "locationMessages" */
  insert_locationMessages_one?: Maybe<LocationMessages>;
  /** insert data into the table: "locations" */
  insert_locations?: Maybe<Locations_Mutation_Response>;
  /** insert a single row into the table: "locations" */
  insert_locations_one?: Maybe<Locations>;
  /** insert data into the table: "orders" */
  insert_orders?: Maybe<Orders_Mutation_Response>;
  /** insert a single row into the table: "orders" */
  insert_orders_one?: Maybe<Orders>;
  /** insert data into the table: "productPrices" */
  insert_productPrices?: Maybe<ProductPrices_Mutation_Response>;
  /** insert a single row into the table: "productPrices" */
  insert_productPrices_one?: Maybe<ProductPrices>;
  /** insert data into the table: "productTransactions" */
  insert_productTransactions?: Maybe<ProductTransactions_Mutation_Response>;
  /** insert a single row into the table: "productTransactions" */
  insert_productTransactions_one?: Maybe<ProductTransactions>;
  /** insert data into the table: "productTypeModels" */
  insert_productTypeModels?: Maybe<ProductTypeModels_Mutation_Response>;
  /** insert a single row into the table: "productTypeModels" */
  insert_productTypeModels_one?: Maybe<ProductTypeModels>;
  /** insert data into the table: "productTypes" */
  insert_productTypes?: Maybe<ProductTypes_Mutation_Response>;
  /** insert a single row into the table: "productTypes" */
  insert_productTypes_one?: Maybe<ProductTypes>;
  /** insert data into the table: "products" */
  insert_products?: Maybe<Products_Mutation_Response>;
  /** insert a single row into the table: "products" */
  insert_products_one?: Maybe<Products>;
  /** insert data into the table: "publicSettings" */
  insert_publicSettings?: Maybe<PublicSettings_Mutation_Response>;
  /** insert a single row into the table: "publicSettings" */
  insert_publicSettings_one?: Maybe<PublicSettings>;
  /** insert data into the table: "seasons" */
  insert_seasons?: Maybe<Seasons_Mutation_Response>;
  /** insert a single row into the table: "seasons" */
  insert_seasons_one?: Maybe<Seasons>;
  /** insert data into the table: "snapshotAvailabilities" */
  insert_snapshotAvailabilities?: Maybe<SnapshotAvailabilities_Mutation_Response>;
  /** insert a single row into the table: "snapshotAvailabilities" */
  insert_snapshotAvailabilities_one?: Maybe<SnapshotAvailabilities>;
  /** insert data into the table: "snapshotBookings" */
  insert_snapshotBookings?: Maybe<SnapshotBookings_Mutation_Response>;
  /** insert a single row into the table: "snapshotBookings" */
  insert_snapshotBookings_one?: Maybe<SnapshotBookings>;
  /** insert data into the table: "transactions" */
  insert_transactions?: Maybe<Transactions_Mutation_Response>;
  /** insert a single row into the table: "transactions" */
  insert_transactions_one?: Maybe<Transactions>;
  /** requestProlongOrder */
  requestProlongOrder?: Maybe<RequestProlongOrderResult>;
  triggerCancellationEmail?: Maybe<TriggerVerificationEmailResult>;
  triggerConfirmationEmail?: Maybe<TriggerConfirmationEmailResult>;
  /** triggerVerificationEmail */
  triggerVerificationEmail?: Maybe<TriggerVerificationEmailResult>;
  /** update data of the table: "bookingRequest" */
  update_bookingRequest?: Maybe<BookingRequest_Mutation_Response>;
  /** update single row of the table: "bookingRequest" */
  update_bookingRequest_by_pk?: Maybe<BookingRequest>;
  /** update multiples rows of table: "bookingRequest" */
  update_bookingRequest_many?: Maybe<Array<Maybe<BookingRequest_Mutation_Response>>>;
  /** update data of the table: "bookings" */
  update_bookings?: Maybe<Bookings_Mutation_Response>;
  /** update single row of the table: "bookings" */
  update_bookings_by_pk?: Maybe<Bookings>;
  /** update multiples rows of table: "bookings" */
  update_bookings_many?: Maybe<Array<Maybe<Bookings_Mutation_Response>>>;
  /** update data of the table: "bookings_transactions" */
  update_bookings_transactions?: Maybe<Bookings_Transactions_Mutation_Response>;
  /** update single row of the table: "bookings_transactions" */
  update_bookings_transactions_by_pk?: Maybe<Bookings_Transactions>;
  /** update multiples rows of table: "bookings_transactions" */
  update_bookings_transactions_many?: Maybe<Array<Maybe<Bookings_Transactions_Mutation_Response>>>;
  /** update data of the table: "closingDates" */
  update_closingDates?: Maybe<ClosingDates_Mutation_Response>;
  /** update single row of the table: "closingDates" */
  update_closingDates_by_pk?: Maybe<ClosingDates>;
  /** update multiples rows of table: "closingDates" */
  update_closingDates_many?: Maybe<Array<Maybe<ClosingDates_Mutation_Response>>>;
  /** update data of the table: "customers" */
  update_customers?: Maybe<Customers_Mutation_Response>;
  /** update single row of the table: "customers" */
  update_customers_by_pk?: Maybe<Customers>;
  /** update multiples rows of table: "customers" */
  update_customers_many?: Maybe<Array<Maybe<Customers_Mutation_Response>>>;
  /** update data of the table: "extraRecipeLines" */
  update_extraRecipeLines?: Maybe<ExtraRecipeLines_Mutation_Response>;
  /** update data of the table: "extraRecipeLinesTransactions" */
  update_extraRecipeLinesTransactions?: Maybe<ExtraRecipeLinesTransactions_Mutation_Response>;
  /** update single row of the table: "extraRecipeLinesTransactions" */
  update_extraRecipeLinesTransactions_by_pk?: Maybe<ExtraRecipeLinesTransactions>;
  /** update multiples rows of table: "extraRecipeLinesTransactions" */
  update_extraRecipeLinesTransactions_many?: Maybe<Array<Maybe<ExtraRecipeLinesTransactions_Mutation_Response>>>;
  /** update single row of the table: "extraRecipeLines" */
  update_extraRecipeLines_by_pk?: Maybe<ExtraRecipeLines>;
  /** update multiples rows of table: "extraRecipeLines" */
  update_extraRecipeLines_many?: Maybe<Array<Maybe<ExtraRecipeLines_Mutation_Response>>>;
  /** update data of the table: "locationMessages" */
  update_locationMessages?: Maybe<LocationMessages_Mutation_Response>;
  /** update single row of the table: "locationMessages" */
  update_locationMessages_by_pk?: Maybe<LocationMessages>;
  /** update multiples rows of table: "locationMessages" */
  update_locationMessages_many?: Maybe<Array<Maybe<LocationMessages_Mutation_Response>>>;
  /** update data of the table: "locations" */
  update_locations?: Maybe<Locations_Mutation_Response>;
  /** update single row of the table: "locations" */
  update_locations_by_pk?: Maybe<Locations>;
  /** update multiples rows of table: "locations" */
  update_locations_many?: Maybe<Array<Maybe<Locations_Mutation_Response>>>;
  /** update data of the table: "orders" */
  update_orders?: Maybe<Orders_Mutation_Response>;
  /** update single row of the table: "orders" */
  update_orders_by_pk?: Maybe<Orders>;
  /** update multiples rows of table: "orders" */
  update_orders_many?: Maybe<Array<Maybe<Orders_Mutation_Response>>>;
  /** update data of the table: "productPrices" */
  update_productPrices?: Maybe<ProductPrices_Mutation_Response>;
  /** update single row of the table: "productPrices" */
  update_productPrices_by_pk?: Maybe<ProductPrices>;
  /** update multiples rows of table: "productPrices" */
  update_productPrices_many?: Maybe<Array<Maybe<ProductPrices_Mutation_Response>>>;
  /** update data of the table: "productTransactions" */
  update_productTransactions?: Maybe<ProductTransactions_Mutation_Response>;
  /** update single row of the table: "productTransactions" */
  update_productTransactions_by_pk?: Maybe<ProductTransactions>;
  /** update multiples rows of table: "productTransactions" */
  update_productTransactions_many?: Maybe<Array<Maybe<ProductTransactions_Mutation_Response>>>;
  /** update data of the table: "productTypeModels" */
  update_productTypeModels?: Maybe<ProductTypeModels_Mutation_Response>;
  /** update single row of the table: "productTypeModels" */
  update_productTypeModels_by_pk?: Maybe<ProductTypeModels>;
  /** update multiples rows of table: "productTypeModels" */
  update_productTypeModels_many?: Maybe<Array<Maybe<ProductTypeModels_Mutation_Response>>>;
  /** update data of the table: "productTypes" */
  update_productTypes?: Maybe<ProductTypes_Mutation_Response>;
  /** update single row of the table: "productTypes" */
  update_productTypes_by_pk?: Maybe<ProductTypes>;
  /** update multiples rows of table: "productTypes" */
  update_productTypes_many?: Maybe<Array<Maybe<ProductTypes_Mutation_Response>>>;
  /** update data of the table: "products" */
  update_products?: Maybe<Products_Mutation_Response>;
  /** update single row of the table: "products" */
  update_products_by_pk?: Maybe<Products>;
  /** update multiples rows of table: "products" */
  update_products_many?: Maybe<Array<Maybe<Products_Mutation_Response>>>;
  /** update data of the table: "publicSettings" */
  update_publicSettings?: Maybe<PublicSettings_Mutation_Response>;
  /** update single row of the table: "publicSettings" */
  update_publicSettings_by_pk?: Maybe<PublicSettings>;
  /** update multiples rows of table: "publicSettings" */
  update_publicSettings_many?: Maybe<Array<Maybe<PublicSettings_Mutation_Response>>>;
  /** update data of the table: "seasons" */
  update_seasons?: Maybe<Seasons_Mutation_Response>;
  /** update single row of the table: "seasons" */
  update_seasons_by_pk?: Maybe<Seasons>;
  /** update multiples rows of table: "seasons" */
  update_seasons_many?: Maybe<Array<Maybe<Seasons_Mutation_Response>>>;
  /** update data of the table: "snapshotAvailabilities" */
  update_snapshotAvailabilities?: Maybe<SnapshotAvailabilities_Mutation_Response>;
  /** update single row of the table: "snapshotAvailabilities" */
  update_snapshotAvailabilities_by_pk?: Maybe<SnapshotAvailabilities>;
  /** update multiples rows of table: "snapshotAvailabilities" */
  update_snapshotAvailabilities_many?: Maybe<Array<Maybe<SnapshotAvailabilities_Mutation_Response>>>;
  /** update data of the table: "snapshotBookings" */
  update_snapshotBookings?: Maybe<SnapshotBookings_Mutation_Response>;
  /** update single row of the table: "snapshotBookings" */
  update_snapshotBookings_by_pk?: Maybe<SnapshotBookings>;
  /** update multiples rows of table: "snapshotBookings" */
  update_snapshotBookings_many?: Maybe<Array<Maybe<SnapshotBookings_Mutation_Response>>>;
  /** update data of the table: "transactions" */
  update_transactions?: Maybe<Transactions_Mutation_Response>;
  /** update single row of the table: "transactions" */
  update_transactions_by_pk?: Maybe<Transactions>;
  /** update multiples rows of table: "transactions" */
  update_transactions_many?: Maybe<Array<Maybe<Transactions_Mutation_Response>>>;
  verifyOrder?: Maybe<VerifyOrderResult>;
};


/** mutation root */
export type Mutation_RootDelete_BookingRequestArgs = {
  where: BookingRequest_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_BookingRequest_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_BookingsArgs = {
  where: Bookings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Bookings_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Bookings_TransactionsArgs = {
  where: Bookings_Transactions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Bookings_Transactions_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ClosingDatesArgs = {
  where: ClosingDates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ClosingDates_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_CustomersArgs = {
  where: Customers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Customers_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ExtraRecipeLinesArgs = {
  where: ExtraRecipeLines_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ExtraRecipeLinesTransactionsArgs = {
  where: ExtraRecipeLinesTransactions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ExtraRecipeLinesTransactions_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ExtraRecipeLines_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_LocationMessagesArgs = {
  where: LocationMessages_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_LocationMessages_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_LocationsArgs = {
  where: Locations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Locations_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_OrdersArgs = {
  where: Orders_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Orders_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ProductPricesArgs = {
  where: ProductPrices_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ProductPrices_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ProductTransactionsArgs = {
  where: ProductTransactions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ProductTransactions_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ProductTypeModelsArgs = {
  where: ProductTypeModels_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ProductTypeModels_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_ProductTypesArgs = {
  where: ProductTypes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ProductTypes_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ProductsArgs = {
  where: Products_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Products_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_PublicSettingsArgs = {
  where: PublicSettings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_PublicSettings_By_PkArgs = {
  key: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_SeasonsArgs = {
  where: Seasons_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Seasons_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_SnapshotAvailabilitiesArgs = {
  where: SnapshotAvailabilities_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_SnapshotAvailabilities_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_SnapshotBookingsArgs = {
  where: SnapshotBookings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_SnapshotBookings_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_TransactionsArgs = {
  where: Transactions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Transactions_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootInsert_BookingRequestArgs = {
  objects: Array<BookingRequest_Insert_Input>;
  on_conflict?: Maybe<BookingRequest_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_BookingRequest_OneArgs = {
  object: BookingRequest_Insert_Input;
  on_conflict?: Maybe<BookingRequest_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_BookingsArgs = {
  objects: Array<Bookings_Insert_Input>;
  on_conflict?: Maybe<Bookings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Bookings_OneArgs = {
  object: Bookings_Insert_Input;
  on_conflict?: Maybe<Bookings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Bookings_TransactionsArgs = {
  objects: Array<Bookings_Transactions_Insert_Input>;
  on_conflict?: Maybe<Bookings_Transactions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Bookings_Transactions_OneArgs = {
  object: Bookings_Transactions_Insert_Input;
  on_conflict?: Maybe<Bookings_Transactions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ClosingDatesArgs = {
  objects: Array<ClosingDates_Insert_Input>;
  on_conflict?: Maybe<ClosingDates_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ClosingDates_OneArgs = {
  object: ClosingDates_Insert_Input;
  on_conflict?: Maybe<ClosingDates_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CustomersArgs = {
  objects: Array<Customers_Insert_Input>;
  on_conflict?: Maybe<Customers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Customers_OneArgs = {
  object: Customers_Insert_Input;
  on_conflict?: Maybe<Customers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ExtraRecipeLinesArgs = {
  objects: Array<ExtraRecipeLines_Insert_Input>;
  on_conflict?: Maybe<ExtraRecipeLines_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ExtraRecipeLinesTransactionsArgs = {
  objects: Array<ExtraRecipeLinesTransactions_Insert_Input>;
  on_conflict?: Maybe<ExtraRecipeLinesTransactions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ExtraRecipeLinesTransactions_OneArgs = {
  object: ExtraRecipeLinesTransactions_Insert_Input;
  on_conflict?: Maybe<ExtraRecipeLinesTransactions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ExtraRecipeLines_OneArgs = {
  object: ExtraRecipeLines_Insert_Input;
  on_conflict?: Maybe<ExtraRecipeLines_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_LocationMessagesArgs = {
  objects: Array<LocationMessages_Insert_Input>;
  on_conflict?: Maybe<LocationMessages_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_LocationMessages_OneArgs = {
  object: LocationMessages_Insert_Input;
  on_conflict?: Maybe<LocationMessages_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_LocationsArgs = {
  objects: Array<Locations_Insert_Input>;
  on_conflict?: Maybe<Locations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Locations_OneArgs = {
  object: Locations_Insert_Input;
  on_conflict?: Maybe<Locations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_OrdersArgs = {
  objects: Array<Orders_Insert_Input>;
  on_conflict?: Maybe<Orders_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Orders_OneArgs = {
  object: Orders_Insert_Input;
  on_conflict?: Maybe<Orders_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ProductPricesArgs = {
  objects: Array<ProductPrices_Insert_Input>;
  on_conflict?: Maybe<ProductPrices_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ProductPrices_OneArgs = {
  object: ProductPrices_Insert_Input;
  on_conflict?: Maybe<ProductPrices_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ProductTransactionsArgs = {
  objects: Array<ProductTransactions_Insert_Input>;
  on_conflict?: Maybe<ProductTransactions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ProductTransactions_OneArgs = {
  object: ProductTransactions_Insert_Input;
  on_conflict?: Maybe<ProductTransactions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ProductTypeModelsArgs = {
  objects: Array<ProductTypeModels_Insert_Input>;
  on_conflict?: Maybe<ProductTypeModels_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ProductTypeModels_OneArgs = {
  object: ProductTypeModels_Insert_Input;
  on_conflict?: Maybe<ProductTypeModels_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ProductTypesArgs = {
  objects: Array<ProductTypes_Insert_Input>;
  on_conflict?: Maybe<ProductTypes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ProductTypes_OneArgs = {
  object: ProductTypes_Insert_Input;
  on_conflict?: Maybe<ProductTypes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ProductsArgs = {
  objects: Array<Products_Insert_Input>;
  on_conflict?: Maybe<Products_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Products_OneArgs = {
  object: Products_Insert_Input;
  on_conflict?: Maybe<Products_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PublicSettingsArgs = {
  objects: Array<PublicSettings_Insert_Input>;
  on_conflict?: Maybe<PublicSettings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PublicSettings_OneArgs = {
  object: PublicSettings_Insert_Input;
  on_conflict?: Maybe<PublicSettings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SeasonsArgs = {
  objects: Array<Seasons_Insert_Input>;
  on_conflict?: Maybe<Seasons_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Seasons_OneArgs = {
  object: Seasons_Insert_Input;
  on_conflict?: Maybe<Seasons_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SnapshotAvailabilitiesArgs = {
  objects: Array<SnapshotAvailabilities_Insert_Input>;
  on_conflict?: Maybe<SnapshotAvailabilities_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SnapshotAvailabilities_OneArgs = {
  object: SnapshotAvailabilities_Insert_Input;
  on_conflict?: Maybe<SnapshotAvailabilities_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SnapshotBookingsArgs = {
  objects: Array<SnapshotBookings_Insert_Input>;
  on_conflict?: Maybe<SnapshotBookings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SnapshotBookings_OneArgs = {
  object: SnapshotBookings_Insert_Input;
  on_conflict?: Maybe<SnapshotBookings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TransactionsArgs = {
  objects: Array<Transactions_Insert_Input>;
  on_conflict?: Maybe<Transactions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Transactions_OneArgs = {
  object: Transactions_Insert_Input;
  on_conflict?: Maybe<Transactions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootRequestProlongOrderArgs = {
  newEndDate: Scalars['String'];
  orderId: Scalars['String'];
};


/** mutation root */
export type Mutation_RootTriggerCancellationEmailArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootTriggerConfirmationEmailArgs = {
  id: Scalars['String'];
  includeExtraData?: Maybe<Scalars['Boolean']>;
  isChange?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
};


/** mutation root */
export type Mutation_RootTriggerVerificationEmailArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootUpdate_BookingRequestArgs = {
  _append?: Maybe<BookingRequest_Append_Input>;
  _delete_at_path?: Maybe<BookingRequest_Delete_At_Path_Input>;
  _delete_elem?: Maybe<BookingRequest_Delete_Elem_Input>;
  _delete_key?: Maybe<BookingRequest_Delete_Key_Input>;
  _prepend?: Maybe<BookingRequest_Prepend_Input>;
  _set?: Maybe<BookingRequest_Set_Input>;
  where: BookingRequest_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_BookingRequest_By_PkArgs = {
  _append?: Maybe<BookingRequest_Append_Input>;
  _delete_at_path?: Maybe<BookingRequest_Delete_At_Path_Input>;
  _delete_elem?: Maybe<BookingRequest_Delete_Elem_Input>;
  _delete_key?: Maybe<BookingRequest_Delete_Key_Input>;
  _prepend?: Maybe<BookingRequest_Prepend_Input>;
  _set?: Maybe<BookingRequest_Set_Input>;
  pk_columns: BookingRequest_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_BookingRequest_ManyArgs = {
  updates: Array<BookingRequest_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_BookingsArgs = {
  _append?: Maybe<Bookings_Append_Input>;
  _delete_at_path?: Maybe<Bookings_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Bookings_Delete_Elem_Input>;
  _delete_key?: Maybe<Bookings_Delete_Key_Input>;
  _prepend?: Maybe<Bookings_Prepend_Input>;
  _set?: Maybe<Bookings_Set_Input>;
  where: Bookings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Bookings_By_PkArgs = {
  _append?: Maybe<Bookings_Append_Input>;
  _delete_at_path?: Maybe<Bookings_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Bookings_Delete_Elem_Input>;
  _delete_key?: Maybe<Bookings_Delete_Key_Input>;
  _prepend?: Maybe<Bookings_Prepend_Input>;
  _set?: Maybe<Bookings_Set_Input>;
  pk_columns: Bookings_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Bookings_ManyArgs = {
  updates: Array<Bookings_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Bookings_TransactionsArgs = {
  _set?: Maybe<Bookings_Transactions_Set_Input>;
  where: Bookings_Transactions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Bookings_Transactions_By_PkArgs = {
  _set?: Maybe<Bookings_Transactions_Set_Input>;
  pk_columns: Bookings_Transactions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Bookings_Transactions_ManyArgs = {
  updates: Array<Bookings_Transactions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ClosingDatesArgs = {
  _set?: Maybe<ClosingDates_Set_Input>;
  where: ClosingDates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ClosingDates_By_PkArgs = {
  _set?: Maybe<ClosingDates_Set_Input>;
  pk_columns: ClosingDates_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ClosingDates_ManyArgs = {
  updates: Array<ClosingDates_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CustomersArgs = {
  _set?: Maybe<Customers_Set_Input>;
  where: Customers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Customers_By_PkArgs = {
  _set?: Maybe<Customers_Set_Input>;
  pk_columns: Customers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Customers_ManyArgs = {
  updates: Array<Customers_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ExtraRecipeLinesArgs = {
  _inc?: Maybe<ExtraRecipeLines_Inc_Input>;
  _set?: Maybe<ExtraRecipeLines_Set_Input>;
  where: ExtraRecipeLines_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ExtraRecipeLinesTransactionsArgs = {
  _set?: Maybe<ExtraRecipeLinesTransactions_Set_Input>;
  where: ExtraRecipeLinesTransactions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ExtraRecipeLinesTransactions_By_PkArgs = {
  _set?: Maybe<ExtraRecipeLinesTransactions_Set_Input>;
  pk_columns: ExtraRecipeLinesTransactions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ExtraRecipeLinesTransactions_ManyArgs = {
  updates: Array<ExtraRecipeLinesTransactions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ExtraRecipeLines_By_PkArgs = {
  _inc?: Maybe<ExtraRecipeLines_Inc_Input>;
  _set?: Maybe<ExtraRecipeLines_Set_Input>;
  pk_columns: ExtraRecipeLines_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ExtraRecipeLines_ManyArgs = {
  updates: Array<ExtraRecipeLines_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_LocationMessagesArgs = {
  _set?: Maybe<LocationMessages_Set_Input>;
  where: LocationMessages_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_LocationMessages_By_PkArgs = {
  _set?: Maybe<LocationMessages_Set_Input>;
  pk_columns: LocationMessages_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_LocationMessages_ManyArgs = {
  updates: Array<LocationMessages_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_LocationsArgs = {
  _set?: Maybe<Locations_Set_Input>;
  where: Locations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Locations_By_PkArgs = {
  _set?: Maybe<Locations_Set_Input>;
  pk_columns: Locations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Locations_ManyArgs = {
  updates: Array<Locations_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_OrdersArgs = {
  _set?: Maybe<Orders_Set_Input>;
  where: Orders_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Orders_By_PkArgs = {
  _set?: Maybe<Orders_Set_Input>;
  pk_columns: Orders_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Orders_ManyArgs = {
  updates: Array<Orders_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ProductPricesArgs = {
  _inc?: Maybe<ProductPrices_Inc_Input>;
  _set?: Maybe<ProductPrices_Set_Input>;
  where: ProductPrices_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ProductPrices_By_PkArgs = {
  _inc?: Maybe<ProductPrices_Inc_Input>;
  _set?: Maybe<ProductPrices_Set_Input>;
  pk_columns: ProductPrices_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ProductPrices_ManyArgs = {
  updates: Array<ProductPrices_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ProductTransactionsArgs = {
  _append?: Maybe<ProductTransactions_Append_Input>;
  _delete_at_path?: Maybe<ProductTransactions_Delete_At_Path_Input>;
  _delete_elem?: Maybe<ProductTransactions_Delete_Elem_Input>;
  _delete_key?: Maybe<ProductTransactions_Delete_Key_Input>;
  _prepend?: Maybe<ProductTransactions_Prepend_Input>;
  _set?: Maybe<ProductTransactions_Set_Input>;
  where: ProductTransactions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ProductTransactions_By_PkArgs = {
  _append?: Maybe<ProductTransactions_Append_Input>;
  _delete_at_path?: Maybe<ProductTransactions_Delete_At_Path_Input>;
  _delete_elem?: Maybe<ProductTransactions_Delete_Elem_Input>;
  _delete_key?: Maybe<ProductTransactions_Delete_Key_Input>;
  _prepend?: Maybe<ProductTransactions_Prepend_Input>;
  _set?: Maybe<ProductTransactions_Set_Input>;
  pk_columns: ProductTransactions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ProductTransactions_ManyArgs = {
  updates: Array<ProductTransactions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ProductTypeModelsArgs = {
  _set?: Maybe<ProductTypeModels_Set_Input>;
  where: ProductTypeModels_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ProductTypeModels_By_PkArgs = {
  _set?: Maybe<ProductTypeModels_Set_Input>;
  pk_columns: ProductTypeModels_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ProductTypeModels_ManyArgs = {
  updates: Array<ProductTypeModels_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ProductTypesArgs = {
  _inc?: Maybe<ProductTypes_Inc_Input>;
  _set?: Maybe<ProductTypes_Set_Input>;
  where: ProductTypes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ProductTypes_By_PkArgs = {
  _inc?: Maybe<ProductTypes_Inc_Input>;
  _set?: Maybe<ProductTypes_Set_Input>;
  pk_columns: ProductTypes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ProductTypes_ManyArgs = {
  updates: Array<ProductTypes_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ProductsArgs = {
  _inc?: Maybe<Products_Inc_Input>;
  _set?: Maybe<Products_Set_Input>;
  where: Products_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Products_By_PkArgs = {
  _inc?: Maybe<Products_Inc_Input>;
  _set?: Maybe<Products_Set_Input>;
  pk_columns: Products_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Products_ManyArgs = {
  updates: Array<Products_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_PublicSettingsArgs = {
  _set?: Maybe<PublicSettings_Set_Input>;
  where: PublicSettings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_PublicSettings_By_PkArgs = {
  _set?: Maybe<PublicSettings_Set_Input>;
  pk_columns: PublicSettings_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_PublicSettings_ManyArgs = {
  updates: Array<PublicSettings_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_SeasonsArgs = {
  _set?: Maybe<Seasons_Set_Input>;
  where: Seasons_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Seasons_By_PkArgs = {
  _set?: Maybe<Seasons_Set_Input>;
  pk_columns: Seasons_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Seasons_ManyArgs = {
  updates: Array<Seasons_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_SnapshotAvailabilitiesArgs = {
  _inc?: Maybe<SnapshotAvailabilities_Inc_Input>;
  _set?: Maybe<SnapshotAvailabilities_Set_Input>;
  where: SnapshotAvailabilities_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_SnapshotAvailabilities_By_PkArgs = {
  _inc?: Maybe<SnapshotAvailabilities_Inc_Input>;
  _set?: Maybe<SnapshotAvailabilities_Set_Input>;
  pk_columns: SnapshotAvailabilities_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_SnapshotAvailabilities_ManyArgs = {
  updates: Array<SnapshotAvailabilities_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_SnapshotBookingsArgs = {
  _append?: Maybe<SnapshotBookings_Append_Input>;
  _delete_at_path?: Maybe<SnapshotBookings_Delete_At_Path_Input>;
  _delete_elem?: Maybe<SnapshotBookings_Delete_Elem_Input>;
  _delete_key?: Maybe<SnapshotBookings_Delete_Key_Input>;
  _prepend?: Maybe<SnapshotBookings_Prepend_Input>;
  _set?: Maybe<SnapshotBookings_Set_Input>;
  where: SnapshotBookings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_SnapshotBookings_By_PkArgs = {
  _append?: Maybe<SnapshotBookings_Append_Input>;
  _delete_at_path?: Maybe<SnapshotBookings_Delete_At_Path_Input>;
  _delete_elem?: Maybe<SnapshotBookings_Delete_Elem_Input>;
  _delete_key?: Maybe<SnapshotBookings_Delete_Key_Input>;
  _prepend?: Maybe<SnapshotBookings_Prepend_Input>;
  _set?: Maybe<SnapshotBookings_Set_Input>;
  pk_columns: SnapshotBookings_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_SnapshotBookings_ManyArgs = {
  updates: Array<SnapshotBookings_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_TransactionsArgs = {
  _append?: Maybe<Transactions_Append_Input>;
  _delete_at_path?: Maybe<Transactions_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Transactions_Delete_Elem_Input>;
  _delete_key?: Maybe<Transactions_Delete_Key_Input>;
  _prepend?: Maybe<Transactions_Prepend_Input>;
  _set?: Maybe<Transactions_Set_Input>;
  where: Transactions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Transactions_By_PkArgs = {
  _append?: Maybe<Transactions_Append_Input>;
  _delete_at_path?: Maybe<Transactions_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Transactions_Delete_Elem_Input>;
  _delete_key?: Maybe<Transactions_Delete_Key_Input>;
  _prepend?: Maybe<Transactions_Prepend_Input>;
  _set?: Maybe<Transactions_Set_Input>;
  pk_columns: Transactions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Transactions_ManyArgs = {
  updates: Array<Transactions_Updates>;
};


/** mutation root */
export type Mutation_RootVerifyOrderArgs = {
  id: Scalars['String'];
};


/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: Maybe<Scalars['numeric']>;
  _gt?: Maybe<Scalars['numeric']>;
  _gte?: Maybe<Scalars['numeric']>;
  _in?: Maybe<Array<Scalars['numeric']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['numeric']>;
  _lte?: Maybe<Scalars['numeric']>;
  _neq?: Maybe<Scalars['numeric']>;
  _nin?: Maybe<Array<Scalars['numeric']>>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "orders" */
export type Orders = {
  __typename?: 'orders';
  /** An array relationship */
  bookings: Array<Bookings>;
  /** An aggregate relationship */
  bookings_aggregate: Bookings_Aggregate;
  cancellation_sent_at?: Maybe<Scalars['timestamptz']>;
  confirmation_sent_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  customer: Customers;
  customerId: Scalars['uuid'];
  duration: Scalars['String'];
  endDate?: Maybe<Scalars['date']>;
  /** An array relationship */
  extraRecipeLines: Array<ExtraRecipeLines>;
  /** An aggregate relationship */
  extraRecipeLines_aggregate: ExtraRecipeLines_Aggregate;
  id: Scalars['uuid'];
  /** An array relationship */
  snapshotBookings: Array<SnapshotBookings>;
  /** An aggregate relationship */
  snapshotBookings_aggregate: SnapshotBookings_Aggregate;
  startDate: Scalars['date'];
  updated_at?: Maybe<Scalars['timestamptz']>;
  verification_sent_at?: Maybe<Scalars['timestamptz']>;
  verified?: Maybe<Scalars['Boolean']>;
};


/** columns and relationships of "orders" */
export type OrdersBookingsArgs = {
  distinct_on?: Maybe<Array<Bookings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bookings_Order_By>>;
  where?: Maybe<Bookings_Bool_Exp>;
};


/** columns and relationships of "orders" */
export type OrdersBookings_AggregateArgs = {
  distinct_on?: Maybe<Array<Bookings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bookings_Order_By>>;
  where?: Maybe<Bookings_Bool_Exp>;
};


/** columns and relationships of "orders" */
export type OrdersExtraRecipeLinesArgs = {
  distinct_on?: Maybe<Array<ExtraRecipeLines_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ExtraRecipeLines_Order_By>>;
  where?: Maybe<ExtraRecipeLines_Bool_Exp>;
};


/** columns and relationships of "orders" */
export type OrdersExtraRecipeLines_AggregateArgs = {
  distinct_on?: Maybe<Array<ExtraRecipeLines_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ExtraRecipeLines_Order_By>>;
  where?: Maybe<ExtraRecipeLines_Bool_Exp>;
};


/** columns and relationships of "orders" */
export type OrdersSnapshotBookingsArgs = {
  distinct_on?: Maybe<Array<SnapshotBookings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SnapshotBookings_Order_By>>;
  where?: Maybe<SnapshotBookings_Bool_Exp>;
};


/** columns and relationships of "orders" */
export type OrdersSnapshotBookings_AggregateArgs = {
  distinct_on?: Maybe<Array<SnapshotBookings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SnapshotBookings_Order_By>>;
  where?: Maybe<SnapshotBookings_Bool_Exp>;
};

/** aggregated selection of "orders" */
export type Orders_Aggregate = {
  __typename?: 'orders_aggregate';
  aggregate?: Maybe<Orders_Aggregate_Fields>;
  nodes: Array<Orders>;
};

export type Orders_Aggregate_Bool_Exp = {
  bool_and?: Maybe<Orders_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<Orders_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<Orders_Aggregate_Bool_Exp_Count>;
};

export type Orders_Aggregate_Bool_Exp_Bool_And = {
  arguments: Orders_Select_Column_Orders_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Orders_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Orders_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Orders_Select_Column_Orders_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Orders_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Orders_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Orders_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Orders_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "orders" */
export type Orders_Aggregate_Fields = {
  __typename?: 'orders_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Orders_Max_Fields>;
  min?: Maybe<Orders_Min_Fields>;
};


/** aggregate fields of "orders" */
export type Orders_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Orders_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "orders" */
export type Orders_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Orders_Max_Order_By>;
  min?: Maybe<Orders_Min_Order_By>;
};

/** input type for inserting array relation for remote table "orders" */
export type Orders_Arr_Rel_Insert_Input = {
  data: Array<Orders_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Orders_On_Conflict>;
};

/** Boolean expression to filter rows from the table "orders". All fields are combined with a logical 'AND'. */
export type Orders_Bool_Exp = {
  _and?: Maybe<Array<Orders_Bool_Exp>>;
  _not?: Maybe<Orders_Bool_Exp>;
  _or?: Maybe<Array<Orders_Bool_Exp>>;
  bookings?: Maybe<Bookings_Bool_Exp>;
  bookings_aggregate?: Maybe<Bookings_Aggregate_Bool_Exp>;
  cancellation_sent_at?: Maybe<Timestamptz_Comparison_Exp>;
  confirmation_sent_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  customer?: Maybe<Customers_Bool_Exp>;
  customerId?: Maybe<Uuid_Comparison_Exp>;
  duration?: Maybe<String_Comparison_Exp>;
  endDate?: Maybe<Date_Comparison_Exp>;
  extraRecipeLines?: Maybe<ExtraRecipeLines_Bool_Exp>;
  extraRecipeLines_aggregate?: Maybe<ExtraRecipeLines_Aggregate_Bool_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  snapshotBookings?: Maybe<SnapshotBookings_Bool_Exp>;
  snapshotBookings_aggregate?: Maybe<SnapshotBookings_Aggregate_Bool_Exp>;
  startDate?: Maybe<Date_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  verification_sent_at?: Maybe<Timestamptz_Comparison_Exp>;
  verified?: Maybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "orders" */
export enum Orders_Constraint {
  /** unique or primary key constraint on columns "id" */
  OrdersPkey = 'orders_pkey'
}

/** input type for inserting data into table "orders" */
export type Orders_Insert_Input = {
  bookings?: Maybe<Bookings_Arr_Rel_Insert_Input>;
  cancellation_sent_at?: Maybe<Scalars['timestamptz']>;
  confirmation_sent_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer?: Maybe<Customers_Obj_Rel_Insert_Input>;
  customerId?: Maybe<Scalars['uuid']>;
  duration?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['date']>;
  extraRecipeLines?: Maybe<ExtraRecipeLines_Arr_Rel_Insert_Input>;
  id?: Maybe<Scalars['uuid']>;
  snapshotBookings?: Maybe<SnapshotBookings_Arr_Rel_Insert_Input>;
  startDate?: Maybe<Scalars['date']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  verification_sent_at?: Maybe<Scalars['timestamptz']>;
  verified?: Maybe<Scalars['Boolean']>;
};

/** aggregate max on columns */
export type Orders_Max_Fields = {
  __typename?: 'orders_max_fields';
  cancellation_sent_at?: Maybe<Scalars['timestamptz']>;
  confirmation_sent_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customerId?: Maybe<Scalars['uuid']>;
  duration?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  startDate?: Maybe<Scalars['date']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  verification_sent_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "orders" */
export type Orders_Max_Order_By = {
  cancellation_sent_at?: Maybe<Order_By>;
  confirmation_sent_at?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  customerId?: Maybe<Order_By>;
  duration?: Maybe<Order_By>;
  endDate?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  startDate?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  verification_sent_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Orders_Min_Fields = {
  __typename?: 'orders_min_fields';
  cancellation_sent_at?: Maybe<Scalars['timestamptz']>;
  confirmation_sent_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customerId?: Maybe<Scalars['uuid']>;
  duration?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  startDate?: Maybe<Scalars['date']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  verification_sent_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "orders" */
export type Orders_Min_Order_By = {
  cancellation_sent_at?: Maybe<Order_By>;
  confirmation_sent_at?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  customerId?: Maybe<Order_By>;
  duration?: Maybe<Order_By>;
  endDate?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  startDate?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  verification_sent_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "orders" */
export type Orders_Mutation_Response = {
  __typename?: 'orders_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Orders>;
};

/** input type for inserting object relation for remote table "orders" */
export type Orders_Obj_Rel_Insert_Input = {
  data: Orders_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Orders_On_Conflict>;
};

/** on_conflict condition type for table "orders" */
export type Orders_On_Conflict = {
  constraint: Orders_Constraint;
  update_columns: Array<Orders_Update_Column>;
  where?: Maybe<Orders_Bool_Exp>;
};

/** Ordering options when selecting data from "orders". */
export type Orders_Order_By = {
  bookings_aggregate?: Maybe<Bookings_Aggregate_Order_By>;
  cancellation_sent_at?: Maybe<Order_By>;
  confirmation_sent_at?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  customer?: Maybe<Customers_Order_By>;
  customerId?: Maybe<Order_By>;
  duration?: Maybe<Order_By>;
  endDate?: Maybe<Order_By>;
  extraRecipeLines_aggregate?: Maybe<ExtraRecipeLines_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  snapshotBookings_aggregate?: Maybe<SnapshotBookings_Aggregate_Order_By>;
  startDate?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  verification_sent_at?: Maybe<Order_By>;
  verified?: Maybe<Order_By>;
};

/** primary key columns input for table: orders */
export type Orders_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "orders" */
export enum Orders_Select_Column {
  /** column name */
  CancellationSentAt = 'cancellation_sent_at',
  /** column name */
  ConfirmationSentAt = 'confirmation_sent_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customerId',
  /** column name */
  Duration = 'duration',
  /** column name */
  EndDate = 'endDate',
  /** column name */
  Id = 'id',
  /** column name */
  StartDate = 'startDate',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VerificationSentAt = 'verification_sent_at',
  /** column name */
  Verified = 'verified'
}

/** select "orders_aggregate_bool_exp_bool_and_arguments_columns" columns of table "orders" */
export enum Orders_Select_Column_Orders_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Verified = 'verified'
}

/** select "orders_aggregate_bool_exp_bool_or_arguments_columns" columns of table "orders" */
export enum Orders_Select_Column_Orders_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Verified = 'verified'
}

/** input type for updating data in table "orders" */
export type Orders_Set_Input = {
  cancellation_sent_at?: Maybe<Scalars['timestamptz']>;
  confirmation_sent_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customerId?: Maybe<Scalars['uuid']>;
  duration?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  startDate?: Maybe<Scalars['date']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  verification_sent_at?: Maybe<Scalars['timestamptz']>;
  verified?: Maybe<Scalars['Boolean']>;
};

/** Streaming cursor of the table "orders" */
export type Orders_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Orders_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Orders_Stream_Cursor_Value_Input = {
  cancellation_sent_at?: Maybe<Scalars['timestamptz']>;
  confirmation_sent_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customerId?: Maybe<Scalars['uuid']>;
  duration?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  startDate?: Maybe<Scalars['date']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  verification_sent_at?: Maybe<Scalars['timestamptz']>;
  verified?: Maybe<Scalars['Boolean']>;
};

/** update columns of table "orders" */
export enum Orders_Update_Column {
  /** column name */
  CancellationSentAt = 'cancellation_sent_at',
  /** column name */
  ConfirmationSentAt = 'confirmation_sent_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customerId',
  /** column name */
  Duration = 'duration',
  /** column name */
  EndDate = 'endDate',
  /** column name */
  Id = 'id',
  /** column name */
  StartDate = 'startDate',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VerificationSentAt = 'verification_sent_at',
  /** column name */
  Verified = 'verified'
}

export type Orders_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Orders_Set_Input>;
  /** filter the rows which have to be updated */
  where: Orders_Bool_Exp;
};

/** columns and relationships of "productPrices" */
export type ProductPrices = {
  __typename?: 'productPrices';
  basePricePerDay: Scalars['Int'];
  id: Scalars['uuid'];
  insuranceCostsPerDay: Scalars['Int'];
  minDuration: Scalars['numeric'];
  /** An object relationship */
  product: Products;
  productId: Scalars['uuid'];
  protectionCostsPerDay: Scalars['Int'];
};

/** aggregated selection of "productPrices" */
export type ProductPrices_Aggregate = {
  __typename?: 'productPrices_aggregate';
  aggregate?: Maybe<ProductPrices_Aggregate_Fields>;
  nodes: Array<ProductPrices>;
};

export type ProductPrices_Aggregate_Bool_Exp = {
  count?: Maybe<ProductPrices_Aggregate_Bool_Exp_Count>;
};

export type ProductPrices_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<ProductPrices_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<ProductPrices_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "productPrices" */
export type ProductPrices_Aggregate_Fields = {
  __typename?: 'productPrices_aggregate_fields';
  avg?: Maybe<ProductPrices_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ProductPrices_Max_Fields>;
  min?: Maybe<ProductPrices_Min_Fields>;
  stddev?: Maybe<ProductPrices_Stddev_Fields>;
  stddev_pop?: Maybe<ProductPrices_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ProductPrices_Stddev_Samp_Fields>;
  sum?: Maybe<ProductPrices_Sum_Fields>;
  var_pop?: Maybe<ProductPrices_Var_Pop_Fields>;
  var_samp?: Maybe<ProductPrices_Var_Samp_Fields>;
  variance?: Maybe<ProductPrices_Variance_Fields>;
};


/** aggregate fields of "productPrices" */
export type ProductPrices_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<ProductPrices_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "productPrices" */
export type ProductPrices_Aggregate_Order_By = {
  avg?: Maybe<ProductPrices_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<ProductPrices_Max_Order_By>;
  min?: Maybe<ProductPrices_Min_Order_By>;
  stddev?: Maybe<ProductPrices_Stddev_Order_By>;
  stddev_pop?: Maybe<ProductPrices_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<ProductPrices_Stddev_Samp_Order_By>;
  sum?: Maybe<ProductPrices_Sum_Order_By>;
  var_pop?: Maybe<ProductPrices_Var_Pop_Order_By>;
  var_samp?: Maybe<ProductPrices_Var_Samp_Order_By>;
  variance?: Maybe<ProductPrices_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "productPrices" */
export type ProductPrices_Arr_Rel_Insert_Input = {
  data: Array<ProductPrices_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<ProductPrices_On_Conflict>;
};

/** aggregate avg on columns */
export type ProductPrices_Avg_Fields = {
  __typename?: 'productPrices_avg_fields';
  basePricePerDay?: Maybe<Scalars['Float']>;
  insuranceCostsPerDay?: Maybe<Scalars['Float']>;
  minDuration?: Maybe<Scalars['Float']>;
  protectionCostsPerDay?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "productPrices" */
export type ProductPrices_Avg_Order_By = {
  basePricePerDay?: Maybe<Order_By>;
  insuranceCostsPerDay?: Maybe<Order_By>;
  minDuration?: Maybe<Order_By>;
  protectionCostsPerDay?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "productPrices". All fields are combined with a logical 'AND'. */
export type ProductPrices_Bool_Exp = {
  _and?: Maybe<Array<ProductPrices_Bool_Exp>>;
  _not?: Maybe<ProductPrices_Bool_Exp>;
  _or?: Maybe<Array<ProductPrices_Bool_Exp>>;
  basePricePerDay?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  insuranceCostsPerDay?: Maybe<Int_Comparison_Exp>;
  minDuration?: Maybe<Numeric_Comparison_Exp>;
  product?: Maybe<Products_Bool_Exp>;
  productId?: Maybe<Uuid_Comparison_Exp>;
  protectionCostsPerDay?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "productPrices" */
export enum ProductPrices_Constraint {
  /** unique or primary key constraint on columns "minDuration", "productId" */
  ProductPricesMinDurationProductIdKey = 'productPrices_minDuration_productId_key',
  /** unique or primary key constraint on columns "id" */
  ProductPricesPkey = 'productPrices_pkey'
}

/** input type for incrementing numeric columns in table "productPrices" */
export type ProductPrices_Inc_Input = {
  basePricePerDay?: Maybe<Scalars['Int']>;
  insuranceCostsPerDay?: Maybe<Scalars['Int']>;
  minDuration?: Maybe<Scalars['numeric']>;
  protectionCostsPerDay?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "productPrices" */
export type ProductPrices_Insert_Input = {
  basePricePerDay?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  insuranceCostsPerDay?: Maybe<Scalars['Int']>;
  minDuration?: Maybe<Scalars['numeric']>;
  product?: Maybe<Products_Obj_Rel_Insert_Input>;
  productId?: Maybe<Scalars['uuid']>;
  protectionCostsPerDay?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type ProductPrices_Max_Fields = {
  __typename?: 'productPrices_max_fields';
  basePricePerDay?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  insuranceCostsPerDay?: Maybe<Scalars['Int']>;
  minDuration?: Maybe<Scalars['numeric']>;
  productId?: Maybe<Scalars['uuid']>;
  protectionCostsPerDay?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "productPrices" */
export type ProductPrices_Max_Order_By = {
  basePricePerDay?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  insuranceCostsPerDay?: Maybe<Order_By>;
  minDuration?: Maybe<Order_By>;
  productId?: Maybe<Order_By>;
  protectionCostsPerDay?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type ProductPrices_Min_Fields = {
  __typename?: 'productPrices_min_fields';
  basePricePerDay?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  insuranceCostsPerDay?: Maybe<Scalars['Int']>;
  minDuration?: Maybe<Scalars['numeric']>;
  productId?: Maybe<Scalars['uuid']>;
  protectionCostsPerDay?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "productPrices" */
export type ProductPrices_Min_Order_By = {
  basePricePerDay?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  insuranceCostsPerDay?: Maybe<Order_By>;
  minDuration?: Maybe<Order_By>;
  productId?: Maybe<Order_By>;
  protectionCostsPerDay?: Maybe<Order_By>;
};

/** response of any mutation on the table "productPrices" */
export type ProductPrices_Mutation_Response = {
  __typename?: 'productPrices_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ProductPrices>;
};

/** on_conflict condition type for table "productPrices" */
export type ProductPrices_On_Conflict = {
  constraint: ProductPrices_Constraint;
  update_columns: Array<ProductPrices_Update_Column>;
  where?: Maybe<ProductPrices_Bool_Exp>;
};

/** Ordering options when selecting data from "productPrices". */
export type ProductPrices_Order_By = {
  basePricePerDay?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  insuranceCostsPerDay?: Maybe<Order_By>;
  minDuration?: Maybe<Order_By>;
  product?: Maybe<Products_Order_By>;
  productId?: Maybe<Order_By>;
  protectionCostsPerDay?: Maybe<Order_By>;
};

/** primary key columns input for table: productPrices */
export type ProductPrices_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "productPrices" */
export enum ProductPrices_Select_Column {
  /** column name */
  BasePricePerDay = 'basePricePerDay',
  /** column name */
  Id = 'id',
  /** column name */
  InsuranceCostsPerDay = 'insuranceCostsPerDay',
  /** column name */
  MinDuration = 'minDuration',
  /** column name */
  ProductId = 'productId',
  /** column name */
  ProtectionCostsPerDay = 'protectionCostsPerDay'
}

/** input type for updating data in table "productPrices" */
export type ProductPrices_Set_Input = {
  basePricePerDay?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  insuranceCostsPerDay?: Maybe<Scalars['Int']>;
  minDuration?: Maybe<Scalars['numeric']>;
  productId?: Maybe<Scalars['uuid']>;
  protectionCostsPerDay?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type ProductPrices_Stddev_Fields = {
  __typename?: 'productPrices_stddev_fields';
  basePricePerDay?: Maybe<Scalars['Float']>;
  insuranceCostsPerDay?: Maybe<Scalars['Float']>;
  minDuration?: Maybe<Scalars['Float']>;
  protectionCostsPerDay?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "productPrices" */
export type ProductPrices_Stddev_Order_By = {
  basePricePerDay?: Maybe<Order_By>;
  insuranceCostsPerDay?: Maybe<Order_By>;
  minDuration?: Maybe<Order_By>;
  protectionCostsPerDay?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type ProductPrices_Stddev_Pop_Fields = {
  __typename?: 'productPrices_stddev_pop_fields';
  basePricePerDay?: Maybe<Scalars['Float']>;
  insuranceCostsPerDay?: Maybe<Scalars['Float']>;
  minDuration?: Maybe<Scalars['Float']>;
  protectionCostsPerDay?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "productPrices" */
export type ProductPrices_Stddev_Pop_Order_By = {
  basePricePerDay?: Maybe<Order_By>;
  insuranceCostsPerDay?: Maybe<Order_By>;
  minDuration?: Maybe<Order_By>;
  protectionCostsPerDay?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type ProductPrices_Stddev_Samp_Fields = {
  __typename?: 'productPrices_stddev_samp_fields';
  basePricePerDay?: Maybe<Scalars['Float']>;
  insuranceCostsPerDay?: Maybe<Scalars['Float']>;
  minDuration?: Maybe<Scalars['Float']>;
  protectionCostsPerDay?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "productPrices" */
export type ProductPrices_Stddev_Samp_Order_By = {
  basePricePerDay?: Maybe<Order_By>;
  insuranceCostsPerDay?: Maybe<Order_By>;
  minDuration?: Maybe<Order_By>;
  protectionCostsPerDay?: Maybe<Order_By>;
};

/** Streaming cursor of the table "productPrices" */
export type ProductPrices_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ProductPrices_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ProductPrices_Stream_Cursor_Value_Input = {
  basePricePerDay?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  insuranceCostsPerDay?: Maybe<Scalars['Int']>;
  minDuration?: Maybe<Scalars['numeric']>;
  productId?: Maybe<Scalars['uuid']>;
  protectionCostsPerDay?: Maybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type ProductPrices_Sum_Fields = {
  __typename?: 'productPrices_sum_fields';
  basePricePerDay?: Maybe<Scalars['Int']>;
  insuranceCostsPerDay?: Maybe<Scalars['Int']>;
  minDuration?: Maybe<Scalars['numeric']>;
  protectionCostsPerDay?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "productPrices" */
export type ProductPrices_Sum_Order_By = {
  basePricePerDay?: Maybe<Order_By>;
  insuranceCostsPerDay?: Maybe<Order_By>;
  minDuration?: Maybe<Order_By>;
  protectionCostsPerDay?: Maybe<Order_By>;
};

/** update columns of table "productPrices" */
export enum ProductPrices_Update_Column {
  /** column name */
  BasePricePerDay = 'basePricePerDay',
  /** column name */
  Id = 'id',
  /** column name */
  InsuranceCostsPerDay = 'insuranceCostsPerDay',
  /** column name */
  MinDuration = 'minDuration',
  /** column name */
  ProductId = 'productId',
  /** column name */
  ProtectionCostsPerDay = 'protectionCostsPerDay'
}

export type ProductPrices_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<ProductPrices_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<ProductPrices_Set_Input>;
  /** filter the rows which have to be updated */
  where: ProductPrices_Bool_Exp;
};

/** aggregate var_pop on columns */
export type ProductPrices_Var_Pop_Fields = {
  __typename?: 'productPrices_var_pop_fields';
  basePricePerDay?: Maybe<Scalars['Float']>;
  insuranceCostsPerDay?: Maybe<Scalars['Float']>;
  minDuration?: Maybe<Scalars['Float']>;
  protectionCostsPerDay?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "productPrices" */
export type ProductPrices_Var_Pop_Order_By = {
  basePricePerDay?: Maybe<Order_By>;
  insuranceCostsPerDay?: Maybe<Order_By>;
  minDuration?: Maybe<Order_By>;
  protectionCostsPerDay?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type ProductPrices_Var_Samp_Fields = {
  __typename?: 'productPrices_var_samp_fields';
  basePricePerDay?: Maybe<Scalars['Float']>;
  insuranceCostsPerDay?: Maybe<Scalars['Float']>;
  minDuration?: Maybe<Scalars['Float']>;
  protectionCostsPerDay?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "productPrices" */
export type ProductPrices_Var_Samp_Order_By = {
  basePricePerDay?: Maybe<Order_By>;
  insuranceCostsPerDay?: Maybe<Order_By>;
  minDuration?: Maybe<Order_By>;
  protectionCostsPerDay?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type ProductPrices_Variance_Fields = {
  __typename?: 'productPrices_variance_fields';
  basePricePerDay?: Maybe<Scalars['Float']>;
  insuranceCostsPerDay?: Maybe<Scalars['Float']>;
  minDuration?: Maybe<Scalars['Float']>;
  protectionCostsPerDay?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "productPrices" */
export type ProductPrices_Variance_Order_By = {
  basePricePerDay?: Maybe<Order_By>;
  insuranceCostsPerDay?: Maybe<Order_By>;
  minDuration?: Maybe<Order_By>;
  protectionCostsPerDay?: Maybe<Order_By>;
};

/** columns and relationships of "products" */
export type Products = {
  __typename?: 'products';
  bikesAvailable?: Maybe<Scalars['Int']>;
  /** An array relationship */
  bookings: Array<Bookings>;
  /** An aggregate relationship */
  bookings_aggregate: Bookings_Aggregate;
  description?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  /** An object relationship */
  location: Locations;
  locationId: Scalars['uuid'];
  /** An array relationship */
  productPrices: Array<ProductPrices>;
  /** An aggregate relationship */
  productPrices_aggregate: ProductPrices_Aggregate;
  /** An array relationship */
  productTransactions: Array<ProductTransactions>;
  /** An aggregate relationship */
  productTransactions_aggregate: ProductTransactions_Aggregate;
  /** An object relationship */
  productType: ProductTypes;
  productTypeId: Scalars['uuid'];
  size: Scalars['String'];
  /** An array relationship */
  snapshotAvailabilities: Array<SnapshotAvailabilities>;
  /** An aggregate relationship */
  snapshotAvailabilities_aggregate: SnapshotAvailabilities_Aggregate;
  sortPosition?: Maybe<Scalars['Int']>;
};


/** columns and relationships of "products" */
export type ProductsBookingsArgs = {
  distinct_on?: Maybe<Array<Bookings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bookings_Order_By>>;
  where?: Maybe<Bookings_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsBookings_AggregateArgs = {
  distinct_on?: Maybe<Array<Bookings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bookings_Order_By>>;
  where?: Maybe<Bookings_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsProductPricesArgs = {
  distinct_on?: Maybe<Array<ProductPrices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductPrices_Order_By>>;
  where?: Maybe<ProductPrices_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsProductPrices_AggregateArgs = {
  distinct_on?: Maybe<Array<ProductPrices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductPrices_Order_By>>;
  where?: Maybe<ProductPrices_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsProductTransactionsArgs = {
  distinct_on?: Maybe<Array<ProductTransactions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductTransactions_Order_By>>;
  where?: Maybe<ProductTransactions_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsProductTransactions_AggregateArgs = {
  distinct_on?: Maybe<Array<ProductTransactions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductTransactions_Order_By>>;
  where?: Maybe<ProductTransactions_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsSnapshotAvailabilitiesArgs = {
  distinct_on?: Maybe<Array<SnapshotAvailabilities_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SnapshotAvailabilities_Order_By>>;
  where?: Maybe<SnapshotAvailabilities_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsSnapshotAvailabilities_AggregateArgs = {
  distinct_on?: Maybe<Array<SnapshotAvailabilities_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SnapshotAvailabilities_Order_By>>;
  where?: Maybe<SnapshotAvailabilities_Bool_Exp>;
};

/** aggregated selection of "products" */
export type Products_Aggregate = {
  __typename?: 'products_aggregate';
  aggregate?: Maybe<Products_Aggregate_Fields>;
  nodes: Array<Products>;
};

export type Products_Aggregate_Bool_Exp = {
  count?: Maybe<Products_Aggregate_Bool_Exp_Count>;
};

export type Products_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Products_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Products_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "products" */
export type Products_Aggregate_Fields = {
  __typename?: 'products_aggregate_fields';
  avg?: Maybe<Products_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Products_Max_Fields>;
  min?: Maybe<Products_Min_Fields>;
  stddev?: Maybe<Products_Stddev_Fields>;
  stddev_pop?: Maybe<Products_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Products_Stddev_Samp_Fields>;
  sum?: Maybe<Products_Sum_Fields>;
  var_pop?: Maybe<Products_Var_Pop_Fields>;
  var_samp?: Maybe<Products_Var_Samp_Fields>;
  variance?: Maybe<Products_Variance_Fields>;
};


/** aggregate fields of "products" */
export type Products_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Products_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "products" */
export type Products_Aggregate_Order_By = {
  avg?: Maybe<Products_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Products_Max_Order_By>;
  min?: Maybe<Products_Min_Order_By>;
  stddev?: Maybe<Products_Stddev_Order_By>;
  stddev_pop?: Maybe<Products_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Products_Stddev_Samp_Order_By>;
  sum?: Maybe<Products_Sum_Order_By>;
  var_pop?: Maybe<Products_Var_Pop_Order_By>;
  var_samp?: Maybe<Products_Var_Samp_Order_By>;
  variance?: Maybe<Products_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "products" */
export type Products_Arr_Rel_Insert_Input = {
  data: Array<Products_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Products_On_Conflict>;
};

/** aggregate avg on columns */
export type Products_Avg_Fields = {
  __typename?: 'products_avg_fields';
  bikesAvailable?: Maybe<Scalars['Float']>;
  sortPosition?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "products" */
export type Products_Avg_Order_By = {
  bikesAvailable?: Maybe<Order_By>;
  sortPosition?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "products". All fields are combined with a logical 'AND'. */
export type Products_Bool_Exp = {
  _and?: Maybe<Array<Products_Bool_Exp>>;
  _not?: Maybe<Products_Bool_Exp>;
  _or?: Maybe<Array<Products_Bool_Exp>>;
  bikesAvailable?: Maybe<Int_Comparison_Exp>;
  bookings?: Maybe<Bookings_Bool_Exp>;
  bookings_aggregate?: Maybe<Bookings_Aggregate_Bool_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  location?: Maybe<Locations_Bool_Exp>;
  locationId?: Maybe<Uuid_Comparison_Exp>;
  productPrices?: Maybe<ProductPrices_Bool_Exp>;
  productPrices_aggregate?: Maybe<ProductPrices_Aggregate_Bool_Exp>;
  productTransactions?: Maybe<ProductTransactions_Bool_Exp>;
  productTransactions_aggregate?: Maybe<ProductTransactions_Aggregate_Bool_Exp>;
  productType?: Maybe<ProductTypes_Bool_Exp>;
  productTypeId?: Maybe<Uuid_Comparison_Exp>;
  size?: Maybe<String_Comparison_Exp>;
  snapshotAvailabilities?: Maybe<SnapshotAvailabilities_Bool_Exp>;
  snapshotAvailabilities_aggregate?: Maybe<SnapshotAvailabilities_Aggregate_Bool_Exp>;
  sortPosition?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "products" */
export enum Products_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProductsPkey = 'products_pkey',
  /** unique or primary key constraint on columns "productTypeId", "size", "locationId" */
  ProductsProductTypeIdSizeLocationIdKey = 'products_productTypeId_size_locationId_key'
}

/** input type for incrementing numeric columns in table "products" */
export type Products_Inc_Input = {
  bikesAvailable?: Maybe<Scalars['Int']>;
  sortPosition?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "products" */
export type Products_Insert_Input = {
  bikesAvailable?: Maybe<Scalars['Int']>;
  bookings?: Maybe<Bookings_Arr_Rel_Insert_Input>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  location?: Maybe<Locations_Obj_Rel_Insert_Input>;
  locationId?: Maybe<Scalars['uuid']>;
  productPrices?: Maybe<ProductPrices_Arr_Rel_Insert_Input>;
  productTransactions?: Maybe<ProductTransactions_Arr_Rel_Insert_Input>;
  productType?: Maybe<ProductTypes_Obj_Rel_Insert_Input>;
  productTypeId?: Maybe<Scalars['uuid']>;
  size?: Maybe<Scalars['String']>;
  snapshotAvailabilities?: Maybe<SnapshotAvailabilities_Arr_Rel_Insert_Input>;
  sortPosition?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Products_Max_Fields = {
  __typename?: 'products_max_fields';
  bikesAvailable?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  locationId?: Maybe<Scalars['uuid']>;
  productTypeId?: Maybe<Scalars['uuid']>;
  size?: Maybe<Scalars['String']>;
  sortPosition?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "products" */
export type Products_Max_Order_By = {
  bikesAvailable?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  locationId?: Maybe<Order_By>;
  productTypeId?: Maybe<Order_By>;
  size?: Maybe<Order_By>;
  sortPosition?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Products_Min_Fields = {
  __typename?: 'products_min_fields';
  bikesAvailable?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  locationId?: Maybe<Scalars['uuid']>;
  productTypeId?: Maybe<Scalars['uuid']>;
  size?: Maybe<Scalars['String']>;
  sortPosition?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "products" */
export type Products_Min_Order_By = {
  bikesAvailable?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  locationId?: Maybe<Order_By>;
  productTypeId?: Maybe<Order_By>;
  size?: Maybe<Order_By>;
  sortPosition?: Maybe<Order_By>;
};

/** response of any mutation on the table "products" */
export type Products_Mutation_Response = {
  __typename?: 'products_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Products>;
};

/** input type for inserting object relation for remote table "products" */
export type Products_Obj_Rel_Insert_Input = {
  data: Products_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Products_On_Conflict>;
};

/** on_conflict condition type for table "products" */
export type Products_On_Conflict = {
  constraint: Products_Constraint;
  update_columns: Array<Products_Update_Column>;
  where?: Maybe<Products_Bool_Exp>;
};

/** Ordering options when selecting data from "products". */
export type Products_Order_By = {
  bikesAvailable?: Maybe<Order_By>;
  bookings_aggregate?: Maybe<Bookings_Aggregate_Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location?: Maybe<Locations_Order_By>;
  locationId?: Maybe<Order_By>;
  productPrices_aggregate?: Maybe<ProductPrices_Aggregate_Order_By>;
  productTransactions_aggregate?: Maybe<ProductTransactions_Aggregate_Order_By>;
  productType?: Maybe<ProductTypes_Order_By>;
  productTypeId?: Maybe<Order_By>;
  size?: Maybe<Order_By>;
  snapshotAvailabilities_aggregate?: Maybe<SnapshotAvailabilities_Aggregate_Order_By>;
  sortPosition?: Maybe<Order_By>;
};

/** primary key columns input for table: products */
export type Products_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "products" */
export enum Products_Select_Column {
  /** column name */
  BikesAvailable = 'bikesAvailable',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'locationId',
  /** column name */
  ProductTypeId = 'productTypeId',
  /** column name */
  Size = 'size',
  /** column name */
  SortPosition = 'sortPosition'
}

/** input type for updating data in table "products" */
export type Products_Set_Input = {
  bikesAvailable?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  locationId?: Maybe<Scalars['uuid']>;
  productTypeId?: Maybe<Scalars['uuid']>;
  size?: Maybe<Scalars['String']>;
  sortPosition?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Products_Stddev_Fields = {
  __typename?: 'products_stddev_fields';
  bikesAvailable?: Maybe<Scalars['Float']>;
  sortPosition?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "products" */
export type Products_Stddev_Order_By = {
  bikesAvailable?: Maybe<Order_By>;
  sortPosition?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Products_Stddev_Pop_Fields = {
  __typename?: 'products_stddev_pop_fields';
  bikesAvailable?: Maybe<Scalars['Float']>;
  sortPosition?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "products" */
export type Products_Stddev_Pop_Order_By = {
  bikesAvailable?: Maybe<Order_By>;
  sortPosition?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Products_Stddev_Samp_Fields = {
  __typename?: 'products_stddev_samp_fields';
  bikesAvailable?: Maybe<Scalars['Float']>;
  sortPosition?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "products" */
export type Products_Stddev_Samp_Order_By = {
  bikesAvailable?: Maybe<Order_By>;
  sortPosition?: Maybe<Order_By>;
};

/** Streaming cursor of the table "products" */
export type Products_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Products_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Products_Stream_Cursor_Value_Input = {
  bikesAvailable?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  locationId?: Maybe<Scalars['uuid']>;
  productTypeId?: Maybe<Scalars['uuid']>;
  size?: Maybe<Scalars['String']>;
  sortPosition?: Maybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Products_Sum_Fields = {
  __typename?: 'products_sum_fields';
  bikesAvailable?: Maybe<Scalars['Int']>;
  sortPosition?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "products" */
export type Products_Sum_Order_By = {
  bikesAvailable?: Maybe<Order_By>;
  sortPosition?: Maybe<Order_By>;
};

/** update columns of table "products" */
export enum Products_Update_Column {
  /** column name */
  BikesAvailable = 'bikesAvailable',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'locationId',
  /** column name */
  ProductTypeId = 'productTypeId',
  /** column name */
  Size = 'size',
  /** column name */
  SortPosition = 'sortPosition'
}

export type Products_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Products_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Products_Set_Input>;
  /** filter the rows which have to be updated */
  where: Products_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Products_Var_Pop_Fields = {
  __typename?: 'products_var_pop_fields';
  bikesAvailable?: Maybe<Scalars['Float']>;
  sortPosition?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "products" */
export type Products_Var_Pop_Order_By = {
  bikesAvailable?: Maybe<Order_By>;
  sortPosition?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Products_Var_Samp_Fields = {
  __typename?: 'products_var_samp_fields';
  bikesAvailable?: Maybe<Scalars['Float']>;
  sortPosition?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "products" */
export type Products_Var_Samp_Order_By = {
  bikesAvailable?: Maybe<Order_By>;
  sortPosition?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Products_Variance_Fields = {
  __typename?: 'products_variance_fields';
  bikesAvailable?: Maybe<Scalars['Float']>;
  sortPosition?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "products" */
export type Products_Variance_Order_By = {
  bikesAvailable?: Maybe<Order_By>;
  sortPosition?: Maybe<Order_By>;
};

/** columns and relationships of "productTransactions" */
export type ProductTransactions = {
  __typename?: 'productTransactions';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  product: Products;
  productId: Scalars['uuid'];
  result?: Maybe<Scalars['jsonb']>;
  /** An object relationship */
  transaction: Transactions;
  transactionId: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "productTransactions" */
export type ProductTransactionsResultArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "productTransactions" */
export type ProductTransactions_Aggregate = {
  __typename?: 'productTransactions_aggregate';
  aggregate?: Maybe<ProductTransactions_Aggregate_Fields>;
  nodes: Array<ProductTransactions>;
};

export type ProductTransactions_Aggregate_Bool_Exp = {
  count?: Maybe<ProductTransactions_Aggregate_Bool_Exp_Count>;
};

export type ProductTransactions_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<ProductTransactions_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<ProductTransactions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "productTransactions" */
export type ProductTransactions_Aggregate_Fields = {
  __typename?: 'productTransactions_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ProductTransactions_Max_Fields>;
  min?: Maybe<ProductTransactions_Min_Fields>;
};


/** aggregate fields of "productTransactions" */
export type ProductTransactions_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<ProductTransactions_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "productTransactions" */
export type ProductTransactions_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<ProductTransactions_Max_Order_By>;
  min?: Maybe<ProductTransactions_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ProductTransactions_Append_Input = {
  result?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "productTransactions" */
export type ProductTransactions_Arr_Rel_Insert_Input = {
  data: Array<ProductTransactions_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<ProductTransactions_On_Conflict>;
};

/** Boolean expression to filter rows from the table "productTransactions". All fields are combined with a logical 'AND'. */
export type ProductTransactions_Bool_Exp = {
  _and?: Maybe<Array<ProductTransactions_Bool_Exp>>;
  _not?: Maybe<ProductTransactions_Bool_Exp>;
  _or?: Maybe<Array<ProductTransactions_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  product?: Maybe<Products_Bool_Exp>;
  productId?: Maybe<Uuid_Comparison_Exp>;
  result?: Maybe<Jsonb_Comparison_Exp>;
  transaction?: Maybe<Transactions_Bool_Exp>;
  transactionId?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "productTransactions" */
export enum ProductTransactions_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProductTransactionsPkey = 'productTransactions_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ProductTransactions_Delete_At_Path_Input = {
  result?: Maybe<Array<Scalars['String']>>;
};

/**
 * delete the array element with specified index (negative integers count from the
 * end). throws an error if top level container is not an array
 */
export type ProductTransactions_Delete_Elem_Input = {
  result?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ProductTransactions_Delete_Key_Input = {
  result?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "productTransactions" */
export type ProductTransactions_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  product?: Maybe<Products_Obj_Rel_Insert_Input>;
  productId?: Maybe<Scalars['uuid']>;
  result?: Maybe<Scalars['jsonb']>;
  transaction?: Maybe<Transactions_Obj_Rel_Insert_Input>;
  transactionId?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type ProductTransactions_Max_Fields = {
  __typename?: 'productTransactions_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  productId?: Maybe<Scalars['uuid']>;
  transactionId?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "productTransactions" */
export type ProductTransactions_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  productId?: Maybe<Order_By>;
  transactionId?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type ProductTransactions_Min_Fields = {
  __typename?: 'productTransactions_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  productId?: Maybe<Scalars['uuid']>;
  transactionId?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "productTransactions" */
export type ProductTransactions_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  productId?: Maybe<Order_By>;
  transactionId?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "productTransactions" */
export type ProductTransactions_Mutation_Response = {
  __typename?: 'productTransactions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ProductTransactions>;
};

/** on_conflict condition type for table "productTransactions" */
export type ProductTransactions_On_Conflict = {
  constraint: ProductTransactions_Constraint;
  update_columns: Array<ProductTransactions_Update_Column>;
  where?: Maybe<ProductTransactions_Bool_Exp>;
};

/** Ordering options when selecting data from "productTransactions". */
export type ProductTransactions_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  product?: Maybe<Products_Order_By>;
  productId?: Maybe<Order_By>;
  result?: Maybe<Order_By>;
  transaction?: Maybe<Transactions_Order_By>;
  transactionId?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: productTransactions */
export type ProductTransactions_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ProductTransactions_Prepend_Input = {
  result?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "productTransactions" */
export enum ProductTransactions_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ProductId = 'productId',
  /** column name */
  Result = 'result',
  /** column name */
  TransactionId = 'transactionId',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "productTransactions" */
export type ProductTransactions_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  productId?: Maybe<Scalars['uuid']>;
  result?: Maybe<Scalars['jsonb']>;
  transactionId?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "productTransactions" */
export type ProductTransactions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ProductTransactions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ProductTransactions_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  productId?: Maybe<Scalars['uuid']>;
  result?: Maybe<Scalars['jsonb']>;
  transactionId?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "productTransactions" */
export enum ProductTransactions_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ProductId = 'productId',
  /** column name */
  Result = 'result',
  /** column name */
  TransactionId = 'transactionId',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type ProductTransactions_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<ProductTransactions_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<ProductTransactions_Delete_At_Path_Input>;
  /**
   * delete the array element with specified index (negative integers count from
   * the end). throws an error if top level container is not an array
   */
  _delete_elem?: Maybe<ProductTransactions_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<ProductTransactions_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<ProductTransactions_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<ProductTransactions_Set_Input>;
  /** filter the rows which have to be updated */
  where: ProductTransactions_Bool_Exp;
};

/** columns and relationships of "productTypeAvailabilities" */
export type ProductTypeAvailabilities = {
  __typename?: 'productTypeAvailabilities';
  date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  locationId?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  products: Array<Products>;
  /** An aggregate relationship */
  products_aggregate: Products_Aggregate;
  sumAvailableBikes?: Maybe<Scalars['bigint']>;
};


/** columns and relationships of "productTypeAvailabilities" */
export type ProductTypeAvailabilitiesProductsArgs = {
  distinct_on?: Maybe<Array<Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Products_Order_By>>;
  where?: Maybe<Products_Bool_Exp>;
};


/** columns and relationships of "productTypeAvailabilities" */
export type ProductTypeAvailabilitiesProducts_AggregateArgs = {
  distinct_on?: Maybe<Array<Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Products_Order_By>>;
  where?: Maybe<Products_Bool_Exp>;
};

/** aggregated selection of "productTypeAvailabilities" */
export type ProductTypeAvailabilities_Aggregate = {
  __typename?: 'productTypeAvailabilities_aggregate';
  aggregate?: Maybe<ProductTypeAvailabilities_Aggregate_Fields>;
  nodes: Array<ProductTypeAvailabilities>;
};

export type ProductTypeAvailabilities_Aggregate_Bool_Exp = {
  count?: Maybe<ProductTypeAvailabilities_Aggregate_Bool_Exp_Count>;
};

export type ProductTypeAvailabilities_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<ProductTypeAvailabilities_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<ProductTypeAvailabilities_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "productTypeAvailabilities" */
export type ProductTypeAvailabilities_Aggregate_Fields = {
  __typename?: 'productTypeAvailabilities_aggregate_fields';
  avg?: Maybe<ProductTypeAvailabilities_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ProductTypeAvailabilities_Max_Fields>;
  min?: Maybe<ProductTypeAvailabilities_Min_Fields>;
  stddev?: Maybe<ProductTypeAvailabilities_Stddev_Fields>;
  stddev_pop?: Maybe<ProductTypeAvailabilities_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ProductTypeAvailabilities_Stddev_Samp_Fields>;
  sum?: Maybe<ProductTypeAvailabilities_Sum_Fields>;
  var_pop?: Maybe<ProductTypeAvailabilities_Var_Pop_Fields>;
  var_samp?: Maybe<ProductTypeAvailabilities_Var_Samp_Fields>;
  variance?: Maybe<ProductTypeAvailabilities_Variance_Fields>;
};


/** aggregate fields of "productTypeAvailabilities" */
export type ProductTypeAvailabilities_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<ProductTypeAvailabilities_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "productTypeAvailabilities" */
export type ProductTypeAvailabilities_Aggregate_Order_By = {
  avg?: Maybe<ProductTypeAvailabilities_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<ProductTypeAvailabilities_Max_Order_By>;
  min?: Maybe<ProductTypeAvailabilities_Min_Order_By>;
  stddev?: Maybe<ProductTypeAvailabilities_Stddev_Order_By>;
  stddev_pop?: Maybe<ProductTypeAvailabilities_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<ProductTypeAvailabilities_Stddev_Samp_Order_By>;
  sum?: Maybe<ProductTypeAvailabilities_Sum_Order_By>;
  var_pop?: Maybe<ProductTypeAvailabilities_Var_Pop_Order_By>;
  var_samp?: Maybe<ProductTypeAvailabilities_Var_Samp_Order_By>;
  variance?: Maybe<ProductTypeAvailabilities_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "productTypeAvailabilities" */
export type ProductTypeAvailabilities_Arr_Rel_Insert_Input = {
  data: Array<ProductTypeAvailabilities_Insert_Input>;
};

/** aggregate avg on columns */
export type ProductTypeAvailabilities_Avg_Fields = {
  __typename?: 'productTypeAvailabilities_avg_fields';
  sumAvailableBikes?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "productTypeAvailabilities" */
export type ProductTypeAvailabilities_Avg_Order_By = {
  sumAvailableBikes?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "productTypeAvailabilities". All fields are combined with a logical 'AND'. */
export type ProductTypeAvailabilities_Bool_Exp = {
  _and?: Maybe<Array<ProductTypeAvailabilities_Bool_Exp>>;
  _not?: Maybe<ProductTypeAvailabilities_Bool_Exp>;
  _or?: Maybe<Array<ProductTypeAvailabilities_Bool_Exp>>;
  date?: Maybe<Date_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  locationId?: Maybe<Uuid_Comparison_Exp>;
  products?: Maybe<Products_Bool_Exp>;
  products_aggregate?: Maybe<Products_Aggregate_Bool_Exp>;
  sumAvailableBikes?: Maybe<Bigint_Comparison_Exp>;
};

/** input type for inserting data into table "productTypeAvailabilities" */
export type ProductTypeAvailabilities_Insert_Input = {
  date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  locationId?: Maybe<Scalars['uuid']>;
  products?: Maybe<Products_Arr_Rel_Insert_Input>;
  sumAvailableBikes?: Maybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type ProductTypeAvailabilities_Max_Fields = {
  __typename?: 'productTypeAvailabilities_max_fields';
  date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  locationId?: Maybe<Scalars['uuid']>;
  sumAvailableBikes?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "productTypeAvailabilities" */
export type ProductTypeAvailabilities_Max_Order_By = {
  date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  locationId?: Maybe<Order_By>;
  sumAvailableBikes?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type ProductTypeAvailabilities_Min_Fields = {
  __typename?: 'productTypeAvailabilities_min_fields';
  date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  locationId?: Maybe<Scalars['uuid']>;
  sumAvailableBikes?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "productTypeAvailabilities" */
export type ProductTypeAvailabilities_Min_Order_By = {
  date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  locationId?: Maybe<Order_By>;
  sumAvailableBikes?: Maybe<Order_By>;
};

/** Ordering options when selecting data from "productTypeAvailabilities". */
export type ProductTypeAvailabilities_Order_By = {
  date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  locationId?: Maybe<Order_By>;
  products_aggregate?: Maybe<Products_Aggregate_Order_By>;
  sumAvailableBikes?: Maybe<Order_By>;
};

/** select columns of table "productTypeAvailabilities" */
export enum ProductTypeAvailabilities_Select_Column {
  /** column name */
  Date = 'date',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'locationId',
  /** column name */
  SumAvailableBikes = 'sumAvailableBikes'
}

/** aggregate stddev on columns */
export type ProductTypeAvailabilities_Stddev_Fields = {
  __typename?: 'productTypeAvailabilities_stddev_fields';
  sumAvailableBikes?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "productTypeAvailabilities" */
export type ProductTypeAvailabilities_Stddev_Order_By = {
  sumAvailableBikes?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type ProductTypeAvailabilities_Stddev_Pop_Fields = {
  __typename?: 'productTypeAvailabilities_stddev_pop_fields';
  sumAvailableBikes?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "productTypeAvailabilities" */
export type ProductTypeAvailabilities_Stddev_Pop_Order_By = {
  sumAvailableBikes?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type ProductTypeAvailabilities_Stddev_Samp_Fields = {
  __typename?: 'productTypeAvailabilities_stddev_samp_fields';
  sumAvailableBikes?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "productTypeAvailabilities" */
export type ProductTypeAvailabilities_Stddev_Samp_Order_By = {
  sumAvailableBikes?: Maybe<Order_By>;
};

/** Streaming cursor of the table "productTypeAvailabilities" */
export type ProductTypeAvailabilities_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ProductTypeAvailabilities_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ProductTypeAvailabilities_Stream_Cursor_Value_Input = {
  date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  locationId?: Maybe<Scalars['uuid']>;
  sumAvailableBikes?: Maybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type ProductTypeAvailabilities_Sum_Fields = {
  __typename?: 'productTypeAvailabilities_sum_fields';
  sumAvailableBikes?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "productTypeAvailabilities" */
export type ProductTypeAvailabilities_Sum_Order_By = {
  sumAvailableBikes?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type ProductTypeAvailabilities_Var_Pop_Fields = {
  __typename?: 'productTypeAvailabilities_var_pop_fields';
  sumAvailableBikes?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "productTypeAvailabilities" */
export type ProductTypeAvailabilities_Var_Pop_Order_By = {
  sumAvailableBikes?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type ProductTypeAvailabilities_Var_Samp_Fields = {
  __typename?: 'productTypeAvailabilities_var_samp_fields';
  sumAvailableBikes?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "productTypeAvailabilities" */
export type ProductTypeAvailabilities_Var_Samp_Order_By = {
  sumAvailableBikes?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type ProductTypeAvailabilities_Variance_Fields = {
  __typename?: 'productTypeAvailabilities_variance_fields';
  sumAvailableBikes?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "productTypeAvailabilities" */
export type ProductTypeAvailabilities_Variance_Order_By = {
  sumAvailableBikes?: Maybe<Order_By>;
};

/** columns and relationships of "productTypeModels" */
export type ProductTypeModels = {
  __typename?: 'productTypeModels';
  id: Scalars['String'];
  name: Scalars['String'];
  /** An array relationship */
  productTypes: Array<ProductTypes>;
  /** An aggregate relationship */
  productTypes_aggregate: ProductTypes_Aggregate;
};


/** columns and relationships of "productTypeModels" */
export type ProductTypeModelsProductTypesArgs = {
  distinct_on?: Maybe<Array<ProductTypes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductTypes_Order_By>>;
  where?: Maybe<ProductTypes_Bool_Exp>;
};


/** columns and relationships of "productTypeModels" */
export type ProductTypeModelsProductTypes_AggregateArgs = {
  distinct_on?: Maybe<Array<ProductTypes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductTypes_Order_By>>;
  where?: Maybe<ProductTypes_Bool_Exp>;
};

/** aggregated selection of "productTypeModels" */
export type ProductTypeModels_Aggregate = {
  __typename?: 'productTypeModels_aggregate';
  aggregate?: Maybe<ProductTypeModels_Aggregate_Fields>;
  nodes: Array<ProductTypeModels>;
};

/** aggregate fields of "productTypeModels" */
export type ProductTypeModels_Aggregate_Fields = {
  __typename?: 'productTypeModels_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ProductTypeModels_Max_Fields>;
  min?: Maybe<ProductTypeModels_Min_Fields>;
};


/** aggregate fields of "productTypeModels" */
export type ProductTypeModels_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<ProductTypeModels_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "productTypeModels". All fields are combined with a logical 'AND'. */
export type ProductTypeModels_Bool_Exp = {
  _and?: Maybe<Array<ProductTypeModels_Bool_Exp>>;
  _not?: Maybe<ProductTypeModels_Bool_Exp>;
  _or?: Maybe<Array<ProductTypeModels_Bool_Exp>>;
  id?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  productTypes?: Maybe<ProductTypes_Bool_Exp>;
  productTypes_aggregate?: Maybe<ProductTypes_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "productTypeModels" */
export enum ProductTypeModels_Constraint {
  /** unique or primary key constraint on columns "name" */
  CategoriesNameKey = 'categories_name_key',
  /** unique or primary key constraint on columns "id" */
  CategoriesPkey = 'categories_pkey'
}

/** input type for inserting data into table "productTypeModels" */
export type ProductTypeModels_Insert_Input = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  productTypes?: Maybe<ProductTypes_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type ProductTypeModels_Max_Fields = {
  __typename?: 'productTypeModels_max_fields';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ProductTypeModels_Min_Fields = {
  __typename?: 'productTypeModels_min_fields';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "productTypeModels" */
export type ProductTypeModels_Mutation_Response = {
  __typename?: 'productTypeModels_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ProductTypeModels>;
};

/** input type for inserting object relation for remote table "productTypeModels" */
export type ProductTypeModels_Obj_Rel_Insert_Input = {
  data: ProductTypeModels_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<ProductTypeModels_On_Conflict>;
};

/** on_conflict condition type for table "productTypeModels" */
export type ProductTypeModels_On_Conflict = {
  constraint: ProductTypeModels_Constraint;
  update_columns: Array<ProductTypeModels_Update_Column>;
  where?: Maybe<ProductTypeModels_Bool_Exp>;
};

/** Ordering options when selecting data from "productTypeModels". */
export type ProductTypeModels_Order_By = {
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  productTypes_aggregate?: Maybe<ProductTypes_Aggregate_Order_By>;
};

/** primary key columns input for table: productTypeModels */
export type ProductTypeModels_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "productTypeModels" */
export enum ProductTypeModels_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "productTypeModels" */
export type ProductTypeModels_Set_Input = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "productTypeModels" */
export type ProductTypeModels_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ProductTypeModels_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ProductTypeModels_Stream_Cursor_Value_Input = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** update columns of table "productTypeModels" */
export enum ProductTypeModels_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export type ProductTypeModels_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<ProductTypeModels_Set_Input>;
  /** filter the rows which have to be updated */
  where: ProductTypeModels_Bool_Exp;
};

/** columns and relationships of "productTypePrices" */
export type ProductTypePrices = {
  __typename?: 'productTypePrices';
  id?: Maybe<Scalars['uuid']>;
  minBasePricePerDay?: Maybe<Scalars['Int']>;
  minDuration?: Maybe<Scalars['numeric']>;
};

/** aggregated selection of "productTypePrices" */
export type ProductTypePrices_Aggregate = {
  __typename?: 'productTypePrices_aggregate';
  aggregate?: Maybe<ProductTypePrices_Aggregate_Fields>;
  nodes: Array<ProductTypePrices>;
};

export type ProductTypePrices_Aggregate_Bool_Exp = {
  count?: Maybe<ProductTypePrices_Aggregate_Bool_Exp_Count>;
};

export type ProductTypePrices_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<ProductTypePrices_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<ProductTypePrices_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "productTypePrices" */
export type ProductTypePrices_Aggregate_Fields = {
  __typename?: 'productTypePrices_aggregate_fields';
  avg?: Maybe<ProductTypePrices_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ProductTypePrices_Max_Fields>;
  min?: Maybe<ProductTypePrices_Min_Fields>;
  stddev?: Maybe<ProductTypePrices_Stddev_Fields>;
  stddev_pop?: Maybe<ProductTypePrices_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ProductTypePrices_Stddev_Samp_Fields>;
  sum?: Maybe<ProductTypePrices_Sum_Fields>;
  var_pop?: Maybe<ProductTypePrices_Var_Pop_Fields>;
  var_samp?: Maybe<ProductTypePrices_Var_Samp_Fields>;
  variance?: Maybe<ProductTypePrices_Variance_Fields>;
};


/** aggregate fields of "productTypePrices" */
export type ProductTypePrices_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<ProductTypePrices_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "productTypePrices" */
export type ProductTypePrices_Aggregate_Order_By = {
  avg?: Maybe<ProductTypePrices_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<ProductTypePrices_Max_Order_By>;
  min?: Maybe<ProductTypePrices_Min_Order_By>;
  stddev?: Maybe<ProductTypePrices_Stddev_Order_By>;
  stddev_pop?: Maybe<ProductTypePrices_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<ProductTypePrices_Stddev_Samp_Order_By>;
  sum?: Maybe<ProductTypePrices_Sum_Order_By>;
  var_pop?: Maybe<ProductTypePrices_Var_Pop_Order_By>;
  var_samp?: Maybe<ProductTypePrices_Var_Samp_Order_By>;
  variance?: Maybe<ProductTypePrices_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "productTypePrices" */
export type ProductTypePrices_Arr_Rel_Insert_Input = {
  data: Array<ProductTypePrices_Insert_Input>;
};

/** aggregate avg on columns */
export type ProductTypePrices_Avg_Fields = {
  __typename?: 'productTypePrices_avg_fields';
  minBasePricePerDay?: Maybe<Scalars['Float']>;
  minDuration?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "productTypePrices" */
export type ProductTypePrices_Avg_Order_By = {
  minBasePricePerDay?: Maybe<Order_By>;
  minDuration?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "productTypePrices". All fields are combined with a logical 'AND'. */
export type ProductTypePrices_Bool_Exp = {
  _and?: Maybe<Array<ProductTypePrices_Bool_Exp>>;
  _not?: Maybe<ProductTypePrices_Bool_Exp>;
  _or?: Maybe<Array<ProductTypePrices_Bool_Exp>>;
  id?: Maybe<Uuid_Comparison_Exp>;
  minBasePricePerDay?: Maybe<Int_Comparison_Exp>;
  minDuration?: Maybe<Numeric_Comparison_Exp>;
};

/** input type for inserting data into table "productTypePrices" */
export type ProductTypePrices_Insert_Input = {
  id?: Maybe<Scalars['uuid']>;
  minBasePricePerDay?: Maybe<Scalars['Int']>;
  minDuration?: Maybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type ProductTypePrices_Max_Fields = {
  __typename?: 'productTypePrices_max_fields';
  id?: Maybe<Scalars['uuid']>;
  minBasePricePerDay?: Maybe<Scalars['Int']>;
  minDuration?: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "productTypePrices" */
export type ProductTypePrices_Max_Order_By = {
  id?: Maybe<Order_By>;
  minBasePricePerDay?: Maybe<Order_By>;
  minDuration?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type ProductTypePrices_Min_Fields = {
  __typename?: 'productTypePrices_min_fields';
  id?: Maybe<Scalars['uuid']>;
  minBasePricePerDay?: Maybe<Scalars['Int']>;
  minDuration?: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "productTypePrices" */
export type ProductTypePrices_Min_Order_By = {
  id?: Maybe<Order_By>;
  minBasePricePerDay?: Maybe<Order_By>;
  minDuration?: Maybe<Order_By>;
};

/** Ordering options when selecting data from "productTypePrices". */
export type ProductTypePrices_Order_By = {
  id?: Maybe<Order_By>;
  minBasePricePerDay?: Maybe<Order_By>;
  minDuration?: Maybe<Order_By>;
};

/** select columns of table "productTypePrices" */
export enum ProductTypePrices_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  MinBasePricePerDay = 'minBasePricePerDay',
  /** column name */
  MinDuration = 'minDuration'
}

/** aggregate stddev on columns */
export type ProductTypePrices_Stddev_Fields = {
  __typename?: 'productTypePrices_stddev_fields';
  minBasePricePerDay?: Maybe<Scalars['Float']>;
  minDuration?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "productTypePrices" */
export type ProductTypePrices_Stddev_Order_By = {
  minBasePricePerDay?: Maybe<Order_By>;
  minDuration?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type ProductTypePrices_Stddev_Pop_Fields = {
  __typename?: 'productTypePrices_stddev_pop_fields';
  minBasePricePerDay?: Maybe<Scalars['Float']>;
  minDuration?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "productTypePrices" */
export type ProductTypePrices_Stddev_Pop_Order_By = {
  minBasePricePerDay?: Maybe<Order_By>;
  minDuration?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type ProductTypePrices_Stddev_Samp_Fields = {
  __typename?: 'productTypePrices_stddev_samp_fields';
  minBasePricePerDay?: Maybe<Scalars['Float']>;
  minDuration?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "productTypePrices" */
export type ProductTypePrices_Stddev_Samp_Order_By = {
  minBasePricePerDay?: Maybe<Order_By>;
  minDuration?: Maybe<Order_By>;
};

/** Streaming cursor of the table "productTypePrices" */
export type ProductTypePrices_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ProductTypePrices_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ProductTypePrices_Stream_Cursor_Value_Input = {
  id?: Maybe<Scalars['uuid']>;
  minBasePricePerDay?: Maybe<Scalars['Int']>;
  minDuration?: Maybe<Scalars['numeric']>;
};

/** aggregate sum on columns */
export type ProductTypePrices_Sum_Fields = {
  __typename?: 'productTypePrices_sum_fields';
  minBasePricePerDay?: Maybe<Scalars['Int']>;
  minDuration?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "productTypePrices" */
export type ProductTypePrices_Sum_Order_By = {
  minBasePricePerDay?: Maybe<Order_By>;
  minDuration?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type ProductTypePrices_Var_Pop_Fields = {
  __typename?: 'productTypePrices_var_pop_fields';
  minBasePricePerDay?: Maybe<Scalars['Float']>;
  minDuration?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "productTypePrices" */
export type ProductTypePrices_Var_Pop_Order_By = {
  minBasePricePerDay?: Maybe<Order_By>;
  minDuration?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type ProductTypePrices_Var_Samp_Fields = {
  __typename?: 'productTypePrices_var_samp_fields';
  minBasePricePerDay?: Maybe<Scalars['Float']>;
  minDuration?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "productTypePrices" */
export type ProductTypePrices_Var_Samp_Order_By = {
  minBasePricePerDay?: Maybe<Order_By>;
  minDuration?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type ProductTypePrices_Variance_Fields = {
  __typename?: 'productTypePrices_variance_fields';
  minBasePricePerDay?: Maybe<Scalars['Float']>;
  minDuration?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "productTypePrices" */
export type ProductTypePrices_Variance_Order_By = {
  minBasePricePerDay?: Maybe<Order_By>;
  minDuration?: Maybe<Order_By>;
};

/** columns and relationships of "productTypes" */
export type ProductTypes = {
  __typename?: 'productTypes';
  /** An array relationship */
  availabilities: Array<ProductTypeAvailabilities>;
  /** An aggregate relationship */
  availabilities_aggregate: ProductTypeAvailabilities_Aggregate;
  category: Scalars['String'];
  description: Scalars['String'];
  disabled?: Maybe<Scalars['Boolean']>;
  forceInsurance: Scalars['Boolean'];
  id: Scalars['uuid'];
  image: Scalars['String'];
  name: Scalars['String'];
  /** An array relationship */
  prices: Array<ProductTypePrices>;
  /** An aggregate relationship */
  prices_aggregate: ProductTypePrices_Aggregate;
  /** An object relationship */
  productTypeModel: ProductTypeModels;
  productTypeModelId: Scalars['String'];
  /** An array relationship */
  products: Array<Products>;
  /** An aggregate relationship */
  products_aggregate: Products_Aggregate;
  sortPosition?: Maybe<Scalars['Int']>;
};


/** columns and relationships of "productTypes" */
export type ProductTypesAvailabilitiesArgs = {
  distinct_on?: Maybe<Array<ProductTypeAvailabilities_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductTypeAvailabilities_Order_By>>;
  where?: Maybe<ProductTypeAvailabilities_Bool_Exp>;
};


/** columns and relationships of "productTypes" */
export type ProductTypesAvailabilities_AggregateArgs = {
  distinct_on?: Maybe<Array<ProductTypeAvailabilities_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductTypeAvailabilities_Order_By>>;
  where?: Maybe<ProductTypeAvailabilities_Bool_Exp>;
};


/** columns and relationships of "productTypes" */
export type ProductTypesPricesArgs = {
  distinct_on?: Maybe<Array<ProductTypePrices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductTypePrices_Order_By>>;
  where?: Maybe<ProductTypePrices_Bool_Exp>;
};


/** columns and relationships of "productTypes" */
export type ProductTypesPrices_AggregateArgs = {
  distinct_on?: Maybe<Array<ProductTypePrices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductTypePrices_Order_By>>;
  where?: Maybe<ProductTypePrices_Bool_Exp>;
};


/** columns and relationships of "productTypes" */
export type ProductTypesProductsArgs = {
  distinct_on?: Maybe<Array<Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Products_Order_By>>;
  where?: Maybe<Products_Bool_Exp>;
};


/** columns and relationships of "productTypes" */
export type ProductTypesProducts_AggregateArgs = {
  distinct_on?: Maybe<Array<Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Products_Order_By>>;
  where?: Maybe<Products_Bool_Exp>;
};

/** aggregated selection of "productTypes" */
export type ProductTypes_Aggregate = {
  __typename?: 'productTypes_aggregate';
  aggregate?: Maybe<ProductTypes_Aggregate_Fields>;
  nodes: Array<ProductTypes>;
};

export type ProductTypes_Aggregate_Bool_Exp = {
  bool_and?: Maybe<ProductTypes_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<ProductTypes_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<ProductTypes_Aggregate_Bool_Exp_Count>;
};

export type ProductTypes_Aggregate_Bool_Exp_Bool_And = {
  arguments: ProductTypes_Select_Column_ProductTypes_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<ProductTypes_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type ProductTypes_Aggregate_Bool_Exp_Bool_Or = {
  arguments: ProductTypes_Select_Column_ProductTypes_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<ProductTypes_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type ProductTypes_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<ProductTypes_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<ProductTypes_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "productTypes" */
export type ProductTypes_Aggregate_Fields = {
  __typename?: 'productTypes_aggregate_fields';
  avg?: Maybe<ProductTypes_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ProductTypes_Max_Fields>;
  min?: Maybe<ProductTypes_Min_Fields>;
  stddev?: Maybe<ProductTypes_Stddev_Fields>;
  stddev_pop?: Maybe<ProductTypes_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ProductTypes_Stddev_Samp_Fields>;
  sum?: Maybe<ProductTypes_Sum_Fields>;
  var_pop?: Maybe<ProductTypes_Var_Pop_Fields>;
  var_samp?: Maybe<ProductTypes_Var_Samp_Fields>;
  variance?: Maybe<ProductTypes_Variance_Fields>;
};


/** aggregate fields of "productTypes" */
export type ProductTypes_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<ProductTypes_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "productTypes" */
export type ProductTypes_Aggregate_Order_By = {
  avg?: Maybe<ProductTypes_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<ProductTypes_Max_Order_By>;
  min?: Maybe<ProductTypes_Min_Order_By>;
  stddev?: Maybe<ProductTypes_Stddev_Order_By>;
  stddev_pop?: Maybe<ProductTypes_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<ProductTypes_Stddev_Samp_Order_By>;
  sum?: Maybe<ProductTypes_Sum_Order_By>;
  var_pop?: Maybe<ProductTypes_Var_Pop_Order_By>;
  var_samp?: Maybe<ProductTypes_Var_Samp_Order_By>;
  variance?: Maybe<ProductTypes_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "productTypes" */
export type ProductTypes_Arr_Rel_Insert_Input = {
  data: Array<ProductTypes_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<ProductTypes_On_Conflict>;
};

/** aggregate avg on columns */
export type ProductTypes_Avg_Fields = {
  __typename?: 'productTypes_avg_fields';
  sortPosition?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "productTypes" */
export type ProductTypes_Avg_Order_By = {
  sortPosition?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "productTypes". All fields are combined with a logical 'AND'. */
export type ProductTypes_Bool_Exp = {
  _and?: Maybe<Array<ProductTypes_Bool_Exp>>;
  _not?: Maybe<ProductTypes_Bool_Exp>;
  _or?: Maybe<Array<ProductTypes_Bool_Exp>>;
  availabilities?: Maybe<ProductTypeAvailabilities_Bool_Exp>;
  availabilities_aggregate?: Maybe<ProductTypeAvailabilities_Aggregate_Bool_Exp>;
  category?: Maybe<String_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  disabled?: Maybe<Boolean_Comparison_Exp>;
  forceInsurance?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  image?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  prices?: Maybe<ProductTypePrices_Bool_Exp>;
  prices_aggregate?: Maybe<ProductTypePrices_Aggregate_Bool_Exp>;
  productTypeModel?: Maybe<ProductTypeModels_Bool_Exp>;
  productTypeModelId?: Maybe<String_Comparison_Exp>;
  products?: Maybe<Products_Bool_Exp>;
  products_aggregate?: Maybe<Products_Aggregate_Bool_Exp>;
  sortPosition?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "productTypes" */
export enum ProductTypes_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProductTypesPkey = 'productTypes_pkey'
}

/** input type for incrementing numeric columns in table "productTypes" */
export type ProductTypes_Inc_Input = {
  sortPosition?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "productTypes" */
export type ProductTypes_Insert_Input = {
  availabilities?: Maybe<ProductTypeAvailabilities_Arr_Rel_Insert_Input>;
  category?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  forceInsurance?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['uuid']>;
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  prices?: Maybe<ProductTypePrices_Arr_Rel_Insert_Input>;
  productTypeModel?: Maybe<ProductTypeModels_Obj_Rel_Insert_Input>;
  productTypeModelId?: Maybe<Scalars['String']>;
  products?: Maybe<Products_Arr_Rel_Insert_Input>;
  sortPosition?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type ProductTypes_Max_Fields = {
  __typename?: 'productTypes_max_fields';
  category?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  productTypeModelId?: Maybe<Scalars['String']>;
  sortPosition?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "productTypes" */
export type ProductTypes_Max_Order_By = {
  category?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  image?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  productTypeModelId?: Maybe<Order_By>;
  sortPosition?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type ProductTypes_Min_Fields = {
  __typename?: 'productTypes_min_fields';
  category?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  productTypeModelId?: Maybe<Scalars['String']>;
  sortPosition?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "productTypes" */
export type ProductTypes_Min_Order_By = {
  category?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  image?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  productTypeModelId?: Maybe<Order_By>;
  sortPosition?: Maybe<Order_By>;
};

/** response of any mutation on the table "productTypes" */
export type ProductTypes_Mutation_Response = {
  __typename?: 'productTypes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ProductTypes>;
};

/** input type for inserting object relation for remote table "productTypes" */
export type ProductTypes_Obj_Rel_Insert_Input = {
  data: ProductTypes_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<ProductTypes_On_Conflict>;
};

/** on_conflict condition type for table "productTypes" */
export type ProductTypes_On_Conflict = {
  constraint: ProductTypes_Constraint;
  update_columns: Array<ProductTypes_Update_Column>;
  where?: Maybe<ProductTypes_Bool_Exp>;
};

/** Ordering options when selecting data from "productTypes". */
export type ProductTypes_Order_By = {
  availabilities_aggregate?: Maybe<ProductTypeAvailabilities_Aggregate_Order_By>;
  category?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  disabled?: Maybe<Order_By>;
  forceInsurance?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  image?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  prices_aggregate?: Maybe<ProductTypePrices_Aggregate_Order_By>;
  productTypeModel?: Maybe<ProductTypeModels_Order_By>;
  productTypeModelId?: Maybe<Order_By>;
  products_aggregate?: Maybe<Products_Aggregate_Order_By>;
  sortPosition?: Maybe<Order_By>;
};

/** primary key columns input for table: productTypes */
export type ProductTypes_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "productTypes" */
export enum ProductTypes_Select_Column {
  /** column name */
  Category = 'category',
  /** column name */
  Description = 'description',
  /** column name */
  Disabled = 'disabled',
  /** column name */
  ForceInsurance = 'forceInsurance',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  Name = 'name',
  /** column name */
  ProductTypeModelId = 'productTypeModelId',
  /** column name */
  SortPosition = 'sortPosition'
}

/** select "productTypes_aggregate_bool_exp_bool_and_arguments_columns" columns of table "productTypes" */
export enum ProductTypes_Select_Column_ProductTypes_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Disabled = 'disabled',
  /** column name */
  ForceInsurance = 'forceInsurance'
}

/** select "productTypes_aggregate_bool_exp_bool_or_arguments_columns" columns of table "productTypes" */
export enum ProductTypes_Select_Column_ProductTypes_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Disabled = 'disabled',
  /** column name */
  ForceInsurance = 'forceInsurance'
}

/** input type for updating data in table "productTypes" */
export type ProductTypes_Set_Input = {
  category?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  forceInsurance?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['uuid']>;
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  productTypeModelId?: Maybe<Scalars['String']>;
  sortPosition?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type ProductTypes_Stddev_Fields = {
  __typename?: 'productTypes_stddev_fields';
  sortPosition?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "productTypes" */
export type ProductTypes_Stddev_Order_By = {
  sortPosition?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type ProductTypes_Stddev_Pop_Fields = {
  __typename?: 'productTypes_stddev_pop_fields';
  sortPosition?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "productTypes" */
export type ProductTypes_Stddev_Pop_Order_By = {
  sortPosition?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type ProductTypes_Stddev_Samp_Fields = {
  __typename?: 'productTypes_stddev_samp_fields';
  sortPosition?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "productTypes" */
export type ProductTypes_Stddev_Samp_Order_By = {
  sortPosition?: Maybe<Order_By>;
};

/** Streaming cursor of the table "productTypes" */
export type ProductTypes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ProductTypes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ProductTypes_Stream_Cursor_Value_Input = {
  category?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  forceInsurance?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['uuid']>;
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  productTypeModelId?: Maybe<Scalars['String']>;
  sortPosition?: Maybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type ProductTypes_Sum_Fields = {
  __typename?: 'productTypes_sum_fields';
  sortPosition?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "productTypes" */
export type ProductTypes_Sum_Order_By = {
  sortPosition?: Maybe<Order_By>;
};

/** update columns of table "productTypes" */
export enum ProductTypes_Update_Column {
  /** column name */
  Category = 'category',
  /** column name */
  Description = 'description',
  /** column name */
  Disabled = 'disabled',
  /** column name */
  ForceInsurance = 'forceInsurance',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  Name = 'name',
  /** column name */
  ProductTypeModelId = 'productTypeModelId',
  /** column name */
  SortPosition = 'sortPosition'
}

export type ProductTypes_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<ProductTypes_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<ProductTypes_Set_Input>;
  /** filter the rows which have to be updated */
  where: ProductTypes_Bool_Exp;
};

/** aggregate var_pop on columns */
export type ProductTypes_Var_Pop_Fields = {
  __typename?: 'productTypes_var_pop_fields';
  sortPosition?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "productTypes" */
export type ProductTypes_Var_Pop_Order_By = {
  sortPosition?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type ProductTypes_Var_Samp_Fields = {
  __typename?: 'productTypes_var_samp_fields';
  sortPosition?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "productTypes" */
export type ProductTypes_Var_Samp_Order_By = {
  sortPosition?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type ProductTypes_Variance_Fields = {
  __typename?: 'productTypes_variance_fields';
  sortPosition?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "productTypes" */
export type ProductTypes_Variance_Order_By = {
  sortPosition?: Maybe<Order_By>;
};

/** columns and relationships of "publicSettings" */
export type PublicSettings = {
  __typename?: 'publicSettings';
  created_at: Scalars['timestamptz'];
  key: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  value: Scalars['String'];
};

/** aggregated selection of "publicSettings" */
export type PublicSettings_Aggregate = {
  __typename?: 'publicSettings_aggregate';
  aggregate?: Maybe<PublicSettings_Aggregate_Fields>;
  nodes: Array<PublicSettings>;
};

/** aggregate fields of "publicSettings" */
export type PublicSettings_Aggregate_Fields = {
  __typename?: 'publicSettings_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<PublicSettings_Max_Fields>;
  min?: Maybe<PublicSettings_Min_Fields>;
};


/** aggregate fields of "publicSettings" */
export type PublicSettings_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<PublicSettings_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "publicSettings". All fields are combined with a logical 'AND'. */
export type PublicSettings_Bool_Exp = {
  _and?: Maybe<Array<PublicSettings_Bool_Exp>>;
  _not?: Maybe<PublicSettings_Bool_Exp>;
  _or?: Maybe<Array<PublicSettings_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  key?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "publicSettings" */
export enum PublicSettings_Constraint {
  /** unique or primary key constraint on columns "key" */
  PublicSettingsKeyKey = 'publicSettings_key_key',
  /** unique or primary key constraint on columns "key" */
  PublicSettingsPkey = 'publicSettings_pkey'
}

/** input type for inserting data into table "publicSettings" */
export type PublicSettings_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  key?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type PublicSettings_Max_Fields = {
  __typename?: 'publicSettings_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  key?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type PublicSettings_Min_Fields = {
  __typename?: 'publicSettings_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  key?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "publicSettings" */
export type PublicSettings_Mutation_Response = {
  __typename?: 'publicSettings_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<PublicSettings>;
};

/** on_conflict condition type for table "publicSettings" */
export type PublicSettings_On_Conflict = {
  constraint: PublicSettings_Constraint;
  update_columns: Array<PublicSettings_Update_Column>;
  where?: Maybe<PublicSettings_Bool_Exp>;
};

/** Ordering options when selecting data from "publicSettings". */
export type PublicSettings_Order_By = {
  created_at?: Maybe<Order_By>;
  key?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: publicSettings */
export type PublicSettings_Pk_Columns_Input = {
  key: Scalars['String'];
};

/** select columns of table "publicSettings" */
export enum PublicSettings_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Key = 'key',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "publicSettings" */
export type PublicSettings_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  key?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "publicSettings" */
export type PublicSettings_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: PublicSettings_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type PublicSettings_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  key?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "publicSettings" */
export enum PublicSettings_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Key = 'key',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

export type PublicSettings_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<PublicSettings_Set_Input>;
  /** filter the rows which have to be updated */
  where: PublicSettings_Bool_Exp;
};

export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "bookingRequest" */
  bookingRequest: Array<BookingRequest>;
  /** fetch aggregated fields from the table: "bookingRequest" */
  bookingRequest_aggregate: BookingRequest_Aggregate;
  /** fetch data from the table: "bookingRequest" using primary key columns */
  bookingRequest_by_pk?: Maybe<BookingRequest>;
  /** An array relationship */
  bookings: Array<Bookings>;
  /** An aggregate relationship */
  bookings_aggregate: Bookings_Aggregate;
  /** fetch data from the table: "bookings" using primary key columns */
  bookings_by_pk?: Maybe<Bookings>;
  /** An array relationship */
  bookings_transactions: Array<Bookings_Transactions>;
  /** An aggregate relationship */
  bookings_transactions_aggregate: Bookings_Transactions_Aggregate;
  /** fetch data from the table: "bookings_transactions" using primary key columns */
  bookings_transactions_by_pk?: Maybe<Bookings_Transactions>;
  /** An array relationship */
  closingDates: Array<ClosingDates>;
  /** An aggregate relationship */
  closingDates_aggregate: ClosingDates_Aggregate;
  /** fetch data from the table: "closingDates" using primary key columns */
  closingDates_by_pk?: Maybe<ClosingDates>;
  /** fetch data from the table: "customers" */
  customers: Array<Customers>;
  /** fetch aggregated fields from the table: "customers" */
  customers_aggregate: Customers_Aggregate;
  /** fetch data from the table: "customers" using primary key columns */
  customers_by_pk?: Maybe<Customers>;
  /** An array relationship */
  extraRecipeLines: Array<ExtraRecipeLines>;
  /** An array relationship */
  extraRecipeLinesTransactions: Array<ExtraRecipeLinesTransactions>;
  /** An aggregate relationship */
  extraRecipeLinesTransactions_aggregate: ExtraRecipeLinesTransactions_Aggregate;
  /** fetch data from the table: "extraRecipeLinesTransactions" using primary key columns */
  extraRecipeLinesTransactions_by_pk?: Maybe<ExtraRecipeLinesTransactions>;
  /** An aggregate relationship */
  extraRecipeLines_aggregate: ExtraRecipeLines_Aggregate;
  /** fetch data from the table: "extraRecipeLines" using primary key columns */
  extraRecipeLines_by_pk?: Maybe<ExtraRecipeLines>;
  /** fetch data from the table: "locationMessages" */
  locationMessages: Array<LocationMessages>;
  /** fetch aggregated fields from the table: "locationMessages" */
  locationMessages_aggregate: LocationMessages_Aggregate;
  /** fetch data from the table: "locationMessages" using primary key columns */
  locationMessages_by_pk?: Maybe<LocationMessages>;
  /** fetch data from the table: "locations" */
  locations: Array<Locations>;
  /** fetch aggregated fields from the table: "locations" */
  locations_aggregate: Locations_Aggregate;
  /** fetch data from the table: "locations" using primary key columns */
  locations_by_pk?: Maybe<Locations>;
  /** An array relationship */
  orders: Array<Orders>;
  /** An aggregate relationship */
  orders_aggregate: Orders_Aggregate;
  /** fetch data from the table: "orders" using primary key columns */
  orders_by_pk?: Maybe<Orders>;
  /** An array relationship */
  productPrices: Array<ProductPrices>;
  /** An aggregate relationship */
  productPrices_aggregate: ProductPrices_Aggregate;
  /** fetch data from the table: "productPrices" using primary key columns */
  productPrices_by_pk?: Maybe<ProductPrices>;
  /** An array relationship */
  productTransactions: Array<ProductTransactions>;
  /** An aggregate relationship */
  productTransactions_aggregate: ProductTransactions_Aggregate;
  /** fetch data from the table: "productTransactions" using primary key columns */
  productTransactions_by_pk?: Maybe<ProductTransactions>;
  /** fetch data from the table: "productTypeAvailabilities" */
  productTypeAvailabilities: Array<ProductTypeAvailabilities>;
  /** fetch aggregated fields from the table: "productTypeAvailabilities" */
  productTypeAvailabilities_aggregate: ProductTypeAvailabilities_Aggregate;
  /** fetch data from the table: "productTypeModels" */
  productTypeModels: Array<ProductTypeModels>;
  /** fetch aggregated fields from the table: "productTypeModels" */
  productTypeModels_aggregate: ProductTypeModels_Aggregate;
  /** fetch data from the table: "productTypeModels" using primary key columns */
  productTypeModels_by_pk?: Maybe<ProductTypeModels>;
  /** fetch data from the table: "productTypePrices" */
  productTypePrices: Array<ProductTypePrices>;
  /** fetch aggregated fields from the table: "productTypePrices" */
  productTypePrices_aggregate: ProductTypePrices_Aggregate;
  /** An array relationship */
  productTypes: Array<ProductTypes>;
  /** An aggregate relationship */
  productTypes_aggregate: ProductTypes_Aggregate;
  /** fetch data from the table: "productTypes" using primary key columns */
  productTypes_by_pk?: Maybe<ProductTypes>;
  /** An array relationship */
  products: Array<Products>;
  /** An aggregate relationship */
  products_aggregate: Products_Aggregate;
  /** fetch data from the table: "products" using primary key columns */
  products_by_pk?: Maybe<Products>;
  /** fetch data from the table: "publicSettings" */
  publicSettings: Array<PublicSettings>;
  /** fetch aggregated fields from the table: "publicSettings" */
  publicSettings_aggregate: PublicSettings_Aggregate;
  /** fetch data from the table: "publicSettings" using primary key columns */
  publicSettings_by_pk?: Maybe<PublicSettings>;
  /** An array relationship */
  seasons: Array<Seasons>;
  /** An aggregate relationship */
  seasons_aggregate: Seasons_Aggregate;
  /** fetch data from the table: "seasons" using primary key columns */
  seasons_by_pk?: Maybe<Seasons>;
  /** An array relationship */
  snapshotAvailabilities: Array<SnapshotAvailabilities>;
  /** An aggregate relationship */
  snapshotAvailabilities_aggregate: SnapshotAvailabilities_Aggregate;
  /** fetch data from the table: "snapshotAvailabilities" using primary key columns */
  snapshotAvailabilities_by_pk?: Maybe<SnapshotAvailabilities>;
  /** An array relationship */
  snapshotBookings: Array<SnapshotBookings>;
  /** An aggregate relationship */
  snapshotBookings_aggregate: SnapshotBookings_Aggregate;
  /** fetch data from the table: "snapshotBookings" using primary key columns */
  snapshotBookings_by_pk?: Maybe<SnapshotBookings>;
  /** fetch data from the table: "transactions" */
  transactions: Array<Transactions>;
  /** fetch aggregated fields from the table: "transactions" */
  transactions_aggregate: Transactions_Aggregate;
  /** fetch data from the table: "transactions" using primary key columns */
  transactions_by_pk?: Maybe<Transactions>;
};


export type Query_RootBookingRequestArgs = {
  distinct_on?: Maybe<Array<BookingRequest_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BookingRequest_Order_By>>;
  where?: Maybe<BookingRequest_Bool_Exp>;
};


export type Query_RootBookingRequest_AggregateArgs = {
  distinct_on?: Maybe<Array<BookingRequest_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BookingRequest_Order_By>>;
  where?: Maybe<BookingRequest_Bool_Exp>;
};


export type Query_RootBookingRequest_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootBookingsArgs = {
  distinct_on?: Maybe<Array<Bookings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bookings_Order_By>>;
  where?: Maybe<Bookings_Bool_Exp>;
};


export type Query_RootBookings_AggregateArgs = {
  distinct_on?: Maybe<Array<Bookings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bookings_Order_By>>;
  where?: Maybe<Bookings_Bool_Exp>;
};


export type Query_RootBookings_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootBookings_TransactionsArgs = {
  distinct_on?: Maybe<Array<Bookings_Transactions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bookings_Transactions_Order_By>>;
  where?: Maybe<Bookings_Transactions_Bool_Exp>;
};


export type Query_RootBookings_Transactions_AggregateArgs = {
  distinct_on?: Maybe<Array<Bookings_Transactions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bookings_Transactions_Order_By>>;
  where?: Maybe<Bookings_Transactions_Bool_Exp>;
};


export type Query_RootBookings_Transactions_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootClosingDatesArgs = {
  distinct_on?: Maybe<Array<ClosingDates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ClosingDates_Order_By>>;
  where?: Maybe<ClosingDates_Bool_Exp>;
};


export type Query_RootClosingDates_AggregateArgs = {
  distinct_on?: Maybe<Array<ClosingDates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ClosingDates_Order_By>>;
  where?: Maybe<ClosingDates_Bool_Exp>;
};


export type Query_RootClosingDates_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCustomersArgs = {
  distinct_on?: Maybe<Array<Customers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customers_Order_By>>;
  where?: Maybe<Customers_Bool_Exp>;
};


export type Query_RootCustomers_AggregateArgs = {
  distinct_on?: Maybe<Array<Customers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customers_Order_By>>;
  where?: Maybe<Customers_Bool_Exp>;
};


export type Query_RootCustomers_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootExtraRecipeLinesArgs = {
  distinct_on?: Maybe<Array<ExtraRecipeLines_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ExtraRecipeLines_Order_By>>;
  where?: Maybe<ExtraRecipeLines_Bool_Exp>;
};


export type Query_RootExtraRecipeLinesTransactionsArgs = {
  distinct_on?: Maybe<Array<ExtraRecipeLinesTransactions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ExtraRecipeLinesTransactions_Order_By>>;
  where?: Maybe<ExtraRecipeLinesTransactions_Bool_Exp>;
};


export type Query_RootExtraRecipeLinesTransactions_AggregateArgs = {
  distinct_on?: Maybe<Array<ExtraRecipeLinesTransactions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ExtraRecipeLinesTransactions_Order_By>>;
  where?: Maybe<ExtraRecipeLinesTransactions_Bool_Exp>;
};


export type Query_RootExtraRecipeLinesTransactions_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootExtraRecipeLines_AggregateArgs = {
  distinct_on?: Maybe<Array<ExtraRecipeLines_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ExtraRecipeLines_Order_By>>;
  where?: Maybe<ExtraRecipeLines_Bool_Exp>;
};


export type Query_RootExtraRecipeLines_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootLocationMessagesArgs = {
  distinct_on?: Maybe<Array<LocationMessages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LocationMessages_Order_By>>;
  where?: Maybe<LocationMessages_Bool_Exp>;
};


export type Query_RootLocationMessages_AggregateArgs = {
  distinct_on?: Maybe<Array<LocationMessages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LocationMessages_Order_By>>;
  where?: Maybe<LocationMessages_Bool_Exp>;
};


export type Query_RootLocationMessages_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootLocationsArgs = {
  distinct_on?: Maybe<Array<Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Locations_Order_By>>;
  where?: Maybe<Locations_Bool_Exp>;
};


export type Query_RootLocations_AggregateArgs = {
  distinct_on?: Maybe<Array<Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Locations_Order_By>>;
  where?: Maybe<Locations_Bool_Exp>;
};


export type Query_RootLocations_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootOrdersArgs = {
  distinct_on?: Maybe<Array<Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Orders_Order_By>>;
  where?: Maybe<Orders_Bool_Exp>;
};


export type Query_RootOrders_AggregateArgs = {
  distinct_on?: Maybe<Array<Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Orders_Order_By>>;
  where?: Maybe<Orders_Bool_Exp>;
};


export type Query_RootOrders_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootProductPricesArgs = {
  distinct_on?: Maybe<Array<ProductPrices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductPrices_Order_By>>;
  where?: Maybe<ProductPrices_Bool_Exp>;
};


export type Query_RootProductPrices_AggregateArgs = {
  distinct_on?: Maybe<Array<ProductPrices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductPrices_Order_By>>;
  where?: Maybe<ProductPrices_Bool_Exp>;
};


export type Query_RootProductPrices_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootProductTransactionsArgs = {
  distinct_on?: Maybe<Array<ProductTransactions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductTransactions_Order_By>>;
  where?: Maybe<ProductTransactions_Bool_Exp>;
};


export type Query_RootProductTransactions_AggregateArgs = {
  distinct_on?: Maybe<Array<ProductTransactions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductTransactions_Order_By>>;
  where?: Maybe<ProductTransactions_Bool_Exp>;
};


export type Query_RootProductTransactions_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootProductTypeAvailabilitiesArgs = {
  distinct_on?: Maybe<Array<ProductTypeAvailabilities_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductTypeAvailabilities_Order_By>>;
  where?: Maybe<ProductTypeAvailabilities_Bool_Exp>;
};


export type Query_RootProductTypeAvailabilities_AggregateArgs = {
  distinct_on?: Maybe<Array<ProductTypeAvailabilities_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductTypeAvailabilities_Order_By>>;
  where?: Maybe<ProductTypeAvailabilities_Bool_Exp>;
};


export type Query_RootProductTypeModelsArgs = {
  distinct_on?: Maybe<Array<ProductTypeModels_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductTypeModels_Order_By>>;
  where?: Maybe<ProductTypeModels_Bool_Exp>;
};


export type Query_RootProductTypeModels_AggregateArgs = {
  distinct_on?: Maybe<Array<ProductTypeModels_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductTypeModels_Order_By>>;
  where?: Maybe<ProductTypeModels_Bool_Exp>;
};


export type Query_RootProductTypeModels_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootProductTypePricesArgs = {
  distinct_on?: Maybe<Array<ProductTypePrices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductTypePrices_Order_By>>;
  where?: Maybe<ProductTypePrices_Bool_Exp>;
};


export type Query_RootProductTypePrices_AggregateArgs = {
  distinct_on?: Maybe<Array<ProductTypePrices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductTypePrices_Order_By>>;
  where?: Maybe<ProductTypePrices_Bool_Exp>;
};


export type Query_RootProductTypesArgs = {
  distinct_on?: Maybe<Array<ProductTypes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductTypes_Order_By>>;
  where?: Maybe<ProductTypes_Bool_Exp>;
};


export type Query_RootProductTypes_AggregateArgs = {
  distinct_on?: Maybe<Array<ProductTypes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductTypes_Order_By>>;
  where?: Maybe<ProductTypes_Bool_Exp>;
};


export type Query_RootProductTypes_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootProductsArgs = {
  distinct_on?: Maybe<Array<Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Products_Order_By>>;
  where?: Maybe<Products_Bool_Exp>;
};


export type Query_RootProducts_AggregateArgs = {
  distinct_on?: Maybe<Array<Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Products_Order_By>>;
  where?: Maybe<Products_Bool_Exp>;
};


export type Query_RootProducts_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootPublicSettingsArgs = {
  distinct_on?: Maybe<Array<PublicSettings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<PublicSettings_Order_By>>;
  where?: Maybe<PublicSettings_Bool_Exp>;
};


export type Query_RootPublicSettings_AggregateArgs = {
  distinct_on?: Maybe<Array<PublicSettings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<PublicSettings_Order_By>>;
  where?: Maybe<PublicSettings_Bool_Exp>;
};


export type Query_RootPublicSettings_By_PkArgs = {
  key: Scalars['String'];
};


export type Query_RootSeasonsArgs = {
  distinct_on?: Maybe<Array<Seasons_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Seasons_Order_By>>;
  where?: Maybe<Seasons_Bool_Exp>;
};


export type Query_RootSeasons_AggregateArgs = {
  distinct_on?: Maybe<Array<Seasons_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Seasons_Order_By>>;
  where?: Maybe<Seasons_Bool_Exp>;
};


export type Query_RootSeasons_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootSnapshotAvailabilitiesArgs = {
  distinct_on?: Maybe<Array<SnapshotAvailabilities_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SnapshotAvailabilities_Order_By>>;
  where?: Maybe<SnapshotAvailabilities_Bool_Exp>;
};


export type Query_RootSnapshotAvailabilities_AggregateArgs = {
  distinct_on?: Maybe<Array<SnapshotAvailabilities_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SnapshotAvailabilities_Order_By>>;
  where?: Maybe<SnapshotAvailabilities_Bool_Exp>;
};


export type Query_RootSnapshotAvailabilities_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootSnapshotBookingsArgs = {
  distinct_on?: Maybe<Array<SnapshotBookings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SnapshotBookings_Order_By>>;
  where?: Maybe<SnapshotBookings_Bool_Exp>;
};


export type Query_RootSnapshotBookings_AggregateArgs = {
  distinct_on?: Maybe<Array<SnapshotBookings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SnapshotBookings_Order_By>>;
  where?: Maybe<SnapshotBookings_Bool_Exp>;
};


export type Query_RootSnapshotBookings_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootTransactionsArgs = {
  distinct_on?: Maybe<Array<Transactions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Transactions_Order_By>>;
  where?: Maybe<Transactions_Bool_Exp>;
};


export type Query_RootTransactions_AggregateArgs = {
  distinct_on?: Maybe<Array<Transactions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Transactions_Order_By>>;
  where?: Maybe<Transactions_Bool_Exp>;
};


export type Query_RootTransactions_By_PkArgs = {
  id: Scalars['uuid'];
};

export type RequestProlongOrderResult = {
  __typename?: 'RequestProlongOrderResult';
  message?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

/** columns and relationships of "seasons" */
export type Seasons = {
  __typename?: 'seasons';
  created_at: Scalars['timestamptz'];
  endDate: Scalars['date'];
  id: Scalars['uuid'];
  /** An object relationship */
  location: Locations;
  locationId: Scalars['uuid'];
  name: Scalars['String'];
  startDate: Scalars['date'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "seasons" */
export type Seasons_Aggregate = {
  __typename?: 'seasons_aggregate';
  aggregate?: Maybe<Seasons_Aggregate_Fields>;
  nodes: Array<Seasons>;
};

export type Seasons_Aggregate_Bool_Exp = {
  count?: Maybe<Seasons_Aggregate_Bool_Exp_Count>;
};

export type Seasons_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Seasons_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Seasons_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "seasons" */
export type Seasons_Aggregate_Fields = {
  __typename?: 'seasons_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Seasons_Max_Fields>;
  min?: Maybe<Seasons_Min_Fields>;
};


/** aggregate fields of "seasons" */
export type Seasons_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Seasons_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "seasons" */
export type Seasons_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Seasons_Max_Order_By>;
  min?: Maybe<Seasons_Min_Order_By>;
};

/** input type for inserting array relation for remote table "seasons" */
export type Seasons_Arr_Rel_Insert_Input = {
  data: Array<Seasons_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Seasons_On_Conflict>;
};

/** Boolean expression to filter rows from the table "seasons". All fields are combined with a logical 'AND'. */
export type Seasons_Bool_Exp = {
  _and?: Maybe<Array<Seasons_Bool_Exp>>;
  _not?: Maybe<Seasons_Bool_Exp>;
  _or?: Maybe<Array<Seasons_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  endDate?: Maybe<Date_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  location?: Maybe<Locations_Bool_Exp>;
  locationId?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  startDate?: Maybe<Date_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "seasons" */
export enum Seasons_Constraint {
  /** unique or primary key constraint on columns "id" */
  SeasonsPkey = 'seasons_pkey'
}

/** input type for inserting data into table "seasons" */
export type Seasons_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  endDate?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  location?: Maybe<Locations_Obj_Rel_Insert_Input>;
  locationId?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['date']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Seasons_Max_Fields = {
  __typename?: 'seasons_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  endDate?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  locationId?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['date']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "seasons" */
export type Seasons_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  endDate?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  locationId?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  startDate?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Seasons_Min_Fields = {
  __typename?: 'seasons_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  endDate?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  locationId?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['date']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "seasons" */
export type Seasons_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  endDate?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  locationId?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  startDate?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "seasons" */
export type Seasons_Mutation_Response = {
  __typename?: 'seasons_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Seasons>;
};

/** on_conflict condition type for table "seasons" */
export type Seasons_On_Conflict = {
  constraint: Seasons_Constraint;
  update_columns: Array<Seasons_Update_Column>;
  where?: Maybe<Seasons_Bool_Exp>;
};

/** Ordering options when selecting data from "seasons". */
export type Seasons_Order_By = {
  created_at?: Maybe<Order_By>;
  endDate?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location?: Maybe<Locations_Order_By>;
  locationId?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  startDate?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: seasons */
export type Seasons_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "seasons" */
export enum Seasons_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EndDate = 'endDate',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'locationId',
  /** column name */
  Name = 'name',
  /** column name */
  StartDate = 'startDate',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "seasons" */
export type Seasons_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  endDate?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  locationId?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['date']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "seasons" */
export type Seasons_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Seasons_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Seasons_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  endDate?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  locationId?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['date']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "seasons" */
export enum Seasons_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EndDate = 'endDate',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'locationId',
  /** column name */
  Name = 'name',
  /** column name */
  StartDate = 'startDate',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Seasons_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Seasons_Set_Input>;
  /** filter the rows which have to be updated */
  where: Seasons_Bool_Exp;
};

/** columns and relationships of "snapshotAvailabilities" */
export type SnapshotAvailabilities = {
  __typename?: 'snapshotAvailabilities';
  availableBikes: Scalars['Int'];
  created_at?: Maybe<Scalars['timestamptz']>;
  date: Scalars['date'];
  id: Scalars['uuid'];
  /** An object relationship */
  product: Products;
  productId: Scalars['uuid'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "snapshotAvailabilities" */
export type SnapshotAvailabilities_Aggregate = {
  __typename?: 'snapshotAvailabilities_aggregate';
  aggregate?: Maybe<SnapshotAvailabilities_Aggregate_Fields>;
  nodes: Array<SnapshotAvailabilities>;
};

export type SnapshotAvailabilities_Aggregate_Bool_Exp = {
  count?: Maybe<SnapshotAvailabilities_Aggregate_Bool_Exp_Count>;
};

export type SnapshotAvailabilities_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<SnapshotAvailabilities_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<SnapshotAvailabilities_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "snapshotAvailabilities" */
export type SnapshotAvailabilities_Aggregate_Fields = {
  __typename?: 'snapshotAvailabilities_aggregate_fields';
  avg?: Maybe<SnapshotAvailabilities_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<SnapshotAvailabilities_Max_Fields>;
  min?: Maybe<SnapshotAvailabilities_Min_Fields>;
  stddev?: Maybe<SnapshotAvailabilities_Stddev_Fields>;
  stddev_pop?: Maybe<SnapshotAvailabilities_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<SnapshotAvailabilities_Stddev_Samp_Fields>;
  sum?: Maybe<SnapshotAvailabilities_Sum_Fields>;
  var_pop?: Maybe<SnapshotAvailabilities_Var_Pop_Fields>;
  var_samp?: Maybe<SnapshotAvailabilities_Var_Samp_Fields>;
  variance?: Maybe<SnapshotAvailabilities_Variance_Fields>;
};


/** aggregate fields of "snapshotAvailabilities" */
export type SnapshotAvailabilities_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<SnapshotAvailabilities_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "snapshotAvailabilities" */
export type SnapshotAvailabilities_Aggregate_Order_By = {
  avg?: Maybe<SnapshotAvailabilities_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<SnapshotAvailabilities_Max_Order_By>;
  min?: Maybe<SnapshotAvailabilities_Min_Order_By>;
  stddev?: Maybe<SnapshotAvailabilities_Stddev_Order_By>;
  stddev_pop?: Maybe<SnapshotAvailabilities_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<SnapshotAvailabilities_Stddev_Samp_Order_By>;
  sum?: Maybe<SnapshotAvailabilities_Sum_Order_By>;
  var_pop?: Maybe<SnapshotAvailabilities_Var_Pop_Order_By>;
  var_samp?: Maybe<SnapshotAvailabilities_Var_Samp_Order_By>;
  variance?: Maybe<SnapshotAvailabilities_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "snapshotAvailabilities" */
export type SnapshotAvailabilities_Arr_Rel_Insert_Input = {
  data: Array<SnapshotAvailabilities_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<SnapshotAvailabilities_On_Conflict>;
};

/** aggregate avg on columns */
export type SnapshotAvailabilities_Avg_Fields = {
  __typename?: 'snapshotAvailabilities_avg_fields';
  availableBikes?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "snapshotAvailabilities" */
export type SnapshotAvailabilities_Avg_Order_By = {
  availableBikes?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "snapshotAvailabilities". All fields are combined with a logical 'AND'. */
export type SnapshotAvailabilities_Bool_Exp = {
  _and?: Maybe<Array<SnapshotAvailabilities_Bool_Exp>>;
  _not?: Maybe<SnapshotAvailabilities_Bool_Exp>;
  _or?: Maybe<Array<SnapshotAvailabilities_Bool_Exp>>;
  availableBikes?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  date?: Maybe<Date_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  product?: Maybe<Products_Bool_Exp>;
  productId?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "snapshotAvailabilities" */
export enum SnapshotAvailabilities_Constraint {
  /** unique or primary key constraint on columns "id" */
  SnapshotAailabilitiesPkey = 'snapshotAailabilities_pkey',
  /** unique or primary key constraint on columns "date", "productId" */
  SnapshotAvailabilitiesProductIdDateKey = 'snapshotAvailabilities_productId_date_key'
}

/** input type for incrementing numeric columns in table "snapshotAvailabilities" */
export type SnapshotAvailabilities_Inc_Input = {
  availableBikes?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "snapshotAvailabilities" */
export type SnapshotAvailabilities_Insert_Input = {
  availableBikes?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  product?: Maybe<Products_Obj_Rel_Insert_Input>;
  productId?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type SnapshotAvailabilities_Max_Fields = {
  __typename?: 'snapshotAvailabilities_max_fields';
  availableBikes?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  productId?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "snapshotAvailabilities" */
export type SnapshotAvailabilities_Max_Order_By = {
  availableBikes?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  productId?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type SnapshotAvailabilities_Min_Fields = {
  __typename?: 'snapshotAvailabilities_min_fields';
  availableBikes?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  productId?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "snapshotAvailabilities" */
export type SnapshotAvailabilities_Min_Order_By = {
  availableBikes?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  productId?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "snapshotAvailabilities" */
export type SnapshotAvailabilities_Mutation_Response = {
  __typename?: 'snapshotAvailabilities_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SnapshotAvailabilities>;
};

/** on_conflict condition type for table "snapshotAvailabilities" */
export type SnapshotAvailabilities_On_Conflict = {
  constraint: SnapshotAvailabilities_Constraint;
  update_columns: Array<SnapshotAvailabilities_Update_Column>;
  where?: Maybe<SnapshotAvailabilities_Bool_Exp>;
};

/** Ordering options when selecting data from "snapshotAvailabilities". */
export type SnapshotAvailabilities_Order_By = {
  availableBikes?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  product?: Maybe<Products_Order_By>;
  productId?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: snapshotAvailabilities */
export type SnapshotAvailabilities_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "snapshotAvailabilities" */
export enum SnapshotAvailabilities_Select_Column {
  /** column name */
  AvailableBikes = 'availableBikes',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Date = 'date',
  /** column name */
  Id = 'id',
  /** column name */
  ProductId = 'productId',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "snapshotAvailabilities" */
export type SnapshotAvailabilities_Set_Input = {
  availableBikes?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  productId?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type SnapshotAvailabilities_Stddev_Fields = {
  __typename?: 'snapshotAvailabilities_stddev_fields';
  availableBikes?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "snapshotAvailabilities" */
export type SnapshotAvailabilities_Stddev_Order_By = {
  availableBikes?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type SnapshotAvailabilities_Stddev_Pop_Fields = {
  __typename?: 'snapshotAvailabilities_stddev_pop_fields';
  availableBikes?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "snapshotAvailabilities" */
export type SnapshotAvailabilities_Stddev_Pop_Order_By = {
  availableBikes?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type SnapshotAvailabilities_Stddev_Samp_Fields = {
  __typename?: 'snapshotAvailabilities_stddev_samp_fields';
  availableBikes?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "snapshotAvailabilities" */
export type SnapshotAvailabilities_Stddev_Samp_Order_By = {
  availableBikes?: Maybe<Order_By>;
};

/** Streaming cursor of the table "snapshotAvailabilities" */
export type SnapshotAvailabilities_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: SnapshotAvailabilities_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type SnapshotAvailabilities_Stream_Cursor_Value_Input = {
  availableBikes?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  productId?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type SnapshotAvailabilities_Sum_Fields = {
  __typename?: 'snapshotAvailabilities_sum_fields';
  availableBikes?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "snapshotAvailabilities" */
export type SnapshotAvailabilities_Sum_Order_By = {
  availableBikes?: Maybe<Order_By>;
};

/** update columns of table "snapshotAvailabilities" */
export enum SnapshotAvailabilities_Update_Column {
  /** column name */
  AvailableBikes = 'availableBikes',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Date = 'date',
  /** column name */
  Id = 'id',
  /** column name */
  ProductId = 'productId',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type SnapshotAvailabilities_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<SnapshotAvailabilities_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<SnapshotAvailabilities_Set_Input>;
  /** filter the rows which have to be updated */
  where: SnapshotAvailabilities_Bool_Exp;
};

/** aggregate var_pop on columns */
export type SnapshotAvailabilities_Var_Pop_Fields = {
  __typename?: 'snapshotAvailabilities_var_pop_fields';
  availableBikes?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "snapshotAvailabilities" */
export type SnapshotAvailabilities_Var_Pop_Order_By = {
  availableBikes?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type SnapshotAvailabilities_Var_Samp_Fields = {
  __typename?: 'snapshotAvailabilities_var_samp_fields';
  availableBikes?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "snapshotAvailabilities" */
export type SnapshotAvailabilities_Var_Samp_Order_By = {
  availableBikes?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type SnapshotAvailabilities_Variance_Fields = {
  __typename?: 'snapshotAvailabilities_variance_fields';
  availableBikes?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "snapshotAvailabilities" */
export type SnapshotAvailabilities_Variance_Order_By = {
  availableBikes?: Maybe<Order_By>;
};

/** columns and relationships of "snapshotBookings" */
export type SnapshotBookings = {
  __typename?: 'snapshotBookings';
  bookingsSnapshot: Scalars['jsonb'];
  /** An object relationship */
  customer: Customers;
  customerId: Scalars['uuid'];
  id: Scalars['uuid'];
  /** An object relationship */
  order: Orders;
  orderId: Scalars['uuid'];
};


/** columns and relationships of "snapshotBookings" */
export type SnapshotBookingsBookingsSnapshotArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "snapshotBookings" */
export type SnapshotBookings_Aggregate = {
  __typename?: 'snapshotBookings_aggregate';
  aggregate?: Maybe<SnapshotBookings_Aggregate_Fields>;
  nodes: Array<SnapshotBookings>;
};

export type SnapshotBookings_Aggregate_Bool_Exp = {
  count?: Maybe<SnapshotBookings_Aggregate_Bool_Exp_Count>;
};

export type SnapshotBookings_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<SnapshotBookings_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<SnapshotBookings_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "snapshotBookings" */
export type SnapshotBookings_Aggregate_Fields = {
  __typename?: 'snapshotBookings_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<SnapshotBookings_Max_Fields>;
  min?: Maybe<SnapshotBookings_Min_Fields>;
};


/** aggregate fields of "snapshotBookings" */
export type SnapshotBookings_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<SnapshotBookings_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "snapshotBookings" */
export type SnapshotBookings_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<SnapshotBookings_Max_Order_By>;
  min?: Maybe<SnapshotBookings_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type SnapshotBookings_Append_Input = {
  bookingsSnapshot?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "snapshotBookings" */
export type SnapshotBookings_Arr_Rel_Insert_Input = {
  data: Array<SnapshotBookings_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<SnapshotBookings_On_Conflict>;
};

/** Boolean expression to filter rows from the table "snapshotBookings". All fields are combined with a logical 'AND'. */
export type SnapshotBookings_Bool_Exp = {
  _and?: Maybe<Array<SnapshotBookings_Bool_Exp>>;
  _not?: Maybe<SnapshotBookings_Bool_Exp>;
  _or?: Maybe<Array<SnapshotBookings_Bool_Exp>>;
  bookingsSnapshot?: Maybe<Jsonb_Comparison_Exp>;
  customer?: Maybe<Customers_Bool_Exp>;
  customerId?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  order?: Maybe<Orders_Bool_Exp>;
  orderId?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "snapshotBookings" */
export enum SnapshotBookings_Constraint {
  /** unique or primary key constraint on columns "id" */
  OrderSnapshotsPkey = 'orderSnapshots_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type SnapshotBookings_Delete_At_Path_Input = {
  bookingsSnapshot?: Maybe<Array<Scalars['String']>>;
};

/**
 * delete the array element with specified index (negative integers count from the
 * end). throws an error if top level container is not an array
 */
export type SnapshotBookings_Delete_Elem_Input = {
  bookingsSnapshot?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type SnapshotBookings_Delete_Key_Input = {
  bookingsSnapshot?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "snapshotBookings" */
export type SnapshotBookings_Insert_Input = {
  bookingsSnapshot?: Maybe<Scalars['jsonb']>;
  customer?: Maybe<Customers_Obj_Rel_Insert_Input>;
  customerId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Orders_Obj_Rel_Insert_Input>;
  orderId?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type SnapshotBookings_Max_Fields = {
  __typename?: 'snapshotBookings_max_fields';
  customerId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  orderId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "snapshotBookings" */
export type SnapshotBookings_Max_Order_By = {
  customerId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  orderId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type SnapshotBookings_Min_Fields = {
  __typename?: 'snapshotBookings_min_fields';
  customerId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  orderId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "snapshotBookings" */
export type SnapshotBookings_Min_Order_By = {
  customerId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  orderId?: Maybe<Order_By>;
};

/** response of any mutation on the table "snapshotBookings" */
export type SnapshotBookings_Mutation_Response = {
  __typename?: 'snapshotBookings_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SnapshotBookings>;
};

/** on_conflict condition type for table "snapshotBookings" */
export type SnapshotBookings_On_Conflict = {
  constraint: SnapshotBookings_Constraint;
  update_columns: Array<SnapshotBookings_Update_Column>;
  where?: Maybe<SnapshotBookings_Bool_Exp>;
};

/** Ordering options when selecting data from "snapshotBookings". */
export type SnapshotBookings_Order_By = {
  bookingsSnapshot?: Maybe<Order_By>;
  customer?: Maybe<Customers_Order_By>;
  customerId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Orders_Order_By>;
  orderId?: Maybe<Order_By>;
};

/** primary key columns input for table: snapshotBookings */
export type SnapshotBookings_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type SnapshotBookings_Prepend_Input = {
  bookingsSnapshot?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "snapshotBookings" */
export enum SnapshotBookings_Select_Column {
  /** column name */
  BookingsSnapshot = 'bookingsSnapshot',
  /** column name */
  CustomerId = 'customerId',
  /** column name */
  Id = 'id',
  /** column name */
  OrderId = 'orderId'
}

/** input type for updating data in table "snapshotBookings" */
export type SnapshotBookings_Set_Input = {
  bookingsSnapshot?: Maybe<Scalars['jsonb']>;
  customerId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  orderId?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "snapshotBookings" */
export type SnapshotBookings_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: SnapshotBookings_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type SnapshotBookings_Stream_Cursor_Value_Input = {
  bookingsSnapshot?: Maybe<Scalars['jsonb']>;
  customerId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  orderId?: Maybe<Scalars['uuid']>;
};

/** update columns of table "snapshotBookings" */
export enum SnapshotBookings_Update_Column {
  /** column name */
  BookingsSnapshot = 'bookingsSnapshot',
  /** column name */
  CustomerId = 'customerId',
  /** column name */
  Id = 'id',
  /** column name */
  OrderId = 'orderId'
}

export type SnapshotBookings_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<SnapshotBookings_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<SnapshotBookings_Delete_At_Path_Input>;
  /**
   * delete the array element with specified index (negative integers count from
   * the end). throws an error if top level container is not an array
   */
  _delete_elem?: Maybe<SnapshotBookings_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<SnapshotBookings_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<SnapshotBookings_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<SnapshotBookings_Set_Input>;
  /** filter the rows which have to be updated */
  where: SnapshotBookings_Bool_Exp;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: Maybe<Scalars['String']>;
  _gt?: Maybe<Scalars['String']>;
  _gte?: Maybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: Maybe<Scalars['String']>;
  _in?: Maybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: Maybe<Scalars['String']>;
  _is_null?: Maybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: Maybe<Scalars['String']>;
  _lt?: Maybe<Scalars['String']>;
  _lte?: Maybe<Scalars['String']>;
  _neq?: Maybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: Maybe<Scalars['String']>;
  _nin?: Maybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: Maybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: Maybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: Maybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: Maybe<Scalars['String']>;
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "bookingRequest" */
  bookingRequest: Array<BookingRequest>;
  /** fetch aggregated fields from the table: "bookingRequest" */
  bookingRequest_aggregate: BookingRequest_Aggregate;
  /** fetch data from the table: "bookingRequest" using primary key columns */
  bookingRequest_by_pk?: Maybe<BookingRequest>;
  /** fetch data from the table in a streaming manner: "bookingRequest" */
  bookingRequest_stream: Array<BookingRequest>;
  /** An array relationship */
  bookings: Array<Bookings>;
  /** An aggregate relationship */
  bookings_aggregate: Bookings_Aggregate;
  /** fetch data from the table: "bookings" using primary key columns */
  bookings_by_pk?: Maybe<Bookings>;
  /** fetch data from the table in a streaming manner: "bookings" */
  bookings_stream: Array<Bookings>;
  /** An array relationship */
  bookings_transactions: Array<Bookings_Transactions>;
  /** An aggregate relationship */
  bookings_transactions_aggregate: Bookings_Transactions_Aggregate;
  /** fetch data from the table: "bookings_transactions" using primary key columns */
  bookings_transactions_by_pk?: Maybe<Bookings_Transactions>;
  /** fetch data from the table in a streaming manner: "bookings_transactions" */
  bookings_transactions_stream: Array<Bookings_Transactions>;
  /** An array relationship */
  closingDates: Array<ClosingDates>;
  /** An aggregate relationship */
  closingDates_aggregate: ClosingDates_Aggregate;
  /** fetch data from the table: "closingDates" using primary key columns */
  closingDates_by_pk?: Maybe<ClosingDates>;
  /** fetch data from the table in a streaming manner: "closingDates" */
  closingDates_stream: Array<ClosingDates>;
  /** fetch data from the table: "customers" */
  customers: Array<Customers>;
  /** fetch aggregated fields from the table: "customers" */
  customers_aggregate: Customers_Aggregate;
  /** fetch data from the table: "customers" using primary key columns */
  customers_by_pk?: Maybe<Customers>;
  /** fetch data from the table in a streaming manner: "customers" */
  customers_stream: Array<Customers>;
  /** An array relationship */
  extraRecipeLines: Array<ExtraRecipeLines>;
  /** An array relationship */
  extraRecipeLinesTransactions: Array<ExtraRecipeLinesTransactions>;
  /** An aggregate relationship */
  extraRecipeLinesTransactions_aggregate: ExtraRecipeLinesTransactions_Aggregate;
  /** fetch data from the table: "extraRecipeLinesTransactions" using primary key columns */
  extraRecipeLinesTransactions_by_pk?: Maybe<ExtraRecipeLinesTransactions>;
  /** fetch data from the table in a streaming manner: "extraRecipeLinesTransactions" */
  extraRecipeLinesTransactions_stream: Array<ExtraRecipeLinesTransactions>;
  /** An aggregate relationship */
  extraRecipeLines_aggregate: ExtraRecipeLines_Aggregate;
  /** fetch data from the table: "extraRecipeLines" using primary key columns */
  extraRecipeLines_by_pk?: Maybe<ExtraRecipeLines>;
  /** fetch data from the table in a streaming manner: "extraRecipeLines" */
  extraRecipeLines_stream: Array<ExtraRecipeLines>;
  /** fetch data from the table: "locationMessages" */
  locationMessages: Array<LocationMessages>;
  /** fetch aggregated fields from the table: "locationMessages" */
  locationMessages_aggregate: LocationMessages_Aggregate;
  /** fetch data from the table: "locationMessages" using primary key columns */
  locationMessages_by_pk?: Maybe<LocationMessages>;
  /** fetch data from the table in a streaming manner: "locationMessages" */
  locationMessages_stream: Array<LocationMessages>;
  /** fetch data from the table: "locations" */
  locations: Array<Locations>;
  /** fetch aggregated fields from the table: "locations" */
  locations_aggregate: Locations_Aggregate;
  /** fetch data from the table: "locations" using primary key columns */
  locations_by_pk?: Maybe<Locations>;
  /** fetch data from the table in a streaming manner: "locations" */
  locations_stream: Array<Locations>;
  /** An array relationship */
  orders: Array<Orders>;
  /** An aggregate relationship */
  orders_aggregate: Orders_Aggregate;
  /** fetch data from the table: "orders" using primary key columns */
  orders_by_pk?: Maybe<Orders>;
  /** fetch data from the table in a streaming manner: "orders" */
  orders_stream: Array<Orders>;
  /** An array relationship */
  productPrices: Array<ProductPrices>;
  /** An aggregate relationship */
  productPrices_aggregate: ProductPrices_Aggregate;
  /** fetch data from the table: "productPrices" using primary key columns */
  productPrices_by_pk?: Maybe<ProductPrices>;
  /** fetch data from the table in a streaming manner: "productPrices" */
  productPrices_stream: Array<ProductPrices>;
  /** An array relationship */
  productTransactions: Array<ProductTransactions>;
  /** An aggregate relationship */
  productTransactions_aggregate: ProductTransactions_Aggregate;
  /** fetch data from the table: "productTransactions" using primary key columns */
  productTransactions_by_pk?: Maybe<ProductTransactions>;
  /** fetch data from the table in a streaming manner: "productTransactions" */
  productTransactions_stream: Array<ProductTransactions>;
  /** fetch data from the table: "productTypeAvailabilities" */
  productTypeAvailabilities: Array<ProductTypeAvailabilities>;
  /** fetch aggregated fields from the table: "productTypeAvailabilities" */
  productTypeAvailabilities_aggregate: ProductTypeAvailabilities_Aggregate;
  /** fetch data from the table in a streaming manner: "productTypeAvailabilities" */
  productTypeAvailabilities_stream: Array<ProductTypeAvailabilities>;
  /** fetch data from the table: "productTypeModels" */
  productTypeModels: Array<ProductTypeModels>;
  /** fetch aggregated fields from the table: "productTypeModels" */
  productTypeModels_aggregate: ProductTypeModels_Aggregate;
  /** fetch data from the table: "productTypeModels" using primary key columns */
  productTypeModels_by_pk?: Maybe<ProductTypeModels>;
  /** fetch data from the table in a streaming manner: "productTypeModels" */
  productTypeModels_stream: Array<ProductTypeModels>;
  /** fetch data from the table: "productTypePrices" */
  productTypePrices: Array<ProductTypePrices>;
  /** fetch aggregated fields from the table: "productTypePrices" */
  productTypePrices_aggregate: ProductTypePrices_Aggregate;
  /** fetch data from the table in a streaming manner: "productTypePrices" */
  productTypePrices_stream: Array<ProductTypePrices>;
  /** An array relationship */
  productTypes: Array<ProductTypes>;
  /** An aggregate relationship */
  productTypes_aggregate: ProductTypes_Aggregate;
  /** fetch data from the table: "productTypes" using primary key columns */
  productTypes_by_pk?: Maybe<ProductTypes>;
  /** fetch data from the table in a streaming manner: "productTypes" */
  productTypes_stream: Array<ProductTypes>;
  /** An array relationship */
  products: Array<Products>;
  /** An aggregate relationship */
  products_aggregate: Products_Aggregate;
  /** fetch data from the table: "products" using primary key columns */
  products_by_pk?: Maybe<Products>;
  /** fetch data from the table in a streaming manner: "products" */
  products_stream: Array<Products>;
  /** fetch data from the table: "publicSettings" */
  publicSettings: Array<PublicSettings>;
  /** fetch aggregated fields from the table: "publicSettings" */
  publicSettings_aggregate: PublicSettings_Aggregate;
  /** fetch data from the table: "publicSettings" using primary key columns */
  publicSettings_by_pk?: Maybe<PublicSettings>;
  /** fetch data from the table in a streaming manner: "publicSettings" */
  publicSettings_stream: Array<PublicSettings>;
  /** An array relationship */
  seasons: Array<Seasons>;
  /** An aggregate relationship */
  seasons_aggregate: Seasons_Aggregate;
  /** fetch data from the table: "seasons" using primary key columns */
  seasons_by_pk?: Maybe<Seasons>;
  /** fetch data from the table in a streaming manner: "seasons" */
  seasons_stream: Array<Seasons>;
  /** An array relationship */
  snapshotAvailabilities: Array<SnapshotAvailabilities>;
  /** An aggregate relationship */
  snapshotAvailabilities_aggregate: SnapshotAvailabilities_Aggregate;
  /** fetch data from the table: "snapshotAvailabilities" using primary key columns */
  snapshotAvailabilities_by_pk?: Maybe<SnapshotAvailabilities>;
  /** fetch data from the table in a streaming manner: "snapshotAvailabilities" */
  snapshotAvailabilities_stream: Array<SnapshotAvailabilities>;
  /** An array relationship */
  snapshotBookings: Array<SnapshotBookings>;
  /** An aggregate relationship */
  snapshotBookings_aggregate: SnapshotBookings_Aggregate;
  /** fetch data from the table: "snapshotBookings" using primary key columns */
  snapshotBookings_by_pk?: Maybe<SnapshotBookings>;
  /** fetch data from the table in a streaming manner: "snapshotBookings" */
  snapshotBookings_stream: Array<SnapshotBookings>;
  /** fetch data from the table: "transactions" */
  transactions: Array<Transactions>;
  /** fetch aggregated fields from the table: "transactions" */
  transactions_aggregate: Transactions_Aggregate;
  /** fetch data from the table: "transactions" using primary key columns */
  transactions_by_pk?: Maybe<Transactions>;
  /** fetch data from the table in a streaming manner: "transactions" */
  transactions_stream: Array<Transactions>;
};


export type Subscription_RootBookingRequestArgs = {
  distinct_on?: Maybe<Array<BookingRequest_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BookingRequest_Order_By>>;
  where?: Maybe<BookingRequest_Bool_Exp>;
};


export type Subscription_RootBookingRequest_AggregateArgs = {
  distinct_on?: Maybe<Array<BookingRequest_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BookingRequest_Order_By>>;
  where?: Maybe<BookingRequest_Bool_Exp>;
};


export type Subscription_RootBookingRequest_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootBookingRequest_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<BookingRequest_Stream_Cursor_Input>>;
  where?: Maybe<BookingRequest_Bool_Exp>;
};


export type Subscription_RootBookingsArgs = {
  distinct_on?: Maybe<Array<Bookings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bookings_Order_By>>;
  where?: Maybe<Bookings_Bool_Exp>;
};


export type Subscription_RootBookings_AggregateArgs = {
  distinct_on?: Maybe<Array<Bookings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bookings_Order_By>>;
  where?: Maybe<Bookings_Bool_Exp>;
};


export type Subscription_RootBookings_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootBookings_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Bookings_Stream_Cursor_Input>>;
  where?: Maybe<Bookings_Bool_Exp>;
};


export type Subscription_RootBookings_TransactionsArgs = {
  distinct_on?: Maybe<Array<Bookings_Transactions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bookings_Transactions_Order_By>>;
  where?: Maybe<Bookings_Transactions_Bool_Exp>;
};


export type Subscription_RootBookings_Transactions_AggregateArgs = {
  distinct_on?: Maybe<Array<Bookings_Transactions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bookings_Transactions_Order_By>>;
  where?: Maybe<Bookings_Transactions_Bool_Exp>;
};


export type Subscription_RootBookings_Transactions_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootBookings_Transactions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Bookings_Transactions_Stream_Cursor_Input>>;
  where?: Maybe<Bookings_Transactions_Bool_Exp>;
};


export type Subscription_RootClosingDatesArgs = {
  distinct_on?: Maybe<Array<ClosingDates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ClosingDates_Order_By>>;
  where?: Maybe<ClosingDates_Bool_Exp>;
};


export type Subscription_RootClosingDates_AggregateArgs = {
  distinct_on?: Maybe<Array<ClosingDates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ClosingDates_Order_By>>;
  where?: Maybe<ClosingDates_Bool_Exp>;
};


export type Subscription_RootClosingDates_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootClosingDates_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<ClosingDates_Stream_Cursor_Input>>;
  where?: Maybe<ClosingDates_Bool_Exp>;
};


export type Subscription_RootCustomersArgs = {
  distinct_on?: Maybe<Array<Customers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customers_Order_By>>;
  where?: Maybe<Customers_Bool_Exp>;
};


export type Subscription_RootCustomers_AggregateArgs = {
  distinct_on?: Maybe<Array<Customers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customers_Order_By>>;
  where?: Maybe<Customers_Bool_Exp>;
};


export type Subscription_RootCustomers_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCustomers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Customers_Stream_Cursor_Input>>;
  where?: Maybe<Customers_Bool_Exp>;
};


export type Subscription_RootExtraRecipeLinesArgs = {
  distinct_on?: Maybe<Array<ExtraRecipeLines_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ExtraRecipeLines_Order_By>>;
  where?: Maybe<ExtraRecipeLines_Bool_Exp>;
};


export type Subscription_RootExtraRecipeLinesTransactionsArgs = {
  distinct_on?: Maybe<Array<ExtraRecipeLinesTransactions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ExtraRecipeLinesTransactions_Order_By>>;
  where?: Maybe<ExtraRecipeLinesTransactions_Bool_Exp>;
};


export type Subscription_RootExtraRecipeLinesTransactions_AggregateArgs = {
  distinct_on?: Maybe<Array<ExtraRecipeLinesTransactions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ExtraRecipeLinesTransactions_Order_By>>;
  where?: Maybe<ExtraRecipeLinesTransactions_Bool_Exp>;
};


export type Subscription_RootExtraRecipeLinesTransactions_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootExtraRecipeLinesTransactions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<ExtraRecipeLinesTransactions_Stream_Cursor_Input>>;
  where?: Maybe<ExtraRecipeLinesTransactions_Bool_Exp>;
};


export type Subscription_RootExtraRecipeLines_AggregateArgs = {
  distinct_on?: Maybe<Array<ExtraRecipeLines_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ExtraRecipeLines_Order_By>>;
  where?: Maybe<ExtraRecipeLines_Bool_Exp>;
};


export type Subscription_RootExtraRecipeLines_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootExtraRecipeLines_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<ExtraRecipeLines_Stream_Cursor_Input>>;
  where?: Maybe<ExtraRecipeLines_Bool_Exp>;
};


export type Subscription_RootLocationMessagesArgs = {
  distinct_on?: Maybe<Array<LocationMessages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LocationMessages_Order_By>>;
  where?: Maybe<LocationMessages_Bool_Exp>;
};


export type Subscription_RootLocationMessages_AggregateArgs = {
  distinct_on?: Maybe<Array<LocationMessages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LocationMessages_Order_By>>;
  where?: Maybe<LocationMessages_Bool_Exp>;
};


export type Subscription_RootLocationMessages_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootLocationMessages_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<LocationMessages_Stream_Cursor_Input>>;
  where?: Maybe<LocationMessages_Bool_Exp>;
};


export type Subscription_RootLocationsArgs = {
  distinct_on?: Maybe<Array<Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Locations_Order_By>>;
  where?: Maybe<Locations_Bool_Exp>;
};


export type Subscription_RootLocations_AggregateArgs = {
  distinct_on?: Maybe<Array<Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Locations_Order_By>>;
  where?: Maybe<Locations_Bool_Exp>;
};


export type Subscription_RootLocations_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootLocations_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Locations_Stream_Cursor_Input>>;
  where?: Maybe<Locations_Bool_Exp>;
};


export type Subscription_RootOrdersArgs = {
  distinct_on?: Maybe<Array<Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Orders_Order_By>>;
  where?: Maybe<Orders_Bool_Exp>;
};


export type Subscription_RootOrders_AggregateArgs = {
  distinct_on?: Maybe<Array<Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Orders_Order_By>>;
  where?: Maybe<Orders_Bool_Exp>;
};


export type Subscription_RootOrders_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootOrders_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Orders_Stream_Cursor_Input>>;
  where?: Maybe<Orders_Bool_Exp>;
};


export type Subscription_RootProductPricesArgs = {
  distinct_on?: Maybe<Array<ProductPrices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductPrices_Order_By>>;
  where?: Maybe<ProductPrices_Bool_Exp>;
};


export type Subscription_RootProductPrices_AggregateArgs = {
  distinct_on?: Maybe<Array<ProductPrices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductPrices_Order_By>>;
  where?: Maybe<ProductPrices_Bool_Exp>;
};


export type Subscription_RootProductPrices_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootProductPrices_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<ProductPrices_Stream_Cursor_Input>>;
  where?: Maybe<ProductPrices_Bool_Exp>;
};


export type Subscription_RootProductTransactionsArgs = {
  distinct_on?: Maybe<Array<ProductTransactions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductTransactions_Order_By>>;
  where?: Maybe<ProductTransactions_Bool_Exp>;
};


export type Subscription_RootProductTransactions_AggregateArgs = {
  distinct_on?: Maybe<Array<ProductTransactions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductTransactions_Order_By>>;
  where?: Maybe<ProductTransactions_Bool_Exp>;
};


export type Subscription_RootProductTransactions_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootProductTransactions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<ProductTransactions_Stream_Cursor_Input>>;
  where?: Maybe<ProductTransactions_Bool_Exp>;
};


export type Subscription_RootProductTypeAvailabilitiesArgs = {
  distinct_on?: Maybe<Array<ProductTypeAvailabilities_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductTypeAvailabilities_Order_By>>;
  where?: Maybe<ProductTypeAvailabilities_Bool_Exp>;
};


export type Subscription_RootProductTypeAvailabilities_AggregateArgs = {
  distinct_on?: Maybe<Array<ProductTypeAvailabilities_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductTypeAvailabilities_Order_By>>;
  where?: Maybe<ProductTypeAvailabilities_Bool_Exp>;
};


export type Subscription_RootProductTypeAvailabilities_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<ProductTypeAvailabilities_Stream_Cursor_Input>>;
  where?: Maybe<ProductTypeAvailabilities_Bool_Exp>;
};


export type Subscription_RootProductTypeModelsArgs = {
  distinct_on?: Maybe<Array<ProductTypeModels_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductTypeModels_Order_By>>;
  where?: Maybe<ProductTypeModels_Bool_Exp>;
};


export type Subscription_RootProductTypeModels_AggregateArgs = {
  distinct_on?: Maybe<Array<ProductTypeModels_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductTypeModels_Order_By>>;
  where?: Maybe<ProductTypeModels_Bool_Exp>;
};


export type Subscription_RootProductTypeModels_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootProductTypeModels_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<ProductTypeModels_Stream_Cursor_Input>>;
  where?: Maybe<ProductTypeModels_Bool_Exp>;
};


export type Subscription_RootProductTypePricesArgs = {
  distinct_on?: Maybe<Array<ProductTypePrices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductTypePrices_Order_By>>;
  where?: Maybe<ProductTypePrices_Bool_Exp>;
};


export type Subscription_RootProductTypePrices_AggregateArgs = {
  distinct_on?: Maybe<Array<ProductTypePrices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductTypePrices_Order_By>>;
  where?: Maybe<ProductTypePrices_Bool_Exp>;
};


export type Subscription_RootProductTypePrices_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<ProductTypePrices_Stream_Cursor_Input>>;
  where?: Maybe<ProductTypePrices_Bool_Exp>;
};


export type Subscription_RootProductTypesArgs = {
  distinct_on?: Maybe<Array<ProductTypes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductTypes_Order_By>>;
  where?: Maybe<ProductTypes_Bool_Exp>;
};


export type Subscription_RootProductTypes_AggregateArgs = {
  distinct_on?: Maybe<Array<ProductTypes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductTypes_Order_By>>;
  where?: Maybe<ProductTypes_Bool_Exp>;
};


export type Subscription_RootProductTypes_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootProductTypes_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<ProductTypes_Stream_Cursor_Input>>;
  where?: Maybe<ProductTypes_Bool_Exp>;
};


export type Subscription_RootProductsArgs = {
  distinct_on?: Maybe<Array<Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Products_Order_By>>;
  where?: Maybe<Products_Bool_Exp>;
};


export type Subscription_RootProducts_AggregateArgs = {
  distinct_on?: Maybe<Array<Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Products_Order_By>>;
  where?: Maybe<Products_Bool_Exp>;
};


export type Subscription_RootProducts_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootProducts_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Products_Stream_Cursor_Input>>;
  where?: Maybe<Products_Bool_Exp>;
};


export type Subscription_RootPublicSettingsArgs = {
  distinct_on?: Maybe<Array<PublicSettings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<PublicSettings_Order_By>>;
  where?: Maybe<PublicSettings_Bool_Exp>;
};


export type Subscription_RootPublicSettings_AggregateArgs = {
  distinct_on?: Maybe<Array<PublicSettings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<PublicSettings_Order_By>>;
  where?: Maybe<PublicSettings_Bool_Exp>;
};


export type Subscription_RootPublicSettings_By_PkArgs = {
  key: Scalars['String'];
};


export type Subscription_RootPublicSettings_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<PublicSettings_Stream_Cursor_Input>>;
  where?: Maybe<PublicSettings_Bool_Exp>;
};


export type Subscription_RootSeasonsArgs = {
  distinct_on?: Maybe<Array<Seasons_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Seasons_Order_By>>;
  where?: Maybe<Seasons_Bool_Exp>;
};


export type Subscription_RootSeasons_AggregateArgs = {
  distinct_on?: Maybe<Array<Seasons_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Seasons_Order_By>>;
  where?: Maybe<Seasons_Bool_Exp>;
};


export type Subscription_RootSeasons_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootSeasons_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Seasons_Stream_Cursor_Input>>;
  where?: Maybe<Seasons_Bool_Exp>;
};


export type Subscription_RootSnapshotAvailabilitiesArgs = {
  distinct_on?: Maybe<Array<SnapshotAvailabilities_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SnapshotAvailabilities_Order_By>>;
  where?: Maybe<SnapshotAvailabilities_Bool_Exp>;
};


export type Subscription_RootSnapshotAvailabilities_AggregateArgs = {
  distinct_on?: Maybe<Array<SnapshotAvailabilities_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SnapshotAvailabilities_Order_By>>;
  where?: Maybe<SnapshotAvailabilities_Bool_Exp>;
};


export type Subscription_RootSnapshotAvailabilities_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootSnapshotAvailabilities_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<SnapshotAvailabilities_Stream_Cursor_Input>>;
  where?: Maybe<SnapshotAvailabilities_Bool_Exp>;
};


export type Subscription_RootSnapshotBookingsArgs = {
  distinct_on?: Maybe<Array<SnapshotBookings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SnapshotBookings_Order_By>>;
  where?: Maybe<SnapshotBookings_Bool_Exp>;
};


export type Subscription_RootSnapshotBookings_AggregateArgs = {
  distinct_on?: Maybe<Array<SnapshotBookings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SnapshotBookings_Order_By>>;
  where?: Maybe<SnapshotBookings_Bool_Exp>;
};


export type Subscription_RootSnapshotBookings_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootSnapshotBookings_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<SnapshotBookings_Stream_Cursor_Input>>;
  where?: Maybe<SnapshotBookings_Bool_Exp>;
};


export type Subscription_RootTransactionsArgs = {
  distinct_on?: Maybe<Array<Transactions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Transactions_Order_By>>;
  where?: Maybe<Transactions_Bool_Exp>;
};


export type Subscription_RootTransactions_AggregateArgs = {
  distinct_on?: Maybe<Array<Transactions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Transactions_Order_By>>;
  where?: Maybe<Transactions_Bool_Exp>;
};


export type Subscription_RootTransactions_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootTransactions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Transactions_Stream_Cursor_Input>>;
  where?: Maybe<Transactions_Bool_Exp>;
};


/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamptz']>;
  _gt?: Maybe<Scalars['timestamptz']>;
  _gte?: Maybe<Scalars['timestamptz']>;
  _in?: Maybe<Array<Scalars['timestamptz']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamptz']>;
  _lte?: Maybe<Scalars['timestamptz']>;
  _neq?: Maybe<Scalars['timestamptz']>;
  _nin?: Maybe<Array<Scalars['timestamptz']>>;
};

/** columns and relationships of "transactions" */
export type Transactions = {
  __typename?: 'transactions';
  /** An array relationship */
  bookings_transactions: Array<Bookings_Transactions>;
  /** An aggregate relationship */
  bookings_transactions_aggregate: Bookings_Transactions_Aggregate;
  comment?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  /** An array relationship */
  extraRecipeLinesTransactions: Array<ExtraRecipeLinesTransactions>;
  /** An aggregate relationship */
  extraRecipeLinesTransactions_aggregate: ExtraRecipeLinesTransactions_Aggregate;
  id: Scalars['uuid'];
  payload?: Maybe<Scalars['jsonb']>;
  /** An array relationship */
  productTransactions: Array<ProductTransactions>;
  /** An aggregate relationship */
  productTransactions_aggregate: ProductTransactions_Aggregate;
  type: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "transactions" */
export type TransactionsBookings_TransactionsArgs = {
  distinct_on?: Maybe<Array<Bookings_Transactions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bookings_Transactions_Order_By>>;
  where?: Maybe<Bookings_Transactions_Bool_Exp>;
};


/** columns and relationships of "transactions" */
export type TransactionsBookings_Transactions_AggregateArgs = {
  distinct_on?: Maybe<Array<Bookings_Transactions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bookings_Transactions_Order_By>>;
  where?: Maybe<Bookings_Transactions_Bool_Exp>;
};


/** columns and relationships of "transactions" */
export type TransactionsExtraRecipeLinesTransactionsArgs = {
  distinct_on?: Maybe<Array<ExtraRecipeLinesTransactions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ExtraRecipeLinesTransactions_Order_By>>;
  where?: Maybe<ExtraRecipeLinesTransactions_Bool_Exp>;
};


/** columns and relationships of "transactions" */
export type TransactionsExtraRecipeLinesTransactions_AggregateArgs = {
  distinct_on?: Maybe<Array<ExtraRecipeLinesTransactions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ExtraRecipeLinesTransactions_Order_By>>;
  where?: Maybe<ExtraRecipeLinesTransactions_Bool_Exp>;
};


/** columns and relationships of "transactions" */
export type TransactionsPayloadArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "transactions" */
export type TransactionsProductTransactionsArgs = {
  distinct_on?: Maybe<Array<ProductTransactions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductTransactions_Order_By>>;
  where?: Maybe<ProductTransactions_Bool_Exp>;
};


/** columns and relationships of "transactions" */
export type TransactionsProductTransactions_AggregateArgs = {
  distinct_on?: Maybe<Array<ProductTransactions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductTransactions_Order_By>>;
  where?: Maybe<ProductTransactions_Bool_Exp>;
};

/** aggregated selection of "transactions" */
export type Transactions_Aggregate = {
  __typename?: 'transactions_aggregate';
  aggregate?: Maybe<Transactions_Aggregate_Fields>;
  nodes: Array<Transactions>;
};

/** aggregate fields of "transactions" */
export type Transactions_Aggregate_Fields = {
  __typename?: 'transactions_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Transactions_Max_Fields>;
  min?: Maybe<Transactions_Min_Fields>;
};


/** aggregate fields of "transactions" */
export type Transactions_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Transactions_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Transactions_Append_Input = {
  payload?: Maybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "transactions". All fields are combined with a logical 'AND'. */
export type Transactions_Bool_Exp = {
  _and?: Maybe<Array<Transactions_Bool_Exp>>;
  _not?: Maybe<Transactions_Bool_Exp>;
  _or?: Maybe<Array<Transactions_Bool_Exp>>;
  bookings_transactions?: Maybe<Bookings_Transactions_Bool_Exp>;
  bookings_transactions_aggregate?: Maybe<Bookings_Transactions_Aggregate_Bool_Exp>;
  comment?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  extraRecipeLinesTransactions?: Maybe<ExtraRecipeLinesTransactions_Bool_Exp>;
  extraRecipeLinesTransactions_aggregate?: Maybe<ExtraRecipeLinesTransactions_Aggregate_Bool_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  payload?: Maybe<Jsonb_Comparison_Exp>;
  productTransactions?: Maybe<ProductTransactions_Bool_Exp>;
  productTransactions_aggregate?: Maybe<ProductTransactions_Aggregate_Bool_Exp>;
  type?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "transactions" */
export enum Transactions_Constraint {
  /** unique or primary key constraint on columns "id" */
  TransactionsPkey = 'transactions_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Transactions_Delete_At_Path_Input = {
  payload?: Maybe<Array<Scalars['String']>>;
};

/**
 * delete the array element with specified index (negative integers count from the
 * end). throws an error if top level container is not an array
 */
export type Transactions_Delete_Elem_Input = {
  payload?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Transactions_Delete_Key_Input = {
  payload?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "transactions" */
export type Transactions_Insert_Input = {
  bookings_transactions?: Maybe<Bookings_Transactions_Arr_Rel_Insert_Input>;
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  extraRecipeLinesTransactions?: Maybe<ExtraRecipeLinesTransactions_Arr_Rel_Insert_Input>;
  id?: Maybe<Scalars['uuid']>;
  payload?: Maybe<Scalars['jsonb']>;
  productTransactions?: Maybe<ProductTransactions_Arr_Rel_Insert_Input>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Transactions_Max_Fields = {
  __typename?: 'transactions_max_fields';
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Transactions_Min_Fields = {
  __typename?: 'transactions_min_fields';
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "transactions" */
export type Transactions_Mutation_Response = {
  __typename?: 'transactions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Transactions>;
};

/** input type for inserting object relation for remote table "transactions" */
export type Transactions_Obj_Rel_Insert_Input = {
  data: Transactions_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Transactions_On_Conflict>;
};

/** on_conflict condition type for table "transactions" */
export type Transactions_On_Conflict = {
  constraint: Transactions_Constraint;
  update_columns: Array<Transactions_Update_Column>;
  where?: Maybe<Transactions_Bool_Exp>;
};

/** Ordering options when selecting data from "transactions". */
export type Transactions_Order_By = {
  bookings_transactions_aggregate?: Maybe<Bookings_Transactions_Aggregate_Order_By>;
  comment?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  extraRecipeLinesTransactions_aggregate?: Maybe<ExtraRecipeLinesTransactions_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  payload?: Maybe<Order_By>;
  productTransactions_aggregate?: Maybe<ProductTransactions_Aggregate_Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: transactions */
export type Transactions_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Transactions_Prepend_Input = {
  payload?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "transactions" */
export enum Transactions_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Payload = 'payload',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "transactions" */
export type Transactions_Set_Input = {
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  payload?: Maybe<Scalars['jsonb']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "transactions" */
export type Transactions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Transactions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Transactions_Stream_Cursor_Value_Input = {
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  payload?: Maybe<Scalars['jsonb']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "transactions" */
export enum Transactions_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Payload = 'payload',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Transactions_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Transactions_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Transactions_Delete_At_Path_Input>;
  /**
   * delete the array element with specified index (negative integers count from
   * the end). throws an error if top level container is not an array
   */
  _delete_elem?: Maybe<Transactions_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Transactions_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Transactions_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Transactions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Transactions_Bool_Exp;
};

export type TriggerConfirmationEmailResult = {
  __typename?: 'TriggerConfirmationEmailResult';
  message?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type TriggerVerificationEmailResult = {
  __typename?: 'TriggerVerificationEmailResult';
  message?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};


/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: Maybe<Scalars['uuid']>;
  _gt?: Maybe<Scalars['uuid']>;
  _gte?: Maybe<Scalars['uuid']>;
  _in?: Maybe<Array<Scalars['uuid']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['uuid']>;
  _lte?: Maybe<Scalars['uuid']>;
  _neq?: Maybe<Scalars['uuid']>;
  _nin?: Maybe<Array<Scalars['uuid']>>;
};

export type VerifyOrderResult = {
  __typename?: 'VerifyOrderResult';
  message?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type CancelBookingMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type CancelBookingMutation = (
  { __typename?: 'mutation_root' }
  & { update_bookings?: Maybe<(
    { __typename?: 'bookings_mutation_response' }
    & { returning: Array<(
      { __typename?: 'bookings' }
      & Pick<Bookings, 'id' | 'status'>
    )> }
  )> }
);

export type DeleteClosingDateMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteClosingDateMutation = (
  { __typename?: 'mutation_root' }
  & { delete_closingDates_by_pk?: Maybe<(
    { __typename?: 'closingDates' }
    & Pick<ClosingDates, 'closedDate' | 'locationId'>
  )> }
);

export type DeleteExtraRecipeLineMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteExtraRecipeLineMutation = (
  { __typename?: 'mutation_root' }
  & { delete_extraRecipeLines?: Maybe<(
    { __typename?: 'extraRecipeLines_mutation_response' }
    & Pick<ExtraRecipeLines_Mutation_Response, 'affected_rows'>
  )> }
);

export type DeleteLocationMessageMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteLocationMessageMutation = (
  { __typename?: 'mutation_root' }
  & { delete_locationMessages_by_pk?: Maybe<(
    { __typename?: 'locationMessages' }
    & Pick<LocationMessages, 'id'>
  )> }
);

export type DeleteProductPriceMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteProductPriceMutation = (
  { __typename?: 'mutation_root' }
  & { delete_productPrices_by_pk?: Maybe<(
    { __typename?: 'productPrices' }
    & Pick<ProductPrices, 'id'>
  )> }
);

export type InsertBookingsTransactionMutationVariables = Exact<{
  bookings: Array<Bookings_Transactions_Insert_Input> | Bookings_Transactions_Insert_Input;
}>;


export type InsertBookingsTransactionMutation = (
  { __typename?: 'mutation_root' }
  & { insert_bookings_transactions?: Maybe<(
    { __typename?: 'bookings_transactions_mutation_response' }
    & { returning: Array<(
      { __typename?: 'bookings_transactions' }
      & Pick<Bookings_Transactions, 'id'>
    )> }
  )> }
);

export type InsertClosingDateMutationVariables = Exact<{
  date: Scalars['date'];
  locationId: Scalars['uuid'];
  description?: Maybe<Scalars['String']>;
}>;


export type InsertClosingDateMutation = (
  { __typename?: 'mutation_root' }
  & { insert_closingDates_one?: Maybe<(
    { __typename?: 'closingDates' }
    & Pick<ClosingDates, 'closedDate' | 'locationId' | 'description'>
  )> }
);

export type InsertExtraRecipeLinesTransactionMutationVariables = Exact<{
  lines: Array<ExtraRecipeLinesTransactions_Insert_Input> | ExtraRecipeLinesTransactions_Insert_Input;
}>;


export type InsertExtraRecipeLinesTransactionMutation = (
  { __typename?: 'mutation_root' }
  & { insert_extraRecipeLinesTransactions?: Maybe<(
    { __typename?: 'extraRecipeLinesTransactions_mutation_response' }
    & { returning: Array<(
      { __typename?: 'extraRecipeLinesTransactions' }
      & Pick<ExtraRecipeLinesTransactions, 'id'>
    )> }
  )> }
);

export type InsertLocationMessageMutationVariables = Exact<{
  locationId?: Maybe<Scalars['uuid']>;
  startDate: Scalars['date'];
  endDate: Scalars['date'];
  title?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
}>;


export type InsertLocationMessageMutation = (
  { __typename?: 'mutation_root' }
  & { insert_locationMessages_one?: Maybe<(
    { __typename?: 'locationMessages' }
    & Pick<LocationMessages, 'id' | 'startDate' | 'endDate' | 'title' | 'message'>
  )> }
);

export type InsertProductTransactionsMutationVariables = Exact<{
  productTransactions: Array<ProductTransactions_Insert_Input> | ProductTransactions_Insert_Input;
}>;


export type InsertProductTransactionsMutation = (
  { __typename?: 'mutation_root' }
  & { insert_productTransactions?: Maybe<(
    { __typename?: 'productTransactions_mutation_response' }
    & { returning: Array<(
      { __typename?: 'productTransactions' }
      & Pick<ProductTransactions, 'id'>
    )> }
  )> }
);

export type InsertTransactionMutationVariables = Exact<{
  type: Scalars['String'];
  payload?: Maybe<Scalars['jsonb']>;
}>;


export type InsertTransactionMutation = (
  { __typename?: 'mutation_root' }
  & { insert_transactions?: Maybe<(
    { __typename?: 'transactions_mutation_response' }
    & { returning: Array<(
      { __typename?: 'transactions' }
      & Pick<Transactions, 'id'>
    )> }
  )> }
);

export type PayExtraRecipeLineMutationVariables = Exact<{
  ids?: Maybe<Array<Scalars['uuid']> | Scalars['uuid']>;
}>;


export type PayExtraRecipeLineMutation = (
  { __typename?: 'mutation_root' }
  & { update_extraRecipeLines?: Maybe<(
    { __typename?: 'extraRecipeLines_mutation_response' }
    & { returning: Array<(
      { __typename?: 'extraRecipeLines' }
      & Pick<ExtraRecipeLines, 'id' | 'status'>
    )> }
  )> }
);

export type RequestProlongOrderMutationVariables = Exact<{
  orderId: Scalars['String'];
  newEndDate: Scalars['String'];
}>;


export type RequestProlongOrderMutation = (
  { __typename?: 'mutation_root' }
  & { requestProlongOrder?: Maybe<(
    { __typename?: 'RequestProlongOrderResult' }
    & Pick<RequestProlongOrderResult, 'message' | 'success'>
  )> }
);

export type ReturnBookingsMutationVariables = Exact<{
  ids?: Maybe<Array<Scalars['uuid']> | Scalars['uuid']>;
}>;


export type ReturnBookingsMutation = (
  { __typename?: 'mutation_root' }
  & { update_bookings?: Maybe<(
    { __typename?: 'bookings_mutation_response' }
    & { returning: Array<(
      { __typename?: 'bookings' }
      & Pick<Bookings, 'id' | 'status'>
    )> }
  )> }
);

export type SetIncludeProtectionMutationVariables = Exact<{
  id: Scalars['uuid'];
  value: Scalars['Boolean'];
}>;


export type SetIncludeProtectionMutation = (
  { __typename?: 'mutation_root' }
  & { update_bookings?: Maybe<(
    { __typename?: 'bookings_mutation_response' }
    & { returning: Array<(
      { __typename?: 'bookings' }
      & Pick<Bookings, 'id' | 'status' | 'includeProtection'>
    )> }
  )> }
);

export type SetIncludeInsuranceMutationVariables = Exact<{
  id: Scalars['uuid'];
  value: Scalars['Boolean'];
}>;


export type SetIncludeInsuranceMutation = (
  { __typename?: 'mutation_root' }
  & { update_bookings?: Maybe<(
    { __typename?: 'bookings_mutation_response' }
    & { returning: Array<(
      { __typename?: 'bookings' }
      & Pick<Bookings, 'id' | 'status' | 'includeInsurance'>
    )> }
  )> }
);

export type ShipBookingsMutationVariables = Exact<{
  ids?: Maybe<Array<Scalars['uuid']> | Scalars['uuid']>;
}>;


export type ShipBookingsMutation = (
  { __typename?: 'mutation_root' }
  & { update_bookings?: Maybe<(
    { __typename?: 'bookings_mutation_response' }
    & { returning: Array<(
      { __typename?: 'bookings' }
      & Pick<Bookings, 'id' | 'status'>
    )> }
  )> }
);

export type TriggerCancellationEmailMutationMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type TriggerCancellationEmailMutationMutation = (
  { __typename?: 'mutation_root' }
  & { triggerCancellationEmail?: Maybe<(
    { __typename?: 'TriggerVerificationEmailResult' }
    & Pick<TriggerVerificationEmailResult, 'message' | 'success'>
  )> }
);

export type TriggerConfirmationEmailMutationMutationVariables = Exact<{
  id: Scalars['String'];
  isChange?: Maybe<Scalars['Boolean']>;
  includeExtraData?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
}>;


export type TriggerConfirmationEmailMutationMutation = (
  { __typename?: 'mutation_root' }
  & { triggerConfirmationEmail?: Maybe<(
    { __typename?: 'TriggerConfirmationEmailResult' }
    & Pick<TriggerConfirmationEmailResult, 'message' | 'success'>
  )> }
);

export type TriggerVerificationEmailMutationMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type TriggerVerificationEmailMutationMutation = (
  { __typename?: 'mutation_root' }
  & { triggerVerificationEmail?: Maybe<(
    { __typename?: 'TriggerVerificationEmailResult' }
    & Pick<TriggerVerificationEmailResult, 'message' | 'success'>
  )> }
);

export type UpdateCustomerMutationVariables = Exact<{
  id: Scalars['uuid'];
  data: Customers_Set_Input;
}>;


export type UpdateCustomerMutation = (
  { __typename?: 'mutation_root' }
  & { update_customers_by_pk?: Maybe<(
    { __typename?: 'customers' }
    & Pick<Customers, 'id'>
  )> }
);

export type UpdateOrderUpdateDateMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type UpdateOrderUpdateDateMutation = (
  { __typename?: 'mutation_root' }
  & { update_orders_by_pk?: Maybe<(
    { __typename?: 'orders' }
    & Pick<Orders, 'id' | 'updated_at'>
  )> }
);

export type UpsertExtraRecipeLineMutationVariables = Exact<{
  line: Array<ExtraRecipeLines_Insert_Input> | ExtraRecipeLines_Insert_Input;
}>;


export type UpsertExtraRecipeLineMutation = (
  { __typename?: 'mutation_root' }
  & { insert_extraRecipeLines?: Maybe<(
    { __typename?: 'extraRecipeLines_mutation_response' }
    & { returning: Array<(
      { __typename?: 'extraRecipeLines' }
      & Pick<ExtraRecipeLines, 'status' | 'orderId' | 'id' | 'description' | 'amount' | 'updated_at' | 'created_at'>
    )> }
  )> }
);

export type UpsertProductMutationVariables = Exact<{
  data: Products_Insert_Input;
}>;


export type UpsertProductMutation = (
  { __typename?: 'mutation_root' }
  & { insert_products_one?: Maybe<(
    { __typename?: 'products' }
    & Pick<Products, 'id'>
  )> }
);

export type UpsertProductPricesMutationVariables = Exact<{
  prices: Array<ProductPrices_Insert_Input> | ProductPrices_Insert_Input;
}>;


export type UpsertProductPricesMutation = (
  { __typename?: 'mutation_root' }
  & { insert_productPrices?: Maybe<(
    { __typename?: 'productPrices_mutation_response' }
    & { returning: Array<(
      { __typename?: 'productPrices' }
      & Pick<ProductPrices, 'id'>
    )> }
  )> }
);

export type UpsertProductTypeMutationVariables = Exact<{
  data: ProductTypes_Insert_Input;
}>;


export type UpsertProductTypeMutation = (
  { __typename?: 'mutation_root' }
  & { insert_productTypes_one?: Maybe<(
    { __typename?: 'productTypes' }
    & Pick<ProductTypes, 'id'>
  )> }
);

export type UpsertSeasonMutationVariables = Exact<{
  data: Seasons_Insert_Input;
}>;


export type UpsertSeasonMutation = (
  { __typename?: 'mutation_root' }
  & { insert_seasons_one?: Maybe<(
    { __typename?: 'seasons' }
    & Pick<Seasons, 'id'>
  )> }
);

export type VerifyOrderMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type VerifyOrderMutation = (
  { __typename?: 'mutation_root' }
  & { verifyOrder?: Maybe<(
    { __typename?: 'VerifyOrderResult' }
    & Pick<VerifyOrderResult, 'message' | 'success'>
  )> }
);

export type GetAvailabilitiesForDateRangeQueryVariables = Exact<{
  productIds?: Maybe<Array<Scalars['uuid']> | Scalars['uuid']>;
  startDate: Scalars['date'];
  endDate: Scalars['date'];
}>;


export type GetAvailabilitiesForDateRangeQuery = (
  { __typename?: 'query_root' }
  & { snapshotAvailabilities: Array<(
    { __typename?: 'snapshotAvailabilities' }
    & Pick<SnapshotAvailabilities, 'productId' | 'date' | 'availableBikes'>
  )> }
);

export type GetOrderQueryVariables = Exact<{
  orderId: Scalars['uuid'];
}>;


export type GetOrderQuery = (
  { __typename?: 'query_root' }
  & { orders_by_pk?: Maybe<(
    { __typename?: 'orders' }
    & Pick<Orders, 'id' | 'duration' | 'startDate' | 'created_at'>
    & { customer: (
      { __typename?: 'customers' }
      & Pick<Customers, 'id' | 'name' | 'firstname' | 'birthdate' | 'street' | 'streetnumber' | 'zipCode' | 'city' | 'countryCode' | 'email' | 'phone'>
    ), extraRecipeLines: Array<(
      { __typename?: 'extraRecipeLines' }
      & Pick<ExtraRecipeLines, 'id' | 'created_at' | 'updated_at' | 'status' | 'description' | 'amount'>
    )>, bookings: Array<(
      { __typename?: 'bookings' }
      & Pick<Bookings, 'id' | 'created_at' | 'riderName' | 'riderWeight' | 'verifiedPriceSnapshot' | 'orderId' | 'includeProtection' | 'includeInsurance' | 'cancelled' | 'status'>
      & { product: (
        { __typename?: 'products' }
        & Pick<Products, 'id' | 'size'>
        & { productType: (
          { __typename?: 'productTypes' }
          & Pick<ProductTypes, 'id' | 'name' | 'category'>
        ) }
      ) }
    )> }
  )> }
);

export type GetOrdersOverviewQueryVariables = Exact<{
  startDate: Scalars['date'];
}>;


export type GetOrdersOverviewQuery = (
  { __typename?: 'query_root' }
  & { orders: Array<(
    { __typename?: 'orders' }
    & Pick<Orders, 'id' | 'duration' | 'startDate' | 'created_at'>
    & { customer: (
      { __typename?: 'customers' }
      & Pick<Customers, 'id' | 'name' | 'firstname' | 'zipCode' | 'city' | 'countryCode' | 'email' | 'phone' | 'street' | 'streetnumber'>
    ), bookings: Array<(
      { __typename?: 'bookings' }
      & Pick<Bookings, 'id' | 'created_at' | 'riderName' | 'riderWeight' | 'verifiedPriceSnapshot' | 'orderId' | 'includeProtection' | 'includeInsurance' | 'cancelled' | 'status'>
      & { product: (
        { __typename?: 'products' }
        & Pick<Products, 'id' | 'size'>
        & { productType: (
          { __typename?: 'productTypes' }
          & Pick<ProductTypes, 'id' | 'name' | 'category'>
        ) }
      ) }
    )> }
  )> }
);

export type GetSeasonsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSeasonsQuery = (
  { __typename?: 'query_root' }
  & { seasons: Array<(
    { __typename?: 'seasons' }
    & Pick<Seasons, 'id' | 'name' | 'locationId' | 'startDate' | 'endDate' | 'created_at' | 'updated_at'>
  )> }
);

export type GetBookingLabelQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetBookingLabelQuery = (
  { __typename?: 'query_root' }
  & { bookings_by_pk?: Maybe<(
    { __typename?: 'bookings' }
    & Pick<Bookings, 'id' | 'riderWeight' | 'riderName'>
    & { product: (
      { __typename?: 'products' }
      & Pick<Products, 'size'>
      & { productType: (
        { __typename?: 'productTypes' }
        & Pick<ProductTypes, 'name'>
      ) }
    ), order: (
      { __typename?: 'orders' }
      & { customer: (
        { __typename?: 'customers' }
        & Pick<Customers, 'firstname' | 'name'>
      ) }
    ) }
  )> }
);

export type GetLocationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLocationsQuery = (
  { __typename?: 'query_root' }
  & { locations: Array<(
    { __typename?: 'locations' }
    & Pick<Locations, 'id' | 'name'>
  )> }
);

export type GetProductTypeQueryVariables = Exact<{
  productTypeId: Scalars['uuid'];
}>;


export type GetProductTypeQuery = (
  { __typename?: 'query_root' }
  & { productTypes_by_pk?: Maybe<(
    { __typename?: 'productTypes' }
    & Pick<ProductTypes, 'id' | 'name' | 'description' | 'category' | 'image' | 'sortPosition' | 'forceInsurance'>
    & { productTypeModel: (
      { __typename?: 'productTypeModels' }
      & Pick<ProductTypeModels, 'id' | 'name'>
    ), products: Array<(
      { __typename?: 'products' }
      & Pick<Products, 'id' | 'size' | 'bikesAvailable' | 'sortPosition'>
    )> }
  )> }
);

export type GetProductTypeAvailabilitiesQueryVariables = Exact<{
  date: Scalars['date'];
  productTypeId: Scalars['uuid'];
}>;


export type GetProductTypeAvailabilitiesQuery = (
  { __typename?: 'query_root' }
  & { productTypes_by_pk?: Maybe<(
    { __typename?: 'productTypes' }
    & Pick<ProductTypes, 'id' | 'name'>
    & { productTypeModel: (
      { __typename?: 'productTypeModels' }
      & Pick<ProductTypeModels, 'id' | 'name'>
    ), products: Array<(
      { __typename?: 'products' }
      & Pick<Products, 'id' | 'size'>
      & { snapshotAvailabilities: Array<(
        { __typename?: 'snapshotAvailabilities' }
        & Pick<SnapshotAvailabilities, 'id' | 'availableBikes'>
      )> }
    )> }
  )> }
);

export type GetProductTypeAvailabilitiesOverviewQueryVariables = Exact<{
  date: Scalars['date'];
}>;


export type GetProductTypeAvailabilitiesOverviewQuery = (
  { __typename?: 'query_root' }
  & { productTypes: Array<(
    { __typename?: 'productTypes' }
    & Pick<ProductTypes, 'id' | 'name' | 'category'>
    & { products: Array<(
      { __typename?: 'products' }
      & Pick<Products, 'id' | 'size' | 'bikesAvailable'>
      & { snapshotAvailabilities: Array<(
        { __typename?: 'snapshotAvailabilities' }
        & Pick<SnapshotAvailabilities, 'id' | 'availableBikes'>
      )>, bookings_aggregate: (
        { __typename?: 'bookings_aggregate' }
        & { aggregate?: Maybe<(
          { __typename?: 'bookings_aggregate_fields' }
          & Pick<Bookings_Aggregate_Fields, 'count'>
        )> }
      ), bookings: Array<(
        { __typename?: 'bookings' }
        & Pick<Bookings, 'id' | 'status'>
        & { order: (
          { __typename?: 'orders' }
          & Pick<Orders, 'id' | 'startDate' | 'endDate' | 'duration'>
          & { customer: (
            { __typename?: 'customers' }
            & Pick<Customers, 'id' | 'name'>
          ) }
        ) }
      )> }
    )> }
  )> }
);

export type GetProductTypeModelsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetProductTypeModelsQuery = (
  { __typename?: 'query_root' }
  & { productTypeModels: Array<(
    { __typename?: 'productTypeModels' }
    & Pick<ProductTypeModels, 'id' | 'name'>
  )> }
);

export type GetTransactionQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetTransactionQuery = (
  { __typename?: 'query_root' }
  & { transactions_by_pk?: Maybe<(
    { __typename?: 'transactions' }
    & Pick<Transactions, 'id'>
    & { extraRecipeLinesTransactions: Array<(
      { __typename?: 'extraRecipeLinesTransactions' }
      & { transaction: (
        { __typename?: 'transactions' }
        & Pick<Transactions, 'comment' | 'type'>
      ), extraRecipeLine: (
        { __typename?: 'extraRecipeLines' }
        & Pick<ExtraRecipeLines, 'id' | 'description' | 'amount' | 'status'>
      ) }
    )>, bookings_transactions: Array<(
      { __typename?: 'bookings_transactions' }
      & Pick<Bookings_Transactions, 'id'>
      & { booking: (
        { __typename?: 'bookings' }
        & Pick<Bookings, 'id' | 'created_at' | 'riderName' | 'riderWeight' | 'verifiedPriceSnapshot' | 'includeProtection' | 'includeInsurance' | 'cancelled' | 'status'>
        & { order: (
          { __typename?: 'orders' }
          & Pick<Orders, 'id' | 'duration' | 'startDate'>
          & { customer: (
            { __typename?: 'customers' }
            & Pick<Customers, 'id' | 'name' | 'firstname' | 'street' | 'streetnumber' | 'zipCode' | 'city' | 'countryCode' | 'email' | 'phone'>
          ) }
        ), product: (
          { __typename?: 'products' }
          & Pick<Products, 'id' | 'size'>
          & { productType: (
            { __typename?: 'productTypes' }
            & Pick<ProductTypes, 'id' | 'name' | 'category'>
          ) }
        ) }
      ) }
    )> }
  )> }
);

export type ObserveOrderSubscriptionVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type ObserveOrderSubscription = (
  { __typename?: 'subscription_root' }
  & { orders_by_pk?: Maybe<(
    { __typename?: 'orders' }
    & Pick<Orders, 'id' | 'verified' | 'duration' | 'startDate' | 'created_at' | 'updated_at' | 'confirmation_sent_at' | 'verification_sent_at' | 'cancellation_sent_at'>
    & { customer: (
      { __typename?: 'customers' }
      & Pick<Customers, 'id' | 'name' | 'firstname' | 'birthdate' | 'street' | 'streetnumber' | 'zipCode' | 'city' | 'countryCode' | 'email' | 'phone' | 'notes'>
    ), extraRecipeLines: Array<(
      { __typename?: 'extraRecipeLines' }
      & Pick<ExtraRecipeLines, 'id' | 'created_at' | 'updated_at' | 'status' | 'description' | 'amount'>
    )>, bookings: Array<(
      { __typename?: 'bookings' }
      & Pick<Bookings, 'id' | 'created_at' | 'updated_at' | 'riderName' | 'riderWeight' | 'verifiedPriceSnapshot' | 'orderId' | 'includeProtection' | 'includeInsurance' | 'cancelled' | 'status'>
      & { order: (
        { __typename?: 'orders' }
        & Pick<Orders, 'id' | 'duration'>
      ), product: (
        { __typename?: 'products' }
        & Pick<Products, 'id' | 'size'>
        & { location: (
          { __typename?: 'locations' }
          & Pick<Locations, 'id' | 'name'>
        ), productType: (
          { __typename?: 'productTypes' }
          & Pick<ProductTypes, 'id' | 'name' | 'category' | 'forceInsurance'>
        ) }
      ) }
    )> }
  )> }
);

export type ObserveOrdersSubscriptionVariables = Exact<{
  locationId: Scalars['uuid'];
  startDatesFilter?: Maybe<Array<Orders_Bool_Exp> | Orders_Bool_Exp>;
  searchTerm?: Maybe<Scalars['String']>;
}>;


export type ObserveOrdersSubscription = (
  { __typename?: 'subscription_root' }
  & { orders: Array<(
    { __typename?: 'orders' }
    & Pick<Orders, 'id' | 'verified' | 'duration' | 'startDate' | 'created_at' | 'updated_at'>
    & { customer: (
      { __typename?: 'customers' }
      & Pick<Customers, 'id' | 'name' | 'firstname' | 'birthdate' | 'street' | 'streetnumber' | 'zipCode' | 'city' | 'countryCode' | 'email' | 'phone'>
    ), extraRecipeLines: Array<(
      { __typename?: 'extraRecipeLines' }
      & Pick<ExtraRecipeLines, 'id' | 'created_at' | 'updated_at' | 'status' | 'description' | 'amount'>
    )>, bookings: Array<(
      { __typename?: 'bookings' }
      & Pick<Bookings, 'id' | 'created_at' | 'updated_at' | 'riderName' | 'riderWeight' | 'verifiedPriceSnapshot' | 'orderId' | 'includeProtection' | 'includeInsurance' | 'cancelled' | 'status'>
      & { order: (
        { __typename?: 'orders' }
        & Pick<Orders, 'id' | 'duration'>
      ), product: (
        { __typename?: 'products' }
        & Pick<Products, 'id' | 'size'>
        & { productType: (
          { __typename?: 'productTypes' }
          & Pick<ProductTypes, 'id' | 'name' | 'category'>
        ) }
      ) }
    )> }
  )> }
);

export type GetAvailabilitiesOverviewSubscriptionVariables = Exact<{
  date: Scalars['date'];
}>;


export type GetAvailabilitiesOverviewSubscription = (
  { __typename?: 'subscription_root' }
  & { snapshotAvailabilities: Array<(
    { __typename?: 'snapshotAvailabilities' }
    & Pick<SnapshotAvailabilities, 'id' | 'date' | 'availableBikes'>
    & { product: (
      { __typename?: 'products' }
      & Pick<Products, 'id' | 'size' | 'bikesAvailable'>
      & { productType: (
        { __typename?: 'productTypes' }
        & Pick<ProductTypes, 'id' | 'productTypeModelId' | 'name' | 'category'>
      ), bookings_aggregate: (
        { __typename?: 'bookings_aggregate' }
        & { aggregate?: Maybe<(
          { __typename?: 'bookings_aggregate_fields' }
          & Pick<Bookings_Aggregate_Fields, 'count'>
        )> }
      ), bookings: Array<(
        { __typename?: 'bookings' }
        & Pick<Bookings, 'id' | 'status'>
        & { order: (
          { __typename?: 'orders' }
          & Pick<Orders, 'id' | 'endDate' | 'duration'>
          & { customer: (
            { __typename?: 'customers' }
            & Pick<Customers, 'id' | 'name'>
          ) }
        ) }
      )> }
    ) }
  )> }
);

export type GetBookingsTransactionsByOrderIdSubscriptionVariables = Exact<{
  orderId: Scalars['uuid'];
}>;


export type GetBookingsTransactionsByOrderIdSubscription = (
  { __typename?: 'subscription_root' }
  & { transactions: Array<(
    { __typename?: 'transactions' }
    & Pick<Transactions, 'id' | 'type' | 'comment' | 'created_at' | 'payload' | 'updated_at'>
    & { extraRecipeLinesTransactions: Array<(
      { __typename?: 'extraRecipeLinesTransactions' }
      & Pick<ExtraRecipeLinesTransactions, 'id'>
      & { extraRecipeLine: (
        { __typename?: 'extraRecipeLines' }
        & Pick<ExtraRecipeLines, 'id' | 'amount' | 'description' | 'status'>
      ) }
    )>, bookings_transactions: Array<(
      { __typename?: 'bookings_transactions' }
      & Pick<Bookings_Transactions, 'id'>
      & { booking: (
        { __typename?: 'bookings' }
        & Pick<Bookings, 'id'>
        & { product: (
          { __typename?: 'products' }
          & Pick<Products, 'id' | 'size'>
          & { productType: (
            { __typename?: 'productTypes' }
            & Pick<ProductTypes, 'id' | 'name'>
          ) }
        ) }
      ) }
    )> }
  )> }
);

export type ObserveClosingDatesByDateRangeSubscriptionVariables = Exact<{
  locationId: Scalars['uuid'];
  startDate: Scalars['date'];
  endDate: Scalars['date'];
}>;


export type ObserveClosingDatesByDateRangeSubscription = (
  { __typename?: 'subscription_root' }
  & { closingDates: Array<(
    { __typename?: 'closingDates' }
    & Pick<ClosingDates, 'id' | 'closedDate' | 'description' | 'locationId'>
  )> }
);

export type ObserveLocationsSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type ObserveLocationsSubscription = (
  { __typename?: 'subscription_root' }
  & { locations: Array<(
    { __typename?: 'locations' }
    & Pick<Locations, 'id' | 'created_at' | 'updated_at' | 'name' | 'description' | 'stealthMode'>
    & { messages: Array<(
      { __typename?: 'locationMessages' }
      & Pick<LocationMessages, 'id' | 'created_at' | 'updated_at' | 'title' | 'message'>
    )> }
  )> }
);

export type ObserveProductSubscriptionVariables = Exact<{
  productId: Scalars['uuid'];
  date: Scalars['date'];
}>;


export type ObserveProductSubscription = (
  { __typename?: 'subscription_root' }
  & { products_by_pk?: Maybe<(
    { __typename?: 'products' }
    & Pick<Products, 'id' | 'size' | 'bikesAvailable'>
    & { productType: (
      { __typename?: 'productTypes' }
      & Pick<ProductTypes, 'id' | 'name' | 'description' | 'category'>
      & { productTypeModel: (
        { __typename?: 'productTypeModels' }
        & Pick<ProductTypeModels, 'id' | 'name'>
      ) }
    ), snapshotAvailabilities: Array<(
      { __typename?: 'snapshotAvailabilities' }
      & Pick<SnapshotAvailabilities, 'id' | 'availableBikes'>
    )>, snapshotAvailabilities_aggregate: (
      { __typename?: 'snapshotAvailabilities_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'snapshotAvailabilities_aggregate_fields' }
        & { sum?: Maybe<(
          { __typename?: 'snapshotAvailabilities_sum_fields' }
          & Pick<SnapshotAvailabilities_Sum_Fields, 'availableBikes'>
        )> }
      )> }
    ), bookings_aggregate: (
      { __typename?: 'bookings_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'bookings_aggregate_fields' }
        & Pick<Bookings_Aggregate_Fields, 'count'>
      )> }
    ), bookings: Array<(
      { __typename?: 'bookings' }
      & Pick<Bookings, 'id' | 'status'>
      & { order: (
        { __typename?: 'orders' }
        & Pick<Orders, 'id' | 'startDate' | 'endDate' | 'duration'>
        & { customer: (
          { __typename?: 'customers' }
          & Pick<Customers, 'id' | 'name'>
        ) }
      ), bookings_transactions: Array<(
        { __typename?: 'bookings_transactions' }
        & Pick<Bookings_Transactions, 'id' | 'updated_at'>
      )> }
    )> }
  )> }
);

export type ObserveProductTransactionResultSubscriptionVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type ObserveProductTransactionResultSubscription = (
  { __typename?: 'subscription_root' }
  & { productTransactions_by_pk?: Maybe<(
    { __typename?: 'productTransactions' }
    & Pick<ProductTransactions, 'result'>
  )> }
);

export type ObserveProductTypeAvailabilitiesOverviewSubscriptionVariables = Exact<{
  date: Scalars['date'];
  locationId: Scalars['uuid'];
}>;


export type ObserveProductTypeAvailabilitiesOverviewSubscription = (
  { __typename?: 'subscription_root' }
  & { productTypes: Array<(
    { __typename?: 'productTypes' }
    & Pick<ProductTypes, 'id' | 'name' | 'category'>
    & { products: Array<(
      { __typename?: 'products' }
      & Pick<Products, 'id' | 'size' | 'bikesAvailable'>
      & { snapshotAvailabilities: Array<(
        { __typename?: 'snapshotAvailabilities' }
        & Pick<SnapshotAvailabilities, 'id' | 'availableBikes'>
      )>, bookings_aggregate: (
        { __typename?: 'bookings_aggregate' }
        & { aggregate?: Maybe<(
          { __typename?: 'bookings_aggregate_fields' }
          & Pick<Bookings_Aggregate_Fields, 'count'>
        )> }
      ), bookings: Array<(
        { __typename?: 'bookings' }
        & Pick<Bookings, 'id' | 'status' | 'riderName' | 'riderWeight' | 'includeInsurance' | 'includeProtection'>
        & { order: (
          { __typename?: 'orders' }
          & Pick<Orders, 'id' | 'startDate' | 'endDate' | 'duration'>
          & { customer: (
            { __typename?: 'customers' }
            & Pick<Customers, 'id' | 'name'>
          ) }
        ), bookings_transactions: Array<(
          { __typename?: 'bookings_transactions' }
          & Pick<Bookings_Transactions, 'id' | 'updated_at'>
        )> }
      )> }
    )> }
  )> }
);

export type ObserveProductTypesSubscriptionVariables = Exact<{
  locationId: Scalars['uuid'];
}>;


export type ObserveProductTypesSubscription = (
  { __typename?: 'subscription_root' }
  & { productTypes: Array<(
    { __typename?: 'productTypes' }
    & Pick<ProductTypes, 'id' | 'name' | 'description' | 'category' | 'image' | 'sortPosition' | 'forceInsurance' | 'productTypeModelId'>
    & { productTypeModel: (
      { __typename?: 'productTypeModels' }
      & Pick<ProductTypeModels, 'id' | 'name'>
    ), products: Array<(
      { __typename?: 'products' }
      & Pick<Products, 'id' | 'size' | 'bikesAvailable' | 'description' | 'sortPosition'>
    )>, products_aggregate: (
      { __typename?: 'products_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'products_aggregate_fields' }
        & Pick<Products_Aggregate_Fields, 'count'>
      )> }
    ) }
  )> }
);

export type ObserveProductsByProductTypeSubscriptionVariables = Exact<{
  productTypeId: Scalars['uuid'];
  locationId: Scalars['uuid'];
}>;


export type ObserveProductsByProductTypeSubscription = (
  { __typename?: 'subscription_root' }
  & { products: Array<(
    { __typename?: 'products' }
    & Pick<Products, 'id' | 'locationId' | 'size' | 'description' | 'sortPosition' | 'bikesAvailable' | 'productTypeId'>
    & { productPrices: Array<(
      { __typename?: 'productPrices' }
      & Pick<ProductPrices, 'id' | 'productId' | 'basePricePerDay' | 'insuranceCostsPerDay' | 'protectionCostsPerDay' | 'minDuration'>
    )>, productType: (
      { __typename?: 'productTypes' }
      & Pick<ProductTypes, 'id' | 'name' | 'description' | 'sortPosition' | 'category' | 'productTypeModelId'>
      & { productTypeModel: (
        { __typename?: 'productTypeModels' }
        & Pick<ProductTypeModels, 'id' | 'name'>
      ) }
    ) }
  )> }
);

export type ObserveSeasonsSubscriptionVariables = Exact<{
  locationId: Scalars['uuid'];
}>;


export type ObserveSeasonsSubscription = (
  { __typename?: 'subscription_root' }
  & { seasons: Array<(
    { __typename?: 'seasons' }
    & Pick<Seasons, 'id' | 'created_at' | 'updated_at' | 'name' | 'startDate' | 'endDate' | 'locationId'>
    & { location: (
      { __typename?: 'locations' }
      & Pick<Locations, 'id' | 'name'>
    ) }
  )> }
);


export const CancelBookingDocument = gql`
    mutation CancelBooking($id: uuid!) {
  update_bookings(where: {id: {_eq: $id}}, _set: {status: "cancelled"}) {
    returning {
      id
      status
    }
  }
}
    `;
export type CancelBookingMutationFn = Apollo.MutationFunction<CancelBookingMutation, CancelBookingMutationVariables>;

/**
 * __useCancelBookingMutation__
 *
 * To run a mutation, you first call `useCancelBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelBookingMutation, { data, loading, error }] = useCancelBookingMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCancelBookingMutation(baseOptions?: Apollo.MutationHookOptions<CancelBookingMutation, CancelBookingMutationVariables>) {
        return Apollo.useMutation<CancelBookingMutation, CancelBookingMutationVariables>(CancelBookingDocument, baseOptions);
      }
export type CancelBookingMutationHookResult = ReturnType<typeof useCancelBookingMutation>;
export type CancelBookingMutationResult = Apollo.MutationResult<CancelBookingMutation>;
export type CancelBookingMutationOptions = Apollo.BaseMutationOptions<CancelBookingMutation, CancelBookingMutationVariables>;
export const DeleteClosingDateDocument = gql`
    mutation DeleteClosingDate($id: uuid!) {
  delete_closingDates_by_pk(id: $id) {
    closedDate
    locationId
  }
}
    `;
export type DeleteClosingDateMutationFn = Apollo.MutationFunction<DeleteClosingDateMutation, DeleteClosingDateMutationVariables>;

/**
 * __useDeleteClosingDateMutation__
 *
 * To run a mutation, you first call `useDeleteClosingDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClosingDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClosingDateMutation, { data, loading, error }] = useDeleteClosingDateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteClosingDateMutation(baseOptions?: Apollo.MutationHookOptions<DeleteClosingDateMutation, DeleteClosingDateMutationVariables>) {
        return Apollo.useMutation<DeleteClosingDateMutation, DeleteClosingDateMutationVariables>(DeleteClosingDateDocument, baseOptions);
      }
export type DeleteClosingDateMutationHookResult = ReturnType<typeof useDeleteClosingDateMutation>;
export type DeleteClosingDateMutationResult = Apollo.MutationResult<DeleteClosingDateMutation>;
export type DeleteClosingDateMutationOptions = Apollo.BaseMutationOptions<DeleteClosingDateMutation, DeleteClosingDateMutationVariables>;
export const DeleteExtraRecipeLineDocument = gql`
    mutation DeleteExtraRecipeLine($id: uuid!) {
  delete_extraRecipeLines(where: {id: {_eq: $id}}) {
    affected_rows
  }
}
    `;
export type DeleteExtraRecipeLineMutationFn = Apollo.MutationFunction<DeleteExtraRecipeLineMutation, DeleteExtraRecipeLineMutationVariables>;

/**
 * __useDeleteExtraRecipeLineMutation__
 *
 * To run a mutation, you first call `useDeleteExtraRecipeLineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteExtraRecipeLineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteExtraRecipeLineMutation, { data, loading, error }] = useDeleteExtraRecipeLineMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteExtraRecipeLineMutation(baseOptions?: Apollo.MutationHookOptions<DeleteExtraRecipeLineMutation, DeleteExtraRecipeLineMutationVariables>) {
        return Apollo.useMutation<DeleteExtraRecipeLineMutation, DeleteExtraRecipeLineMutationVariables>(DeleteExtraRecipeLineDocument, baseOptions);
      }
export type DeleteExtraRecipeLineMutationHookResult = ReturnType<typeof useDeleteExtraRecipeLineMutation>;
export type DeleteExtraRecipeLineMutationResult = Apollo.MutationResult<DeleteExtraRecipeLineMutation>;
export type DeleteExtraRecipeLineMutationOptions = Apollo.BaseMutationOptions<DeleteExtraRecipeLineMutation, DeleteExtraRecipeLineMutationVariables>;
export const DeleteLocationMessageDocument = gql`
    mutation DeleteLocationMessage($id: uuid!) {
  delete_locationMessages_by_pk(id: $id) {
    id
  }
}
    `;
export type DeleteLocationMessageMutationFn = Apollo.MutationFunction<DeleteLocationMessageMutation, DeleteLocationMessageMutationVariables>;

/**
 * __useDeleteLocationMessageMutation__
 *
 * To run a mutation, you first call `useDeleteLocationMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLocationMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLocationMessageMutation, { data, loading, error }] = useDeleteLocationMessageMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteLocationMessageMutation(baseOptions?: Apollo.MutationHookOptions<DeleteLocationMessageMutation, DeleteLocationMessageMutationVariables>) {
        return Apollo.useMutation<DeleteLocationMessageMutation, DeleteLocationMessageMutationVariables>(DeleteLocationMessageDocument, baseOptions);
      }
export type DeleteLocationMessageMutationHookResult = ReturnType<typeof useDeleteLocationMessageMutation>;
export type DeleteLocationMessageMutationResult = Apollo.MutationResult<DeleteLocationMessageMutation>;
export type DeleteLocationMessageMutationOptions = Apollo.BaseMutationOptions<DeleteLocationMessageMutation, DeleteLocationMessageMutationVariables>;
export const DeleteProductPriceDocument = gql`
    mutation DeleteProductPrice($id: uuid!) {
  delete_productPrices_by_pk(id: $id) {
    id
  }
}
    `;
export type DeleteProductPriceMutationFn = Apollo.MutationFunction<DeleteProductPriceMutation, DeleteProductPriceMutationVariables>;

/**
 * __useDeleteProductPriceMutation__
 *
 * To run a mutation, you first call `useDeleteProductPriceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProductPriceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProductPriceMutation, { data, loading, error }] = useDeleteProductPriceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProductPriceMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProductPriceMutation, DeleteProductPriceMutationVariables>) {
        return Apollo.useMutation<DeleteProductPriceMutation, DeleteProductPriceMutationVariables>(DeleteProductPriceDocument, baseOptions);
      }
export type DeleteProductPriceMutationHookResult = ReturnType<typeof useDeleteProductPriceMutation>;
export type DeleteProductPriceMutationResult = Apollo.MutationResult<DeleteProductPriceMutation>;
export type DeleteProductPriceMutationOptions = Apollo.BaseMutationOptions<DeleteProductPriceMutation, DeleteProductPriceMutationVariables>;
export const InsertBookingsTransactionDocument = gql`
    mutation InsertBookingsTransaction($bookings: [bookings_transactions_insert_input!]!) {
  insert_bookings_transactions(objects: $bookings) {
    returning {
      id
    }
  }
}
    `;
export type InsertBookingsTransactionMutationFn = Apollo.MutationFunction<InsertBookingsTransactionMutation, InsertBookingsTransactionMutationVariables>;

/**
 * __useInsertBookingsTransactionMutation__
 *
 * To run a mutation, you first call `useInsertBookingsTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertBookingsTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertBookingsTransactionMutation, { data, loading, error }] = useInsertBookingsTransactionMutation({
 *   variables: {
 *      bookings: // value for 'bookings'
 *   },
 * });
 */
export function useInsertBookingsTransactionMutation(baseOptions?: Apollo.MutationHookOptions<InsertBookingsTransactionMutation, InsertBookingsTransactionMutationVariables>) {
        return Apollo.useMutation<InsertBookingsTransactionMutation, InsertBookingsTransactionMutationVariables>(InsertBookingsTransactionDocument, baseOptions);
      }
export type InsertBookingsTransactionMutationHookResult = ReturnType<typeof useInsertBookingsTransactionMutation>;
export type InsertBookingsTransactionMutationResult = Apollo.MutationResult<InsertBookingsTransactionMutation>;
export type InsertBookingsTransactionMutationOptions = Apollo.BaseMutationOptions<InsertBookingsTransactionMutation, InsertBookingsTransactionMutationVariables>;
export const InsertClosingDateDocument = gql`
    mutation InsertClosingDate($date: date!, $locationId: uuid!, $description: String) {
  insert_closingDates_one(object: {locationId: $locationId, description: $description, closedDate: $date}) {
    closedDate
    locationId
    description
  }
}
    `;
export type InsertClosingDateMutationFn = Apollo.MutationFunction<InsertClosingDateMutation, InsertClosingDateMutationVariables>;

/**
 * __useInsertClosingDateMutation__
 *
 * To run a mutation, you first call `useInsertClosingDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertClosingDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertClosingDateMutation, { data, loading, error }] = useInsertClosingDateMutation({
 *   variables: {
 *      date: // value for 'date'
 *      locationId: // value for 'locationId'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useInsertClosingDateMutation(baseOptions?: Apollo.MutationHookOptions<InsertClosingDateMutation, InsertClosingDateMutationVariables>) {
        return Apollo.useMutation<InsertClosingDateMutation, InsertClosingDateMutationVariables>(InsertClosingDateDocument, baseOptions);
      }
export type InsertClosingDateMutationHookResult = ReturnType<typeof useInsertClosingDateMutation>;
export type InsertClosingDateMutationResult = Apollo.MutationResult<InsertClosingDateMutation>;
export type InsertClosingDateMutationOptions = Apollo.BaseMutationOptions<InsertClosingDateMutation, InsertClosingDateMutationVariables>;
export const InsertExtraRecipeLinesTransactionDocument = gql`
    mutation InsertExtraRecipeLinesTransaction($lines: [extraRecipeLinesTransactions_insert_input!]!) {
  insert_extraRecipeLinesTransactions(objects: $lines) {
    returning {
      id
    }
  }
}
    `;
export type InsertExtraRecipeLinesTransactionMutationFn = Apollo.MutationFunction<InsertExtraRecipeLinesTransactionMutation, InsertExtraRecipeLinesTransactionMutationVariables>;

/**
 * __useInsertExtraRecipeLinesTransactionMutation__
 *
 * To run a mutation, you first call `useInsertExtraRecipeLinesTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertExtraRecipeLinesTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertExtraRecipeLinesTransactionMutation, { data, loading, error }] = useInsertExtraRecipeLinesTransactionMutation({
 *   variables: {
 *      lines: // value for 'lines'
 *   },
 * });
 */
export function useInsertExtraRecipeLinesTransactionMutation(baseOptions?: Apollo.MutationHookOptions<InsertExtraRecipeLinesTransactionMutation, InsertExtraRecipeLinesTransactionMutationVariables>) {
        return Apollo.useMutation<InsertExtraRecipeLinesTransactionMutation, InsertExtraRecipeLinesTransactionMutationVariables>(InsertExtraRecipeLinesTransactionDocument, baseOptions);
      }
export type InsertExtraRecipeLinesTransactionMutationHookResult = ReturnType<typeof useInsertExtraRecipeLinesTransactionMutation>;
export type InsertExtraRecipeLinesTransactionMutationResult = Apollo.MutationResult<InsertExtraRecipeLinesTransactionMutation>;
export type InsertExtraRecipeLinesTransactionMutationOptions = Apollo.BaseMutationOptions<InsertExtraRecipeLinesTransactionMutation, InsertExtraRecipeLinesTransactionMutationVariables>;
export const InsertLocationMessageDocument = gql`
    mutation InsertLocationMessage($locationId: uuid, $startDate: date!, $endDate: date!, $title: String, $message: String) {
  insert_locationMessages_one(object: {locationId: $locationId, startDate: $startDate, endDate: $endDate, title: $title, message: $message}) {
    id
    startDate
    endDate
    title
    message
  }
}
    `;
export type InsertLocationMessageMutationFn = Apollo.MutationFunction<InsertLocationMessageMutation, InsertLocationMessageMutationVariables>;

/**
 * __useInsertLocationMessageMutation__
 *
 * To run a mutation, you first call `useInsertLocationMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertLocationMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertLocationMessageMutation, { data, loading, error }] = useInsertLocationMessageMutation({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      title: // value for 'title'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useInsertLocationMessageMutation(baseOptions?: Apollo.MutationHookOptions<InsertLocationMessageMutation, InsertLocationMessageMutationVariables>) {
        return Apollo.useMutation<InsertLocationMessageMutation, InsertLocationMessageMutationVariables>(InsertLocationMessageDocument, baseOptions);
      }
export type InsertLocationMessageMutationHookResult = ReturnType<typeof useInsertLocationMessageMutation>;
export type InsertLocationMessageMutationResult = Apollo.MutationResult<InsertLocationMessageMutation>;
export type InsertLocationMessageMutationOptions = Apollo.BaseMutationOptions<InsertLocationMessageMutation, InsertLocationMessageMutationVariables>;
export const InsertProductTransactionsDocument = gql`
    mutation InsertProductTransactions($productTransactions: [productTransactions_insert_input!]!) {
  insert_productTransactions(objects: $productTransactions) {
    returning {
      id
    }
  }
}
    `;
export type InsertProductTransactionsMutationFn = Apollo.MutationFunction<InsertProductTransactionsMutation, InsertProductTransactionsMutationVariables>;

/**
 * __useInsertProductTransactionsMutation__
 *
 * To run a mutation, you first call `useInsertProductTransactionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertProductTransactionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertProductTransactionsMutation, { data, loading, error }] = useInsertProductTransactionsMutation({
 *   variables: {
 *      productTransactions: // value for 'productTransactions'
 *   },
 * });
 */
export function useInsertProductTransactionsMutation(baseOptions?: Apollo.MutationHookOptions<InsertProductTransactionsMutation, InsertProductTransactionsMutationVariables>) {
        return Apollo.useMutation<InsertProductTransactionsMutation, InsertProductTransactionsMutationVariables>(InsertProductTransactionsDocument, baseOptions);
      }
export type InsertProductTransactionsMutationHookResult = ReturnType<typeof useInsertProductTransactionsMutation>;
export type InsertProductTransactionsMutationResult = Apollo.MutationResult<InsertProductTransactionsMutation>;
export type InsertProductTransactionsMutationOptions = Apollo.BaseMutationOptions<InsertProductTransactionsMutation, InsertProductTransactionsMutationVariables>;
export const InsertTransactionDocument = gql`
    mutation InsertTransaction($type: String!, $payload: jsonb) {
  insert_transactions(objects: {type: $type, comment: "", payload: $payload}) {
    returning {
      id
    }
  }
}
    `;
export type InsertTransactionMutationFn = Apollo.MutationFunction<InsertTransactionMutation, InsertTransactionMutationVariables>;

/**
 * __useInsertTransactionMutation__
 *
 * To run a mutation, you first call `useInsertTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertTransactionMutation, { data, loading, error }] = useInsertTransactionMutation({
 *   variables: {
 *      type: // value for 'type'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useInsertTransactionMutation(baseOptions?: Apollo.MutationHookOptions<InsertTransactionMutation, InsertTransactionMutationVariables>) {
        return Apollo.useMutation<InsertTransactionMutation, InsertTransactionMutationVariables>(InsertTransactionDocument, baseOptions);
      }
export type InsertTransactionMutationHookResult = ReturnType<typeof useInsertTransactionMutation>;
export type InsertTransactionMutationResult = Apollo.MutationResult<InsertTransactionMutation>;
export type InsertTransactionMutationOptions = Apollo.BaseMutationOptions<InsertTransactionMutation, InsertTransactionMutationVariables>;
export const PayExtraRecipeLineDocument = gql`
    mutation PayExtraRecipeLine($ids: [uuid!]) {
  update_extraRecipeLines(where: {id: {_in: $ids}}, _set: {status: "paid"}) {
    returning {
      id
      status
    }
  }
}
    `;
export type PayExtraRecipeLineMutationFn = Apollo.MutationFunction<PayExtraRecipeLineMutation, PayExtraRecipeLineMutationVariables>;

/**
 * __usePayExtraRecipeLineMutation__
 *
 * To run a mutation, you first call `usePayExtraRecipeLineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayExtraRecipeLineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payExtraRecipeLineMutation, { data, loading, error }] = usePayExtraRecipeLineMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function usePayExtraRecipeLineMutation(baseOptions?: Apollo.MutationHookOptions<PayExtraRecipeLineMutation, PayExtraRecipeLineMutationVariables>) {
        return Apollo.useMutation<PayExtraRecipeLineMutation, PayExtraRecipeLineMutationVariables>(PayExtraRecipeLineDocument, baseOptions);
      }
export type PayExtraRecipeLineMutationHookResult = ReturnType<typeof usePayExtraRecipeLineMutation>;
export type PayExtraRecipeLineMutationResult = Apollo.MutationResult<PayExtraRecipeLineMutation>;
export type PayExtraRecipeLineMutationOptions = Apollo.BaseMutationOptions<PayExtraRecipeLineMutation, PayExtraRecipeLineMutationVariables>;
export const RequestProlongOrderDocument = gql`
    mutation RequestProlongOrder($orderId: String!, $newEndDate: String!) {
  requestProlongOrder(orderId: $orderId, newEndDate: $newEndDate) {
    message
    success
  }
}
    `;
export type RequestProlongOrderMutationFn = Apollo.MutationFunction<RequestProlongOrderMutation, RequestProlongOrderMutationVariables>;

/**
 * __useRequestProlongOrderMutation__
 *
 * To run a mutation, you first call `useRequestProlongOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestProlongOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestProlongOrderMutation, { data, loading, error }] = useRequestProlongOrderMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      newEndDate: // value for 'newEndDate'
 *   },
 * });
 */
export function useRequestProlongOrderMutation(baseOptions?: Apollo.MutationHookOptions<RequestProlongOrderMutation, RequestProlongOrderMutationVariables>) {
        return Apollo.useMutation<RequestProlongOrderMutation, RequestProlongOrderMutationVariables>(RequestProlongOrderDocument, baseOptions);
      }
export type RequestProlongOrderMutationHookResult = ReturnType<typeof useRequestProlongOrderMutation>;
export type RequestProlongOrderMutationResult = Apollo.MutationResult<RequestProlongOrderMutation>;
export type RequestProlongOrderMutationOptions = Apollo.BaseMutationOptions<RequestProlongOrderMutation, RequestProlongOrderMutationVariables>;
export const ReturnBookingsDocument = gql`
    mutation ReturnBookings($ids: [uuid!]) {
  update_bookings(where: {id: {_in: $ids}}, _set: {status: "returned"}) {
    returning {
      id
      status
    }
  }
}
    `;
export type ReturnBookingsMutationFn = Apollo.MutationFunction<ReturnBookingsMutation, ReturnBookingsMutationVariables>;

/**
 * __useReturnBookingsMutation__
 *
 * To run a mutation, you first call `useReturnBookingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReturnBookingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [returnBookingsMutation, { data, loading, error }] = useReturnBookingsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useReturnBookingsMutation(baseOptions?: Apollo.MutationHookOptions<ReturnBookingsMutation, ReturnBookingsMutationVariables>) {
        return Apollo.useMutation<ReturnBookingsMutation, ReturnBookingsMutationVariables>(ReturnBookingsDocument, baseOptions);
      }
export type ReturnBookingsMutationHookResult = ReturnType<typeof useReturnBookingsMutation>;
export type ReturnBookingsMutationResult = Apollo.MutationResult<ReturnBookingsMutation>;
export type ReturnBookingsMutationOptions = Apollo.BaseMutationOptions<ReturnBookingsMutation, ReturnBookingsMutationVariables>;
export const SetIncludeProtectionDocument = gql`
    mutation SetIncludeProtection($id: uuid!, $value: Boolean!) {
  update_bookings(where: {id: {_eq: $id}}, _set: {includeProtection: $value}) {
    returning {
      id
      status
      includeProtection
    }
  }
}
    `;
export type SetIncludeProtectionMutationFn = Apollo.MutationFunction<SetIncludeProtectionMutation, SetIncludeProtectionMutationVariables>;

/**
 * __useSetIncludeProtectionMutation__
 *
 * To run a mutation, you first call `useSetIncludeProtectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetIncludeProtectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setIncludeProtectionMutation, { data, loading, error }] = useSetIncludeProtectionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useSetIncludeProtectionMutation(baseOptions?: Apollo.MutationHookOptions<SetIncludeProtectionMutation, SetIncludeProtectionMutationVariables>) {
        return Apollo.useMutation<SetIncludeProtectionMutation, SetIncludeProtectionMutationVariables>(SetIncludeProtectionDocument, baseOptions);
      }
export type SetIncludeProtectionMutationHookResult = ReturnType<typeof useSetIncludeProtectionMutation>;
export type SetIncludeProtectionMutationResult = Apollo.MutationResult<SetIncludeProtectionMutation>;
export type SetIncludeProtectionMutationOptions = Apollo.BaseMutationOptions<SetIncludeProtectionMutation, SetIncludeProtectionMutationVariables>;
export const SetIncludeInsuranceDocument = gql`
    mutation SetIncludeInsurance($id: uuid!, $value: Boolean!) {
  update_bookings(where: {id: {_eq: $id}}, _set: {includeInsurance: $value}) {
    returning {
      id
      status
      includeInsurance
    }
  }
}
    `;
export type SetIncludeInsuranceMutationFn = Apollo.MutationFunction<SetIncludeInsuranceMutation, SetIncludeInsuranceMutationVariables>;

/**
 * __useSetIncludeInsuranceMutation__
 *
 * To run a mutation, you first call `useSetIncludeInsuranceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetIncludeInsuranceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setIncludeInsuranceMutation, { data, loading, error }] = useSetIncludeInsuranceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useSetIncludeInsuranceMutation(baseOptions?: Apollo.MutationHookOptions<SetIncludeInsuranceMutation, SetIncludeInsuranceMutationVariables>) {
        return Apollo.useMutation<SetIncludeInsuranceMutation, SetIncludeInsuranceMutationVariables>(SetIncludeInsuranceDocument, baseOptions);
      }
export type SetIncludeInsuranceMutationHookResult = ReturnType<typeof useSetIncludeInsuranceMutation>;
export type SetIncludeInsuranceMutationResult = Apollo.MutationResult<SetIncludeInsuranceMutation>;
export type SetIncludeInsuranceMutationOptions = Apollo.BaseMutationOptions<SetIncludeInsuranceMutation, SetIncludeInsuranceMutationVariables>;
export const ShipBookingsDocument = gql`
    mutation ShipBookings($ids: [uuid!]) {
  update_bookings(where: {id: {_in: $ids}}, _set: {status: "shipped"}) {
    returning {
      id
      status
    }
  }
}
    `;
export type ShipBookingsMutationFn = Apollo.MutationFunction<ShipBookingsMutation, ShipBookingsMutationVariables>;

/**
 * __useShipBookingsMutation__
 *
 * To run a mutation, you first call `useShipBookingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShipBookingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shipBookingsMutation, { data, loading, error }] = useShipBookingsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useShipBookingsMutation(baseOptions?: Apollo.MutationHookOptions<ShipBookingsMutation, ShipBookingsMutationVariables>) {
        return Apollo.useMutation<ShipBookingsMutation, ShipBookingsMutationVariables>(ShipBookingsDocument, baseOptions);
      }
export type ShipBookingsMutationHookResult = ReturnType<typeof useShipBookingsMutation>;
export type ShipBookingsMutationResult = Apollo.MutationResult<ShipBookingsMutation>;
export type ShipBookingsMutationOptions = Apollo.BaseMutationOptions<ShipBookingsMutation, ShipBookingsMutationVariables>;
export const TriggerCancellationEmailMutationDocument = gql`
    mutation TriggerCancellationEmailMutation($id: String!) {
  triggerCancellationEmail(id: $id) {
    message
    success
  }
}
    `;
export type TriggerCancellationEmailMutationMutationFn = Apollo.MutationFunction<TriggerCancellationEmailMutationMutation, TriggerCancellationEmailMutationMutationVariables>;

/**
 * __useTriggerCancellationEmailMutationMutation__
 *
 * To run a mutation, you first call `useTriggerCancellationEmailMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTriggerCancellationEmailMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [triggerCancellationEmailMutationMutation, { data, loading, error }] = useTriggerCancellationEmailMutationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTriggerCancellationEmailMutationMutation(baseOptions?: Apollo.MutationHookOptions<TriggerCancellationEmailMutationMutation, TriggerCancellationEmailMutationMutationVariables>) {
        return Apollo.useMutation<TriggerCancellationEmailMutationMutation, TriggerCancellationEmailMutationMutationVariables>(TriggerCancellationEmailMutationDocument, baseOptions);
      }
export type TriggerCancellationEmailMutationMutationHookResult = ReturnType<typeof useTriggerCancellationEmailMutationMutation>;
export type TriggerCancellationEmailMutationMutationResult = Apollo.MutationResult<TriggerCancellationEmailMutationMutation>;
export type TriggerCancellationEmailMutationMutationOptions = Apollo.BaseMutationOptions<TriggerCancellationEmailMutationMutation, TriggerCancellationEmailMutationMutationVariables>;
export const TriggerConfirmationEmailMutationDocument = gql`
    mutation TriggerConfirmationEmailMutation($id: String!, $isChange: Boolean, $includeExtraData: Boolean, $message: String) {
  triggerConfirmationEmail(id: $id, isChange: $isChange, includeExtraData: $includeExtraData, message: $message) {
    message
    success
  }
}
    `;
export type TriggerConfirmationEmailMutationMutationFn = Apollo.MutationFunction<TriggerConfirmationEmailMutationMutation, TriggerConfirmationEmailMutationMutationVariables>;

/**
 * __useTriggerConfirmationEmailMutationMutation__
 *
 * To run a mutation, you first call `useTriggerConfirmationEmailMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTriggerConfirmationEmailMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [triggerConfirmationEmailMutationMutation, { data, loading, error }] = useTriggerConfirmationEmailMutationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isChange: // value for 'isChange'
 *      includeExtraData: // value for 'includeExtraData'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useTriggerConfirmationEmailMutationMutation(baseOptions?: Apollo.MutationHookOptions<TriggerConfirmationEmailMutationMutation, TriggerConfirmationEmailMutationMutationVariables>) {
        return Apollo.useMutation<TriggerConfirmationEmailMutationMutation, TriggerConfirmationEmailMutationMutationVariables>(TriggerConfirmationEmailMutationDocument, baseOptions);
      }
export type TriggerConfirmationEmailMutationMutationHookResult = ReturnType<typeof useTriggerConfirmationEmailMutationMutation>;
export type TriggerConfirmationEmailMutationMutationResult = Apollo.MutationResult<TriggerConfirmationEmailMutationMutation>;
export type TriggerConfirmationEmailMutationMutationOptions = Apollo.BaseMutationOptions<TriggerConfirmationEmailMutationMutation, TriggerConfirmationEmailMutationMutationVariables>;
export const TriggerVerificationEmailMutationDocument = gql`
    mutation TriggerVerificationEmailMutation($id: String!) {
  triggerVerificationEmail(id: $id) {
    message
    success
  }
}
    `;
export type TriggerVerificationEmailMutationMutationFn = Apollo.MutationFunction<TriggerVerificationEmailMutationMutation, TriggerVerificationEmailMutationMutationVariables>;

/**
 * __useTriggerVerificationEmailMutationMutation__
 *
 * To run a mutation, you first call `useTriggerVerificationEmailMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTriggerVerificationEmailMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [triggerVerificationEmailMutationMutation, { data, loading, error }] = useTriggerVerificationEmailMutationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTriggerVerificationEmailMutationMutation(baseOptions?: Apollo.MutationHookOptions<TriggerVerificationEmailMutationMutation, TriggerVerificationEmailMutationMutationVariables>) {
        return Apollo.useMutation<TriggerVerificationEmailMutationMutation, TriggerVerificationEmailMutationMutationVariables>(TriggerVerificationEmailMutationDocument, baseOptions);
      }
export type TriggerVerificationEmailMutationMutationHookResult = ReturnType<typeof useTriggerVerificationEmailMutationMutation>;
export type TriggerVerificationEmailMutationMutationResult = Apollo.MutationResult<TriggerVerificationEmailMutationMutation>;
export type TriggerVerificationEmailMutationMutationOptions = Apollo.BaseMutationOptions<TriggerVerificationEmailMutationMutation, TriggerVerificationEmailMutationMutationVariables>;
export const UpdateCustomerDocument = gql`
    mutation UpdateCustomer($id: uuid!, $data: customers_set_input!) {
  update_customers_by_pk(pk_columns: {id: $id}, _set: $data) {
    id
  }
}
    `;
export type UpdateCustomerMutationFn = Apollo.MutationFunction<UpdateCustomerMutation, UpdateCustomerMutationVariables>;

/**
 * __useUpdateCustomerMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerMutation, { data, loading, error }] = useUpdateCustomerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateCustomerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCustomerMutation, UpdateCustomerMutationVariables>) {
        return Apollo.useMutation<UpdateCustomerMutation, UpdateCustomerMutationVariables>(UpdateCustomerDocument, baseOptions);
      }
export type UpdateCustomerMutationHookResult = ReturnType<typeof useUpdateCustomerMutation>;
export type UpdateCustomerMutationResult = Apollo.MutationResult<UpdateCustomerMutation>;
export type UpdateCustomerMutationOptions = Apollo.BaseMutationOptions<UpdateCustomerMutation, UpdateCustomerMutationVariables>;
export const UpdateOrderUpdateDateDocument = gql`
    mutation updateOrderUpdateDate($id: uuid!) {
  update_orders_by_pk(pk_columns: {id: $id}, _set: {updated_at: "now()"}) {
    id
    updated_at
  }
}
    `;
export type UpdateOrderUpdateDateMutationFn = Apollo.MutationFunction<UpdateOrderUpdateDateMutation, UpdateOrderUpdateDateMutationVariables>;

/**
 * __useUpdateOrderUpdateDateMutation__
 *
 * To run a mutation, you first call `useUpdateOrderUpdateDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderUpdateDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderUpdateDateMutation, { data, loading, error }] = useUpdateOrderUpdateDateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateOrderUpdateDateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrderUpdateDateMutation, UpdateOrderUpdateDateMutationVariables>) {
        return Apollo.useMutation<UpdateOrderUpdateDateMutation, UpdateOrderUpdateDateMutationVariables>(UpdateOrderUpdateDateDocument, baseOptions);
      }
export type UpdateOrderUpdateDateMutationHookResult = ReturnType<typeof useUpdateOrderUpdateDateMutation>;
export type UpdateOrderUpdateDateMutationResult = Apollo.MutationResult<UpdateOrderUpdateDateMutation>;
export type UpdateOrderUpdateDateMutationOptions = Apollo.BaseMutationOptions<UpdateOrderUpdateDateMutation, UpdateOrderUpdateDateMutationVariables>;
export const UpsertExtraRecipeLineDocument = gql`
    mutation UpsertExtraRecipeLine($line: [extraRecipeLines_insert_input!]!) {
  insert_extraRecipeLines(objects: $line, on_conflict: {constraint: freeRecipeLines_pkey, update_columns: [status, amount, description]}) {
    returning {
      status
      orderId
      id
      description
      amount
      updated_at
      created_at
    }
  }
}
    `;
export type UpsertExtraRecipeLineMutationFn = Apollo.MutationFunction<UpsertExtraRecipeLineMutation, UpsertExtraRecipeLineMutationVariables>;

/**
 * __useUpsertExtraRecipeLineMutation__
 *
 * To run a mutation, you first call `useUpsertExtraRecipeLineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertExtraRecipeLineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertExtraRecipeLineMutation, { data, loading, error }] = useUpsertExtraRecipeLineMutation({
 *   variables: {
 *      line: // value for 'line'
 *   },
 * });
 */
export function useUpsertExtraRecipeLineMutation(baseOptions?: Apollo.MutationHookOptions<UpsertExtraRecipeLineMutation, UpsertExtraRecipeLineMutationVariables>) {
        return Apollo.useMutation<UpsertExtraRecipeLineMutation, UpsertExtraRecipeLineMutationVariables>(UpsertExtraRecipeLineDocument, baseOptions);
      }
export type UpsertExtraRecipeLineMutationHookResult = ReturnType<typeof useUpsertExtraRecipeLineMutation>;
export type UpsertExtraRecipeLineMutationResult = Apollo.MutationResult<UpsertExtraRecipeLineMutation>;
export type UpsertExtraRecipeLineMutationOptions = Apollo.BaseMutationOptions<UpsertExtraRecipeLineMutation, UpsertExtraRecipeLineMutationVariables>;
export const UpsertProductDocument = gql`
    mutation upsertProduct($data: products_insert_input!) {
  insert_products_one(object: $data, on_conflict: {constraint: products_productTypeId_size_locationId_key, update_columns: [bikesAvailable, description, sortPosition]}) {
    id
  }
}
    `;
export type UpsertProductMutationFn = Apollo.MutationFunction<UpsertProductMutation, UpsertProductMutationVariables>;

/**
 * __useUpsertProductMutation__
 *
 * To run a mutation, you first call `useUpsertProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertProductMutation, { data, loading, error }] = useUpsertProductMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpsertProductMutation(baseOptions?: Apollo.MutationHookOptions<UpsertProductMutation, UpsertProductMutationVariables>) {
        return Apollo.useMutation<UpsertProductMutation, UpsertProductMutationVariables>(UpsertProductDocument, baseOptions);
      }
export type UpsertProductMutationHookResult = ReturnType<typeof useUpsertProductMutation>;
export type UpsertProductMutationResult = Apollo.MutationResult<UpsertProductMutation>;
export type UpsertProductMutationOptions = Apollo.BaseMutationOptions<UpsertProductMutation, UpsertProductMutationVariables>;
export const UpsertProductPricesDocument = gql`
    mutation upsertProductPrices($prices: [productPrices_insert_input!]!) {
  insert_productPrices(objects: $prices, on_conflict: {constraint: productPrices_pkey, update_columns: [minDuration, basePricePerDay, insuranceCostsPerDay, protectionCostsPerDay]}) {
    returning {
      id
    }
  }
}
    `;
export type UpsertProductPricesMutationFn = Apollo.MutationFunction<UpsertProductPricesMutation, UpsertProductPricesMutationVariables>;

/**
 * __useUpsertProductPricesMutation__
 *
 * To run a mutation, you first call `useUpsertProductPricesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertProductPricesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertProductPricesMutation, { data, loading, error }] = useUpsertProductPricesMutation({
 *   variables: {
 *      prices: // value for 'prices'
 *   },
 * });
 */
export function useUpsertProductPricesMutation(baseOptions?: Apollo.MutationHookOptions<UpsertProductPricesMutation, UpsertProductPricesMutationVariables>) {
        return Apollo.useMutation<UpsertProductPricesMutation, UpsertProductPricesMutationVariables>(UpsertProductPricesDocument, baseOptions);
      }
export type UpsertProductPricesMutationHookResult = ReturnType<typeof useUpsertProductPricesMutation>;
export type UpsertProductPricesMutationResult = Apollo.MutationResult<UpsertProductPricesMutation>;
export type UpsertProductPricesMutationOptions = Apollo.BaseMutationOptions<UpsertProductPricesMutation, UpsertProductPricesMutationVariables>;
export const UpsertProductTypeDocument = gql`
    mutation upsertProductType($data: productTypes_insert_input!) {
  insert_productTypes_one(object: $data, on_conflict: {constraint: productTypes_pkey, update_columns: [description, image, name, category, productTypeModelId, sortPosition, forceInsurance]}) {
    id
  }
}
    `;
export type UpsertProductTypeMutationFn = Apollo.MutationFunction<UpsertProductTypeMutation, UpsertProductTypeMutationVariables>;

/**
 * __useUpsertProductTypeMutation__
 *
 * To run a mutation, you first call `useUpsertProductTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertProductTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertProductTypeMutation, { data, loading, error }] = useUpsertProductTypeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpsertProductTypeMutation(baseOptions?: Apollo.MutationHookOptions<UpsertProductTypeMutation, UpsertProductTypeMutationVariables>) {
        return Apollo.useMutation<UpsertProductTypeMutation, UpsertProductTypeMutationVariables>(UpsertProductTypeDocument, baseOptions);
      }
export type UpsertProductTypeMutationHookResult = ReturnType<typeof useUpsertProductTypeMutation>;
export type UpsertProductTypeMutationResult = Apollo.MutationResult<UpsertProductTypeMutation>;
export type UpsertProductTypeMutationOptions = Apollo.BaseMutationOptions<UpsertProductTypeMutation, UpsertProductTypeMutationVariables>;
export const UpsertSeasonDocument = gql`
    mutation upsertSeason($data: seasons_insert_input!) {
  insert_seasons_one(object: $data, on_conflict: {constraint: seasons_pkey, update_columns: [name, startDate, endDate, locationId]}) {
    id
  }
}
    `;
export type UpsertSeasonMutationFn = Apollo.MutationFunction<UpsertSeasonMutation, UpsertSeasonMutationVariables>;

/**
 * __useUpsertSeasonMutation__
 *
 * To run a mutation, you first call `useUpsertSeasonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertSeasonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertSeasonMutation, { data, loading, error }] = useUpsertSeasonMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpsertSeasonMutation(baseOptions?: Apollo.MutationHookOptions<UpsertSeasonMutation, UpsertSeasonMutationVariables>) {
        return Apollo.useMutation<UpsertSeasonMutation, UpsertSeasonMutationVariables>(UpsertSeasonDocument, baseOptions);
      }
export type UpsertSeasonMutationHookResult = ReturnType<typeof useUpsertSeasonMutation>;
export type UpsertSeasonMutationResult = Apollo.MutationResult<UpsertSeasonMutation>;
export type UpsertSeasonMutationOptions = Apollo.BaseMutationOptions<UpsertSeasonMutation, UpsertSeasonMutationVariables>;
export const VerifyOrderDocument = gql`
    mutation VerifyOrder($id: String!) {
  verifyOrder(id: $id) {
    message
    success
  }
}
    `;
export type VerifyOrderMutationFn = Apollo.MutationFunction<VerifyOrderMutation, VerifyOrderMutationVariables>;

/**
 * __useVerifyOrderMutation__
 *
 * To run a mutation, you first call `useVerifyOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyOrderMutation, { data, loading, error }] = useVerifyOrderMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useVerifyOrderMutation(baseOptions?: Apollo.MutationHookOptions<VerifyOrderMutation, VerifyOrderMutationVariables>) {
        return Apollo.useMutation<VerifyOrderMutation, VerifyOrderMutationVariables>(VerifyOrderDocument, baseOptions);
      }
export type VerifyOrderMutationHookResult = ReturnType<typeof useVerifyOrderMutation>;
export type VerifyOrderMutationResult = Apollo.MutationResult<VerifyOrderMutation>;
export type VerifyOrderMutationOptions = Apollo.BaseMutationOptions<VerifyOrderMutation, VerifyOrderMutationVariables>;
export const GetAvailabilitiesForDateRangeDocument = gql`
    query GetAvailabilitiesForDateRange($productIds: [uuid!], $startDate: date!, $endDate: date!) {
  snapshotAvailabilities(where: {_and: [{productId: {_in: $productIds}}, {date: {_gte: $startDate}}, {date: {_lte: $endDate}}]}, order_by: {date: asc}) {
    productId
    date
    availableBikes
  }
}
    `;

/**
 * __useGetAvailabilitiesForDateRangeQuery__
 *
 * To run a query within a React component, call `useGetAvailabilitiesForDateRangeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAvailabilitiesForDateRangeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAvailabilitiesForDateRangeQuery({
 *   variables: {
 *      productIds: // value for 'productIds'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetAvailabilitiesForDateRangeQuery(baseOptions: Apollo.QueryHookOptions<GetAvailabilitiesForDateRangeQuery, GetAvailabilitiesForDateRangeQueryVariables>) {
        return Apollo.useQuery<GetAvailabilitiesForDateRangeQuery, GetAvailabilitiesForDateRangeQueryVariables>(GetAvailabilitiesForDateRangeDocument, baseOptions);
      }
export function useGetAvailabilitiesForDateRangeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAvailabilitiesForDateRangeQuery, GetAvailabilitiesForDateRangeQueryVariables>) {
          return Apollo.useLazyQuery<GetAvailabilitiesForDateRangeQuery, GetAvailabilitiesForDateRangeQueryVariables>(GetAvailabilitiesForDateRangeDocument, baseOptions);
        }
export type GetAvailabilitiesForDateRangeQueryHookResult = ReturnType<typeof useGetAvailabilitiesForDateRangeQuery>;
export type GetAvailabilitiesForDateRangeLazyQueryHookResult = ReturnType<typeof useGetAvailabilitiesForDateRangeLazyQuery>;
export type GetAvailabilitiesForDateRangeQueryResult = Apollo.QueryResult<GetAvailabilitiesForDateRangeQuery, GetAvailabilitiesForDateRangeQueryVariables>;
export const GetOrderDocument = gql`
    query GetOrder($orderId: uuid!) {
  orders_by_pk(id: $orderId) {
    id
    duration
    startDate
    created_at
    customer {
      id
      name
      firstname
      birthdate
      street
      streetnumber
      zipCode
      city
      countryCode
      email
      phone
    }
    extraRecipeLines {
      id
      created_at
      updated_at
      status
      description
      amount
    }
    bookings(order_by: {id: asc}) {
      id
      created_at
      riderName
      riderWeight
      verifiedPriceSnapshot
      orderId
      includeProtection
      includeInsurance
      cancelled
      status
      product {
        id
        size
        productType {
          id
          name
          category
        }
      }
    }
  }
}
    `;

/**
 * __useGetOrderQuery__
 *
 * To run a query within a React component, call `useGetOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useGetOrderQuery(baseOptions: Apollo.QueryHookOptions<GetOrderQuery, GetOrderQueryVariables>) {
        return Apollo.useQuery<GetOrderQuery, GetOrderQueryVariables>(GetOrderDocument, baseOptions);
      }
export function useGetOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrderQuery, GetOrderQueryVariables>) {
          return Apollo.useLazyQuery<GetOrderQuery, GetOrderQueryVariables>(GetOrderDocument, baseOptions);
        }
export type GetOrderQueryHookResult = ReturnType<typeof useGetOrderQuery>;
export type GetOrderLazyQueryHookResult = ReturnType<typeof useGetOrderLazyQuery>;
export type GetOrderQueryResult = Apollo.QueryResult<GetOrderQuery, GetOrderQueryVariables>;
export const GetOrdersOverviewDocument = gql`
    query GetOrdersOverview($startDate: date!) {
  orders(where: {startDate: {_eq: $startDate}}) {
    id
    duration
    startDate
    created_at
    customer {
      id
      name
      firstname
      zipCode
      city
      countryCode
      email
      phone
      street
      streetnumber
    }
    bookings {
      id
      created_at
      riderName
      riderWeight
      verifiedPriceSnapshot
      orderId
      includeProtection
      includeInsurance
      cancelled
      status
      product {
        id
        size
        productType {
          id
          name
          category
        }
      }
    }
  }
}
    `;

/**
 * __useGetOrdersOverviewQuery__
 *
 * To run a query within a React component, call `useGetOrdersOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrdersOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrdersOverviewQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *   },
 * });
 */
export function useGetOrdersOverviewQuery(baseOptions: Apollo.QueryHookOptions<GetOrdersOverviewQuery, GetOrdersOverviewQueryVariables>) {
        return Apollo.useQuery<GetOrdersOverviewQuery, GetOrdersOverviewQueryVariables>(GetOrdersOverviewDocument, baseOptions);
      }
export function useGetOrdersOverviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrdersOverviewQuery, GetOrdersOverviewQueryVariables>) {
          return Apollo.useLazyQuery<GetOrdersOverviewQuery, GetOrdersOverviewQueryVariables>(GetOrdersOverviewDocument, baseOptions);
        }
export type GetOrdersOverviewQueryHookResult = ReturnType<typeof useGetOrdersOverviewQuery>;
export type GetOrdersOverviewLazyQueryHookResult = ReturnType<typeof useGetOrdersOverviewLazyQuery>;
export type GetOrdersOverviewQueryResult = Apollo.QueryResult<GetOrdersOverviewQuery, GetOrdersOverviewQueryVariables>;
export const GetSeasonsDocument = gql`
    query GetSeasons {
  seasons {
    id
    name
    locationId
    startDate
    endDate
    created_at
    updated_at
  }
}
    `;

/**
 * __useGetSeasonsQuery__
 *
 * To run a query within a React component, call `useGetSeasonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSeasonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSeasonsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSeasonsQuery(baseOptions?: Apollo.QueryHookOptions<GetSeasonsQuery, GetSeasonsQueryVariables>) {
        return Apollo.useQuery<GetSeasonsQuery, GetSeasonsQueryVariables>(GetSeasonsDocument, baseOptions);
      }
export function useGetSeasonsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSeasonsQuery, GetSeasonsQueryVariables>) {
          return Apollo.useLazyQuery<GetSeasonsQuery, GetSeasonsQueryVariables>(GetSeasonsDocument, baseOptions);
        }
export type GetSeasonsQueryHookResult = ReturnType<typeof useGetSeasonsQuery>;
export type GetSeasonsLazyQueryHookResult = ReturnType<typeof useGetSeasonsLazyQuery>;
export type GetSeasonsQueryResult = Apollo.QueryResult<GetSeasonsQuery, GetSeasonsQueryVariables>;
export const GetBookingLabelDocument = gql`
    query GetBookingLabel($id: uuid!) {
  bookings_by_pk(id: $id) {
    id
    product {
      productType {
        name
      }
      size
    }
    riderWeight
    riderName
    order {
      customer {
        firstname
        name
      }
    }
  }
}
    `;

/**
 * __useGetBookingLabelQuery__
 *
 * To run a query within a React component, call `useGetBookingLabelQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookingLabelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookingLabelQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBookingLabelQuery(baseOptions: Apollo.QueryHookOptions<GetBookingLabelQuery, GetBookingLabelQueryVariables>) {
        return Apollo.useQuery<GetBookingLabelQuery, GetBookingLabelQueryVariables>(GetBookingLabelDocument, baseOptions);
      }
export function useGetBookingLabelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBookingLabelQuery, GetBookingLabelQueryVariables>) {
          return Apollo.useLazyQuery<GetBookingLabelQuery, GetBookingLabelQueryVariables>(GetBookingLabelDocument, baseOptions);
        }
export type GetBookingLabelQueryHookResult = ReturnType<typeof useGetBookingLabelQuery>;
export type GetBookingLabelLazyQueryHookResult = ReturnType<typeof useGetBookingLabelLazyQuery>;
export type GetBookingLabelQueryResult = Apollo.QueryResult<GetBookingLabelQuery, GetBookingLabelQueryVariables>;
export const GetLocationsDocument = gql`
    query getLocations {
  locations {
    id
    name
  }
}
    `;

/**
 * __useGetLocationsQuery__
 *
 * To run a query within a React component, call `useGetLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLocationsQuery(baseOptions?: Apollo.QueryHookOptions<GetLocationsQuery, GetLocationsQueryVariables>) {
        return Apollo.useQuery<GetLocationsQuery, GetLocationsQueryVariables>(GetLocationsDocument, baseOptions);
      }
export function useGetLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLocationsQuery, GetLocationsQueryVariables>) {
          return Apollo.useLazyQuery<GetLocationsQuery, GetLocationsQueryVariables>(GetLocationsDocument, baseOptions);
        }
export type GetLocationsQueryHookResult = ReturnType<typeof useGetLocationsQuery>;
export type GetLocationsLazyQueryHookResult = ReturnType<typeof useGetLocationsLazyQuery>;
export type GetLocationsQueryResult = Apollo.QueryResult<GetLocationsQuery, GetLocationsQueryVariables>;
export const GetProductTypeDocument = gql`
    query getProductType($productTypeId: uuid!) {
  productTypes_by_pk(id: $productTypeId) {
    id
    name
    description
    category
    image
    sortPosition
    forceInsurance
    productTypeModel {
      id
      name
    }
    products {
      id
      size
      bikesAvailable
      sortPosition
    }
  }
}
    `;

/**
 * __useGetProductTypeQuery__
 *
 * To run a query within a React component, call `useGetProductTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductTypeQuery({
 *   variables: {
 *      productTypeId: // value for 'productTypeId'
 *   },
 * });
 */
export function useGetProductTypeQuery(baseOptions: Apollo.QueryHookOptions<GetProductTypeQuery, GetProductTypeQueryVariables>) {
        return Apollo.useQuery<GetProductTypeQuery, GetProductTypeQueryVariables>(GetProductTypeDocument, baseOptions);
      }
export function useGetProductTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductTypeQuery, GetProductTypeQueryVariables>) {
          return Apollo.useLazyQuery<GetProductTypeQuery, GetProductTypeQueryVariables>(GetProductTypeDocument, baseOptions);
        }
export type GetProductTypeQueryHookResult = ReturnType<typeof useGetProductTypeQuery>;
export type GetProductTypeLazyQueryHookResult = ReturnType<typeof useGetProductTypeLazyQuery>;
export type GetProductTypeQueryResult = Apollo.QueryResult<GetProductTypeQuery, GetProductTypeQueryVariables>;
export const GetProductTypeAvailabilitiesDocument = gql`
    query getProductTypeAvailabilities($date: date!, $productTypeId: uuid!) {
  productTypes_by_pk(id: $productTypeId) {
    id
    name
    productTypeModel {
      id
      name
    }
    products {
      id
      size
      snapshotAvailabilities(where: {date: {_eq: $date}}) {
        id
        availableBikes
      }
    }
  }
}
    `;

/**
 * __useGetProductTypeAvailabilitiesQuery__
 *
 * To run a query within a React component, call `useGetProductTypeAvailabilitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductTypeAvailabilitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductTypeAvailabilitiesQuery({
 *   variables: {
 *      date: // value for 'date'
 *      productTypeId: // value for 'productTypeId'
 *   },
 * });
 */
export function useGetProductTypeAvailabilitiesQuery(baseOptions: Apollo.QueryHookOptions<GetProductTypeAvailabilitiesQuery, GetProductTypeAvailabilitiesQueryVariables>) {
        return Apollo.useQuery<GetProductTypeAvailabilitiesQuery, GetProductTypeAvailabilitiesQueryVariables>(GetProductTypeAvailabilitiesDocument, baseOptions);
      }
export function useGetProductTypeAvailabilitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductTypeAvailabilitiesQuery, GetProductTypeAvailabilitiesQueryVariables>) {
          return Apollo.useLazyQuery<GetProductTypeAvailabilitiesQuery, GetProductTypeAvailabilitiesQueryVariables>(GetProductTypeAvailabilitiesDocument, baseOptions);
        }
export type GetProductTypeAvailabilitiesQueryHookResult = ReturnType<typeof useGetProductTypeAvailabilitiesQuery>;
export type GetProductTypeAvailabilitiesLazyQueryHookResult = ReturnType<typeof useGetProductTypeAvailabilitiesLazyQuery>;
export type GetProductTypeAvailabilitiesQueryResult = Apollo.QueryResult<GetProductTypeAvailabilitiesQuery, GetProductTypeAvailabilitiesQueryVariables>;
export const GetProductTypeAvailabilitiesOverviewDocument = gql`
    query GetProductTypeAvailabilitiesOverview($date: date!) {
  productTypes(order_by: {category: asc, name: asc}) {
    id
    name
    category
    products(order_by: {size: asc}) {
      id
      size
      bikesAvailable
      snapshotAvailabilities(where: {date: {_eq: $date}}) {
        id
        availableBikes
      }
      bookings_aggregate(where: {_and: [{order: {_and: [{startDate: {_lte: $date}}, {endDate: {_gte: $date}}]}}, {status: {_nin: ["cancelled", "returned"]}}]}) {
        aggregate {
          count
        }
      }
      bookings(where: {order: {_and: [{startDate: {_lte: $date}}, {endDate: {_gte: $date}}]}}) {
        id
        status
        order {
          id
          startDate
          endDate
          duration
          customer {
            id
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetProductTypeAvailabilitiesOverviewQuery__
 *
 * To run a query within a React component, call `useGetProductTypeAvailabilitiesOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductTypeAvailabilitiesOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductTypeAvailabilitiesOverviewQuery({
 *   variables: {
 *      date: // value for 'date'
 *   },
 * });
 */
export function useGetProductTypeAvailabilitiesOverviewQuery(baseOptions: Apollo.QueryHookOptions<GetProductTypeAvailabilitiesOverviewQuery, GetProductTypeAvailabilitiesOverviewQueryVariables>) {
        return Apollo.useQuery<GetProductTypeAvailabilitiesOverviewQuery, GetProductTypeAvailabilitiesOverviewQueryVariables>(GetProductTypeAvailabilitiesOverviewDocument, baseOptions);
      }
export function useGetProductTypeAvailabilitiesOverviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductTypeAvailabilitiesOverviewQuery, GetProductTypeAvailabilitiesOverviewQueryVariables>) {
          return Apollo.useLazyQuery<GetProductTypeAvailabilitiesOverviewQuery, GetProductTypeAvailabilitiesOverviewQueryVariables>(GetProductTypeAvailabilitiesOverviewDocument, baseOptions);
        }
export type GetProductTypeAvailabilitiesOverviewQueryHookResult = ReturnType<typeof useGetProductTypeAvailabilitiesOverviewQuery>;
export type GetProductTypeAvailabilitiesOverviewLazyQueryHookResult = ReturnType<typeof useGetProductTypeAvailabilitiesOverviewLazyQuery>;
export type GetProductTypeAvailabilitiesOverviewQueryResult = Apollo.QueryResult<GetProductTypeAvailabilitiesOverviewQuery, GetProductTypeAvailabilitiesOverviewQueryVariables>;
export const GetProductTypeModelsDocument = gql`
    query getProductTypeModels {
  productTypeModels {
    id
    name
  }
}
    `;

/**
 * __useGetProductTypeModelsQuery__
 *
 * To run a query within a React component, call `useGetProductTypeModelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductTypeModelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductTypeModelsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProductTypeModelsQuery(baseOptions?: Apollo.QueryHookOptions<GetProductTypeModelsQuery, GetProductTypeModelsQueryVariables>) {
        return Apollo.useQuery<GetProductTypeModelsQuery, GetProductTypeModelsQueryVariables>(GetProductTypeModelsDocument, baseOptions);
      }
export function useGetProductTypeModelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductTypeModelsQuery, GetProductTypeModelsQueryVariables>) {
          return Apollo.useLazyQuery<GetProductTypeModelsQuery, GetProductTypeModelsQueryVariables>(GetProductTypeModelsDocument, baseOptions);
        }
export type GetProductTypeModelsQueryHookResult = ReturnType<typeof useGetProductTypeModelsQuery>;
export type GetProductTypeModelsLazyQueryHookResult = ReturnType<typeof useGetProductTypeModelsLazyQuery>;
export type GetProductTypeModelsQueryResult = Apollo.QueryResult<GetProductTypeModelsQuery, GetProductTypeModelsQueryVariables>;
export const GetTransactionDocument = gql`
    query GetTransaction($id: uuid!) {
  transactions_by_pk(id: $id) {
    id
    extraRecipeLinesTransactions {
      transaction {
        comment
        type
      }
      extraRecipeLine {
        id
        description
        amount
        status
      }
    }
    bookings_transactions {
      id
      booking {
        id
        created_at
        riderName
        riderWeight
        verifiedPriceSnapshot
        order {
          id
          duration
          startDate
          customer {
            id
            name
            firstname
            street
            streetnumber
            zipCode
            city
            countryCode
            email
            phone
          }
        }
        includeProtection
        includeInsurance
        cancelled
        status
        product {
          id
          size
          productType {
            id
            name
            category
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetTransactionQuery__
 *
 * To run a query within a React component, call `useGetTransactionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransactionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransactionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTransactionQuery(baseOptions: Apollo.QueryHookOptions<GetTransactionQuery, GetTransactionQueryVariables>) {
        return Apollo.useQuery<GetTransactionQuery, GetTransactionQueryVariables>(GetTransactionDocument, baseOptions);
      }
export function useGetTransactionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTransactionQuery, GetTransactionQueryVariables>) {
          return Apollo.useLazyQuery<GetTransactionQuery, GetTransactionQueryVariables>(GetTransactionDocument, baseOptions);
        }
export type GetTransactionQueryHookResult = ReturnType<typeof useGetTransactionQuery>;
export type GetTransactionLazyQueryHookResult = ReturnType<typeof useGetTransactionLazyQuery>;
export type GetTransactionQueryResult = Apollo.QueryResult<GetTransactionQuery, GetTransactionQueryVariables>;
export const ObserveOrderDocument = gql`
    subscription ObserveOrder($id: uuid!) {
  orders_by_pk(id: $id) {
    id
    verified
    duration
    startDate
    created_at
    updated_at
    confirmation_sent_at
    verification_sent_at
    cancellation_sent_at
    customer {
      id
      name
      firstname
      birthdate
      street
      streetnumber
      zipCode
      city
      countryCode
      email
      phone
      notes
    }
    extraRecipeLines {
      id
      created_at
      updated_at
      status
      description
      amount
    }
    bookings(order_by: [{id: asc}]) {
      id
      created_at
      updated_at
      riderName
      riderWeight
      verifiedPriceSnapshot
      orderId
      order {
        id
        duration
      }
      includeProtection
      includeInsurance
      cancelled
      status
      product {
        id
        size
        location {
          id
          name
        }
        productType {
          id
          name
          category
          forceInsurance
        }
      }
    }
  }
}
    `;

/**
 * __useObserveOrderSubscription__
 *
 * To run a query within a React component, call `useObserveOrderSubscription` and pass it any options that fit your needs.
 * When your component renders, `useObserveOrderSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useObserveOrderSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useObserveOrderSubscription(baseOptions: Apollo.SubscriptionHookOptions<ObserveOrderSubscription, ObserveOrderSubscriptionVariables>) {
        return Apollo.useSubscription<ObserveOrderSubscription, ObserveOrderSubscriptionVariables>(ObserveOrderDocument, baseOptions);
      }
export type ObserveOrderSubscriptionHookResult = ReturnType<typeof useObserveOrderSubscription>;
export type ObserveOrderSubscriptionResult = Apollo.SubscriptionResult<ObserveOrderSubscription>;
export const ObserveOrdersDocument = gql`
    subscription ObserveOrders($locationId: uuid!, $startDatesFilter: [orders_bool_exp!], $searchTerm: String = "%") {
  orders(where: {_and: [{bookings: {product: {locationId: {_eq: $locationId}}}}, {_or: $startDatesFilter}, {_or: [{customer: {name: {_ilike: $searchTerm}}}, {customer: {email: {_ilike: $searchTerm}}}, {customer: {firstname: {_ilike: $searchTerm}}}, {customer: {street: {_ilike: $searchTerm}}}, {customer: {phone: {_ilike: $searchTerm}}}]}]}) {
    id
    verified
    duration
    startDate
    created_at
    updated_at
    customer {
      id
      name
      firstname
      birthdate
      street
      streetnumber
      zipCode
      city
      countryCode
      email
      phone
    }
    extraRecipeLines {
      id
      created_at
      updated_at
      status
      description
      amount
    }
    bookings {
      id
      created_at
      updated_at
      riderName
      riderWeight
      verifiedPriceSnapshot
      orderId
      order {
        id
        duration
      }
      includeProtection
      includeInsurance
      cancelled
      status
      product {
        id
        size
        productType {
          id
          name
          category
        }
      }
    }
  }
}
    `;

/**
 * __useObserveOrdersSubscription__
 *
 * To run a query within a React component, call `useObserveOrdersSubscription` and pass it any options that fit your needs.
 * When your component renders, `useObserveOrdersSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useObserveOrdersSubscription({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      startDatesFilter: // value for 'startDatesFilter'
 *      searchTerm: // value for 'searchTerm'
 *   },
 * });
 */
export function useObserveOrdersSubscription(baseOptions: Apollo.SubscriptionHookOptions<ObserveOrdersSubscription, ObserveOrdersSubscriptionVariables>) {
        return Apollo.useSubscription<ObserveOrdersSubscription, ObserveOrdersSubscriptionVariables>(ObserveOrdersDocument, baseOptions);
      }
export type ObserveOrdersSubscriptionHookResult = ReturnType<typeof useObserveOrdersSubscription>;
export type ObserveOrdersSubscriptionResult = Apollo.SubscriptionResult<ObserveOrdersSubscription>;
export const GetAvailabilitiesOverviewDocument = gql`
    subscription GetAvailabilitiesOverview($date: date!) {
  snapshotAvailabilities(where: {date: {_eq: $date}}) {
    id
    date
    availableBikes
    product {
      id
      size
      bikesAvailable
      productType {
        id
        productTypeModelId
        name
        category
      }
      bookings_aggregate(where: {_and: [{order: {_and: [{startDate: {_lte: $date}}, {endDate: {_gte: $date}}]}}, {status: {_nin: ["cancelled", "returned"]}}]}) {
        aggregate {
          count
        }
      }
      bookings(where: {order: {_and: [{startDate: {_lte: $date}}, {endDate: {_gte: $date}}]}}) {
        id
        status
        order {
          id
          endDate
          duration
          customer {
            id
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetAvailabilitiesOverviewSubscription__
 *
 * To run a query within a React component, call `useGetAvailabilitiesOverviewSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetAvailabilitiesOverviewSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAvailabilitiesOverviewSubscription({
 *   variables: {
 *      date: // value for 'date'
 *   },
 * });
 */
export function useGetAvailabilitiesOverviewSubscription(baseOptions: Apollo.SubscriptionHookOptions<GetAvailabilitiesOverviewSubscription, GetAvailabilitiesOverviewSubscriptionVariables>) {
        return Apollo.useSubscription<GetAvailabilitiesOverviewSubscription, GetAvailabilitiesOverviewSubscriptionVariables>(GetAvailabilitiesOverviewDocument, baseOptions);
      }
export type GetAvailabilitiesOverviewSubscriptionHookResult = ReturnType<typeof useGetAvailabilitiesOverviewSubscription>;
export type GetAvailabilitiesOverviewSubscriptionResult = Apollo.SubscriptionResult<GetAvailabilitiesOverviewSubscription>;
export const GetBookingsTransactionsByOrderIdDocument = gql`
    subscription getBookingsTransactionsByOrderId($orderId: uuid!) {
  transactions(where: {_or: [{bookings_transactions: {booking: {orderId: {_eq: $orderId}}}}, {extraRecipeLinesTransactions: {extraRecipeLine: {orderId: {_eq: $orderId}}}}]}) {
    id
    type
    comment
    created_at
    payload
    updated_at
    extraRecipeLinesTransactions {
      id
      extraRecipeLine {
        id
        amount
        description
        status
      }
    }
    bookings_transactions {
      id
      booking {
        id
        product {
          id
          size
          productType {
            id
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetBookingsTransactionsByOrderIdSubscription__
 *
 * To run a query within a React component, call `useGetBookingsTransactionsByOrderIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetBookingsTransactionsByOrderIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookingsTransactionsByOrderIdSubscription({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useGetBookingsTransactionsByOrderIdSubscription(baseOptions: Apollo.SubscriptionHookOptions<GetBookingsTransactionsByOrderIdSubscription, GetBookingsTransactionsByOrderIdSubscriptionVariables>) {
        return Apollo.useSubscription<GetBookingsTransactionsByOrderIdSubscription, GetBookingsTransactionsByOrderIdSubscriptionVariables>(GetBookingsTransactionsByOrderIdDocument, baseOptions);
      }
export type GetBookingsTransactionsByOrderIdSubscriptionHookResult = ReturnType<typeof useGetBookingsTransactionsByOrderIdSubscription>;
export type GetBookingsTransactionsByOrderIdSubscriptionResult = Apollo.SubscriptionResult<GetBookingsTransactionsByOrderIdSubscription>;
export const ObserveClosingDatesByDateRangeDocument = gql`
    subscription observeClosingDatesByDateRange($locationId: uuid!, $startDate: date!, $endDate: date!) {
  closingDates(where: {_and: [{closedDate: {_gte: $startDate}}, {closedDate: {_lte: $endDate}}, {locationId: {_eq: $locationId}}]}) {
    id
    closedDate
    description
    locationId
  }
}
    `;

/**
 * __useObserveClosingDatesByDateRangeSubscription__
 *
 * To run a query within a React component, call `useObserveClosingDatesByDateRangeSubscription` and pass it any options that fit your needs.
 * When your component renders, `useObserveClosingDatesByDateRangeSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useObserveClosingDatesByDateRangeSubscription({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useObserveClosingDatesByDateRangeSubscription(baseOptions: Apollo.SubscriptionHookOptions<ObserveClosingDatesByDateRangeSubscription, ObserveClosingDatesByDateRangeSubscriptionVariables>) {
        return Apollo.useSubscription<ObserveClosingDatesByDateRangeSubscription, ObserveClosingDatesByDateRangeSubscriptionVariables>(ObserveClosingDatesByDateRangeDocument, baseOptions);
      }
export type ObserveClosingDatesByDateRangeSubscriptionHookResult = ReturnType<typeof useObserveClosingDatesByDateRangeSubscription>;
export type ObserveClosingDatesByDateRangeSubscriptionResult = Apollo.SubscriptionResult<ObserveClosingDatesByDateRangeSubscription>;
export const ObserveLocationsDocument = gql`
    subscription observeLocations {
  locations {
    id
    created_at
    updated_at
    name
    description
    stealthMode
    messages {
      id
      created_at
      updated_at
      title
      message
    }
  }
}
    `;

/**
 * __useObserveLocationsSubscription__
 *
 * To run a query within a React component, call `useObserveLocationsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useObserveLocationsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useObserveLocationsSubscription({
 *   variables: {
 *   },
 * });
 */
export function useObserveLocationsSubscription(baseOptions?: Apollo.SubscriptionHookOptions<ObserveLocationsSubscription, ObserveLocationsSubscriptionVariables>) {
        return Apollo.useSubscription<ObserveLocationsSubscription, ObserveLocationsSubscriptionVariables>(ObserveLocationsDocument, baseOptions);
      }
export type ObserveLocationsSubscriptionHookResult = ReturnType<typeof useObserveLocationsSubscription>;
export type ObserveLocationsSubscriptionResult = Apollo.SubscriptionResult<ObserveLocationsSubscription>;
export const ObserveProductDocument = gql`
    subscription ObserveProduct($productId: uuid!, $date: date!) {
  products_by_pk(id: $productId) {
    id
    size
    bikesAvailable
    productType {
      id
      name
      description
      category
      productTypeModel {
        id
        name
      }
    }
    snapshotAvailabilities(where: {date: {_eq: $date}}) {
      id
      availableBikes
    }
    snapshotAvailabilities_aggregate(where: {date: {_eq: $date}}) {
      aggregate {
        sum {
          availableBikes
        }
      }
    }
    bookings_aggregate(where: {_and: [{order: {_and: [{startDate: {_lte: $date}}, {endDate: {_gte: $date}}]}}, {status: {_nin: ["cancelled", "returned"]}}]}) {
      aggregate {
        count
      }
    }
    bookings(where: {order: {_and: [{startDate: {_lte: $date}}, {endDate: {_gte: $date}}]}}, order_by: {order: {duration: asc, endDate: asc}}) {
      id
      status
      order {
        id
        customer {
          id
          name
        }
        startDate
        endDate
        duration
      }
      bookings_transactions(where: {transaction: {type: {_eq: "shipped"}}}, order_by: {updated_at: desc}) {
        id
        updated_at
      }
    }
  }
}
    `;

/**
 * __useObserveProductSubscription__
 *
 * To run a query within a React component, call `useObserveProductSubscription` and pass it any options that fit your needs.
 * When your component renders, `useObserveProductSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useObserveProductSubscription({
 *   variables: {
 *      productId: // value for 'productId'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useObserveProductSubscription(baseOptions: Apollo.SubscriptionHookOptions<ObserveProductSubscription, ObserveProductSubscriptionVariables>) {
        return Apollo.useSubscription<ObserveProductSubscription, ObserveProductSubscriptionVariables>(ObserveProductDocument, baseOptions);
      }
export type ObserveProductSubscriptionHookResult = ReturnType<typeof useObserveProductSubscription>;
export type ObserveProductSubscriptionResult = Apollo.SubscriptionResult<ObserveProductSubscription>;
export const ObserveProductTransactionResultDocument = gql`
    subscription observeProductTransactionResult($id: uuid!) {
  productTransactions_by_pk(id: $id) {
    result
  }
}
    `;

/**
 * __useObserveProductTransactionResultSubscription__
 *
 * To run a query within a React component, call `useObserveProductTransactionResultSubscription` and pass it any options that fit your needs.
 * When your component renders, `useObserveProductTransactionResultSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useObserveProductTransactionResultSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useObserveProductTransactionResultSubscription(baseOptions: Apollo.SubscriptionHookOptions<ObserveProductTransactionResultSubscription, ObserveProductTransactionResultSubscriptionVariables>) {
        return Apollo.useSubscription<ObserveProductTransactionResultSubscription, ObserveProductTransactionResultSubscriptionVariables>(ObserveProductTransactionResultDocument, baseOptions);
      }
export type ObserveProductTransactionResultSubscriptionHookResult = ReturnType<typeof useObserveProductTransactionResultSubscription>;
export type ObserveProductTransactionResultSubscriptionResult = Apollo.SubscriptionResult<ObserveProductTransactionResultSubscription>;
export const ObserveProductTypeAvailabilitiesOverviewDocument = gql`
    subscription ObserveProductTypeAvailabilitiesOverview($date: date!, $locationId: uuid!) {
  productTypes(order_by: {category: asc, name: asc}) {
    id
    name
    category
    products(order_by: {size: asc}, where: {locationId: {_eq: $locationId}}) {
      id
      size
      bikesAvailable
      snapshotAvailabilities(where: {date: {_eq: $date}}) {
        id
        availableBikes
      }
      bookings_aggregate(where: {_and: [{order: {_and: [{startDate: {_lte: $date}}, {endDate: {_gte: $date}}]}}, {status: {_nin: ["cancelled", "returned"]}}]}) {
        aggregate {
          count
        }
      }
      bookings(where: {order: {_and: [{startDate: {_lte: $date}}, {endDate: {_gte: $date}}]}}, order_by: {order: {duration: asc, endDate: asc}}) {
        id
        status
        riderName
        riderWeight
        includeInsurance
        includeProtection
        order {
          id
          customer {
            id
            name
          }
          startDate
          endDate
          duration
        }
        bookings_transactions(where: {transaction: {type: {_eq: "shipped"}}}, order_by: {updated_at: desc}) {
          id
          updated_at
        }
      }
    }
  }
}
    `;

/**
 * __useObserveProductTypeAvailabilitiesOverviewSubscription__
 *
 * To run a query within a React component, call `useObserveProductTypeAvailabilitiesOverviewSubscription` and pass it any options that fit your needs.
 * When your component renders, `useObserveProductTypeAvailabilitiesOverviewSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useObserveProductTypeAvailabilitiesOverviewSubscription({
 *   variables: {
 *      date: // value for 'date'
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useObserveProductTypeAvailabilitiesOverviewSubscription(baseOptions: Apollo.SubscriptionHookOptions<ObserveProductTypeAvailabilitiesOverviewSubscription, ObserveProductTypeAvailabilitiesOverviewSubscriptionVariables>) {
        return Apollo.useSubscription<ObserveProductTypeAvailabilitiesOverviewSubscription, ObserveProductTypeAvailabilitiesOverviewSubscriptionVariables>(ObserveProductTypeAvailabilitiesOverviewDocument, baseOptions);
      }
export type ObserveProductTypeAvailabilitiesOverviewSubscriptionHookResult = ReturnType<typeof useObserveProductTypeAvailabilitiesOverviewSubscription>;
export type ObserveProductTypeAvailabilitiesOverviewSubscriptionResult = Apollo.SubscriptionResult<ObserveProductTypeAvailabilitiesOverviewSubscription>;
export const ObserveProductTypesDocument = gql`
    subscription observeProductTypes($locationId: uuid!) {
  productTypes(order_by: [{name: asc}]) {
    id
    name
    description
    category
    image
    sortPosition
    forceInsurance
    productTypeModel {
      id
      name
    }
    productTypeModelId
    products(where: {locationId: {_eq: $locationId}}) {
      id
      size
      bikesAvailable
      description
      sortPosition
    }
    products_aggregate(where: {locationId: {_eq: $locationId}}) {
      aggregate {
        count
      }
    }
  }
}
    `;

/**
 * __useObserveProductTypesSubscription__
 *
 * To run a query within a React component, call `useObserveProductTypesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useObserveProductTypesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useObserveProductTypesSubscription({
 *   variables: {
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useObserveProductTypesSubscription(baseOptions: Apollo.SubscriptionHookOptions<ObserveProductTypesSubscription, ObserveProductTypesSubscriptionVariables>) {
        return Apollo.useSubscription<ObserveProductTypesSubscription, ObserveProductTypesSubscriptionVariables>(ObserveProductTypesDocument, baseOptions);
      }
export type ObserveProductTypesSubscriptionHookResult = ReturnType<typeof useObserveProductTypesSubscription>;
export type ObserveProductTypesSubscriptionResult = Apollo.SubscriptionResult<ObserveProductTypesSubscription>;
export const ObserveProductsByProductTypeDocument = gql`
    subscription observeProductsByProductType($productTypeId: uuid!, $locationId: uuid!) {
  products(where: {_and: [{productTypeId: {_eq: $productTypeId}}, {locationId: {_eq: $locationId}}]}, order_by: [{size: asc}]) {
    id
    locationId
    productPrices(order_by: [{minDuration: asc}]) {
      id
      productId
      basePricePerDay
      insuranceCostsPerDay
      protectionCostsPerDay
      minDuration
    }
    size
    description
    sortPosition
    bikesAvailable
    productTypeId
    productType {
      id
      name
      description
      sortPosition
      category
      productTypeModelId
      productTypeModel {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useObserveProductsByProductTypeSubscription__
 *
 * To run a query within a React component, call `useObserveProductsByProductTypeSubscription` and pass it any options that fit your needs.
 * When your component renders, `useObserveProductsByProductTypeSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useObserveProductsByProductTypeSubscription({
 *   variables: {
 *      productTypeId: // value for 'productTypeId'
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useObserveProductsByProductTypeSubscription(baseOptions: Apollo.SubscriptionHookOptions<ObserveProductsByProductTypeSubscription, ObserveProductsByProductTypeSubscriptionVariables>) {
        return Apollo.useSubscription<ObserveProductsByProductTypeSubscription, ObserveProductsByProductTypeSubscriptionVariables>(ObserveProductsByProductTypeDocument, baseOptions);
      }
export type ObserveProductsByProductTypeSubscriptionHookResult = ReturnType<typeof useObserveProductsByProductTypeSubscription>;
export type ObserveProductsByProductTypeSubscriptionResult = Apollo.SubscriptionResult<ObserveProductsByProductTypeSubscription>;
export const ObserveSeasonsDocument = gql`
    subscription observeSeasons($locationId: uuid!) {
  seasons(where: {locationId: {_eq: $locationId}}) {
    id
    created_at
    updated_at
    name
    startDate
    endDate
    locationId
    location {
      id
      name
    }
  }
}
    `;

/**
 * __useObserveSeasonsSubscription__
 *
 * To run a query within a React component, call `useObserveSeasonsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useObserveSeasonsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useObserveSeasonsSubscription({
 *   variables: {
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useObserveSeasonsSubscription(baseOptions: Apollo.SubscriptionHookOptions<ObserveSeasonsSubscription, ObserveSeasonsSubscriptionVariables>) {
        return Apollo.useSubscription<ObserveSeasonsSubscription, ObserveSeasonsSubscriptionVariables>(ObserveSeasonsDocument, baseOptions);
      }
export type ObserveSeasonsSubscriptionHookResult = ReturnType<typeof useObserveSeasonsSubscription>;
export type ObserveSeasonsSubscriptionResult = Apollo.SubscriptionResult<ObserveSeasonsSubscription>;

      export interface IntrospectionResultData {
        __schema: {
          types: {
            kind: string;
            name: string;
            possibleTypes: {
              name: string;
            }[];
          }[];
        };
      }
      const result: IntrospectionResultData = {
  "__schema": {
    "types": []
  }
};
      export default result;
    