import React, { ChangeEvent, ReactElement } from 'react';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle, FormControlLabel, TextField
} from '@material-ui/core';

type AlertModalProps = {
  alertContent: ReactElement,
  alertTitle?: string,
  onCancel: () => void,
  onAccept: (primaryAction: boolean, message: string) => void,
};

export default function ChangeEmailAlertModal({
  onCancel,
  onAccept,
  alertContent,
  alertTitle,
}: AlertModalProps) {
  const [includeExtraRecipeLines, setIncludeExtraRecipeLines] = React.useState(false);
  const [message, setMessage] = React.useState("");

  const handleMessageUpdate = async (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): Promise<void> => {
    setMessage(e.currentTarget.value);
  };

  return (
    <>
      <Dialog
        open={true}
        onClose={() => onCancel()}
      >
        {alertTitle && (
          <DialogTitle>{alertTitle}</DialogTitle>
        )}

        <DialogContent>
          <DialogContentText>
            {alertContent}
          </DialogContentText>

          <TextField
            label="Nachricht an den Kunden"
            fullWidth={true}
            value={message}
            onChange={handleMessageUpdate}
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={includeExtraRecipeLines}
                onChange={() => setIncludeExtraRecipeLines((checked) => !checked)}
                name="includeExtraRecipeLines"
              />
            }
            label="Freie Buchungszeilen im E-Mail inkludieren"
          />

        </DialogContent>
        <DialogActions>
          <Button onClick={() => onCancel()} color="default" variant={'contained'}>
            Abbrechen
          </Button>
          <Button onClick={() => onAccept(includeExtraRecipeLines, message)} color="primary" variant={'contained'} autoFocus>
            Email senden
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
