import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import { formatPrice } from '../helpers/price';
import { getBookingPrice, getOrderTotalString } from '../helpers/order';
import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useGlobalStyles } from '../styles/global';
import {
  useCancelBookingMutation,
  useGetBookingsTransactionsByOrderIdSubscription,
  useInsertBookingsTransactionMutation,
  useInsertExtraRecipeLinesTransactionMutation,
  useInsertTransactionMutation,
  useObserveOrderSubscription,
  usePayExtraRecipeLineMutation,
  useReturnBookingsMutation,
  useSetIncludeInsuranceMutation,
  useSetIncludeProtectionMutation,
  useShipBookingsMutation,
  useTriggerVerificationEmailMutationMutation,
  useTriggerConfirmationEmailMutationMutation,
  useTriggerCancellationEmailMutationMutation,
  useVerifyOrderMutation, useUpdateOrderUpdateDateMutation,
  useRequestProlongOrderMutation,
} from '../api/generated';
import Typography from '@material-ui/core/Typography';
import {
  formatDateRange,
  formatTime,
  getDurationString,
  getEndDate,
} from '../helpers/date';
import PrintIcon from '@material-ui/icons/Print';
import PlusIcon from '@material-ui/icons/AddCircle';
import WarningIcon from '@material-ui/icons/WarningRounded';
import MailRounded from '@material-ui/icons/MailRounded';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import VerifiedUser from '@material-ui/icons/VerifiedUser';
import Iframe from 'react-iframe';
import { BookingStatusEnum } from './BookingStatusIcon';
import BookingRows from './BookingRows';
import ExtraRecipes from './ExtraRecipes';
import Loader from './shared/Loader';
import CustomerCard from './customers/CustomerCard';
import AlertModal from './shared/AlertModal';
import CancellationAlertModal from './shared/CancellationAlertModal';
import ChangeEmailAlertModal from './shared/ChangeEmailAlertModal';
import EditIcon from '@material-ui/icons/Edit';
import OrderProlongForm from './OrderProlongForm';

const REACT_APP_BOOKING_APP_HOST = process.env.REACT_APP_BOOKING_APP_HOST;
const DISABLE_PRINTING: boolean = 'true' === process.env.REACT_APP_TENANT_CONFIG_DISABLE_AUTOMATIC_PRINT;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      paddingBottom: 0,
      marginTop: theme.spacing(2),
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
    table: {
      marginBottom: '2em',
    },
    totalRow: {
      '& *': {
        fontSize: '1.2em',
      },
    },
    priceCol: {
      textAlign: 'right',
      whiteSpace: 'nowrap',
    },
    content: {
      flexGrow: 1,
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
    customerData: {
      flexGrow: 1,
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
      paddingTop: theme.spacing(2),
    },
    orderActions: {
      textAlign: 'center',
      marginTop: '1em',
      marginBottom: '1em',
    },
    paidAction: {
      marginTop: '1em',
      '& .MuiFormControlLabel-label.MuiTypography-body1': {
        padding: 0,
        margin: 0,
      },
    },
    printContainer: {
      paddingTop: '1em',
      paddingBottom: '1em',
    },
    drawerFooter: {
      position: 'sticky',
      zIndex: 100,
      width: '100%',
      marginTop: 'calc(5% + 60px)',
      bottom: 0,
      '& h2': {
        paddingBottom: 0,
        marginBottom: 0,
        marginTop: 0,
      },
    },
    printIframe: {
      display: 'none',
    },
    bookingIframe: {
      width: '100%',
      height: '700px',
    },
    bookingModal: {},
    bookingModalContent: {
      minHeight: '800px',
    },
    orderDateRange: {
      '& *': {
        textAlign: 'right',
      },
    },
    contentWarning: {
      paddingTop: '0.4em',
    },
    addBillingLineIcon: {
      marginRight: '1em',
    },
  }),
);

type OrderProps = {
  orderId: string;
};

export default function Order({ orderId }: OrderProps) {
  // Styles
  const classes = {
    ...useGlobalStyles(),
    ...useStyles(),
  };

  // Local states
  const [openBookingModal, setOpenBookingModal] = React.useState(false);
  const [openCancellationModal, setOpenCancellationModal] = React.useState(false);
  const [openChangeMailModal, setOpenChangeMailModal] = React.useState(false);
  const [openPaymentModal, setOpenPaymentModal] = React.useState(false);
  const [showOrderProlongForm, setShowOrderProlongForm] = React.useState(false);
  const [selectedBookings, setSelectedBookings] = React.useState<string[]>([]);
  const [
    selectedExtraRecipeLines,
    setSelectedExtraRecipeLines,
  ] = React.useState<string[]>([]);
  const [activeBookingId, setActiveBookingId] = React.useState<string | undefined>();

  // GraphQL
  const [
    cancelBookingMutation,
    { loading: cancelBookingLoading, error: cancelBookingError },
  ] = useCancelBookingMutation();

  const {
    data: orderData,
    loading: orderLoading,
    error: orderError,
  } = useObserveOrderSubscription({
    variables: {
      id: orderId,
    },
    fetchPolicy: 'no-cache',
  });

  const {
    data: transactionsData,
    loading: transactionsLoading,
    error: transactionsError,
  } = useGetBookingsTransactionsByOrderIdSubscription({
    variables: {
      orderId: orderId,
    },
  });

  const [
    insertBookingsTransactionMutation,
    {
      loading: insertBookingsTransactionLoading,
      error: insertBookingsTransactionError,
    },
  ] = useInsertBookingsTransactionMutation();

  const [
    shipBookingsMutation,
    { loading: shipBookingsLoading, error: shipBookingsError },
  ] = useShipBookingsMutation();

  const [
    returnBookingsMutation,
    { loading: returnBookingsLoading, error: returnBookingsError },
  ] = useReturnBookingsMutation();

  const [
    setIncludeInsurance,
    { loading: setIncludeInsuranceLoading, error: setIncludeInsuranceError },
  ] = useSetIncludeInsuranceMutation();

  const [
    setIncludeProtection,
    { loading: setIncludeProtectionLoading, error: setIncludeProtectionError },
  ] = useSetIncludeProtectionMutation();

  const [
    insertTransaction,
    { loading: insertTransactionLoading, error: insertTransactionError },
  ] = useInsertTransactionMutation();

  const [
    insertExtraRecipeLinesTransactionMutation,
    {
      loading: insertExtraRecipeLinesTransactionLoading,
      error: insertExtraRecipeLinesTransactionError,
    },
  ] = useInsertExtraRecipeLinesTransactionMutation();

  const [
    payExtraRecipeLine,
    { loading: payExtraRecipeLineLoading, error: payExtraRecipeLineError },
  ] = usePayExtraRecipeLineMutation();

  const [
    verifyOrderMutation,
    { loading: verifyOrderLoading, error: verifyOrderError },
  ] = useVerifyOrderMutation();

  const [
    triggerVerificationEmailMutation,
    { loading: triggerVerificationEmailLoading, error: triggerVerificationEmailError },
  ] = useTriggerVerificationEmailMutationMutation();

  const [
    triggerConfirmationEmailMutation,
    { loading: triggerConfirmationEmailLoading, error: triggerConfirmationEmailError },
  ] = useTriggerConfirmationEmailMutationMutation();

  const [
    triggerCancellationEmailMutation,
    { loading: triggerCancellationEmailLoading, error: triggerCancellationEmailError },
  ] = useTriggerCancellationEmailMutationMutation();

  const [
    triggerUpdateOrderUpdateDateMutation,
    { loading: triggerUpdateOrderUpdateDateLoading, error: triggerUpdateOrderUpdateDateError },
  ] = useUpdateOrderUpdateDateMutation();

  const [
    requestProlongOrderMutation,
    { loading: requestProlongOrderMutationLoading, error: requestProlongOrderMutationError },
  ] = useRequestProlongOrderMutation();

  // GraphQL data
  if (orderLoading) return <Loader />;
  if (orderError || !orderData?.orders_by_pk) {
    console.log(
      `Order could not be loaded. Error: ${JSON.stringify(orderError)}`,
    );

    return <p>Error :(</p>;
  }

  const order = orderData.orders_by_pk;
  const orderEndDate = getEndDate(
    order?.startDate,
    parseFloat(order.duration ?? '0'),
  );
  const transactions = transactionsData?.transactions;
  const bookingLocationId = order.bookings[0].product.location.id;
  const hasUnsentChanges = new Date(order.updated_at) > new Date(order.confirmation_sent_at);
  const isVerified = order?.verified ?? false;
  const hasAllBookingsCancelled = order.bookings
    .filter(b => b.status === 'cancelled').length === order.bookings.length;

  // Handlers
  // // Cancellations
  const handleBookingCancellation = async (bookingId: string, sendEmail: boolean) => {
    setActiveBookingId(undefined);
    setOpenCancellationModal(false);

    try {
      await cancelBookingMutation({
        variables: {
          id: bookingId,
        },
      });

      await triggerUpdateOrderUpdateDateMutation({ variables: { id: order.id } });

      if (sendEmail) {
        try {
          await triggerCancellationEmailMutation({
            variables: {
              id: order?.id,
            },
          });
        } catch (e) {
          console.log(`Error when trying to send confirmation email: ${JSON.stringify(e)}`);
        }
      }
    } catch (e) {
      console.log(`Error sending cancellation: ${JSON.stringify(e)}`);
    }

    setSelectedBookings([]);
  };

  const handleBookingCancellationConfirmation = (bookingId: string) => {
    setActiveBookingId(bookingId);
    setOpenCancellationModal(true);
  };

  const handleChangeMailConfirmation = () => {
    console.log('set change mail modal to true')
    setOpenChangeMailModal(true);
  };

  const handleCloseBookingModal = () => {
    setOpenBookingModal(false);
  };

  const handleCloseChangeMailModal = () => {
    setOpenChangeMailModal(false);
  };

  // // Add reservation
  const handleAddReservation = (): void => {
    // Open app with admin params
    setOpenBookingModal(true);
  };

  const handleSelectBooking = (bookingId: string, checked: boolean): void => {
    let bookings = selectedBookings;

    if (checked) {
      bookings.push(bookingId);
    } else {
      bookings = bookings.filter(booking => booking !== bookingId);
    }

    setSelectedBookings([...bookings]);
  };

  const handleSelectAll = () => {
    handleSelectAllBookings();
    handleSelectAllExtraRecipeLines();
  };

  const handleSelectAllBookings = () => {
    const validBookings =
      order?.bookings?.filter(b => b.status === BookingStatusEnum.NEW) ?? [];

    for (const booking of validBookings) {
      handleSelectBooking(booking.id, true);
    }
  };

  const handleSelectAllExtraRecipeLines = () => {
    const validLines =
      order?.extraRecipeLines
        ?.filter(l => l.status === 'new')
        ?.map(l => l.id) ?? [];

    setSelectedExtraRecipeLines(validLines);
  };

  const handleSelectExtraRecipeLine = async (id: string, selected: boolean): Promise<void> => {
    let lines = [...selectedExtraRecipeLines];

    if (selected && !lines.includes(id)) {
      lines.push(id);
    }

    if (!selected) {
      lines = lines.filter(line => line !== id);
    }

    setSelectedExtraRecipeLines([...lines]);

    await triggerUpdateOrderUpdateDateMutation({ variables: { id: order.id } });
  };

  const createTransaction = async (status = 'shipped'): Promise<string> => {
    // Create a transaction for the bookings
    const { data } = await insertTransaction({
      variables: {
        type: status,
      },
    });

    const transaction = data?.insert_transactions?.returning.shift();

    return transaction?.id;
  };

  const handlePay = async () => {
    const bookings = selectedBookings;
    const extraRecipeLines = selectedExtraRecipeLines;
    const transactionId = await createTransaction();

    if (bookings?.length > 0) {
      await handleShipBookings(bookings, transactionId);
    }

    if (extraRecipeLines?.length > 0) {
      await handlePayExtraRecipeLines(extraRecipeLines, transactionId);
    }

    // Print transaction
    if (!DISABLE_PRINTING) {
      handlePrintTransaction(transactionId);
    }

    setOpenPaymentModal(false);
    setSelectedBookings([]);
    setSelectedExtraRecipeLines([]);
  };

  const handleShipBookings = async (
    bookingIds: string[],
    transactionId: string,
  ) => {
    try {
      // Create a transaction for the bookings
      await insertBookingsTransactionMutation({
        variables: {
          bookings: bookingIds.map(b => ({
            bookingId: b,
            transactionId: transactionId,
          })),
        },
      });

      // Update bookings
      await shipBookingsMutation({
        variables: {
          ids: bookingIds,
        },
      });
    } catch (e) {
      console.log(`Error when trying to ship bookings: ${JSON.stringify(e)}`);
    }
  };

  const handlePayExtraRecipeLines = async (
    extraRecipeLineIds: string[],
    transactionId: string,
  ) => {
    try {
      // Create a transaction for the bookings
      await insertExtraRecipeLinesTransactionMutation({
        variables: {
          lines: extraRecipeLineIds.map(lineId => ({
            extraRecipeLineId: lineId,
            transactionId: transactionId,
          })),
        },
      });

      // Update extraRecipeLine status to paid.
      await payExtraRecipeLine({
        variables: {
          ids: extraRecipeLineIds,
        },
      });
    } catch (e) {
      console.log(`Error when trying to ship bookings: ${JSON.stringify(e)}`);
    }
  };

  const handlePrintTransaction = (transactionId: string): void => {
    const printIFrame: HTMLIFrameElement | null = document.getElementById(
      'print-transaction',
    ) as HTMLIFrameElement;

    if (printIFrame) {
      printIFrame.src = '/print-transaction/' + transactionId;

      window.setTimeout(() => printIFrame?.contentWindow?.print(), 2000);
    }
  };

  const handlePrintCustomerRecipe = (transactionId: string): void => {
    const printIFrame: HTMLIFrameElement | null = document.getElementById(
      'print-transaction',
    ) as HTMLIFrameElement;

    if (printIFrame) {
      printIFrame.src = '/print-customer-recipe/' + transactionId;

      window.setTimeout(() => printIFrame?.contentWindow?.print(), 2000);
    }
  };

  const handleReturnBookings = async (bookingIds: string[]) => {
    try {
      await returnBookingsMutation({
        variables: {
          ids: bookingIds,
        },
      });
    } catch (e) {
      console.log(`Error when trying to ship bookings: ${JSON.stringify(e)}`);
    }

    setSelectedBookings([]);
  };

  const handleToggleInsurance = async (bookingId: string, selected: boolean) => {
    await setIncludeInsurance({
      variables: {
        id: bookingId,
        value: selected,
      },
    });

    await triggerUpdateOrderUpdateDateMutation({ variables: { id: order.id } });
  };

  const handleChangeCustomer = async () => {
    await triggerUpdateOrderUpdateDateMutation({ variables: { id: order.id } });
  }

  const handleToggleProtection = async (bookingId: string, selected: boolean) => {
    await setIncludeProtection({
      variables: {
        id: bookingId,
        value: selected,
      },
    });

    await triggerUpdateOrderUpdateDateMutation({ variables: { id: order.id } });
  };

  const getSelectedTotal = (): string => {
    let total = 0.0;

    for (const booking of selectedBookings) {
      const bookingData = order?.bookings?.find(b => b.id === booking);
      const price = getBookingPrice(bookingData);
      total += price;
    }

    for (const lineId of selectedExtraRecipeLines) {
      const line = order?.extraRecipeLines?.find(l => l.id === lineId);

      total += line?.amount ?? 0.0;
    }

    return formatPrice(total);
  };

  const isAnyItemSelected: boolean =
    selectedBookings.length > 0 || selectedExtraRecipeLines.length > 0;

  const handleCloseCancellationModal = () => {
    setOpenCancellationModal(false)
  };
  const handleOpenPaymentModal = () => {
    setOpenPaymentModal(true)
  };

  const handleManuallyVerifyOrder = async () => {
    try {
      await verifyOrderMutation({
        variables: {
          id: order?.id,
        },
      });
    } catch (e) {
      console.log(`Error when trying to verify order: ${JSON.stringify(e)}`);
    }
  };

  const handleSendVerificationEmail = async () => {
    try {
      await triggerVerificationEmailMutation({
        variables: {
          id: order?.id,
        },
      });
    } catch (e) {
      console.log(`Error when trying to send verification email: ${JSON.stringify(e)}`);
    }
  }

  const handleSendConfirmationEmail = async () => {
    try {
      await triggerConfirmationEmailMutation({
        variables: {
          id: order?.id,
          isChange: false,
        },
      });
    } catch (e) {
      console.log(`Error when trying to send confirmation email: ${JSON.stringify(e)}`);
    }
  }

  const handleSendChangeConfirmationEmail = async (includeExtraData: boolean, message: string) => {
    try {
      await triggerConfirmationEmailMutation({
        variables: {
          id: order?.id,
          isChange: true,
          // @todo: Add includeExtraData and message to booking-service controller and service
          includeExtraData: includeExtraData,
          message: message
        },
      });
    } catch (e) {
      console.log(`Error when trying to send confirmation email: ${JSON.stringify(e)}`);
    }

    setOpenChangeMailModal(false);
  }

  const handleShowOrderDuration = () => {
    setShowOrderProlongForm(true);
  }

  const handleOrderProlongAccept = async (endDate: string) => {
    // Submit prolongOrder mutation
    try {
      await requestProlongOrderMutation({
        variables: {
          orderId: order.id,
          newEndDate: endDate,
        }
      });
    } catch (error) {
      console.error('Order could not be prolonged', JSON.stringify(error))
    }

    setShowOrderProlongForm(false);
  }

  const handleOrderProlongCancel = () => {
    setShowOrderProlongForm(false);
  }

  return (
    <>
      {showOrderProlongForm && (
        <OrderProlongForm
          onAccept={handleOrderProlongAccept}
          onCancel={handleOrderProlongCancel}
          order={order}
        />
      )}
      {(openCancellationModal && activeBookingId) && (
        <CancellationAlertModal
          alertTitle={"Buchung Stornieren?"}
          alertContent={
            <>Dieser Vorgang kann nicht rückgängig gemacht werden!</>
          }
          entityId={activeBookingId}
          onCancel={handleCloseCancellationModal}
          onAccept={handleBookingCancellation}
        />
      )}

      {openChangeMailModal && (
        <ChangeEmailAlertModal
          alertTitle={"E-Mail mit angepasster Buchung an Kunden senden?"}
          alertContent={
            <>Dieser Vorgang kann nicht rückgängig gemacht werden!</>
          }
          onCancel={handleCloseChangeMailModal}
          onAccept={handleSendChangeConfirmationEmail}
        />
      )}

      {/* Pay now confirmation modal */}
      {openPaymentModal && (
        <AlertModal
          alertTitle={"Zahlungseingang bestätigen"}
          alertContent={
            <>
              Jetzt <strong>{getSelectedTotal()}</strong> mittels
              Registrierkassa kassieren und bestätigen.
            </>
          }
          onCancel={() => setOpenPaymentModal(false)}
          onAccept={handlePay}
        />
      )}

      {/** iframe to print transactions */}
      <Iframe
        id={"print-transaction"}
        frameBorder={0}
        display={"none"}
        className={classes.printIframe}
        title={"Print transaction"}
        url={"data:text/html, <h1>Error: No Transaction loaded.</h1>"}
      />

      {/** Booking component */}
      <Box className={classes.root}>
        {/** Component header */}
        <Grid container className={classes.content}>
          <Grid item xs={6}>
            <Typography variant="h2" gutterBottom>
              Reservierung {order?.customer.firstname} {order?.customer.name}
              {isVerified && (
                <> (<CheckCircleIcon color={'primary'} /> Verifiziert)</>
              )}
            </Typography>
          </Grid>
          <Grid item xs={6} className={classes.orderDateRange}>
            <Typography variant="h2" gutterBottom>
              {formatDateRange(order?.startDate, orderEndDate)}
              &nbsp;({getDurationString(parseFloat(order?.duration ?? "0"))})

              {!hasAllBookingsCancelled && (
                <IconButton onClick={handleShowOrderDuration} color="secondary">
                  <EditIcon />
                </IconButton>
              )}
            </Typography>
          </Grid>
        </Grid>

        <Divider />

        <Grid container>
          <Grid item xs={8}>
            {order?.customer && <CustomerCard customer={order.customer} onSave={handleChangeCustomer} />}
          </Grid>
          <Grid item xs={4}>
            {/* @todo: Move to component */}
            <Box className={classes.root}>
              <Card>
                <CardContent>
                  <List>
                    <ListItem>
                      <Button
                        fullWidth={true}
                        variant={"outlined"}
                        color={"secondary"}
                        startIcon={<MailRounded />}
                        onClick={() => handleSendConfirmationEmail()}
                      >
                        Bestätigung senden
                      </Button>
                    </ListItem>

                    {hasUnsentChanges && (
                      <ListItem>
                        <Button
                          fullWidth={true}
                          variant={"contained"}
                          color={"secondary"}
                          startIcon={<MailRounded />}
                          endIcon={<WarningIcon />}
                          disabled={
                            triggerConfirmationEmailLoading
                          }
                          onClick={handleChangeMailConfirmation}
                        >
                          Änderungen senden
                        </Button>
                      </ListItem>
                    )}

                    {(!isVerified && !hasAllBookingsCancelled) && (
                      <>
                        <ListItem>
                          <Button
                            fullWidth={true}
                            variant={"outlined"}
                            color={"secondary"}
                            startIcon={<MailRounded />}
                            disabled={triggerVerificationEmailLoading}
                            onClick={() => handleSendVerificationEmail()}
                          >
                            Verifizierung senden
                          </Button>
                        </ListItem>
                        <ListItem>
                          <Button
                            fullWidth={true}
                            variant={"outlined"}
                            color={"secondary"}
                            startIcon={<VerifiedUser />}
                            disabled={
                              verifyOrderLoading
                            }
                            onClick={() => handleManuallyVerifyOrder()}
                          >
                            Manuell verifizieren
                          </Button>
                        </ListItem>
                      </>
                    )}

                  </List>
                </CardContent>
              </Card>
            </Box>
          </Grid>
        </Grid>

        {transactions?.length ?? false ? (
          <>
            <Typography variant="h3" gutterBottom className={classes.content}>
              Belege
            </Typography>

            <Divider />

            <Box className={classes.content}>
              <TableContainer className={classes.table}>
                <Table stickyHeader size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ width: "10%" }}>Uhrzeit</TableCell>
                      <TableCell>Artikel</TableCell>
                      <TableCell style={{ width: "10%" }}>Ausgabe</TableCell>
                      <TableCell style={{ width: "10%" }}>Beleg</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {transactions?.map(transaction => {
                      return (
                        <TableRow key={transaction.id}>
                          <TableCell>
                            {formatTime(transaction.created_at)}
                          </TableCell>
                          <TableCell>
                            {transaction.bookings_transactions
                              ?.map(t => {
                                return `${t.booking.product.productType.name} (${t.booking.product.size})`;
                              })
                              .join(", ")}

                            {transaction.bookings_transactions.length > 0 && (
                              <>
                                <br />
                              </>
                            )}

                            {transaction.extraRecipeLinesTransactions
                              ?.map(t => {
                                return `${
                                  t.extraRecipeLine.description
                                } (${formatPrice(t.extraRecipeLine.amount)})`;
                              })
                              .join(", ")}
                          </TableCell>
                          <TableCell align={"center"}>
                            <IconButton
                              aria-label="print-shipping-recipe"
                              color="primary"
                              onClick={() =>
                                handlePrintTransaction(transaction.id)
                              }
                              style={{ padding: 0 }}
                            >
                              <PrintIcon color={"primary"} />
                            </IconButton>
                          </TableCell>
                          <TableCell align={"center"}>
                            <IconButton
                              aria-label="print-customer-recipe"
                              color="primary"
                              onClick={() =>
                                handlePrintCustomerRecipe(transaction.id)
                              }
                              style={{ padding: 0 }}
                            >
                              <PrintIcon color={"primary"} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </>
        ) : (
          <></>
        )}

        <Dialog
          fullWidth={true}
          maxWidth={"xs"}
          open={openBookingModal}
          onClose={handleCloseBookingModal}
          className={classes.bookingModal}
        >
          <DialogTitle id="max-width-dialog-title">
            Zusätzlichen Artikel reservieren
          </DialogTitle>
          <DialogContent className={classes.bookingModalContent}>
            <iframe
              src={`${REACT_APP_BOOKING_APP_HOST}?kiosk=1&orderId=${order?.id}&orderDuration=${order?.duration}&orderStartDate=${order?.startDate}&orderLocationId=${bookingLocationId}`}
              className={classes.bookingIframe}
              frameBorder={"0"}
              title={"Admin Booking"}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseBookingModal} color="primary">
              Beenden
            </Button>
          </DialogActions>
        </Dialog>

        {/**
         Bookings Component
         */}

        <Typography variant="h3" gutterBottom className={classes.content}>
          Reservierungen
          <Tooltip title="Artikel hinzufügen">
            <Button
              color={"primary"}
              onClick={handleAddReservation}
              aria-label="Add reservation to booking"
            >
              <PlusIcon color={"primary"} />
            </Button>
          </Tooltip>
        </Typography>

        <Divider />

        <Box className={classes.content}>
          <TableContainer className={classes.table}>
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: "10%" }}>Status</TableCell>
                  <TableCell>Artikel</TableCell>
                  <TableCell align={"center"}>Vers.</TableCell>
                  <TableCell align={"center"}>Schutz</TableCell>
                  <TableCell align={"center"}>Aktionen</TableCell>
                  <TableCell className={classes.priceCol}>Preis</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {/* TODO: Group by riderName? */}

                <BookingRows
                  bookings={order?.bookings}
                  selectedBookings={selectedBookings}
                  onSelectBooking={handleSelectBooking}
                  onCancelBooking={handleBookingCancellationConfirmation}
                  onReturnBooking={handleReturnBookings}
                  onChangeInsurance={handleToggleInsurance}
                  onChangeProtection={handleToggleProtection}
                  orderEndDate={orderEndDate}
                />
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        <ExtraRecipes
          extraRecipeLines={order?.extraRecipeLines}
          selectedLines={selectedExtraRecipeLines}
          orderId={order?.id ?? ""}
          onSelectLine={handleSelectExtraRecipeLine}
        />

        <Box className={classes.content}>
          <TableContainer className={classes.table}>
            <Table stickyHeader size="small">
              <TableBody>
                <TableRow className={classes.totalRow}>
                  <TableCell colSpan={4}>
                    <strong>Summe</strong>
                  </TableCell>
                  <TableCell className={classes.priceCol} colSpan={2}>
                    <strong>{getOrderTotalString(order)}</strong>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        <Paper elevation={5} className={classes.drawerFooter}>
          <Box className={classes.printContainer} bgcolor={"warning.main"}>
            {!isAnyItemSelected ? (
              <>
                <Grid container className={classes.content}>
                  <Grid item xs={6} className={classes.paidAction}>
                    <Typography
                      variant="body1"
                      className={classes.contentWarning}
                    >
                      Keine Produkte für die Ausgabe ausgewählt!
                    </Typography>
                  </Grid>
                  <Grid item xs={6} className={classes.paidAction}>
                    <Button
                      color={"secondary"}
                      variant={"contained"}
                      fullWidth={true}
                      onClick={handleSelectAll}
                    >
                      Alle auswählen
                    </Button>
                  </Grid>
                </Grid>
              </>
            ) : (
              <>
                <Grid container className={classes.content}>
                  <Grid item xs={12} className={classes.paidAction}>
                    <Button
                      variant="contained"
                      color={"primary"}
                      fullWidth={true}
                      onClick={handleOpenPaymentModal}
                    >
                      Ausgeben{!DISABLE_PRINTING ? <> & Drucken</> : <></>}
                    </Button>
                  </Grid>
                </Grid>
              </>
            )}
          </Box>
        </Paper>
      </Box>
    </>
  );
}
